import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  startup: null,
  setInvitePassed: ['invitePassed'],
  setIsOnboarded: ['isOnboarded'],
  setLoading: ['loading'],
  setLoaded: ['loaded'],
  setAppSettings: ['value'],
});

export const AppTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  loading: false,
  loaded: false,
  invitePassed: false,
  settings: {
    visitCreated: false,
    visitMaster: false,
    newProgramSubmitted: false,
  },
};

/* ------- Selectors --------- */
export const AppSelectors = {
  selectLoading: (state) => state.app.loading,
  selectLoaded: (state) => state.app.loaded,
  selectInvitePassed: (state) => state.app.invitePassed,
  selectIsFirstCreate: (state) => state.app.settings.visitCreated,
  selectIstMasterVisited: (state) => state.app.settings.visitMaster,
  selectIsNewProgramSubmitted: (state) => state.app.settings.newProgramSubmitted,
};

/* -------- Reducers ---------- */
export const setLoading = (state, { loading }) => ({
  ...state,
  loading,
});

export const setLoaded = (state, { loaded }) => ({
  ...state,
  loaded,
});

export const setInvitePassed = (state, { invitePassed }) => ({
  ...state,
  invitePassed,
});

export const setSettings = (state, { value }) => ({
  ...state,
  settings: {
    ...state.settings,
    ...value,
  },
});

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADING]: setLoading,
  [Types.SET_LOADED]: setLoaded,
  [Types.SET_INVITE_PASSED]: setInvitePassed,
  [Types.SET_APP_SETTINGS]: setSettings,
});
