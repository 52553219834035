import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import styled from 'styled-components';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import * as Yup from 'yup';
import { Box, Title3, Button, BInput, Body2 } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';
import { InputField, SelectField } from 'components/form';
import { SubscriptionButtons } from 'components/others';
import COUNTRIES from 'constants/countries.json';
import { colors } from 'styles/theme';
import { getErrors } from 'utils/err';

import AmexCard from 'assets/cards/amex.svg';
import VisaCard from 'assets/cards/visa.svg';
import UnionPayCard from 'assets/cards/unionpay.svg';
import MasterCard from 'assets/cards/mastercard.svg';
import CvcCard from 'assets/cards/cvc.svg';

const validationSchema = Yup.object().shape({
  price: Yup.number(),
  country: Yup.string(),
  name: Yup.string().when(['price'], (price, schema) =>
    price > 0 ? schema.required('Please enter name on card') : schema
  ),
  zip: Yup.string().when(['price'], (price, schema) =>
    price > 0 ? schema.required('ZIP is required') : schema
  ),
});

const INITIAL_VALUES = {
  country: 'US',
  name: '',
  zip: '',
};

const Image = styled.img`
  width: 36px;
  height: 20px;
  margin-left: 5px;
  border-radius: 2px;

  @media (max-width: 768px) {
    width: 16px;
  }
`;

const StripeWrapper = styled(Box)`
  .StripeElement {
    border: 1px solid ${colors.contrastText};
    padding: 12px;
    background-color: ${(props) =>
      props.disabled ? colors.text : colors.clear};
  }

  .StripeElement--invalid {
    border: 1px solid ${colors.red};
  }
`;

function StripeForm({ onSubmit, setAction, price, buttonText }) {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const disabled = !price;
  const cardOptions = {
    disabled: disabled,
    style: {
      base: {
        fontSize: '18px',
        color: colors.contrastText,
        backgroundColor: 'transparent',
        '::placeholder': {
          color: colors.text,
          opacity: 0.8,
        },
        ':-webkit-autofill': {
          color: colors.contrastText,
          backgroundColor: 'transparent',
        },
      },
      invalid: {
        color: colors.red,
        backgroundColor: 'transparent',
        ':-webkit-autofill': {
          color: colors.red,
          backgroundColor: 'transparent',
        },
      },
    },
  };

  const submit = async (values, actions) => {
    try {
      actions.setSubmitting(true);
      if (price) {
        const cardNumberElement = elements.getElement(CardNumberElement);

        const { token, error } = await stripe.createToken(cardNumberElement, {
          name: values.name,
          country: values.country,
          address_zip: values.zip,
        });
        if (error) throw error;
        await onSubmit(token.id);
      } else {
        await onSubmit();
      }
    } catch (e) {
      console.error(e);
      setError(getErrors(e).message || 'Purchase failed');
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ ...INITIAL_VALUES, price: price || 0 }}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Form>
            <Title3 color="contrastText" mt={[0, 6]} mb={1}>
              Card Information
            </Title3>
            <StripeWrapper disabled={disabled}>
              <Box position="relative">
                <CardNumberElement options={cardOptions} />
                <Box position="absolute" right={3} top={3}>
                  <Image src={VisaCard} />
                  <Image src={MasterCard} />
                  <Image src={AmexCard} />
                  <Image src={UnionPayCard} />
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box flex={1}>
                  <CardExpiryElement options={cardOptions} />
                </Box>
                <Box flex={1} position="relative">
                  <CardCvcElement options={cardOptions} />
                  <Box position="absolute" right={3} top={3}>
                    <Image src={CvcCard} />
                  </Box>
                </Box>
              </Box>
            </StripeWrapper>

            <Title3 color="contrastText" mt={[4, 8]} mb={1}>
              Name on card
            </Title3>
            <Field
              component={InputField}
              name="name"
              InputComponent={BInput}
              color="contrastText"
              disabled={disabled}
            />

            <Title3 color="contrastText" mt={[4, 8]} mb={1}>
              Country or region
            </Title3>
            <Field
              component={SelectField}
              name="country"
              options={COUNTRIES}
              label="Country"
              color="contrastText"
              disabled={disabled}
              width={1}
            />
            <Field
              component={InputField}
              name="zip"
              label="ZIP"
              InputComponent={BInput}
              color="contrastText"
              disabled={disabled}
            />

            {error && (
              <Body2 color="red" mt={3}>
                {error}
              </Body2>
            )}

            {buttonText ? (
              <Button type="submit" variant="contrast" width={1} mt={9}>
                {buttonText}
              </Button>
            ) : (
              <SubscriptionButtons
                isSubmitting={isSubmitting}
                setAction={setAction}
                handleSubmit={handleSubmit}
              />
            )}

            {isSubmitting && (
              <LoadingContainer>
                <DefaultLoader variant="secondary" />
              </LoadingContainer>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default StripeForm;
