import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { colors } from 'styles/theme';

const BASE_SIZE = 64;

const motion = props => keyframes`
  0% {
    transform: scale(1);
    background: ${props.color};
  }
  25% {
    transform: scale(1);
    background: ${props.color};
  }
  50% {
    transform: scale(1.6);
    background: ${props.highlighted};
  }
  75% {
    transform: scale(1);
    background: ${props.color};
  }
  100% {
    transform: scale(1);
    background: ${props.color};
  }
`;

const DefaultSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: ${p => `${p.size}px`};
  height: ${p => `${p.size}px`};

  div {
    position: absolute;
    width: ${p => p.scale * 6}px;
    height: ${p => p.scale * 6}px;
    background: #fff;
    border-radius: 50%;
    animation: ${p => motion(p)} 1.2s linear infinite;
  }
  div:nth-child(1) {
    animation-delay: 0s;
    top: 46.25%;
    left: 82.5%;
  }
  div:nth-child(2) {
    animation-delay: -0.1s;
    top: 27.5%;
    left: 77.5%;
  }
  div:nth-child(3) {
    animation-delay: -0.2s;
    top: 13.75%;
    left: 65%;
  }
  div:nth-child(4) {
    animation-delay: -0.3s;
    top: 8.75%;
    left: 46.25%;
  }
  div:nth-child(5) {
    animation-delay: -0.4s;
    top: 13.75%;
    left: 27.5%;
  }
  div:nth-child(6) {
    animation-delay: -0.5s;
    top: 27.5%;
    left: 13.75%;
  }
  div:nth-child(7) {
    animation-delay: -0.6s;
    top: 46.25%;
    left: 8.75%;
  }
  div:nth-child(8) {
    animation-delay: -0.7s;
    top: 65%;
    left: 13.75%;
  }
  div:nth-child(9) {
    animation-delay: -0.8s;
    top: 77.5%;
    left: 27.5%;
  }
  div:nth-child(10) {
    animation-delay: -0.9s;
    top: 82.5%;
    left: 46.25%;
  }
  div:nth-child(11) {
    animation-delay: -1s;
    top: 77.5%;
    left: 65%;
  }
  div:nth-child(12) {
    animation-delay: -1.1s;
    top: 65%;
    left: 77.5%;
  }
`;

const DefaultLoader = ({ variant, size, sizeUnit }) => {
  const scale = size / BASE_SIZE;

  const mainColor = {
    color: colors.main,
    highlighted: colors.text
  };
  const disabledColor = {
    color: colors.white,
    highlighted: colors.contrastText
  };

  const colorVariant = variant === 'primary' ? mainColor : disabledColor;

  return (
    <DefaultSpinner
      scale={scale}
      {...colorVariant}
      size={size}
      sizeUnit={sizeUnit}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </DefaultSpinner>
  );
};

DefaultLoader.defaultProps = {
  variant: 'primary',
  size: 100,
  sizeUnit: 'px'
};

DefaultLoader.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.number,
  sizeUnit: PropTypes.string
};

export default DefaultLoader;
