import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AppLayout } from './containers/layout';

import Home from './pages/Home';
import CreateProgram from './pages/Create';
import EditProgram from './pages/Edit';
import Profile from './pages/Profile';
import Subscription from './pages/Subscription';
import PromoteProgram from './pages/PromoteProgram';
import ProgramShare from './pages/ProgramShare';
import Settings from './pages/Settings';
import UpgradePremium from './pages/UpgradePremium';
import VendorSettings from './pages/VendorSettings';
import UserDetail from './pages/UserDetail';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Admin from 'pages/Admin';

const AppMain = () => (
  <AppLayout>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/create" component={CreateProgram} />
      <Route exact path="/edit/:programId" component={EditProgram} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/profile/:tab" component={Profile} />
      <Route exact path="/subscription/:programId" component={Subscription} />
      <Route exact path="/promote/:programId" component={PromoteProgram} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/admin" component={Admin} />
      <Route path="/settings/premium" component={UpgradePremium} />
      <Route exact path="/users/:userId" component={UserDetail} />
      <Route
        exact
        path="/users/:userId/programs/:programId"
        component={ProgramShare}
      />
      <Route path="/settings/vendor" component={VendorSettings} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  </AppLayout>
);

export default AppMain;
