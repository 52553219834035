import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'components/core';

import { Animal } from 'components/rank';

const Image = styled.img``;

const AnimalAvatar = styled(Animal)`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-radius: 8px;
`;

const Avatar = ({ url, rank, name, size, ...props }) => {
  let urlCropped = `${url}?w=${size}&h=${size}&fit=crop`;

  return (
    <Box position="relative" width={size} height={size} {...props}>
      <Image width={size} height={size} src={urlCropped} alt={name} />
      <AnimalAvatar value={rank} alt="rank" />
    </Box>
  );
};

Avatar.propTypes = {
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 56,
};

export default Avatar;
