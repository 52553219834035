import moment from 'moment';
import { put, call, select } from 'redux-saga/effects';
import pick from 'lodash-es/pick';

import ReportActions from './ReportRedux';
import { AuthSelectors } from './AuthRedux';

import API from 'services/api';
import { PROGRAM_ACTION_TYPES } from 'services/terms';
import { transfromRecordForGraph } from 'services/graph';

const DATE_FORMAT_STR = 'YYYY-MM-DD';

const REPORT_SECTIONS = [...PROGRAM_ACTION_TYPES, 'bmi'];

export function* loadReportdata() {
  yield put(ReportActions.setReportLoading(true));

  const dateFrom = moment().subtract(30, 'd').format(DATE_FORMAT_STR);
  const dateTo = moment().format(DATE_FORMAT_STR);

  const apiResponse = yield call(API.getAllRecords, {
    dateFrom,
    dateTo,
  });

  const recordsData = pick(apiResponse, REPORT_SECTIONS);
  const profile = yield select(AuthSelectors.selectProfile);

  const graphData = transfromRecordForGraph(recordsData, profile);

  yield put(ReportActions.setReportData(graphData));

  yield put(ReportActions.setReportLoading(false));
}
