import React from 'react';
import { Box, DialogBox, Body1, Button } from 'components/core';

const CHOOSE_HELP_TEXT =
  'This is your masthead message. it will be displayed prominently in your program. It is your opportunity to explain the components, philosophy and effectiveness of your program to other Bcult members.';

function MasterHelperModal({ show, onOk, ...props }) {
  return (
    <DialogBox show={show} onClickOutside={onOk} width={784}>
      <Box {...props} m={8}>
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mx={4}
          mt={9}
        >
          <Body1 textAlign="center">{CHOOSE_HELP_TEXT}</Body1>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={9}
          mb={8}
        >
          <Button variant="secondaryPlus" mx={4} onClick={onOk}>
            OK
          </Button>
        </Box>
      </Box>
    </DialogBox>
  );
}

export default MasterHelperModal;
