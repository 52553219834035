import React, { useState } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, get, set } from 'lodash-es';
import { Box } from 'components/core';
import { RegisterModal } from 'modals';

import { ProfileAvatar, AboutView } from 'containers/profile';
import SectionSwitch from './SectionSwitch';
import ProgramListView from './ProgramListView';
import UserListView from './UserListView';
import getDetailOptions from './getDetail';

import useViewport from 'hooks/useViewport';
import { AuthSelectors } from 'redux/AuthRedux';
import OtherUserActions from 'redux/OtherUserRedux';
import API from 'services/api';

function UserDetail({ user, isLoggedIn, userId, topProgram, updateUser }) {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const { width: vewportWith } = useViewport();

  const isFollowing = get(user, 'followers', []).some(
    (item) => item._id === userId
  );

  const [index, setIndex] = useState(0);
  const options = getDetailOptions(user);
  const currentOption = options[index];

  const isSmallWindow = vewportWith < 768;

  const handleRegister = () => {
    setShowRegisterModal(true);
  };

  const handleFollow = async (toFollow) => {
    if (!isLoggedIn) {
      handleRegister();
      return;
    }

    setLoading(true);
    try {
      const newUser = cloneDeep(user);
      if (toFollow) {
        const currentUser = await API.followUser(user._id);
        set(
          newUser,
          'stats.followerCount',
          Number(get(user, 'stats.followerCount', 0)) + 1
        );
        set(
          newUser,
          'followers',
          get(user, 'followers', []).concat([currentUser])
        );
      } else {
        await API.unfollowUser(user._id);
        set(
          newUser,
          'stats.followerCount',
          Number(get(user, 'stats.followerCount', 0)) - 1
        );
        set(
          newUser,
          'followers',
          get(user, 'followers', []).filter((item) => item._id !== userId)
        );
      }

      updateUser(newUser);
      if (index === 3) {
        // if current tab = follower
        setForceRefresh(!forceRefresh);
      }
    } catch (err) {}

    setLoading(false);
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignSelf="stretch"
        justifyContent="flex-start"
        mb={8}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignSelf={['stretch', 'unset']}
          mb={[8, 0]}
        >
          <ProfileAvatar
            size={isSmallWindow ? 140 : 280}
            profile={user.profile}
            mr={[4, 0]}
            isPublic
            onFollow={handleFollow}
            isFollowing={isFollowing}
            loadig={loading}
            rank={parseInt(user.ranking) + 1}
          />
          <AboutView
            profile={user.profile}
            mx={[0, 8]}
            height={isSmallWindow ? 140 : 280}
            width={isSmallWindow ? 140 : 280}
          />
        </Box>

        <SectionSwitch options={options} index={index} onChange={setIndex} />
      </Box>
      {currentOption.category === 'Program' ? (
        <ProgramListView
          key={currentOption.index}
          topProgram={currentOption.index === 0 ? topProgram : null}
          {...currentOption}
          onRegister={handleRegister}
        />
      ) : (
        <UserListView
          key={currentOption.index + forceRefresh.toString()}
          {...currentOption}
        />
      )}
      {!isLoggedIn && (
        <RegisterModal
          show={showRegisterModal}
          onOk={() => {
            setShowRegisterModal(false);
          }}
        />
      )}
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  isLoggedIn: AuthSelectors.selectIsLoggedIn(state),
  userId: AuthSelectors.selectUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(OtherUserActions.setPublicUserData(data)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(UserDetail);
