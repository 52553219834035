import React from 'react';
import { connect } from 'react-redux';
// import styled from 'styled-components';

import { Box, Button, Checkbox, Body1, Body2 } from 'components/core';
import ChangePasswordForm from '../ChangePasswordForm';

import SettingsActions, { SettingsSelectors } from 'redux/SettingsRedux';
// import { colors } from 'styles/theme';
import confirm from 'lib/confirm';
import AuthActions, { AuthSelectors } from 'redux/AuthRedux';
import API from 'services/api';

// const Divider = styled(Box)`
//   background-color: ${colors.line};
//   opacity: 0.6;
//   height: 1px;
//   margin: 20px 0;
// `;

// const PRIVACY_TEXTS = {
//   privacyCompletedGoesPublic: 'All completed programs public',
//   privacyProgramsPrivate: 'All programs private except those I submit',
// };

const NOTIFICATION_TEXTS = {
  notifyNewFollowers: 'New follower',
  notifyNewSubscribers: 'New subscriber',
  notifyInterestPrograms: 'Programs that may interest you',
  notifyNewProgramsFromFollowing: 'New program from people you are following',
  notifyProgramTipsImprovement: 'Tips for program improvement',
};

function SecuritySettings({
  privacy,
  notification,
  logout,
  currentUser,
  updateUser,
  setPrivacySettings,
  setNotificationSettings,
  ...props
}) {
  const deleteAccount = async () => {
    if (await confirm('Are you sure you want to delete your account?')) {
      try {
        await API.deleteAccount();
        logout();
      } catch (e) {
        console.error(e);
      }
    }
  };
  const handleSaveSettings = async (updates) => {
    const newProfile = {
      profile: {
        ...currentUser.profile,
        ...updates,
      },
    };
    const newUser = {
      ...currentUser,
      ...newProfile,
    };

    await API.updateProfile(newProfile);

    updateUser(newUser);
  };
  // const handleSavePrivacySettings = (updates) => {
  //   setPrivacySettings(updates);
  //   handleSaveSettings(updates);
  // };
  const handleSaveNotifySettings = (updates) => {
    setNotificationSettings(updates);
    handleSaveSettings(updates);
  };

  return (
    <Box {...props}>
      {/* <Body1 my={4}>Privacy</Body1>
      {Object.keys(PRIVACY_TEXTS).map((settingKey) => {
        const value = privacy[settingKey];
        const text = PRIVACY_TEXTS[settingKey];
        return (
          <Box
            key={settingKey}
            display="flex"
            flexDirection="row"
            alignItems="center"
            mb={2}
          >
            <Checkbox
              checked={value}
              onChange={() =>
                handleSavePrivacySettings({
                  [settingKey]: !value,
                })
              }
            />
            <Body2 ml={3}>{text}</Body2>
          </Box>
        );
      })}
      <Divider /> */}
      <Body1 my={4}>Password</Body1>
      <Box maxWidth="700px">
        <ChangePasswordForm />
      </Box>
      <Body1 my={4}>Notifications</Body1>
      {Object.keys(NOTIFICATION_TEXTS).map((settingKey) => {
        const value = notification[settingKey];
        const text = NOTIFICATION_TEXTS[settingKey];
        return (
          <Box
            key={settingKey}
            display="flex"
            flexDirection="row"
            alignItems="center"
            mb={2}
          >
            <Checkbox
              checked={value}
              onChange={() =>
                handleSaveNotifySettings({
                  [settingKey]: !value,
                })
              }
            />
            <Body2 ml={3}>{text}</Body2>
          </Box>
        );
      })}
      <Button variant="danger" type="button" onClick={deleteAccount} mt={4}>
        Delete Account
      </Button>
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  currentUser: AuthSelectors.selectCurrentUser(state),
  privacy: SettingsSelectors.selectPrivacySettings(state),
  notification: SettingsSelectors.selectNotificationSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.setLoggedOut()),
  updateUser: (newUser) => {
    dispatch(AuthActions.setUser(newUser));
  },
  setNotificationSettings: (data) =>
    dispatch(SettingsActions.setNotificationSettings(data)),
  setPrivacySettings: (data) =>
    dispatch(SettingsActions.setPrivacySettings(data)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(SecuritySettings);
