import styled from 'styled-components';
import { Box } from 'components/core';
import { isValidRank, rankBackground } from 'services/rankHelpers';


const RankItem = styled(Box)`
  background: ${(props) =>
    isValidRank(props.value) ? rankBackground[props.value - 1].background : '#6B6B6B'};
`;

export default RankItem;
