import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Box, DialogBox, Caption2, Caption3 } from 'components/core';
import { Rating } from 'components/others';
import { DefaultLoader, LoadingContainer } from 'components/loading';
import { Avatar } from 'components/program';

import API from 'services/api';
import { transformRatingsItem } from 'utils/transform';
import { colors } from 'styles/theme';

const CenterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Divider = styled(Box)`
  background-color: ${colors.line};
  opacity: 0.6;
  height: 1px;
  align-self: stretch;
`;

function RatingModal({ show, rating, programId, onOk, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [allRatings, setAllRatings] = useState([]);

  const loadRelatedRatings = async () => {
    setAllRatings([]);
    if (!programId) {
      return;
    }

    setIsLoading(true);
    const records = await API.getRatings(programId);
    setAllRatings(records.map((item) => transformRatingsItem(item)));

    setIsLoading(false);
  };

  useEffect(() => {
    loadRelatedRatings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  return (
    <DialogBox show={show} onClickOutside={onOk}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        m={[5, 8]}
      >
        <Rating size="big" value={rating} mb={6} />
        <Divider mb={6} />

        <Caption2 mb={6}>
          {allRatings.length ? 'Moon Rating' : 'No Rating Yet'}{' '}
        </Caption2>

        {allRatings.map((item) => {
          const {
            id,
            user: { id: userId, name, avatar },
            description,
            rating: itemRating,
          } = item;
          return (
            <CenterContainer mb={4} pl={2} pr={4} key={id}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Link to={`/users/${userId}`}>
                  <Avatar mr={2} name={name} {...avatar} />
                </Link>
                <Box>
                  <Caption3 px={2} mb={2}>
                    {name}
                  </Caption3>
                  <Rating value={itemRating} />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Caption3 mr={2}>{description || ''}</Caption3>
              </Box>
            </CenterContainer>
          );
        })}
        {isLoading && (
          <LoadingContainer>
            <DefaultLoader />
          </LoadingContainer>
        )}
      </Box>
    </DialogBox>
  );
}

export default RatingModal;
