import React from 'react';
import styled from 'styled-components';
import noop from 'lodash-es/noop';
import { Box, Body3, SvgIcon } from 'components/core';
import FitnessItem, { BmiItem, WeightItem } from './FitnessItem';
import {
  GraphMain,
  ItemsContainer,
  CategoryContainer,
  DaysRow,
  FitnessRow,
  DayWrapper,
  FitPlusButton,
  CloseButton,
  ItemWrapper,
  DayItemWrapper,
} from './CommonStyled';

import { colors } from 'styles/theme';
import { PROGRAM_TYPE, PROGRAM_STATUS } from 'services/terms';
import { getBmiStat } from 'services/calc';

const SectionIcon = styled(SvgIcon)`
  ${(props) => props.isClickable && `cursor: pointer;`}

  path {
    fill: ${colors.text};
  }
`;

const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const GoalWeight = styled(WeightItem)`
  position: absolute;
  right: -26px;
  top: ${(props) => props.rowIndex * 104 + 64}px;
`;

export const GoalBMI = styled(BmiItem)`
  position: absolute;
  right: -26px;
  top: ${(props) => props.rowIndex * 104 + 64}px;
`;

const isDayAllowedToAdd = (
  type,
  status,
  currentDay,
  day,
  isEditing,
  editable,
  sectionType
) => {
  if (status === PROGRAM_STATUS.CURRENT) {
    if (+currentDay >= +day) {
      if (editable) {
        return true;
      }
      if (sectionType === 'bmi' || sectionType === 'weight') {
        return true;
      }
    }
    return false;
  }

  if (currentDay === day && isEditing) {
    return true;
  }

  if (!isEditing || !editable) {
    return false;
  }

  if (type === PROGRAM_TYPE.FULL) {
    return true;
  }

  return false;
};

const ProgramGraph = ({
  viewOnly = false,
  editable = true,
  isEditing = false,
  sections = [],
  dayCount = 15,
  data = [],
  goal = {},
  type = PROGRAM_TYPE.ONGOING,
  status = PROGRAM_STATUS.DRAFT,
  onAdd = noop,
  onView = noop,
  onDelete = noop,
  onMoveSection = noop,
  onDeleteRow = noop,
  onSelectRow = noop,
  selectedRow = null,
  currentDay = 1,
  hightlightCurrentDay = false,
  sectionIds,
  startDate,
  endDate,
  ...props
}) => {
  const { weight: goalWeight, bmi: goalBmi } = goal;

  const bmiRowIndex = sections.findIndex((item) => item === 'bmi');
  const weightRowIndex = sections.findIndex((item) => item === 'weight');

  const bmiColor = goalBmi ? getBmiStat(goalBmi) : 'Ideal';

  const handleSelectRow = (sectionIndex) => {
    if (!isEditing) {
      return;
    }
    let newRow = sectionIndex;

    if (selectedRow === newRow) {
      newRow = null;
    }

    if (onSelectRow) {
      onSelectRow(newRow);
    }
  };

  return (
    <GraphMain {...props}>
      <CategoryContainer>
        {sections.map((sectionType, sectionIndex) => (
          <FitnessRow
            key={`${sectionType}_${sectionIndex}`}
            px={[1, 5]}
            onClick={() => handleSelectRow(sectionIndex)}
            selected={sectionIndex === selectedRow}
            width={1}
          >
            <Box position="relative">
              {isEditing && sectionIndex !== 0 && (
                <CloseButton
                  categoryClose
                  borderWidth={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteRow(sectionIndex);
                  }}
                >
                  <SvgIcon name="delete" />
                </CloseButton>
              )}
              <SectionIcon
                height={56}
                width={56}
                name={`fit-${sectionType}`}
                isClickable={isEditing}
              />
            </Box>
          </FitnessRow>
        ))}
      </CategoryContainer>
      <ItemsContainer flex={1}>
        <DaysRow px={[0, 4]}>
          {DAYS.map((day) => (
            <DayItemWrapper
              key={day}
              selected={hightlightCurrentDay && currentDay === day}
            >
              <DayWrapper mx={2}>
                <Body3>{day}</Body3>
              </DayWrapper>
            </DayItemWrapper>
          ))}
        </DaysRow>
        {sections.map((sectionType, sectionIndex) => {
          const sectionData = data[sectionIndex] || {};

          const isMasterThread = sectionIndex === 0;

          return (
            <FitnessRow
              key={`${sectionType}_${sectionIndex}`}
              px={[0, 4]}
              selected={sectionIndex === selectedRow}
              style={{ borderTop: `1px solid ${colors.line}` }}
            >
              {DAYS.map((day) => {
                const data = sectionData[day];
                let isDayAllowed = isDayAllowedToAdd(
                  type,
                  status,
                  currentDay,
                  day,
                  isEditing,
                  editable,
                  sectionType
                );
                const shouldAdd = !data && isDayAllowed && !viewOnly;

                if (shouldAdd) {
                  return (
                    <ItemWrapper
                      key={day}
                      selected={hightlightCurrentDay && currentDay === day}
                    >
                      <FitPlusButton
                        key={day}
                        isCurrentDay={currentDay === day}
                        mx={2}
                        onClick={() =>
                          onAdd(sectionType, sectionIndex, day, currentDay)
                        }
                        isMasterThread={isMasterThread}
                      />
                    </ItemWrapper>
                  );
                }

                return (
                  <ItemWrapper
                    key={day}
                    selected={hightlightCurrentDay && currentDay === day}
                  >
                    <FitnessItem
                      mx={2}
                      key={day}
                      isEditing={isEditing}
                      category={sectionType}
                      onDelete={() =>
                        onDelete(
                          sectionType,
                          data,
                          sectionIndex,
                          day,
                          currentDay
                        )
                      }
                      data={data}
                      onClick={() =>
                        onView(sectionType, data, sectionIndex, day, currentDay)
                      }
                    />
                  </ItemWrapper>
                );
              })}
            </FitnessRow>
          );
        })}
      </ItemsContainer>
      {!viewOnly && weightRowIndex !== -1 && goalWeight && (
        <GoalWeight
          value={goalWeight}
          bg={`bmi${bmiColor}`}
          rowIndex={weightRowIndex}
        />
      )}
      {!viewOnly && bmiRowIndex !== -1 && goalBmi && (
        <GoalBMI value={goalBmi} rowIndex={bmiRowIndex} />
      )}
    </GraphMain>
  );
};

export default ProgramGraph;
