import React from 'react';
import styled from 'styled-components';
import DefaultLoader from './DefaultLoader';

const FullWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

function FullScreenLoader(props) {
  return (
    <FullWrapper>
      <DefaultLoader {...props} />
    </FullWrapper>
  );
}

export default FullScreenLoader;
