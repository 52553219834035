import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Box } from 'components/core';
import { Carousel } from 'components/others';

import RankProgressView from './RankProgressView';
import ReportProgressView from './ReportProgressView';

import { ReportSelectors } from 'redux/ReportRedux';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 300px;
  position: relative;
`;

const ProgressView = ({ currentSlide, ...props }) => {
  return (
    <Wrapper {...props}>
      <Carousel currentSlide={currentSlide}>
        <ReportProgressView />
        <RankProgressView />
      </Carousel>
    </Wrapper>
  );
};

const mapStatesToProps = (state) => ({
  currentSlide: ReportSelectors.selectCurrentSlide(state),
});

export default connect(mapStatesToProps)(ProgressView);
