import styled from 'styled-components';
import {
  borderRadius,
  buttonStyle,
  space,
  fontSize,
  fontWeight,
  lineHeight,
  variant,
  flexbox,
  width,
  background
} from 'styled-system';

const buttonSize = variant({
  prop: 'size',
  key: 'buttonSizes'
});

const Button = styled('button')`
  appearance: button;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-family: inherit;

  ${borderRadius}
  ${buttonStyle}
  ${buttonSize}
  ${space}
  ${width}  
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${flexbox}
  ${background}
`;

Button.defaultProps = {
  variant: 'primary',
  size: 'medium',
  borderRadius: 2,
  fontSize: 2,
  lineHeight: 1.29,
  fontWeight: 500
};

export default Button;
