import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Rating } from 'components/others';
import { Box, DialogBox, BTextArea, Button } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';

import API from 'services/api';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

function RatingFormModal({ show, rating, programId, onOk, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [score, setScore] = useState(rating);

  const onSubmitRating = async () => {
    if (!programId) {
      return;
    }

    setIsLoading(true);
    try {
      await API.giveRating(programId, {
        score,
        description,
      });
    } catch (err) {}

    setIsLoading(false);
    onOk();
  };

  useEffect(() => {
    setScore(rating);
  }, [rating]);

  return (
    <DialogBox show={show} onClickOutside={onOk}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        m={[5, 8]}
      >
        <Rating
          size="big"
          value={score}
          onChange={(val) => setScore(val)}
          mb={[5, 8]}
        />
        <TextArea
          mb={[5, 8]}
          value={description}
          placeholder="Please give brief description of your rating here..."
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button variant="secondary" onClick={onSubmitRating}>
          OK
        </Button>

        {isLoading && (
          <LoadingContainer>
            <DefaultLoader />
          </LoadingContainer>
        )}
      </Box>
    </DialogBox>
  );
}

export default RatingFormModal;
