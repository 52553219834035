import React, { useState } from 'react';
import { Box, Select, BInput, SvgIcon, IconButton } from 'components/core';

import { GOAL_OPTIONS } from 'services/terms';

const INPUT_PLACEHOLDERS = {
  bmi: '',
  weight: 'LBS',
};

function GoalForm({ goal = {}, onChange, ...props }) {
  const [goalType, setGoalType] = useState('weight');

  const onChangeType = ({ value: newGoalType }) => {
    setGoalType(newGoalType);
    onChange({ goalType: newGoalType, value: '' });
  };

  const onChangeValue = (e) => {
    onChange({ goalType, value: e.target.value });
  };

  const onReset = () => {
    onChange({ goalType: '', value: '' });
  };

  return (
    <Box display="flex" alignItems="center" {...props}>
      <SvgIcon name="target" />

      <Box
        flex={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        ml={3}
      >
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          alignItems="center"
        >
          <Select
            width={[1, 'unset']}
            placeholder="Goal Type"
            value={goalType}
            color="contrastText"
            options={GOAL_OPTIONS}
            onChange={onChangeType}
            mr={6}
          />
          <BInput
            width={[1, 'unset']}
            color="contrastText"
            value={goal[goalType] || ''}
            type="number"
            placeholder={INPUT_PLACEHOLDERS[goalType]}
            onChange={onChangeValue}
            mr={6}
          />
        </Box>

        <IconButton borderWidth={0} onClick={onReset}>
          <SvgIcon name="close" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default GoalForm;
