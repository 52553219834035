import React, { useState, useEffect } from 'react';
import { Box, Tab, Pagination } from 'components/core';
import { LoadingContainer, DefaultLoader } from 'components/loading';
import { AdminProgramList } from 'containers/programs';
import API from 'services/api';
import { transformProgram } from 'utils/transform';

const PER_PAGE = 5;
const TABS = [
  { label: 'Approved', value: 'approved' },
  { label: 'Unapproved', value: 'unapproved' },
];

function AdminPage() {
  const [page, setPage] = useState(0);
  const [tab, setTab] = useState('approved');
  const [programs, setPrograms] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleUpdateProgram = program => {
    setPrograms(programs.map(p => p.id === program.id ? program : p));
  };
  const handleDeleteProgram = programId => {
    setPrograms(programs.filter(p => p.id !== programId));
  };
  const handleChangeTab = (newTab) => {
    setPage(0);
    setTotal(0);
    setTab(newTab);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setPrograms([]);
      const { count, programs } = await API.getAllPrograms({
        approved: tab === 'approved' ? true : '',
        limit: PER_PAGE,
        skip: page * PER_PAGE
      });
      setPrograms(programs.map(transformProgram));
      setTotal(Math.ceil(count / PER_PAGE));
      setLoading(false);
    })();
  }, [page, tab]);

  return (
    <Box alignSelf="stretch" position="relative" pt={8} pb={8} px={2}>
      {loading && (
        <LoadingContainer>
          <DefaultLoader />
        </LoadingContainer>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Tab options={TABS} tab={tab} onChange={handleChangeTab} />
      </Box>
      <Box position="absolute" right={-100}>
        <Pagination total={total} active={page} onChange={setPage} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="flex-start"
        mb={8}
        py={6}
      >
        <AdminProgramList
          programs={programs}
          updateMyProgram={handleUpdateProgram}
          deleteMyProgram={handleDeleteProgram}
        />
      </Box>
    </Box>
  );
}

export default AdminPage;
