import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from 'components/core';

const SCarouselWrapper = styled(Box)`
  display: flex;
  width: 100%;
`;

const SCarouselSlide = styled(Box)`
  flex: 0 0 auto;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all 0.5s ease;
  width: 100%;
  z-index: 1;
`;

const SCarouselSlides = styled(Box)`
  display: flex;
  ${(props) =>
    props.currentSlide &&
    css`
      transform: translateX(-${props.currentSlide * 100}%);
    `};
  transition: all 0.5s ease;
`;

const Carousel = ({ children, currentSlide }) => {
  const activeSlide = children.map((slide, index) => (
    <SCarouselSlide active={currentSlide === index} key={index}>
      {slide}
    </SCarouselSlide>
  ));

  return (
    <SCarouselWrapper>
      <SCarouselSlides currentSlide={currentSlide}>
        {activeSlide}
      </SCarouselSlides>
    </SCarouselWrapper>
  );
};

export default Carousel;
