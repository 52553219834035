export function formatError(error) {
  const { path, message, name } = error || {};
  if (path && Array.isArray(path)) {
    return {
      [path]: message
    };
  }

  return {
    name,
    message
  };
}

export function getErrors(err) {
  if (err.response) {
    const { data } = err.response;
    const { error } = data || {};
    const { errors } = error || {};

    if (errors && Array.isArray(errors)) {
      return formatError(errors[0]);
    }

    return formatError(error);
  }

  return formatError(err);
}
