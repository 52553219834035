import moment from 'moment';
import isEmpty from 'lodash-es/isEmpty';
import groupBy from 'lodash-es/groupBy';
import uniq from 'lodash-es/uniq';
import last from 'lodash-es/last';
import startCase from 'lodash-es/startCase';
import { calcBmiUSC, calcWeightFromBmi, getKgFromLbs } from './calc';

import { PROGRAM_ACTION_TYPES, CATEGORIES } from './terms';

const DATE_FORMAT_STR = 'YYYY-MM-DD';

const populateDateAndLabels = (dateFrom, dayCount) => {
  const startDate = moment(dateFrom);

  const labels = [startDate.format('MM/DD')];
  const dates = [startDate.format(DATE_FORMAT_STR)];
  let currentMonth = startDate.month();

  for (let i = 0; i < dayCount; i += 1) {
    startDate.add(1, 'd');
    const monthNum = startDate.month();

    const label =
      monthNum !== currentMonth || i === dayCount - 1
        ? startDate.format('MM/DD')
        : startDate.format('DD');
    labels.push(label);
    dates.push(startDate.format(DATE_FORMAT_STR));

    currentMonth = monthNum;
  }

  return {
    labels,
    dates,
  };
};

/** record */

export const transformWeight = (apiResponse, { height }) => {
  let { value, date, type, sectionType } = apiResponse;
  if (sectionType === 'weight' && type !== 'bmi') {
    value = calcBmiUSC({ height, weight: value });
  }

  return {
    date: moment(date).format(DATE_FORMAT_STR),
    value: value || 45,
  };
};

export const transformNormalRecord = (apiResponse) => {
  const { value, date, type } = apiResponse;
  return {
    date: moment(date).format(DATE_FORMAT_STR),
    value: value || 0,
    type,
  };
};

export const transformStrength = (apiResponse) => {
  const { value, date, subType, unit, reps, sets } = apiResponse;
  return {
    date: moment(date).format(DATE_FORMAT_STR),
    value: value || 0,
    reps: reps || 0,
    sets: sets || 0,
    type: subType, // subType is used for strength
    unit,
  };
};

export const transformFitness = (apiResponse) => {
  const { value, date, type, unit, reps, sets } = apiResponse;
  return {
    date: moment(date).format(DATE_FORMAT_STR),
    value: value || 0,
    reps: reps || 0,
    sets: sets || 0,
    type,
    unit,
  };
};

export const transformDiet = (apiResponse) => {
  const { value, date, type, protein, carbs, fat } = apiResponse;
  return {
    date: moment(date).format(DATE_FORMAT_STR),
    type,
    calories: value,
    protein,
    carbs,
    fat,
  };
};

const mergeWeight = (a, b) => {
  if (!a) {
    return Math.max(15, Math.min(b.value, 39));
  }
  return Math.max(15, Math.min(a, b.value, 39));
};

const getFieldsMerger = (fields) => {
  return (a, b) => {
    // clean up data
    const { type } = b;
    const target = a && a[type] ? a[type] : {};

    fields.forEach((f) => {
      target[f] = target[f] || 0;
      target[f] += target[f] + (b[f] || 0);
    });

    return {
      ...a,
      [type]: target,
    };
  };
};

const mergeStrengthRecord = (a, b) => {
  return getFieldsMerger(['reps', 'sets', 'lbs'])(a, {
    ...b,
    lbs: b.value,
  });
};

const mergeFitnessRecord = (a, b) => {
  return getFieldsMerger(['reps', 'sets', 'minutes', 'miles'])(a, {
    ...b,
    [b.unit]: b.value,
  });
};

const mergeDietRecord = (a, b) => {
  return getFieldsMerger(['calories', 'protein', 'carbs', 'fat'])(a, {
    ...b,
    [b.unit]: b.value,
  });
};

const mergeNormalRecord = (a, b) => {
  if (!a) {
    a = {};
  }
  const { type } = b;

  if (!a[type]) {
    return {
      ...a,
      [type]: b.value,
    };
  }

  return {
    ...a,
    [type]: b.value + a[type],
  };
};

const getRecordTransformer = (sectionType) => {
  if (sectionType === 'bmi' || sectionType === 'weight') {
    return transformWeight;
  }

  if (sectionType === CATEGORIES.STRENGTH) {
    return transformStrength;
  }

  if (sectionType === CATEGORIES.FITNESS) {
    return transformFitness;
  }

  if (sectionType === CATEGORIES.DIET) {
    return transformDiet;
  }

  return transformNormalRecord;
};

const getRecordMerger = (sectionType) => {
  if (sectionType === 'bmi' || sectionType === 'weight') {
    return mergeWeight;
  }

  if (sectionType === CATEGORIES.FITNESS) {
    return mergeFitnessRecord;
  }

  if (sectionType === CATEGORIES.STRENGTH) {
    return mergeStrengthRecord;
  }

  if (sectionType === CATEGORIES.DIET) {
    return mergeDietRecord;
  }

  return mergeNormalRecord;
};

const getSectionDataSets = (
  sectionData,
  merger,
  dayCount,
  defaultValue = 0
) => {
  const byDate = groupBy(sectionData, 'date');

  const allDates = Object.keys(byDate);
  allDates.sort((a, b) => a - b);

  allDates.forEach((date) => {
    const values = byDate[date];
    byDate[date] = values.reduce((prev, current) => merger(prev, current), 0);
  });

  const { dates, labels } = populateDateAndLabels(allDates[0], dayCount);

  const allSubSections = allDates.reduce((prev, currentDate) => {
    const valueSet = byDate[currentDate];
    if (!valueSet) {
      return prev;
    }

    return uniq(prev.concat(Object.keys(valueSet)));
  }, []);
  const graphDataSets = allSubSections.map((subType) => {
    const dataValues = dates.map((currDate) => {
      const valueSet = byDate[currDate];
      const { [subType]: value } = valueSet || {};
      return value || defaultValue;
    });

    return {
      dataValues,
      label: startCase(subType),
    };
  });

  return {
    data: graphDataSets,
    labels,
  };
};

export const transfromRecordForGraph = (recordsData, extra, dayCount = 14) => {
  const graphSectionData = {
    bmi: [],
    weight: [],
  };

  PROGRAM_ACTION_TYPES.forEach((sectionType) => {
    graphSectionData[sectionType] = [];
  });

  const sections = Object.keys(recordsData);

  sections.forEach((sectionType) => {
    const sectionData = recordsData[sectionType];
    if (!Array.isArray(sectionData)) {
      return;
    }

    let graphSectionType = sectionType;

    if (sectionType === 'weight' || sectionType === 'bmi') {
      graphSectionType = 'weight';
    }

    const transformer = getRecordTransformer(sectionType);
    graphSectionData[graphSectionType] = graphSectionData[
      graphSectionType
    ].concat(sectionData.map((item) => transformer(item, extra)));
  });

  // MERGE, calculate date

  const graphData = {};

  // generate BMI data sets

  PROGRAM_ACTION_TYPES.forEach((sectionType) => {
    const sectionData = graphSectionData[sectionType];
    const isBmi = sectionType === 'weight';

    if (isEmpty(sectionData)) {
      graphData[sectionType] = {
        info: {
          title: isBmi ? `Bmi` : startCase(sectionType),
        },
      };
      return;
    }
    const byDate = groupBy(sectionData, 'date');

    const allDates = Object.keys(byDate);
    allDates.sort((a, b) => a - b);
    const merger = getRecordMerger(sectionType);

    //continuous data
    if (isBmi) {
      allDates.forEach((date) => {
        const values = byDate[date];
        byDate[date] = values.reduce(
          (prev, current) => merger(prev, current),
          0
        );
      });

      const { dates, labels } = populateDateAndLabels(allDates[0], dayCount);

      let prevValue = -1;
      const graphValues = dates.map((currDate) => {
        const value = byDate[currDate];
        if (prevValue === -1) {
          prevValue = value;
          return value;
        }

        if (!value) {
          return prevValue;
        }

        prevValue = value;
        return value;
      });

      const weightLbData = graphValues.map((g) =>
        calcWeightFromBmi({ ...extra, bmi: g })
      );
      const weightKgData = weightLbData.map((g) => getKgFromLbs(g));

      graphData[sectionType] = {
        data: {
          bmi: graphValues,
          lbs: weightLbData,
          kg: weightKgData,
        },
        labels,
        info: {
          title: 'Bmi',
        },
      };
    } else {
      let defaultValue = 0;
      if (sectionType === 'fitness') {
        defaultValue = {
          reps: 0,
          sets: 0,
          miles: 0,
          minutes: 0,
        };
      } else if (sectionType === 'strength') {
        defaultValue = {
          reps: 0,
          sets: 0,
          minutes: 0,
        };
      }

      graphData[sectionType] = {
        ...getSectionDataSets(sectionData, merger, dayCount, defaultValue),
        info: {
          title: startCase(sectionType),
        },
      };
    }
  });

  return graphData;
};

// convert value into 1-100 custom scale
export const convertToRangedScale = (value, ranges) => {
  const lastRange = last(ranges);
  if (value < ranges[0][0]) return ranges[0][0];
  if (value > lastRange[1]) return lastRange[1];

  const portion = 100 / ranges.length;
  const index = ranges.findIndex((r) => r[0] <= value && value < r[1]);
  const range = ranges[index];

  if (index === -1) {
    return;
  }

  return (
    portion * index + (portion / (range[1] - range[0])) * (value - range[0])
  );
};
