import React from 'react';
import styled from 'styled-components';

import { Box, Input, SvgIcon } from 'components/core';
import { colors } from 'styles/theme';

const PADDING = 8;
const POINTER_WIDTH = 76;
const HEIGHT = 72;
const POINTER_HEIGHT = 56;

const KeyIcon = styled(SvgIcon)`
  path {
    fill: ${colors.contrastText};
  }
`;

const MainWrapper = styled(Box)`
  position: relative;
  height: ${HEIGHT}px;
  cursor: pointer;
  width: ${(props) => props.width}px;
`;

const Bg = styled(Input)`
  height: ${HEIGHT}px;
  cursor: pointer;
  width: ${(props) => props.width}px;
`;

const Wrapper = styled(Box)`
  position: absolute;
  width: ${POINTER_WIDTH}px;
  height: ${POINTER_HEIGHT}px;
  border-radius: ${POINTER_HEIGHT / 2}px;
  top: ${PADDING}px;
  left: ${(props) => props.pos}px;
  padding-left: 8px;
  background-color: ${colors.blueGray};
  transition: 0.2s;
`;

const Switch = ({
  variant,
  onChange,
  selected,
  disabled,
  width = 280,
  props,
}) => {
  const isSelected = !!selected;
  const pos = !isSelected ? PADDING : width - PADDING - POINTER_WIDTH;
  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (onChange) onChange(!isSelected);
  };

  return (
    <MainWrapper onClick={handleClick} width={width} {...props}>
      <Bg disabled borderRadius={36} width={width} />
      <Wrapper pos={pos} variant={variant}>
        <KeyIcon name="key-pointer" />
      </Wrapper>
    </MainWrapper>
  );
};

export default Switch;
