import React from 'react';
import { Box, Body2, Title1Bold } from 'components/core';

const P = Body2.withComponent('p');
P.defaultProps = {
  fontSize: 0,
};
const H1 = Title1Bold.withComponent('h1');
// const H2 = Title3.withComponent('h2');

function Terms() {
  return (
    <Box color="black" p={[2, 6]}>
      <H1>
        <strong>PRIVACY NOTICE</strong>
      </H1>
      <P>Last Modified: October 15, 2020</P>
      <P>
        This privacy notice (“<strong>Privacy Notice</strong>”) applies to BCult
        Corp., a Delaware Corporation (hereinafter, variously{' '}
        <strong>“BCult”, “we”, “us”, </strong>or<strong> “our”</strong>), its
        privacy practices related to the users of its products and/or services
        BCult provides to consumers (<strong>“you”</strong> or{' '}
        <strong>“your”</strong>) and/or your access to and/or use of the website
        www.BCult.com, any mobile versions and/or applications related thereto
        and any sites BCult has now or acquires in the future (collectively, the{' '}
        <strong>“Services”</strong>), including without limitation as it relates
        to offering opportunities to create, share, and view personalized
        digital health, fitness, strength and wellness programs.
      </P>
      <P>
        This Privacy Notice explains our privacy practices for the activities
        described herein. Please read this Privacy Notice carefully to learn how
        we collect, use, share, and otherwise process information relating to
        individuals, and to learn about your rights and choices regarding such
        information.
      </P>
      <P>
        <strong>Consent to Electronic Communications</strong>
      </P>
      <P>
        By using the Services, you consent to receiving certain recurring
        electronic communications from BCult as further described in this
        Privacy Notice. You agree that any referrals, notices, agreements,
        disclosures, or other communications that we send to you electronically
        will satisfy any legal communication requirements, including that those
        communications be in writing.
      </P>
      <P>
        For questions about the electronic communications, please send an email
        to support@bcult.com.
      </P>
      <P>
        <strong>
          By using the Services in any manner, you indicate to BCult that you
          have read and accept our Privacy Notice and consent to the data
          practices described in this Privacy Notice. If you do not accept this
          Privacy Notice or do not meet or comply with the provisions set forth
          herein, then you may not use the Services
        </strong>
        . We take the privacy of our users very seriously, and we will not share
        your personally identifiable information in ways unrelated to those
        described in this Privacy Notice without providing you with an
        opportunity to opt out of such use or otherwise prohibit such unrelated
        use. If required by applicable law, we will seek your express consent to
        process personal data (i) provided by you and/or (ii) collected during
        your use of the Services. Any consent shall be entirely voluntary. In
        the event you do not provide consent to use or process your personal
        data, then you may not access and/or use the Services.
      </P>
      <P>
        <strong>INFORMATION WE COLLECT</strong>
      </P>
      <P>
        When you use the Services, you may provide BCult with two main types of
        information: (1) personally identifiable information that you knowingly
        choose to disclose, which is collected on an individual basis, and (2)
        general user information that does not contain personally identifiable
        information, which is collected on an aggregate basis as you browse and
        use the Services and/or provide information to us. We collect
        information from you when you (a) use and/or access the Services, (b)
        register an account with BCult, (c) open and/or respond to our emails,
        (d) contact BCult, (e) visit any page online that displays our content,
        (f) make purchases through the Services, and/or (g) provide information
        to any of our service providers, customers, partners, and/or other
        users.
      </P>
      <P>
        <strong>
          Personally-Identifiable Information You Choose to Provide and Other
          Information We Collect
        </strong>
      </P>
      <h3>
        We may ask for certain personal information from you for the purpose of
        providing to you content and/or services that you request. We collect
        the content, communications and other information you provide when you
        use our Service, including when you create or share content and message
        or communicate with others. We collect personal information and other
        information such as your: (i) personal contact information (including
        name and email); (ii) username and password; (iii) demographic
        information (including age, birthdate and gender); (iv) location
        information (such as geographic location of the device you are using);
        (v) fitness information (including height, weight, BMI and activities
        such as types of workouts, exercises, programs, frequency of workout and
        duration of workout); (vi) progress benchmarks including height, weight
        and body mass index data; (vii) use of supplements, calories consumed
        and macros (i.e.: carbohydrates, fat and protein); (viii) time spent
        viewing programs, programs followed, programs liked, reviews and ratings
        of programs; and (ix) financial information (including information about
        the purchase, payment information, credit or debit card number and other
        card information, billing information and contact details). We do not
        encrypt data related to a user’s progress, program statistics and other
        information provided. Once you register for an account, you have opted
        in to receive electronic communications from BCult and can change your
        options under account settings.{' '}
      </h3>

      <h3>
        <strong>Categories of Personal Information Collected</strong>
      </h3>

      <P>
        The categories of information we collect will depend on your
        interactions with BCult:
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>
          Personal information
        </span>
        : If you use and interact with the Services, we may require you to
        provide your name, email address, address, phone number, user names and
        passwords, gender and date of birth, and photographs.
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>Payment Information</span>
        : If you use the Services and charge a fee for User Content, we may
        collect payment services information, financial information, bank
        account information, and personal information from other sources such as
        credit agencies or payment services providers.
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>
          Location Information
        </span>
        : If you use the Services, we may collect your location information,
        such as the time, date and gps location.
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>
          Inferred and derived information
        </span>
        : We may collect data such as propensities and attributes that BCult
        generates to help us understand you and your preferences.
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>
          Registration information
        </span>
        : We may collect information which is generated in the course of your
        interaction with the Services, including without limitation account
        information, logins and passwords.
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>
          Images, Videos and Recordings
        </span>
        : Images, videos and recordings may be collected.
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>Usage data</span>: BCult
        collects information related to your use of the Services so we can
        analyze the usage data to improve the Services and your experience with
        the Services. Usage data includes online and technical information about
        your computer's or mobile device's operating system and browser type;
        your device type; details about how you are using our services
        (including natural language queries); your Internet Protocol (IP)
        address, and geographic areas derived from your IP address; networking
        connection data; file information; metadata; time stamped logs regarding
        access times and duration of visits; the web pages you visited before
        coming to the Services (referring URL); data from cookies stored on your
        device; and other usage data relating to your activities on the
        Services, including the pages you request. We may link this information
        to the Personal Information we have collected about you and use it for
        the purposes described in this Privacy Notice, and we may deliver
        targeted marketing and product information back to you based on your
        usage data.
      </P>
      <P>
        If you decline to provide your personal information or ask us to delete
        it, we may be unable to continue to provide the Services to you.
      </P>
      <P>
        <strong>Information We Collect Automatically </strong>
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>Cookies</span>: In an
        effort to be transparent with our data collection practice and the
        technologies we use to provide the Services, the following explains how
        we use Cookies. We use Cookies and web log files to improve the website
        experience and quality of the Services, and to customize your
        experience, by tracking website usage and trends, conducting research
        and development for additional services, and determining marketing
        efforts. A “Cookie” is a tiny data file that resides on your computer,
        mobile phone, or other device, and allows BCult to recognize you as a
        user when you return to our website using the same device and web
        browser and enables BCult to collect information about how you use our
        Services. Information gathered through Cookies may include the date and
        time of visits, the pages viewed, and time spent at using the Service.
        If you do not agree to our use of Cookies, you should set your browser
        settings accordingly or not use the Services. BCult uses Cookies and web
        log files for the following, which may include without limitation (i) to
        track usage and trends, (ii) to improve the quality of the Services,
        (iii) to customize your experience and/or (iv) to compile anonymous,
        aggregated information that allow us to understand how our Services are
        used. Cookies are categorized by how long they are stored and their
        function. The Cookies BCult uses are as follows:
      </P>
      <ul>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            Persistent Cookies
          </span>
          : Persistent Cookies remain on a visitor’s device for a set period of
          time specified in the Cookie. They are activated each time that the
          visitor visits the Services that created that particular Cookie.
        </li>

        <li>
          <span style={{ textDecoration: 'underline' }}>Session Cookies</span>:
          Session Cookies are temporary and deleted from your computer when you
          close your web browser. They allow BCult to link the actions of a user
          while using the Services.{' '}
        </li>

        <li>
          <span style={{ textDecoration: 'underline' }}>
            Strictly Necessary Cookies
          </span>
          : Strictly necessary Cookies are essential to navigate around the
          Services and to use its features.
        </li>

        <li>
          <span style={{ textDecoration: 'underline' }}>
            Performance Cookies
          </span>
          : Performance Cookies collect anonymous data for statistical purposes
          on how users use the Services, they don’t contain personal information
          and are used to improve the user experience.
        </li>

        <li>
          <span style={{ textDecoration: 'underline' }}>
            Functionality Cookies
          </span>
          : Functionality Cookies allow BCult to operate the Services in
          accordance with your choices, such as ‘remembering’ you in between
          visits.
        </li>
      </ul>
      <P>
        You may remove or block Cookies using the settings in your browser, but
        in some cases that may impact your ability to use the Services and/or
        impact your user experience while using the Services. By accessing or
        using the Services, you consent to the placement of Cookies on your
        device as described in this Privacy Notice. If you prefer not to receive
        Cookies through the Services, you may control how your browser responds
        to Cookies by adjusting the privacy and security settings of your web
        browser.
      </P>
      <P>
        <span style={{ textDecoration: 'underline' }}>
          Third Party Services
        </span>
        :<strong> </strong>To support the services we provide to you, we may use
        services hosted by third parties that may use cookies and various
        tracking technologies and may collect information sent by a browser as
        part of a web page request, including ‘Internet Protocol’ addresses,
        browser software, and/or clickstream patterns. If such third-party
        services collect information, they do so anonymously without identifying
        individual visitors. However, we may link the information we record
        using tracking technology to personal information we collect. In
        addition, we may allow third parties who list services to use Cookies in
        connection with your use of the Services. They may collect and store the
        same type of information and use it similarly to BCult, as described
        above. All such third parties are prohibited from using your personal
        information except to provide these services to BCult, and such third
        parties are required to maintain the confidentiality of your
        information. You agree to release BCult from any and all liability
        arising from any unauthorized disclosure of your personal information
        due to a third-party services data breach. You will be required to
        provide the third party payment services provider with information about
        you and/or your business to meet certain compliance requirements
        including identity verification, anti-money laundering, risk
        assessments, sanctions screening and other compliance checks as required
        by applicable law. We may send personal data associated with you and
        your account to the third party payments service providers. You will be
        required to provide bank account information on a U.S. based checking
        account so that the third party payment services provider can link your
        account allowing the third party payment services provider to pay you.
      </P>
      <P>
        <strong>How We Use Personal Information</strong>
      </P>
      <P>
        We use information we collect for the purposes described in this Privacy
        Notice or as disclosed to you in connection with your access to and use
        of the Services. We also use the information to take the following
        actions:
      </P>
      <ul>
        <li>provide the Services;</li>

        <li>
          manage your user account in accordance with the Terms of Service
          located at https://bcult.com/terms;
        </li>

        <li>
          operate, maintain and improve our operations, systems and Services;
        </li>

        <li>perform analytics and conduct research;</li>

        <li>
          understand you and your preferences to enhance your experience on the
          Services;
        </li>

        <li>
          respond to your comments and questions and provide customer service;
        </li>

        <li>contact you through email, social media, blog or newsletter;</li>

        <li>
          provide service and support, such as sending confirmations, invoices,
          technical notices, updates, security alerts, and administrative
          messages and providing customer support and troubleshooting;
        </li>

        <li>developing and improving the Services;</li>

        <li>
          sending marketing communications and other non-transactional
          communications (i.e., marketing newsletters, telemarketing calls or
          push notifications) about promotions, events, and news about products
          and services offered by BCult and our partners;
        </li>

        <li>
          displaying tailored advertisements based on your information and/or
          preferences provided while using the services;
        </li>

        <li>display content and conducting market research;</li>

        <li>compliance with legal obligations; </li>

        <li>
          link or combine information about you with other personal information
          we get from third parties, to help understand your needs and provide
          you with better and more personalized service;
        </li>

        <li>enforce our terms and conditions;</li>

        <li>
          protect against, investigate, and deter fraudulent, unauthorized, or
          illegal activity;
        </li>

        <li>promoting the security of the Services; </li>

        <li>
          determine the most effective programs as far as weight loss, strength
          and customer satisfaction; and
        </li>

        <li>use information for other purposes about which we notify you. </li>
      </ul>
      <P>
        The information collected in the aggregate enables BCult to better
        understand your use of the Services and to enhance your enjoyment. We
        may use financial information to enroll you in one of our accounts
        and/or other related services in which you elect to participate. If you
        use the Services, you agree to receive certain communications from us
        including but not limited to the following:
      </P>
      <P>
        <strong>Special Offers, Newsletters and Updates</strong>. We will
        occasionally send you information on products, special deals, promotions
        and newsletters. Out of respect for your privacy, you may elect not to
        receive these types of communications by contacting us at
        support@BCult.com.
      </P>
      <P>
        <strong>Customer Service</strong>. We will communicate with you by email
        or telephone, as you may elect. You can reach BCult by email at
        support@BCult.com.
      </P>
      <P>
        <strong>Sharing of Personal Information</strong>
      </P>
      <P>
        BCult will not disclose your personal information to third parties
        except as set forth in this Privacy Notice. BCult may share your
        information with the following parties:
      </P>
      <ul>
        <li>
          We may share your personal information with service providers as well
          as information from tools such as cookies, log files, and device
          identifiers and location data (such as usage data, referring/exit
          pages and URLs, platform types, number of clicks, etc.). These service
          providers may use your personal information to help BCult provide the
          Services, including but not limited to, payment services, data
          analysis, data base and search services, and/or to communicate with
          you;
        </li>

        <li>
          We may facilitate third party services or ways to share data through
          third parties, including payment services, social media platforms,
          websites, applications, and services through plug-ins, widgets,
          buttons, and other third party features on and connected with the
          Services.
        </li>

        <li>
          If you connect to your third party accounts through our products, we
          will use that information to authenticate you, enumerate the data
          sources available to you, download any data you request us to, and
          download and refresh authentication tokens or persist authentication
          information such as user names and passwords as necessary to continue
          to connect to these data;
        </li>

        <li>
          We will disclose your personal information to comply with legal
          requirements, such as in response to a court order or a subpoena. We
          also may disclose your personal information in response to: (i) a law
          enforcement agency's request, (ii) to investigate, verify, prevent,
          enforce compliance with, or take action regarding illegal or suspected
          illegal activities; (iii) to investigate and defend BCult, its
          directors, officers and employees, against any third party claims or
          allegations, or otherwise to protect BCult from liability; (iv) to
          exercise or protect the rights, property and/or personal safety of the
          users of the services; (v) to investigate violations or suspected
          violations of our agreements and policies; (vi) to protect the
          security and/or integrity of the Service, or (vii) as otherwise
          required or permitted by law or consistent with legal requirements;
        </li>

        <li>
          We transfer or disclose your personal information for business
          purposes. We may share your contact and transactional information with
          our current or future affiliates, which may include parent and
          subsidiary companies, joint ventures, or other companies under common
          control, in which case we will require our affiliates to honor this
          Privacy Notice. If we sell or otherwise transfer part or all of BCult
          or our assets to another organization (i.e., in the course of a
          transaction such as a merger, acquisition, bankruptcy, dissolution,
          liquidation, etc.), your information such as name and email address,
          user content and/or any other information collected through the
          Services may be among the items sold and/or transferred. We may also
          share personal information with our auditors, attorneys or other
          advisors in connection with corporate functions; and
        </li>

        <li>
          Finally, we also share aggregated, anonymized or statistical
          information about you, including demographics data, with others for a
          variety of purposes, for example, for improving the Services for BCult
          and others.
        </li>
      </ul>
      <P>
        <strong>Information Choices</strong>
      </P>
      <P>
        We respect your privacy and give you an opportunity to opt out of
        receiving announcements of certain information. Although we think that
        you will benefit from a more personalized experience when we know more
        about you and what you like, you can limit the information you provide
        to BCult, and you can limit the communications that BCult sends to you.
        If you visit the Services and volunteer personally identifiable
        information, you can opt out of receiving notifications, and you may
        review, modify, update, and/or delete such personally identifiable
        information by contacting and/or by changing your preferences on the
        Service. Please be aware that even after your request for a change is
        processed, BCult may, for a time, retain residual information about you
        in its backup and/or archival copies of its database, and reserves the
        right to access and utilize collected user data in the future. Please be
        aware that we may still email you non-commercial emails related to your
        account and your transactions through the Services. Following
        termination and/or deactivation of your account, BCult may retain
        collected information and data (including without limitation your
        profile information) for a commercially reasonable time for backup,
        archival, and/or audit purposes, and reserves the right to access and
        utilize collected user data in the future. In some cases, if you choose
        not to provide BCult with requested information, you may not be able to
        use and/or access all of the Services.
      </P>
      <P></P>
      <P>
        <strong>Your California Privacy Rights</strong>
      </P>
      <P>
        Under California Consumer Privacy Act (<strong>“CCPA”</strong>),
        California residents are entitled to receive the right to access and
        obtain a copy of your personal information. You may write us and ask for
        records of any and all personal information. The CCPA requires
        businesses to disclose (1) the categories of personal information the
        business collected about you, (2) the categories of sources from which
        personal information is collected, (3) the business or commercial
        purpose for collecting or selling personal information, (4) the
        categories of third parties with whom the business shares personal
        information, and (5) the specific pieces of personal information the
        business has collected about you. To obtain such information from BCult,
        please email your request to support@BCult.com and we will provide a
        list of categories of personal information disclosed within thirty (30)
        days of receiving such a request. This request may be made no more than
        once per calendar year. We reserve the right not to respond to requests
        submitted in ways other than those specified above. The following
        <strong> </strong>exceptions may prevail with respect to fulfillment of
        data access requests: (a) BCult may not provide personal data to you, if
        management determines that we cannot identify you in our systems because
        the data we have has been de-identified to the extent that
        identification is not possible; and/or (b) BCult may not provide
        personal data to you, if you are involved in a civil or criminal
        proceeding with BCult.
      </P>
      <P>
        <strong>Children</strong>
      </P>
      <P>
        The Services are not directed at children. BCult requires everyone to be
        at least thirteen (13) years old before they create an account (in some
        areas, the age limit may be higher). We do not knowingly collect
        personally identifiable information online from individuals under the
        age of 13. If we learn that we have collected personal information from
        an individual under age thirteen (13), we will take steps to delete such
        information from our systems. If you are a parent or guardian and would
        like to report an account belonging to someone under 13, or if you
        believe your child has provided us with personal information without
        your consent, please contact us at support@BCult.com.
      </P>
      <P>
        <strong>Your Preferences for Communications.</strong>
      </P>
      <P>
        If we process your personal information for the purpose of sending you
        marketing communications, you may manage your receipt of marketing and
        non-transactional communications from BCult by clicking on the
        unsubscribe link located on the bottom of BCult marketing emails. Please
        note that opting out of marketing communications does not opt you out of
        receiving important business communications.
      </P>
      <P>
        <strong>Security</strong>
      </P>
      <P>
        We take appropriate precautions including organizational, technical and
        physical measures to help safeguard against accidental or unlawful
        destruction, personal information we process or use. We follow generally
        accepted industry standards to protect the personal information
        submitted to us, both during transmission and once we receive it. No
        method of storage or transmission is 100% secure. The internet is not a
        completely secure environment, and BCult cannot warrant the security of
        any information you transmit to BCult or guarantee that information on
        the Services may not be accessed, disclosed, altered, and/or destroyed
        by breach of any of our physical, technical and/or managerial
        safeguards. Please do your part to help BCult. You are solely
        responsible for protecting your password and account information,
        limiting access to your devices, signing out of the Services after your
        sessions, and for controlling access to emails between you and BCult, at
        all times. We are not responsible for the functionality, privacy and/or
        security measures of any other organization.
      </P>
      <P>
        <strong>Data Retention</strong>
      </P>
      <P>
        We will retain your personal data for as long as necessary to fulfill
        the purposes we collected it for, including for the purposes of
        satisfying any legal, accounting, or reporting requirements. To
        determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorized use and/or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements. In some circumstances we may anonymize
        your personal data (so that it can no longer be associated with you) for
        research or statistical purposes in which case we may use this
        information indefinitely without further notice to you. We may retain
        information (including without limitation your personally identifiable
        information) for a commercially reasonable time for backup, archival,
        audit purposes, and/or to comply with legal obligations, resolve
        disputes and enforce agreements. In some cases, if you choose not to
        provide BCult with requested information, you may not be able to use
        and/or access all of the Services. You can request further details of
        retention periods for different aspects of your personal data by
        contacting us.
      </P>
      <P>
        <strong>Data Transfers </strong>
      </P>
      <P>
        Please be aware that the Services are subject to United States laws,
        including those governing privacy and security of your information. By
        using the Services, you agree and consent (and represent that you have
        the authority to provide such consent) to the information collection,
        use and sharing practices described in this Privacy Notice and
        understand that the laws of the United States, and territories related
        thereto, may differ from those of other countries and may not be as
        protective as the laws in the country where you reside. Regardless of
        the laws in place in such countries, we will treat the privacy of your
        information in accordance with this Privacy Notice.
      </P>
      <P>
        <strong>Third Party Links, Web Sites and Services</strong>
      </P>
      <P>
        This Privacy Notice does not apply to the activities of third parties
        when they are collecting or using data for their own purpose or on
        behalf of others. We are not responsible for the practices employed by
        any third parties, including their websites and/or services linked to
        and/or from the Services, or the information and/or content contained
        therein. We encourage you to review their privacy policies to understand
        how they use your information. You agree that BCult is not responsible,
        and does not have control over, any third parties that access your
        personal information. If you are using a third-party website and/or
        service and you allow them to access your personal information, you do
        so at your own risk.
      </P>
      <P>
        <strong>Changes to Privacy Notice</strong>.
      </P>
      <P>
        In general, changes will be made to this Privacy Notice to address new
        or modified laws and/or new or modified business procedures. We may
        update this Privacy Notice at any time, with or without advance notice,
        so please review it periodically. We will update the ‘Last Modified’
        date above and may provide you additional forms of notice of
        modifications and/or updates as appropriate under the circumstances. You
        can determine when this Privacy Notice was last revised by referring to
        the date it was “Last Modified” above. Your continued use of the
        Services after any modification to this Privacy Notice will constitute
        your acceptance of such modifications and/or updates.
      </P>
      <P>
        <strong>How to Contact Us</strong>
      </P>
      <P>
        If you have questions and/or comments about this Privacy Notice, please
        email us at support@BCult.com. Because email communication is not always
        secure, please do not include sensitive personal information (such as
        race, ethnic origin, health information, financial and credit card
        information) in your emails to us.
      </P>
      <P>
        <strong>BCult contact information:</strong>
      </P>
      <P>BCult</P>
      <P>600 N Broad Street Suite 5 #3318</P>
      <br />
      <P>Middletown, DE 19709</P>
      <P>support@BCult.com</P>
    </Box>
  );
}

export default Terms;
