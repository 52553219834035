import { createReducer, createActions } from 'reduxsauce';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';

const PAYMENT_DESC =
  'Guided workouts\nMeal plan with shopping list\nWeekly check-ins and coaching';

const { Types, Creators } = createActions({
  setSubscriptionProgramLoadRequest: ['programId'],
  setSubscriptionProgram: ['programInfo'],
  setSubscriptionGoal: ['goal'],
  setSubscriptionGoalSuccess: ['goal'],
  setClearSubscription: null,
  setSubscriptionLoading: ['isLoading'],
  setSubscriptionMaking: ['isSubscribing'],
  setSubscriptionMakeRequest: ['data'],
  setSubscriptionError: ['error'],
});

export const SubscriptionTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  error: false,
  isLoading: true,
  isSubscribing: false,
  programInfo: null,
  goal: {},
};

/* ------- Selectors --------- */
export const SubscriptionSelectors = {
  selectSubscribingProgramId: (state) =>
    get(state, 'subscription.programInfo.id'),
  selectSubscribingProgram: (state) => state.subscription.programInfo,
  selectGoal: (state) => state.subscription.goal,
  selectIsLoading: (state) => state.subscription.isLoading,
  selectIsSubscribing: (state) => state.subscription.isSubscribing,
  selectPaymentDescription: (state) =>
    get(state, 'subscription.programInfo.paymentDescription', PAYMENT_DESC) ||
    PAYMENT_DESC,
  selectIndividualPrice: (state) =>
    Math.ceil((get(state, 'subscription.programInfo.price') / 6) * 10),
  selectProgramStripeAccount: (state) =>
    get(state, 'subscription.programInfo.user.vendor.stripeUserId'),
};

/* -------- Reducers ---------- */
export const setSubscriptionProgram = (state, { programInfo }) => {
  return {
    ...state,
    programInfo,
  };
};

export const setSubscriptionGoalSuccess = (state, { goal }) => {
  return {
    ...state,
    goal,
  };
};

export const setSubscriptionLoading = (state, { isLoading }) => {
  return {
    ...state,
    isLoading,
  };
};

export const setSubscriptionSubmitting = (state, { isSubscribing }) => {
  return {
    ...state,
    isSubscribing,
  };
};

export const setSubscriptionError = (state, { error }) => {
  return {
    ...state,
    error,
  };
};

export const setClearSubscription = () => {
  return cloneDeep(INITIAL_STATE);
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SUBSCRIPTION_LOADING]: setSubscriptionLoading,
  [Types.SET_SUBSCRIPTION_MAKING]: setSubscriptionSubmitting,
  [Types.SET_SUBSCRIPTION_PROGRAM]: setSubscriptionProgram,
  [Types.SET_CLEAR_SUBSCRIPTION]: setClearSubscription,
  [Types.SET_SUBSCRIPTION_GOAL_SUCCESS]: setSubscriptionGoalSuccess,
  [Types.SET_SUBSCRIPTION_ERROR]: setSubscriptionError,
});
