import React from 'react';
import styled from 'styled-components';

import { Box, Body3, Caption3 } from 'components/core';

import { colors } from 'styles/theme';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid ${colors.line};
  width: 100%;
`;

const MainWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
`;

const BottomTitle = styled(Body3)`
  position: absolute;
  font-weight: 400;
  bottom: 0;
`;

const DaysRow = styled(Box)`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 1080px;
`;

export const DayWrapper = styled(Box)`
  width: 70.8px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1070px;
  height: 350px;
  position: relative;
`;

const Background = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 8px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.5;
  z-index: 0;
`;

const BmiRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

export const InfoContainer = styled(Box)`
  width: 104px;
  height: 390px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid ${colors.line};
  text-transform: capitalize;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    width: 60px;
  }
`;

export const YTickLabel = styled(Body3)`
  position: absolute;
  top: ${(props) => props.pos}px;
  right: 0px;
`;

export const YAxisLabel = styled(Caption3)`
  position: absolute;
  bottom: 125px;
  left: 10px;
  white-space: pre;

  /* Border is the new background */
  background: none;

  /* Rotate from top left corner (not default) */
  transform-origin: 0 0;
  transform: rotate(-90deg);
`;

const ChartContainer = ({
  title,
  children,
  yLabels,
  xLabels,
  tickSizes,
  yAxisLabel,
  colorOptions,
  bottomTitle,
  ...props
}) => {
  let letPrev = 0;
  const tickPositions = [0].concat(
    tickSizes.map((item) => {
      const val = letPrev + item;
      letPrev = val;
      return val;
    })
  );

  return (
    <Wrapper my={4} {...props}>
      <InfoContainer>
        <Body3>{title}</Body3>
        {yAxisLabel && <YAxisLabel>{yAxisLabel}</YAxisLabel>}
        <Background>
          {yLabels.map((label, index) => (
            <YTickLabel key={label} pos={tickPositions[index] + 40}>
              {label}
            </YTickLabel>
          ))}
        </Background>
        {bottomTitle && <BottomTitle>{bottomTitle}</BottomTitle>}
      </InfoContainer>
      <MainWrapper px={2}>
        <DaysRow>
          {xLabels.map((day) => (
            <DayWrapper key={day}>
              <Body3>{day}</Body3>
            </DayWrapper>
          ))}
        </DaysRow>
        <ChartWrapper>
          <Background>
            {colorOptions.map((name, index) => (
              <BmiRow key={name} bg={`bmi${name}`} height={tickSizes[index]} />
            ))}
          </Background>
          <Box zIndex="1">{children}</Box>
        </ChartWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

ChartContainer.defaultProps = {
  colorOptions: ['Obese', 'Over', 'Ideal', 'Under'],
};

export default ChartContainer;
