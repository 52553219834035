import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Box,
  Select,
  BInput,
  BTextArea,
  Button,
  Title1,
} from 'components/core';
import { FormUpload } from 'components/upload';

import { GOAL_OPTIONS } from 'services/terms';
import { getIsValid, generateUploadKey } from 'utils/form';
import { getCategoryImageKey } from 'utils/image';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

const category = 'weight';
const isValid = getIsValid(['type', 'value']);

function WeightForm({
  userId,
  userGender,
  position,
  onSubmit,
  value: initialValue = {},
  ...props
}) {
  const [type, setType] = useState(initialValue.type || 'weight');
  const [note, setNote] = useState(initialValue.note || '');
  const [value, setValue] = useState(initialValue.value || '');

  const [assetType, setAssetType] = useState(initialValue.assetType || 'image');
  const [assetUrl, setAssetUrl] = useState(initialValue.assetUrl || '');
  const [thumbUrl, setThumb] = useState(initialValue.thumbUrl || '');
  const [isDisabled, setIsDisabled] = useState(false);

  const { day } = position || { day: 1 };
  const uploadKey = generateUploadKey(userId, category, 0, day);
  const videoCategoryImage = getCategoryImageKey(category, type, userGender);

  const resetValues = (values) => {
    setType(values.type || 'weight');
    setNote(values.note);
    setValue(values.value);
  };

  const onUpload = ({ assetUrl, type, fileKey }) => {
    setAssetType(type);
    setAssetUrl(assetUrl);
    if (type === 'image') {
      setThumb(`https://bcult.imgix.net/${fileKey}`);
    } else if (type === 'video') {
      setThumb(`https://bcult.imgix.net/${videoCategoryImage}`);
    } else {
      setThumb('https://source.unsplash.com/random/105x155');
    }
    setIsDisabled(false);
  };

  const onReset = () => {
    setAssetType('');
    setAssetUrl('');
    setThumb('');
  };

  useEffect(() => {
    resetValues(initialValue);
  }, [initialValue]);

  const onChangeType = ({ value: newType }) => {
    setType(newType);
  };

  const onChangeValue = (e) => {
    const { value } = e.target;

    setValue(value);
  };

  const onChangeNote = (e) => {
    const { value } = e.target;

    setNote(value);
  };

  const onSubmitForm = () => {
    const submitValue = {
      type,
      value,
      note,
      assetType,
      assetUrl,
      thumbUrl,
    };

    if (!isValid(submitValue)) {
      return;
    }
    if (onSubmit) {
      onSubmit(submitValue);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      alignSelf="stretch"
      alignItems={['center', 'flex-start']}
      justifyContent="flex-start"
      {...props}
    >
      <FormUpload
        category={category}
        thumbUrl={thumbUrl}
        uploadKey={uploadKey}
        assetType={assetType}
        assetUrl={assetUrl}
        onReset={onReset}
        onUpload={onUpload}
        onUploadProgress={(isUploading) => {
          setIsDisabled(isUploading);
        }}
      />

      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems={['center', 'flex-start']}
        justifyContent="flex-start"
        py={[2, 8]}
        pr={[0, 6]}
      >
        <Title1 mb={[4, 8]}>Weight</Title1>
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="flex-start"
          mb={[2, 4]}
        >
          <Select
            placeholder="Type"
            value={type}
            options={GOAL_OPTIONS}
            onChange={onChangeType}
            mr={6}
          />
          <BInput value={value} placeholder="" onChange={onChangeValue} />
        </Box>
        <TextArea
          placeholder="Note..."
          value={note}
          onChange={onChangeNote}
          mb={6}
        />
        <Button
          variant="secondary"
          onClick={onSubmitForm}
          disabled={isDisabled}
        >
          OK
        </Button>
      </Box>
    </Box>
  );
}

export default WeightForm;
