import React from 'react';
import styled from 'styled-components';

import { Box, DialogBox, Body1, Button, SvgIcon } from 'components/core';
import { colors } from 'styles/theme';

const LINE1 = 'Uncompleted programs may not be offered to other members.';
const LINE2 = 'Either enter a completed program';
const LINE3 = 'or complete a new ';
const LINE4 = 'program before submitting for gold.';

const Icon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  path {
    fill: ${colors.main};
  }
`;

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Inline = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
`;

function InvalidGoldModal({ show, onOk, ...props }) {
  return (
    <DialogBox show={show} onClickOutside={onOk}>
      <Box {...props} m={8}>
        <Wrapper mx={4} mt={9}>
          <Body1 textAlign="center">{LINE1}</Body1>
          <Inline>
            <Body1 textAlign="center" mr={2}>{LINE2}</Body1>
            <Icon name="program-completed" />
            <Body1 textAlign="left" ml={2}>{LINE3}</Body1>
          </Inline>
          <Body1 textAlign="center">{LINE4}</Body1>
        </Wrapper>

        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={9}
          mb={8}
        >
          <Button variant="secondary" mx={4} onClick={onOk}>
            OK
          </Button>
        </Box>
      </Box>
    </DialogBox>
  );
}

export default InvalidGoldModal;
