import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Checkbox, Body2, Body4 } from 'components/core';
import { colors } from 'styles/theme';

/**
 *
 * Options format
 *
 * { name, descriptions }
 */

const ItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  flex: 1;
  cursor: pointer;
`;

const ExpandBox = styled(Box)`
  ${(props) =>
    props.isSelected
      ? css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-self: stretch;
        `
      : css`
          display: none;
        `};
  transition: all 1s ease;
`;

export const Divider = styled(Box)`
  background-color: ${colors.text};
  opacity: 0.6;
  height: 1px;
  align-self: stretch;
`;

const PlanSelect = ({ plans = [], value, onSelect, hideBorder, ...props }) => {
  const handleSelect = (newValue) => {
    if (!onSelect) {
      return;
    }
    if (value === newValue) {
      onSelect(null);
      return;
    }
    onSelect(newValue);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="stretch"
      {...props}
    >
      {plans.map((plan, planIndex) => {
        const { name, description, title, price, duration } = plan;
        const isSelected = name === value;
        return (
          <React.Fragment key={name}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              alignSelf="stretch"
              mb={2}
            >
              <Checkbox
                variant="secondary"
                checked={isSelected}
                onChange={() => handleSelect(name)}
              />
              <Box display="flex" flexDirection="column" flex={1}>
                <ItemContainer ml={4} onClick={() => handleSelect(name)}>
                  <Body2 color="line">{title}</Body2>
                  <Body2 color="line">
                    {duration ? `${price}/${duration}` : price}
                  </Body2>
                </ItemContainer>
                {description && (
                  <ExpandBox my={2} ml={4} isSelected={isSelected}>
                    {description.map((item) => (
                      <Body4 key={item} color="line">
                        {`- ${item}`}
                      </Body4>
                    ))}
                  </ExpandBox>
                )}
              </Box>
            </Box>
            {planIndex !== plans.length - 1 && !hideBorder && (
              <Divider mb={2} />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default PlanSelect;
