import { createReducer, createActions } from 'reduxsauce';

import { getCurrentDayInfo, getDiffDayInfo } from 'utils/date';

const currentDayInfo = getCurrentDayInfo();

const { Types, Creators } = createActions({
  setCurrentCalendarDay: ['date'],
  setCalendarGoNext: ['isMonth'],
  setCalendarGoPrev: ['isMonth'],
});

export const CalendarTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  ...currentDayInfo,
};

/* ------- Selectors --------- */
export const CalendarSelectors = {
  selectCurrentYear: (state) => state.calendar.year,
  selectCurrentDate: (state) => state.calendar.date,
  selectCurrentMonth: (state) => state.calendar.month,
};

/* -------- Reducers ---------- */
export const setCalendarDay = (state, { date }) => {
  return {
    ...state,
    ...date,
  };
};

export const setGoNext = (state, { isMonth }) => {
  const newValues = getDiffDayInfo(state, isMonth ? 'month' : 'd', 1);
  return {
    ...state,
    ...newValues,
  };
};

export const setGoPrev = (state, { isMonth }) => {
  const newValues = getDiffDayInfo(state, isMonth ? 'month' : 'd', -1);
  return {
    ...state,
    ...newValues,
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_CALENDAR_DAY]: setCalendarDay,
  [Types.SET_CALENDAR_GO_NEXT]: setGoNext,
  [Types.SET_CALENDAR_GO_PREV]: setGoPrev,
});
