import React from 'react';
import styled from 'styled-components';
import get from 'lodash-es/get';

import { Box, Body2DarkCondensed } from 'components/core';
import CalendarButton from './CalendarButton';

import { colors } from 'styles/theme';
import { DAYS_TITLE, getMonthRows, isDateToday } from 'utils/date';

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  border-bottom: 1px solid ${colors.text};

  &:last-child {
    border-bottom: 0;
  }
  @media (max-width: 768px) {
    border-bottom: 0;
  }
`;

const HeaderText = styled(Box)`
  position: relative;
  width: 141.4px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    width: 40px;
  }
`;

function DateView({
  densed,
  month,
  year,
  date,
  tasksMap = {},
  onSelect,
  ...props
}) {
  const rows = getMonthRows(month, year);

  const renderRow = (row) => {
    const { days } = row;
    return days.map((momentDate) => {
      const key = `calendar_btn_${momentDate.unix()}`;
      const dateNum = momentDate.date();
      const monthNum = momentDate.month();
      const yearNum = momentDate.year();
      const weekday = momentDate.day();

      const isToday = isDateToday(momentDate);
      const isWeekDay = weekday % 6 !== 0;
      const isOtherMonth = monthNum !== month;
      const isSelected =
        month === monthNum && year === yearNum && date === dateNum;

      const data = get(tasksMap, `${yearNum}-${monthNum}.${dateNum}`);

      return (
        <CalendarButton
          densed={densed}
          key={key}
          year={yearNum}
          month={monthNum}
          date={dateNum}
          isToday={isToday}
          isWeekDay={isWeekDay}
          isOtherMonth={isOtherMonth}
          isSelected={isSelected}
          tasks={data}
          onSelect={onSelect}
        />
      );
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignSelf="stretch"
      flex={['unset', 1]}
      {...props}
    >
      <Row>
        {DAYS_TITLE.map((day) => (
          <HeaderText key={day}>
            <Body2DarkCondensed textAlign="center">{day}</Body2DarkCondensed>
          </HeaderText>
        ))}
      </Row>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {rows.map((row) => {
          const { week } = row;
          const key = `calendar_row_${month}_${week}`;
          return <Row key={key}>{renderRow(row)}</Row>;
        })}
      </Box>
    </Box>
  );
}

export default DateView;
