import styled from 'styled-components';
import { space } from 'styled-system';
import Box from './Box';

const InputGroup = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  border-width: 0px;
  border-style: solid;
  ${space}

  & > ${Box} {
    width: 100%;
    flex: 1 1 auto;
  }

`;

InputGroup.propTypes = {
  ...space.propTypes
};

export default InputGroup;
