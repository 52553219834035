import { createReducer, createActions } from 'reduxsauce';
import get from 'lodash-es/get';
import countBy from 'lodash-es/countBy';
import { convertProgramsToTask } from 'utils/transform';
import { PROGRAM_STATUS } from '../services/terms';

const { Types, Creators } = createActions({
  setProfileView: ['view'],
  setLoadMyProgramsRequest: null,
  setMyPrograms: ['myPrograms'],
  updateMyProgram: ['newProgram'],
  deleteMyProgram: ['programId'],
  addMyProgram: ['newProgram'],
  batchUpdateMyPrograms: ['newPrograms'],
  setRecordRequest: ['programId', 'data'],
  setProfileLoading: ['isLoading'],
  updateProgramAction: ['programId', 'sectionId', 'day', 'data'],
});

export const ProfileTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  view: 'current',
  myPrograms: [],
  programTasks: [],
  isLoading: false,
};

/* ------- Selectors --------- */
const selectCurrentActiveList = (state) =>
  state.profile.myPrograms.filter(
    (item) => item.status === PROGRAM_STATUS.CURRENT
  );
const selectCreatedList = (state) =>
  state.profile.myPrograms.filter(
    (item) =>
      get(item, 'user.id') === get(state, 'auth.me._id') &&
      (item.status !== PROGRAM_STATUS.CURRENT ||
        item.status === PROGRAM_STATUS.DRAFT)
  );

const selectCompletedList = (state) =>
  state.profile.myPrograms.filter(
    (item) => item.status === PROGRAM_STATUS.COMPLETED
  );

const selectTaskMap = (state) =>
  convertProgramsToTask(selectCurrentActiveList(state));

export const ProfileSelectors = {
  selectProfileView: (state) => state.profile.view,
  selectAllMyPrograms: (state) => state.profile.myPrograms,
  selectProgram: (id) => (state) =>
    state.profile.myPrograms.find((p) => p.id === id),
  selectProfileProgramList: (state) => {
    switch (state.profile.view) {
      case 'current':
        return state.profile.myPrograms.filter(
          (item) =>
            item.status === PROGRAM_STATUS.CURRENT ||
            (get(item, 'user.id') !== get(state, 'auth.me._id') &&
              item.status === PROGRAM_STATUS.DRAFT)
        );
      case 'completed':
        return selectCompletedList(state);
      default:
    }
    return selectCreatedList(state);
  },
  selectCurrentActiveList,
  selectCompletedList,
  selectCreatedList,
  selectCountOfCurrentList: (state) =>
    countBy(
      state.profile.myPrograms,
      (item) => item.status === PROGRAM_STATUS.CURRENT
    ),
  selectTaskMap,
};

/* -------- Reducers ---------- */
export const setProfileView = (state, { view }) => {
  return {
    ...state,
    view,
  };
};

export const setProfileLoading = (state, { isLoading }) => {
  return {
    ...state,
    isLoading,
  };
};

export const setMyPrograms = (state, { myPrograms }) => {
  return {
    ...state,
    myPrograms,
  };
};

export const updateMyProgram = (state, { newProgram }) => {
  return {
    ...state,
    myPrograms: state.myPrograms.map((item) =>
      item.id === newProgram.id ? newProgram : item
    ),
  };
};

export const deleteMyProgram = (state, { programId }) => {
  return {
    ...state,
    myPrograms: state.myPrograms.filter((item) => item.id !== programId),
  };
};

export const addMyProgram = (state, { newProgram }) => {
  return {
    ...state,
    myPrograms: state.myPrograms.concat([newProgram]),
  };
};

export const batchUpdateMyPrograms = (state, { newPrograms }) => {
  return {
    ...state,
    myPrograms: state.myPrograms.map((item) => {
      const findItem = newPrograms.find(
        (newProgram) => newProgram.id === item.id
      );
      return findItem
        ? {
            ...findItem,
            user: item.user,
          }
        : item;
    }),
  };
};

export const updateProgramAction = (
  state,
  { programId, sectionId, day, data }
) => {
  return {
    ...state,
    myPrograms: state.myPrograms.map((item) => {
      if (item.id !== programId) {
        return item;
      }

      const sectionIndex = item.program.sectionIds.indexOf(sectionId);

      return {
        ...item,
        program: {
          ...item.program,
          data: item.program.data.map((d, index) =>
            index === sectionIndex
              ? {
                  ...d,
                  [day]: {
                    ...d[day],
                    ...data,
                  },
                }
              : d
          ),
        },
      };
    }),
  };
};

export const setRecordRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PROFILE_VIEW]: setProfileView,
  [Types.SET_PROFILE_LOADING]: setProfileLoading,
  [Types.SET_MY_PROGRAMS]: setMyPrograms,
  [Types.UPDATE_MY_PROGRAM]: updateMyProgram,
  [Types.DELETE_MY_PROGRAM]: deleteMyProgram,
  [Types.ADD_MY_PROGRAM]: addMyProgram,
  [Types.BATCH_UPDATE_MY_PROGRAMS]: batchUpdateMyPrograms,
  [Types.SET_RECORD_REQUEST]: setRecordRequest,
  [Types.UPDATE_PROGRAM_ACTION]: updateProgramAction,
});
