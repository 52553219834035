import styled from 'styled-components';
import Text from './Text';

const LinkText = styled(Text)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  text-decoration: underline;
`;

export default LinkText;
