import React, { useRef } from 'react';
import get from 'lodash-es/get';
import Chart, { defaults } from 'react-chartjs-2';
import ChartContainer from './ChartContainer';

import { colors } from 'styles/theme';
import { convertToRangedScale } from 'services/graph';

// Disable animating charts by default.
defaults.global.animation = false;
// @TODO apply different regions
// const colorOptions = ['Obese', 'Over', 'Ideal', 'Under'];

const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const HEIGHT = 350;
const STEP_SIZE = 0.5;
const RANGE_COUNT = 4;
const REGION_HEIGHT = HEIGHT / RANGE_COUNT;
const TICK_SIZES = [REGION_HEIGHT, REGION_HEIGHT, REGION_HEIGHT, REGION_HEIGHT];

const CommonBarChart = ({
  isBigGraph,
  containerSize,
  recordData,
  sectionType,
  ranges,
  unit,
  yLabels,
  ...props
}) => {
  const chartRef = useRef();

  const sectionData = get(recordData, sectionType);
  const xLabels = get(sectionData, 'labels', DAYS);
  const graphData = get(sectionData, 'data', []);
  const infoData = get(sectionData, 'info', {});

  const { title } = infoData;

  const data = {
    labels: xLabels,
    datasets: graphData.map((dataset) => {
      const values = unit
        ? dataset.dataValues.map((item) => get(item, unit))
        : dataset.dataValues;

      return {
        data: values.map((v) => convertToRangedScale(v, ranges)),
        label: dataset.label,
        borderColor: colors.white,
        backgroundColor: colors.white,
        fill: false,
        steppedLine: true,
        spanGaps: false,
        minHeight: 5,
      };
    }),
  };

  const options = {
    responsive: false,
    legend: {
      display: false,
    },
    hover: {
      animationDuration: 0,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: 100,
            min: 0,
            stepSize: STEP_SIZE,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            max: 15,
            min: 1,
            stepSize: 1,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: -10,
      },
    },
    animation: {
      easing: 'linear',
      onComplete: function () {
        var ctx = this.chart.ctx;
        var chartInstance = this.chart;

        ctx.font = '500 14px Arial';
        ctx.textBaseline = 'bottom';

        const totalDataSetLength = this.data.datasets.length;
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            if (data > 0 && totalDataSetLength > 0) {
              ctx.save();
              ctx.translate(bar._model.x, bar._model.base);
              ctx.rotate(-Math.PI / 2);
              ctx.fillStyle = colors.text;
              ctx.textAlign = 'left';
              ctx.fillText(dataset.label, 5, 7);
              ctx.restore();
            }
          });
        });
      },
    },
  };

  return (
    <ChartContainer
      title={title}
      tickSizes={TICK_SIZES}
      xLabels={xLabels}
      yLabels={yLabels}
      {...props}
    >
      <Chart
        ref={chartRef}
        type="bar"
        width={1070}
        height={350}
        data={data}
        options={options}
      />
    </ChartContainer>
  );
};

export default CommonBarChart;
