import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { FullScreenLoader } from 'components/loading';
import { Box, Body2, Button } from 'components/core';

import API from 'services/api';
import history from 'utils/history';
import Analytics, { EVENTS } from 'services/analytics';

function VendorSettingsPage({ location }) {
  const [stripeError, setStripeErrror] = useState(null);
  const parseLocation = async () => {
    try {
      const parsed = QueryString.parse(location.search);
      const { code, error } = parsed;
      if (!code || error) {
        setStripeErrror(error);
        return;
      }

      await API.connectVendor(code);

      Analytics.track(EVENTS.USER_CONNECT_STRIPE, {
        code,
      });
      history.replace('/settings');
    } catch (err) {
      setStripeErrror('Would you try again?');
    }
  };

  useEffect(() => {
    parseLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToSettings = () => {
    history.replace('/settings');
  };

  if (!stripeError) {
    return <FullScreenLoader />;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" p={8}>
      <Body2 mb={4}>{stripeError}</Body2>
      <Button variant="secondary" width={250} onClick={goToSettings}>
        Go to settings
      </Button>
    </Box>
  );
}

export default VendorSettingsPage;
