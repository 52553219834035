import React from 'react';
import { Box, IconButton, SvgIcon, Tooltip } from 'components/core';

const ProgramToolbar = ({ options = [], onSelect, ...props }) => {
  const handleSelect = (name) => {
    if (onSelect) {
      onSelect(name);
    }
  };
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="flex-end"
      {...props}
    >
      {options.map((toolbarOption) => {
        const { name, disabled, selected, help } = toolbarOption;
        return (
          <Tooltip
            key={name}
            animation="scale"
            delay={[300, 0]}
            duration={300}
            content={help || name}
            theme="light-border"
            placement="bottom"
          >
            <span>
              <IconButton
                mx={1}
                key={name}
                size="large"
                bg="background"
                borderWidth={selected ? 1 : 0}
                disabled={disabled}
                onClick={() => handleSelect(name)}
              >
                <SvgIcon name={`toolbar-${name}`} />
              </IconButton>
            </span>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default ProgramToolbar;
