import moment from 'moment';

const SAFE_DATE = '06-06';

export const DAYS_TITLE = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const MONTH_TITLE = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SHORT_MONTH_TITLE = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function getCurrentDayInfo() {
  const momentDate = moment();

  const dateNum = momentDate.date();
  const monthNum = momentDate.month();
  const yearNum = momentDate.year();

  return {
    date: dateNum,
    year: yearNum,
    month: monthNum,
  };
}

export function getDiffDayInfo({ year, date, month }, unit, value) {
  let momentDate = moment({ year, month, date: 15 });
  if (unit !== 'month') {
    momentDate = moment({ year, date, month });
  }

  if (value > 0) {
    momentDate.add(value, unit);
  } else {
    momentDate.subtract(value * -1, unit);
  }

  const dateNum = momentDate.date();
  const monthNum = momentDate.month();
  const yearNum = momentDate.year();

  return {
    date: dateNum,
    year: yearNum,
    month: monthNum,
  };
}

export function getMonthTitle(month) {
  return MONTH_TITLE[month];
}

export function getShortMonthTitle(month = 1) {
  return SHORT_MONTH_TITLE[month];
}

export function getMonthRows(month, year = 2020) {
  const momentDate = moment();
  if (month || month === 0) {
    momentDate.year(year).month(month).date(15);
  }

  const startWeek = momentDate.startOf('month').week();
  let endWeek = momentDate.endOf('month').week();

  const weekInfos = [];
  const targetWeek = endWeek < startWeek ? 52 : endWeek;
  for (let week = startWeek; week <= targetWeek; week++) {
    weekInfos.push({
      week,
      year,
    });
  }

  if (endWeek < startWeek) {
    for (let week = 1; week <= endWeek; week += 1) {
      weekInfos.push({
        week: 52 + week,
        year: year,
      });
    }
  }

  const rows = weekInfos.map((infoItem) => {
    const { week: infoWeek, year: infoYear } = infoItem;
    const weekStartDate = moment(`${infoYear}-${SAFE_DATE}`, 'YYYY-MM-DD')
      .week(infoWeek)
      .startOf('week');

    return {
      week: infoWeek,
      year: infoYear,
      days: Array(7)
        .fill(0)
        .map((n, i) => {
          return weekStartDate.clone().add(n + i, 'day');
        }),
    };
  });

  return rows;
}

export function isDateToday(momentDate) {
  return momentDate.isSame(moment(), 'd');
}

export function getCurrentDayForProgram(startDate) {
  if (!startDate) {
    return 1;
  }
  const diff = moment()
    .startOf('day')
    .diff(moment(startDate).startOf('day'), 'day');

  return diff + 1;
}

export function getCurrentDateOfProgram(startDate, currentDay = 1) {
  const clonedDate = moment(startDate).startOf('day');
  clonedDate.add(currentDay - 1);

  return clonedDate;
}
