// function getimgBlob(imageUrl) {
//   return fetch(imageUrl || `${process.env.PUBLIC_URL}/share.png`).then(
//     function (response) {
//       return response.blob();
//     }
//   );
// }

export async function copyToClipboard(text, imageUrl) {
  try {
    navigator.clipboard.writeText(text);
    // const blob = await getimgBlob(imageUrl);
    // navigator.clipboard.write([
    //   new window.ClipboardItem({
    //     [imageUrl ? 'image/jpeg' : 'image/png']: blob,
    //   }),
    // ]);
  } catch (error) {
    console.error(error);
  }
}
