import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  PSelectButton,
  DialogBox,
  Body1Bold,
  Body1,
  Button,
} from 'components/core';
import useViewport from 'hooks/useViewport';

const TypeRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ButtonRow = styled(Box)`
  width: 80px;
`;

const CREATE_NEW_TITLE = 'Would you like to create a new or completed program?';

const TEXT_LINES = [
  {
    title: 'New programs',
    textLines:
      'start on day one.  You may add templates and day one activities. Track your progress and add components daily.',
  },
  {
    title: 'Completed programs',
    textLines:
      'are ones you have already finished. Add media and program components as well as progress info for the entire length of your program. You may then offer your program for free or for a price.',
  },
];

function ChooseCreateModal({ show, options, value, onOk, onChange, ...props }) {
  const [mode, setMode] = useState(value);
  const { width } = useViewport();
  const isSmallView = width < 768;

  const handleClick = (name) => {
    setMode(name);
    if (onChange) onChange(name);
  };

  const renderSmallView = () => {
    const selectedTextItemIndex = mode === 'new' ? 0 : 1;

    const textItem = TEXT_LINES[selectedTextItemIndex];
    const { title, textLines } = textItem;

    return (
      <DialogBox show={show} onClickOutside={onOk} justifyContent="flex-start">
        <Box {...props} mt={9} mb={8} mx={[2, 8]}>
          <Body1Bold mb={4} textAlign="center">
            {CREATE_NEW_TITLE}
          </Body1Bold>

          <TypeRow mb={4}>
            {options.map((name, index) => {
              return (
                <PSelectButton
                  mr={index === 0 ? 4 : 0}
                  name={name}
                  key={name}
                  size={50}
                  selected={mode === name}
                  onClick={() => handleClick(name, index)}
                />
              );
            })}
          </TypeRow>

          <Box
            key={title}
            flex={1}
            display="flex"
            flexDirection="row"
            alignSelf="stretch"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            mx={4}
          >
            <Body1Bold mb={1} mr={1}>
              {title}
            </Body1Bold>
            {textLines.split(' ').map((item, index) => (
              <Body1 mb={1} mr={1} key={`${item}_${index}`}>
                {item}
              </Body1>
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignSelf="stretch"
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            <Button variant="secondaryPlus" mx={4} onClick={onOk}>
              OK
            </Button>
          </Box>
        </Box>
      </DialogBox>
    );
  };

  if (isSmallView) {
    return renderSmallView();
  }

  return (
    <DialogBox show={show} onClickOutside={onOk} justifyContent="flex-start">
      <Box {...props} mt={9} mb={8} mx={8}>
        <Body1Bold mb={1} textAlign="center">
          {CREATE_NEW_TITLE}
        </Body1Bold>

        <Box
          display="flex"
          flexDirection="column"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mx={4}
        >
          {TEXT_LINES.map((textItem, index) => {
            const { title, textLines } = textItem;
            const name = options[index];
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignSelf="stretch"
                alignItems="flex-start"
                justifyContent="center"
                mt={6}
              >
                <ButtonRow>
                  <PSelectButton
                    name={name}
                    size={50}
                    selected={mode === name}
                    onClick={() => handleClick(name, index)}
                  />
                </ButtonRow>
                <Box
                  key={title}
                  flex={1}
                  display="flex"
                  flexDirection="row"
                  alignSelf="stretch"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  mx={4}
                >
                  <Body1Bold mb={1} mr={1}>
                    {title}
                  </Body1Bold>
                  {textLines.split(' ').map((item, index) => (
                    <Body1 mb={1} mr={1} key={`${item}_${index}`}>
                      {item}
                    </Body1>
                  ))}
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={6}
        >
          <Button variant="secondaryPlus" mx={4} onClick={onOk}>
            OK
          </Button>
        </Box>
      </Box>
    </DialogBox>
  );
}

export default ChooseCreateModal;
