import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  Select,
  BInput,
  BTextArea,
  Button,
  Title1,
  OptionButton,
  Checkbox,
  Body2,
} from 'components/core';
import { FormUpload } from 'components/upload';

import { FITNESS_OPTIONS_FOR_PROGRESS_GRAPH } from 'services/terms';
import { getIsValid, generateUploadKey } from 'utils/form';
import { getCategoryImageKey } from 'utils/image';
import useViewport from 'hooks/useViewport';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

const ValueText = styled(BInput)`
  width: 97px;
`;

const OptionRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
`;

const isValid = getIsValid(['type', 'subType', 'value']);
const typeOptions = FITNESS_OPTIONS_FOR_PROGRESS_GRAPH;
const subTypeOptions = ['Reps', 'Sets', 'Miles', 'Minutes'];
const category = 'fitness';

function CardioForm({
  userId,
  userGender,
  position,
  onSubmit,
  allowRepeat = true,
  value: initialValue = {},
  ...props
}) {
  const { width } = useViewport();

  const [type, setType] = useState(initialValue.type || 'hiit');
  const [note, setNote] = useState(initialValue.note);
  const [value, setValue] = useState(initialValue.value);
  const [subType, setSubType] = useState(initialValue.subType);
  const [other, setOther] = useState(initialValue.other);

  const [assetType, setAssetType] = useState(initialValue.assetType || 'image');
  const [assetUrl, setAssetUrl] = useState(initialValue.assetUrl || '');
  const [thumbUrl, setThumb] = useState(initialValue.thumbUrl || '');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRepeated, setRepeated] = useState(false);

  const { day } = position || { day: 1 };
  const uploadKey = generateUploadKey(userId, 'master', 0, day);
  const videoCategoryImage = getCategoryImageKey(category, type);

  const onUpload = ({ assetUrl, type, fileKey }) => {
    setAssetType(type);
    setAssetUrl(assetUrl);
    if (type === 'image') {
      setThumb(`https://bcult.imgix.net/${fileKey}`);
    } else if (type === 'video') {
      setThumb(`https://bcult.imgix.net/${videoCategoryImage}`);
    } else {
      setThumb('https://source.unsplash.com/random/105x155');
    }
    setIsDisabled(false);
  };

  const onReset = () => {
    setAssetType('');
    setAssetUrl('');
    setThumb('');
  };

  const onChangeType = ({ value: newType }) => {
    setType(newType);

    if (newType !== 'other') {
      setOther('');
    }

    if (assetType === 'video') {
      setThumb(`https://bcult.imgix.net/${videoCategoryImage}`);
    }
  };

  const onChangeValue = (e) => {
    const { value } = e.target;

    setValue(value);
  };

  const onChangeOther = (e) => {
    const { value } = e.target;

    setOther(value);
  };

  const onChangeNote = (e) => {
    const { value } = e.target;

    setNote(value);
  };

  const onToggleOption = (option) => {
    setSubType(option);
  };

  const onSubmitForm = () => {
    const submitValue = {
      type,
      value,
      note,
      subType,
      unit: subType.toLowerCase(),
      other,
      assetType,
      assetUrl,
      thumbUrl,
    };
    if (!isValid(submitValue)) {
      return;
    }

    if (onSubmit) {
      onSubmit(submitValue, isRepeated);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      alignSelf="stretch"
      alignItems={['center', 'flex-start']}
      justifyContent="flex-start"
      {...props}
    >
      <FormUpload
        category={category}
        thumbUrl={thumbUrl}
        uploadKey={uploadKey}
        assetType={assetType}
        assetUrl={assetUrl}
        onReset={onReset}
        onUpload={onUpload}
        onUploadProgress={(isUploading) => {
          setIsDisabled(isUploading);
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems={['center', 'flex-start']}
        justifyContent="flex-start"
        py={[2, 8]}
        pr={[0, 6]}
      >
        <Title1 mb={[4, 8]}>Fitness</Title1>
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          alignSelf="stretch"
          alignItems="center"
          justifyContent="flex-start"
          mb={[2, 4]}
        >
          <Select
            minWidth="200px"
            placeholder="Type"
            value={type}
            options={typeOptions}
            onChange={onChangeType}
            width={[1, 'unset']}
            mr={[0, 6]}
          />
          <BInput
            disabled={type !== 'other'}
            value={other}
            placeholder="Other..."
            onChange={onChangeOther}
            mt={[2, 0]}
          />
        </Box>
        <OptionRow mb={[2, 4]}>
          {width < 768 ? (
            <Select
              minWidth="200px"
              placeholder="Options"
              value={subType}
              options={subTypeOptions}
              onChange={onToggleOption}
              width={[1, 'unset']}
              mr={[0, 6]}
            />
          ) : (
            subTypeOptions.map((name, index) => (
              <OptionButton
                px={8}
                key={name}
                name={name}
                selected={subType === name}
                onClick={() => onToggleOption(name, index)}
              >
                {name}
              </OptionButton>
            ))
          )}
        </OptionRow>
        <ValueText
          value={value}
          placeholder="#"
          onChange={onChangeValue}
          mb={[2, 4]}
        />
        <TextArea
          placeholder="Note..."
          value={note}
          onChange={onChangeNote}
          mb={[2, 4]}
        />
        {allowRepeat && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            alignSelf={['unset', 'stretch']}
            mb={[2, 6]}
          >
            <Checkbox
              variant="tick"
              size="sm"
              pt={`2px`}
              mr={1}
              checked={isRepeated}
              onChange={() => setRepeated(!isRepeated)}
            />
            <Body2>Repeat</Body2>
          </Box>
        )}
        <Button
          variant="secondary"
          onClick={onSubmitForm}
          disabled={isDisabled}
        >
          OK
        </Button>
      </Box>
    </Box>
  );
}

export default CardioForm;
