import React, { useState } from 'react';
import styled from 'styled-components';

import Box from './Box';
import IconButton from './IconButton';
import SvgIcon from './SvgIcon';
import { colors } from 'styles/theme';

const Content = styled(Box)`
  max-height: ${(props) => (props.open ? 'unset' : `${props.maxHeight}px`)};
  transition: all 0.3s;
  overflow-y: ${(props) => (props.open ? 'unset' : `hidden`)};
  align-self: stretch;
`;

const ExpandButton = styled(IconButton)`
  height: 24px;
  align-self: stretch;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background-color: ${(props) =>
    props.darkMode ? colors.text : 'transparent'};

  svg {
    path {
      stroke: ${(props) =>
        props.darkMode ? colors.contrastText : colors.text};
    }
  }
`;

const Collapsible = ({
  desned,
  initialOpen = false,
  maxHeight = 376,
  children,
  darkMode = false,
  onExpand,
  ...props
}) => {
  const [open, setOpen] = useState(initialOpen);

  const toggleOpen = () => {
    setOpen(!open);
    if (onExpand) {
      onExpand(!open);
    }
  };

  return (
    <Box {...props}>
      <Content maxHeight={maxHeight} open={open}>
        {children}
      </Content>
      <ExpandButton
        darkMode={darkMode}
        size="none"
        fontSize={0}
        onClick={toggleOpen}
      >
        {open ? <SvgIcon name="chevron-up" /> : <SvgIcon name="chevron-down" />}
      </ExpandButton>
    </Box>
  );
};

export default Collapsible;
