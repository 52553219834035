import React from 'react';
import styled from 'styled-components';

import Box from './Box';
import FoodBackImage from 'assets/FoodBack.png';
import BcultTextImage from 'assets/LogoText.png';

const Image1 = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: screen;
`;

const BCultContainer = styled(Box)`
  position: relative;
  width: 548px;
  background: white;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

function BcultLogo(props) {
  return (
    <BCultContainer {...props}>
      <img src={BcultTextImage} width="100%" alt="bCult" />
      <Image1 src={FoodBackImage} width="100%" alt="bCult" />
    </BCultContainer>
  );
}

export default BcultLogo;
