import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, SvgIcon, IconButton } from 'components/core';
import { colors } from 'styles/theme';

const MODAL_OPEN_CLASS = 'body--modal-open';

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Wrapper = styled(Box)`
  position: relative;
  z-index: 10000;
`;

const Modal = styled(Box)`
  display: flex;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: opacity 0.4s ease-out, background-color 0.4s ease-out;
  position: fixed;
  z-index: 99999;

  opacity: ${(props) => (props.show ? 1 : 0)};
  overflow-y: scroll;
`;

const ModalContainer = styled(Box)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-width: 400px;
  max-width: 880px;
  min-height: 24px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
  border-radius: 2px;

  @media (max-width: 768px) {
    min-width: 320px;
    max-width: 320px;
    min-height: 24px;
  }
`;

const ModalInnerContainer = styled(Box)`
  flex: 1;
  border: 1px solid ${colors.text};
  box-sizing: border-box;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

Modal.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};

const DialogBox = ({
  show,
  onClickOutside,
  hasClose = true,
  bg = 'background',
  backDropColor,
  ...props
}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add(MODAL_OPEN_CLASS);
    } else {
      document.body.classList.remove(MODAL_OPEN_CLASS);
    }

    return () => {
      document.body.classList.remove(MODAL_OPEN_CLASS);
    };
  }, [show]);

  return (
    <Wrapper>
      <Modal show={show} onClick={onClickOutside} bg={backDropColor}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ModalContainer bg={bg} {...props}>
            <ModalInnerContainer>
              {hasClose && (
                <CloseButton borderWidth={0} onClick={onClickOutside}>
                  <SvgIcon name="close" />
                </CloseButton>
              )}
              {props.children}
            </ModalInnerContainer>
          </ModalContainer>
        </div>
      </Modal>
    </Wrapper>
  );
};

DialogBox.propTypes = {
  show: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func,
};
export default DialogBox;
