/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Title1Bold, Box } from 'components/core';
import { DefaultLoader } from 'components/loading';
import { transformUser } from 'utils/transform';
import UserItem from './UserItem';
import useViewport from 'hooks/useViewport';

function UserListView({ title, loadData }) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const { width: vewportWith } = useViewport();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await loadData();
      setUsers(data.map(transformUser));
      setLoading(false);
    })();
  }, []);

  return (
    <Box>
      {title && <Title1Bold mb={5}>{title}</Title1Bold>}
      {loading && (
        <Box display="flex" p={4} justifyContent="center">
          <DefaultLoader />
        </Box>
      )}
      {users.map((userInfo) => (
        <UserItem key={userInfo.id} vewportWith={vewportWith} user={userInfo} />
      ))}
    </Box>
  );
}

export default UserListView;
