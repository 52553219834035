import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import range from 'lodash/range';
import Box from './Box';

const PageButton = styled(Box)`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({theme}) => theme.colors.main};
`;

const PageButtonInner = styled(Box)`
  background-color: ${({ selected, theme }) => selected ? theme.colors.bmiNormal : theme.colors.background };
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  font-weight: 600;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.main};
`;

function Pagination({ total, active, onChange }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {range(total).map(i => (
        <PageButton
          key={`button_${i}`}
          onClick={() => onChange(i)}
          my={1}
        >
          <PageButtonInner selected={active === i}>
            {i + 1}
          </PageButtonInner>
        </PageButton>
      ))}
    </Box>
  )
}

Pagination.propTypes = {
  options: PropTypes.array,
  tab: PropTypes.string,
  onChange: PropTypes.func
};

export default Pagination;
