import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles/theme';

const SvgWrapper = styled.svg`
  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `};
`;

export function Rating1({
  id = Math.random(),
  selected,
  width = 9,
  height = 24,
  ...props
}) {
  return (
    <SvgWrapper
      {...props}
      width={width}
      height={height}
      viewBox="0 0 9 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id={id} fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.44864 0.534058C3.55501 2.04808 0 6.60881 0 11.9999C0 17.3908 3.55488 21.9515 8.44836 23.4656C5.68541 20.4426 4 16.4181 4 12C4 7.58168 5.68552 3.55707 8.44864 0.534058Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44864 0.534058C3.55501 2.04808 0 6.60881 0 11.9999C0 17.3908 3.55488 21.9515 8.44836 23.4656C5.68541 20.4426 4 16.4181 4 12C4 7.58168 5.68552 3.55707 8.44864 0.534058Z"
        fill={selected ? colors.text : 'none'}
      />
      <path
        d="M8.44864 0.534058L9.18677 1.20872L11.6716 -1.50985L8.15308 -0.421266L8.44864 0.534058ZM8.44836 23.4656L8.15278 24.4209L11.6713 25.5096L9.1865 22.7909L8.44836 23.4656ZM1 11.9999C1 7.05957 4.25753 2.8775 8.74421 1.48938L8.15308 -0.421266C2.85249 1.21866 -1 6.15805 -1 11.9999H1ZM8.74395 22.5103C4.2574 21.1221 1 16.9401 1 11.9999H-1C-1 17.8416 2.85235 22.7809 8.15278 24.4209L8.74395 22.5103ZM9.1865 22.7909C6.58539 19.945 5 16.1588 5 12H3C3 16.6774 4.78543 20.9402 7.71023 24.1402L9.1865 22.7909ZM5 12C5 7.84101 6.5855 4.05466 9.18677 1.20872L7.71052 -0.140609C4.78555 3.05947 3 7.32235 3 12H5Z"
        fill={colors.text}
        mask={`url(#${id})`}
      />
    </SvgWrapper>
  );
}

export function Rating2({
  id = Math.random(),
  selected,
  width = 16,
  height = 24,
  ...props
}) {
  return (
    <SvgWrapper
      {...props}
      width={width}
      height={height}
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id={id} fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9193 0.509658C14.8204 0.178177 13.6552 0 12.4482 0C5.82083 0 0.448242 5.37258 0.448242 12C0.448242 18.6274 5.82083 24 12.4482 24C13.6552 24 14.8204 23.8218 15.9193 23.4903C13.1429 20.4646 11.4482 16.4302 11.4482 12C11.4482 7.5698 13.1429 3.5354 15.9193 0.509658Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9193 0.509658C14.8204 0.178177 13.6552 0 12.4482 0C5.82083 0 0.448242 5.37258 0.448242 12C0.448242 18.6274 5.82083 24 12.4482 24C13.6552 24 14.8204 23.8218 15.9193 23.4903C13.1429 20.4646 11.4482 16.4302 11.4482 12C11.4482 7.5698 13.1429 3.5354 15.9193 0.509658Z"
        fill={selected ? colors.text : 'none'}
      />
      <path
        d="M15.9193 0.509658L16.6561 1.18575L17.7329 0.0122575L16.2081 -0.447727L15.9193 0.509658ZM15.9193 23.4903L16.2081 24.4477L17.7329 23.9877L16.6561 22.8142L15.9193 23.4903ZM12.4482 1C13.5563 1 14.6243 1.16351 15.6304 1.46704L16.2081 -0.447727C15.0166 -0.807157 13.7541 -1 12.4482 -1V1ZM1.44824 12C1.44824 5.92487 6.37311 1 12.4482 1V-1C5.26854 -1 -0.551758 4.8203 -0.551758 12H1.44824ZM12.4482 23C6.37311 23 1.44824 18.0751 1.44824 12H-0.551758C-0.551758 19.1797 5.26854 25 12.4482 25V23ZM15.6304 22.533C14.6243 22.8365 13.5563 23 12.4482 23V25C13.7541 25 15.0166 24.8072 16.2081 24.4477L15.6304 22.533ZM16.6561 22.8142C14.0423 19.9657 12.4482 16.1702 12.4482 12H10.4482C10.4482 16.6902 12.2434 20.9635 15.1824 24.1664L16.6561 22.8142ZM12.4482 12C12.4482 7.82984 14.0423 4.03427 16.6561 1.18575L15.1824 -0.166437C12.2434 3.03652 10.4482 7.30976 10.4482 12H12.4482Z"
        fill={colors.text}
        mask={`url(#${id})`}
      />
    </SvgWrapper>
  );
}

export function Rating3({
  id = Math.random(),
  selected,
  width = 20,
  height = 24,
  ...props
}) {
  return (
    <SvgWrapper
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id={id} fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9658 2.28601C17.9871 0.848087 15.5521 0 12.9189 0C6.29153 0 0.918945 5.37258 0.918945 12C0.918945 18.6274 6.29153 24 12.9189 24C15.5521 24 17.9871 23.1519 19.9658 21.714C18.0453 18.9605 16.9189 15.6118 16.9189 12C16.9189 8.38824 18.0453 5.03955 19.9658 2.28601Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9658 2.28601C17.9871 0.848087 15.5521 0 12.9189 0C6.29153 0 0.918945 5.37258 0.918945 12C0.918945 18.6274 6.29153 24 12.9189 24C15.5521 24 17.9871 23.1519 19.9658 21.714C18.0453 18.9605 16.9189 15.6118 16.9189 12C16.9189 8.38824 18.0453 5.03955 19.9658 2.28601Z"
        fill={selected ? colors.text : 'none'}
      />
      <path
        d="M19.9658 2.28601L20.786 2.85809L21.3471 2.05363L20.5537 1.47705L19.9658 2.28601ZM19.9658 21.714L20.5537 22.523L21.3471 21.9464L20.786 21.1419L19.9658 21.714ZM12.9189 1C15.3339 1 17.5644 1.77704 19.378 3.09497L20.5537 1.47705C18.4099 -0.0808681 15.7703 -1 12.9189 -1V1ZM1.91895 12C1.91895 5.92487 6.84381 1 12.9189 1V-1C5.73924 -1 -0.0810547 4.8203 -0.0810547 12H1.91895ZM12.9189 23C6.84381 23 1.91895 18.0751 1.91895 12H-0.0810547C-0.0810547 19.1797 5.73924 25 12.9189 25V23ZM19.378 20.905C17.5644 22.223 15.3339 23 12.9189 23V25C15.7703 25 18.4099 24.0809 20.5537 22.523L19.378 20.905ZM20.786 21.1419C18.9787 18.5507 17.9189 15.4006 17.9189 12H15.9189C15.9189 15.8229 17.1118 19.3702 19.1456 22.2861L20.786 21.1419ZM17.9189 12C17.9189 8.59937 18.9787 5.44925 20.786 2.85809L19.1456 1.71393C17.1118 4.62984 15.9189 8.17711 15.9189 12H17.9189Z"
        fill={colors.text}
        mask={`url(#${id})`}
      />
    </SvgWrapper>
  );
}

export function Rating4({ id, selected, ...props }) {
  return (
    <SvgWrapper
      {...props}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.9658"
        cy="12"
        r="11.5"
        fill={selected ? colors.text : 'none'}
        stroke={colors.text}
      />
    </SvgWrapper>
  );
}
