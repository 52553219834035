import React from 'react';
import styled from 'styled-components';

import { Box, CalendarText } from 'components/core';
import { colors } from 'styles/theme';

import { getShortMonthTitle } from 'utils/date';

const Wrapper = styled(Box)`
  position: relative;
  width: 141.4px;
  height: 104px;

  border-right: 1px solid ${colors.text};

  &:last-child {
    border-right: 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;

    border-right: 0;
  }
`;

const TitleWrapper = styled(Box)`
  position: absolute;
  top: 16px;
  right: 16px;

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const TaskWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    margin-top: 35px;
    justify-content: center;
  }
`;

const TaskBox = styled(Box)`
  background: ${(props) => (props.isCompleted ? colors.text : colors.line)};
  border: 1px solid
    ${(props) => (props.isCompleted ? colors.main : colors.line)};
  border-radius: 1px;
  width: 16px;
  height: 16px;

  margin: 4px;

  @media (max-width: 768px) {
    margin: 1px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
  }
`;

function CalendarButton({
  densed = false,
  year,
  month,
  date,
  isToday,
  isSelected,
  isOtherMonth,
  tasks,
  isWeekDay,
  onSelect,
  ...props
}) {
  const monthTitle = getShortMonthTitle(month);

  const taskItems = (tasks || []).slice(0, densed ? 5 : 10);

  let bg = 'line'; //theme value
  let textColor = 'text';

  if (isToday) {
    bg = 'text';
    textColor = 'contrastText';
  } else if (isSelected) {
    bg = 'main';
    textColor = 'contrastText';
  } else if (isWeekDay) {
    bg = 'clear';
  }

  const onDate = () => {
    if (!onSelect) {
      return;
    }
    onSelect(
      {
        year,
        month,
        date,
      },
      tasks
    );
  };

  return (
    <Wrapper bg={bg} onClick={onDate} {...props}>
      <TitleWrapper disabled={isOtherMonth}>
        <CalendarText color={textColor}>
          {date === 1 && !densed ? `${monthTitle} ${date}` : date}
        </CalendarText>
      </TitleWrapper>
      <TaskWrapper>
        {taskItems.map(({ id, record }) => (
          <TaskBox key={id} isCompleted={!!record} />
        ))}
      </TaskWrapper>
    </Wrapper>
  );
}

export default CalendarButton;
