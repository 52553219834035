import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import throttle from 'lodash-es/throttle';
import API from 'services/api';
import { colors } from 'styles/theme';

const NoteContainer = styled.textarea`
  width: 100%;
  position: relative;
  height: 100%;
  color: ${colors.text};
  background-position: 100% 10px;
  background-image: -webkit-linear-gradient(
      left,
      ${colors.background} 20px,
      transparent 20px
    ),
    -webkit-linear-gradient(right, ${colors.background} 20px, transparent 20px),
    -webkit-linear-gradient(${colors.background} 20px, ${colors.line} 20px, ${colors.line}
          21px, ${colors.background} 21px);
  background-image: linear-gradient(
      left,
      ${colors.background} 20px,
      transparent 20px
    ),
    linear-gradient(right, ${colors.background} 20px, transparent 20px),
    linear-gradient(
      ${colors.background} 20px,
      ${colors.line} 20px,
      ${colors.line} 21px,
      ${colors.background} 21px
    );
  background-size: 100% 0%, 0% 0%, 10% 32px;
  border: none;
  line-height: 32px;
  font-size: 22px;
  resize: none;
  outline: none;
  flex: 1;
`;

const throttledSave = throttle(API.updateNote, 5000);

function NoteView({ year, month, date }) {
  const [note, setNote] = useState('');
  useEffect(() => {
    const load = async () => {
      const momentDate = moment().date(date).year(year).month(month);
      try {
        const data = await API.getNote(momentDate.format('YYYY-MM-DD'));
        setNote(data && data.note ? data.note : '');
      } catch (e) {}
    };

    load();
  }, [year, month, date]);

  const handleChangeNote = (e) => {
    const momentDate = moment().date(date).year(year).month(month);
    throttledSave(momentDate.format('YYYY-MM-DD'), e.target.value);
    setNote(e.target.value);
  };

  return <NoteContainer onChange={handleChangeNote} value={note} />;
}

export default NoteView;
