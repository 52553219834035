import React from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  SvgIcon,
  Title1Bold,
  Title1,
  IconButton,
} from 'components/core';

import { getMonthTitle } from 'utils/date';

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;
`;

const AbsoluteWrapper = styled(Box)`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Left = styled(AbsoluteWrapper)`
  top: 0;
  left: 0;
  justify-content: flex-start;
`;

const Right = styled(AbsoluteWrapper)`
  top: 0;
  right: 0;
  justify-content: flex-end;
`;

function CalendarHeader({
  onDone,
  year,
  month,
  date,
  mode = 'date',
  onChangeMode,
  onPrev,
  onNext,
  ...props
}) {
  const monthTitle = getMonthTitle(month);
  const dateTitle = mode === 'date' ? monthTitle : `${monthTitle} ${date},`;

  const onChange = (newMode) => {
    if (onChangeMode) {
      onChangeMode(newMode);
    }
  };

  return (
    <Wrapper p={4} {...props}>
      <Left px={2}>
        <Button size="small" variant="secondary" onClick={onDone}>
          Done
        </Button>
      </Left>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton borderWidth={0} mr={4} onClick={onPrev}>
          <SvgIcon name="chevron-left" />
        </IconButton>
        <Title1Bold mr={2}>{dateTitle}</Title1Bold>
        <Title1>{year}</Title1>
        <IconButton borderWidth={0} ml={4} onClick={onNext}>
          <SvgIcon name="chevron-right" />
        </IconButton>
      </Box>

      <Right px={4}>
        <IconButton borderWidth={0} mr={1} onClick={() => onChange('date')}>
          <SvgIcon
            name={`calendar-${mode === 'date' ? 'date-selected' : 'date'}`}
          />
        </IconButton>
        <IconButton borderWidth={0} onClick={() => onChange('list')}>
          <SvgIcon
            name={`calendar-${mode === 'list' ? 'list-selected' : 'list'}`}
          />
        </IconButton>
      </Right>
    </Wrapper>
  );
}

export default CalendarHeader;
