import React, { useState } from 'react';
import { Box, Title1 } from 'components/core';

import {
  ProgramToolbar,
  ProgramProfile,
  ProgramGraph,
  ProgramStat,
  ProgramShare,
} from 'components/program';

import {
  GraphWrapper,
  CollapsibleWrapper,
} from 'components/program/CommonStyled';
import ChatView from './ChatView';

const CompletedProgramCard = ({
  isSubscribedFromOther,
  subscribedFrom,
  title,
  user,
  description,
  stats,
  type,
  program,
  onPromote,
  onReset,
  onGold,
  isRestarted,
  price,
  promotion,
  onViewSummary,
  onViewRating,
  onEdit,
  onDelete,
  onView,
  isSmallView = false,
  shareImage,
  ...props
}) => {
  const [densed, setDensed] = useState(true);

  const toolbarOptions = isSubscribedFromOther
    ? [
        {
          name: 'reset',
          help: 'Restart',
        },
        {
          name: 'delete',
          help: 'Delete',
        },
      ]
    : [
        {
          name: 'edit',
          help: 'Edit',
        },
        {
          name: 'gold',
          help: 'Offer gold',
          selected: price !== 0,
        },
        {
          name: 'promote',
          help: 'Promote',
          selected: !!promotion,
        },
        {
          name: 'reset',
          help: 'Restart',
        },
        {
          name: 'delete',
          help: 'Delete',
        },
      ];

  const handleSelectTool = (name) => {
    switch (name) {
      case 'edit':
        onEdit();
        return;
      case 'delete':
        onDelete();
        return;
      case 'reset':
        onReset();
        return;
      case 'promote':
        onPromote();
        return;
      case 'gold':
        onGold();
        return;
      default:
    }
  };

  return (
    <GraphWrapper {...props}>
      <Box mb={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection={['column', 'row']}
          alignItems={['flex-start', 'flex-end']}
        >
          <Title1 color="text" as="a" name={props.id}>
            {title}
          </Title1>
          {densed && !isSmallView && !isRestarted && (
            <ProgramToolbar
              options={toolbarOptions}
              onSelect={handleSelectTool}
            />
          )}
        </Box>

        {(!densed || isSmallView) && (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={['column', 'row']}
          >
            <ProgramProfile {...user} description={description} />
            {!isRestarted && (
              <Box
                flex={1}
                display="flex"
                justifyContent="flex-end"
                flexDirection={['column', 'row']}
                alignItems={['flex-start', 'flex-end']}
              >
                <ProgramStat
                  {...stats}
                  onClickStat={onViewSummary}
                  onClickRating={onViewRating}
                />
                <ProgramToolbar
                  options={toolbarOptions}
                  onSelect={handleSelectTool}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {isSmallView ? (
        <CollapsibleWrapper
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          initialOpen={!densed}
          densed={densed}
          onExpand={() => setDensed(!densed)}
        >
          <ProgramGraph type={type} {...program} onView={onView} />
        </CollapsibleWrapper>
      ) : (
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <CollapsibleWrapper
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            initialOpen={!densed}
            densed={densed}
            onExpand={() => setDensed(!densed)}
          >
            <ProgramGraph type={type} {...program} onView={onView} />
          </CollapsibleWrapper>

          {densed && !isRestarted && (
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              alignItems="flex-end"
              ml={2}
            >
              <ProgramStat
                {...stats}
                onClickStat={onViewSummary}
                onClickRating={onViewRating}
              />
            </Box>
          )}
        </Box>
      )}

      {!densed && <ChatView programId={subscribedFrom} />}
      <ProgramShare
        {...program}
        shareImage={shareImage}
        user={user}
        likes={props.likes}
        programId={props.id}
        description={description}
      />
    </GraphWrapper>
  );
};

export default CompletedProgramCard;
