import React, { useState } from 'react';
import { Box, Title1, SvgIcon, Tooltip } from 'components/core';

import {
  ProgramToolbar,
  ProgramProfile,
  ProgramGraph,
  ProgramStat,
  ProgramShare,
} from 'components/program';
import {
  GraphWrapper,
  CollapsibleWrapper,
} from 'components/program/CommonStyled';
import ChatView from './ChatView';

const SearchProgramCard = ({
  title,
  user,
  description,
  stats,
  type,
  program,
  onJoin,
  isSubscribed,
  onViewSummary,
  onViewRating,
  onView,
  featured,
  shareImage,
  ...props
}) => {
  const [densed, setDensed] = useState(true);
  const handleSelectTool = (name) => {
    switch (name) {
      case 'join':
        onJoin();
        return;
      default:
    }
  };

  const toolbarOptions = [
    {
      name: 'join',
      selected: isSubscribed,
      disabled: isSubscribed,
      help: 'Subscribe',
    },
  ];

  return (
    <GraphWrapper {...props}>
      <Box display="flex" flexDirection="column" alignSelf="stretch" mb={2}>
        <Title1 color="text">
          {title}
          {featured && (
            <Tooltip
              key="featured"
              animation="scale"
              delay={[300, 0]}
              duration={50}
              content={'master program'}
              theme="light-border"
              placement="bottom"
            >
              <span>
                <SvgIcon name="featured" />
              </span>
            </Tooltip>
          )}
        </Title1>
        <Box display="flex" justifyContent="flex-start" flexDirection="row">
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-start"
            flexDirection={['column', 'row']}
          >
            <ProgramProfile
              {...user}
              flex={1}
              densed={densed}
              description={description}
            />
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="row"
              alignItems="flex-end"
            >
              <ProgramStat
                {...stats}
                onClickStat={onViewSummary}
                onClickRating={onViewRating}
              />
            </Box>
          </Box>
          <ProgramToolbar
            mb={[`36px`, 0]}
            options={toolbarOptions}
            onSelect={handleSelectTool}
          />
        </Box>
      </Box>
      <CollapsibleWrapper
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
        initialOpen={!densed}
        onExpand={() => setDensed(!densed)}
      >
        <ProgramGraph type={type} {...program} onView={onView} viewOnly />
      </CollapsibleWrapper>
      {!densed && <ChatView programId={props.id} />}
      <ProgramShare
        {...program}
        shareImage={shareImage}
        user={user}
        likes={props.likes}
        programId={props.id}
        description={description}
      />
    </GraphWrapper>
  );
};

export default SearchProgramCard;
