import styled from 'styled-components';
import {
  color,
  border,
  space,
  fontSize,
  width,
  display,
  flexbox,
} from 'styled-system';

import { colors } from 'styles/theme';

const BInput = styled.textarea`
  appearance: textarea;
  display: block;
  width: 100%;
  margin: 0;
  font-family:inherit;

  ${border}
  ${space}
  ${fontSize}
  ${color}
  ${width}
  ${display}
  ${flexbox}

  border: 0; 
  outline: 0;
  box-shadow: inset 0px 1px 1px 0px ${(props) =>
    props.error ? colors.red : colors.line}, inset 0px 0px 1px 1px ${(
  props
) => (props.error ? colors.red : colors.line)};

  &::placeholder {
    color: ${colors.text};
    opacity: 0.8;
  }
`;

BInput.displayName = 'BInput';
BInput.propTypes = {
  ...border.propTypes,
  ...space.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...display.propTypes,
};

BInput.defaultProps = {
  color: 'text',
  bg: 'clear',
  fontSize: 2,
  borderRadius: 1,
  lineHiehgt: '22px',
  p: 3
};

export default BInput;
