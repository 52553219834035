import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Router } from 'react-router-dom';

import configureStore from './redux/store';
import history from 'utils/history';
import theme from 'styles/theme';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { initStripe } from 'services/stripe';
import { initEventService } from 'services/event';

import './global.css';

export const store = configureStore();
initEventService(store);

initStripe();

const Root = styled.div`
  font-family: Montserrat;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
`;

const Style = createGlobalStyle`
  * { box-sizing: border-box; }
  body{ margin:0; color: #E5E5E5; }
`;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Root>
        <Style />
        <Provider store={store}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </Root>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
