export const rankBackground = [
  { background: '#D8FFB2', hasFilter: true, blendMode: false },

  { background: '#AFFFF1', hasFilter: true, blendMode: false },

  { background: '#B3FFCC', hasFilter: true, blendMode: false },

  { background: '#B0E2FF', hasFilter: true, blendMode: false },

  { background: '#BAD2FF', hasFilter: true, blendMode: false },

  { background: '#F4FFB0', hasFilter: true, blendMode: false },

  { background: '#FFD9A0', hasFilter: true, blendMode: false },

  { background: '#FFBE92', hasFilter: false, blendMode: false },

  { background: '#FFA999', hasFilter: true, blendMode: false },

  { background: '#F2B6CA', hasFilter: true, blendMode: false },

  {
    background:
      'linear-gradient(342.65deg, #FFFFFF 6.01%, #A8A8A8 54.53%, #B7B7B7 83.11%)',
    hasFilter: true,
    blendMode: false,
  },

  {
    background:
      'linear-gradient(342.65deg, #FFD84F 6.01%, #AA853D 54.53%, #D7BD61 83.11%)',
    hasFilter: true,
    blendMode: true,
  },
];

export const rankExtraStyle = [
  'filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));',
];

export const rankAnimals = [
  'Grasshopper',
  'Beetle',
  'Mantis',
  'Seahorse',
  'Salmon',
  'LemonShark',
  'Eagle',
  'Condor',
  'Cheetah',
  'Wolf',
  'Gorilla',
  'Lion',
];

export const isValidRank = (value) => {
  return value > 0 && value <= rankBackground.length;
};

const getPrefix = (value) => {
  if (value === 1) return 'st';
  if (value === 2) return 'nd';
  if (value === 3) return 'rd';

  return 'th';
};

export const getRankText = (value) => {
  return {
    animalValue: rankAnimals[value - 1],
    level: `${value}${getPrefix(value)}`,
  };
};
