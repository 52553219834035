import React, { useState, useRef } from 'react';
import isObject from 'lodash-es/isObject';
import styled from 'styled-components';
import Box from './Box';
import { Body2 } from './Typography';
import SvgIcon from './SvgIcon';
import IconButton from './IconButton';
import useOutsideClick from 'hooks/useOutsideClick';

import { colors } from 'styles/theme';

const Wrapper = styled(Box)`
  position: relative;
  outline: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

const OptionWrapper = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  background: ${colors.background};
  border: 1px solid ${colors.text};
  box-sizing: border-box;
  border-radius: 2px;
  z-index: 99;
`;

const Option = styled(Box)`
  cursor: pointer;
  min-height: 48px;
  background: ${(props) => (props.selected ? colors.text : colors.background)};
  white-space: pre;

  &:hover {
    background: ${(props) => (props.selected ? colors.text : colors.line)};
  }
`;

const ExpandButton = styled(IconButton)`
  height: 24px;
  width: 100%;
`;

const CloseButton = styled(Box)`
  height: 24px;
  width: 100%;
  background: ${colors.text};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  path {
    stroke: ${colors.contrastText};
  }
`;

function Dropdown({
  value,
  dropdownlabel = 'dropdown',
  options = [],
  tabIndex = 0,
  onChange,
  floatOption = false,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const onClickOption = (option, e) => {
    e.stopPropagation();
    e.preventDefault();

    if (onChange) {
      onChange(option);
    }

    setOpen(false);
  };

  const onClick = () => {
    setOpen(!open);
  };

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  return (
    <Wrapper
      ref={ref}
      bg="clear"
      tabIndex={tabIndex}
      onClick={onClick}
      {...props}
    >
      <ExpandButton mt={2} fontSize={0} borderWidth={0}>
        <SvgIcon name="chevron-down" />
      </ExpandButton>
      {open && (
        <OptionWrapper floatOption={floatOption}>
          {options.map((option) => {
            let optionValue;
            let optionLabel;
            if (isObject(option)) {
              optionValue = option.value;
              optionLabel = option.label;
            } else {
              optionValue = option;
              optionLabel = option;
            }

            const isSelected = value === optionValue;
            return (
              <Option
                p={3}
                key={optionValue}
                selected={isSelected}
                onClick={(e) => onClickOption(option, e)}
              >
                <Body2 color={isSelected ? 'contrastText' : 'text'}>
                  {optionLabel}
                </Body2>
              </Option>
            );
          })}
          <CloseButton>
            <SvgIcon name="chevron-up" />
          </CloseButton>
        </OptionWrapper>
      )}
    </Wrapper>
  );
}

export default Dropdown;
