import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AppActions, { AppSelectors } from 'redux/AppRedux';
import { AuthSelectors } from 'redux/AuthRedux';

import { FullScreenLoader } from 'components/loading';

import AppMain from './AppMain';
import AppNoUser from './AppNoUser';

const App = ({ loaded, loading, isLoggedIn, isOnboarded, startup }) => {
  useEffect(() => {
    startup();
  }, [startup]);

  if (!loaded || loading) {
    return <FullScreenLoader />;
  }

  if (!isLoggedIn) {
    return <AppNoUser />;
  }

  return <AppMain />;
};

const mapStatesToProps = (state) => ({
  loading: AppSelectors.selectLoading(state),
  loaded: AppSelectors.selectLoaded(state),
  isOnboarded: AuthSelectors.selectIsOnboarded(state),
  isLoggedIn: AuthSelectors.selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(AppActions.startup()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(App);
