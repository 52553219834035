import React from 'react';
import styled from 'styled-components';
import { Box, SvgIcon, Title1Bold, Title1, IconButton } from 'components/core';

import { getMonthTitle } from 'utils/date';

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;
`;

function CalendarHeader({
  onDone,
  year,
  month,
  date,
  onChangeMode,
  onPrev,
  onNext,
  ...props
}) {
  const monthTitle = getMonthTitle(month);
  const dateTitle = `${monthTitle}`;
  return (
    <Wrapper p={4} {...props}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton borderWidth={0} mr={4} onClick={onPrev}>
          <SvgIcon name="chevron-left" />
        </IconButton>
        <Title1Bold mr={2}>{dateTitle}</Title1Bold>
        <Title1>{year}</Title1>
        <IconButton borderWidth={0} ml={4} onClick={onNext}>
          <SvgIcon name="chevron-right" />
        </IconButton>
      </Box>
    </Wrapper>
  );
}

export default CalendarHeader;
