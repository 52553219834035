import React from 'react';
import { Box, DialogBox, Body2, Button } from 'components/core';

const CHOOSE_HELP_TEXT =
  'Your program has been submitted! Once approved, your program will appear on the homepage for other users to subscribe. You will recieve an email alerting you of approval so that you can share your program on social media.';

function SubmissionModal({ show, onOk, ...props }) {
  return (
    <DialogBox show={show} onClickOutside={onOk}>
      <Box {...props} m={[5, 8]}>
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mx={4}
          mt={9}
        >
          <Body2 textAlign="center">{CHOOSE_HELP_TEXT}</Body2>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={9}
          mb={8}
        >
          <Button variant="secondaryPlus" mx={4} onClick={onOk}>
            OK
          </Button>
        </Box>
      </Box>
    </DialogBox>
  );
}

export default SubmissionModal;
