import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Box, Text, LinkText } from 'components/core';
import { AuthLayout } from 'containers/layout';
import LoginForm from 'containers/LoginForm';

import history from 'utils/history';
import { load as loadFacebook } from 'services/facebook';

const Wrapper = styled(Box)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 100vh;
`;

const MainSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 50px;

  @media (max-width: 768px) {
    width: 320px;
  }

  border: 1px solid rgba(220, 220, 220, 0.5);
  border-radius: 2px;
`;

function Login() {
  const initFacebook = async () => {
    try {
      await loadFacebook();
    } catch (err) {
      console.log(err);
    }
  };

  const handleFacebookRegister = (data) => {
    history.push({
      pathname: '/auth/register',
      state: data,
    });
  };

  useEffect(() => {
    initFacebook();
  }, []);

  return (
    <AuthLayout>
      <Wrapper>
        <LoginForm setFacebook={handleFacebookRegister} />
        <MainSection mt={10}>
          <Text color="blue" fontSize={1} ml={1} mr={1}>
            Don’t have an account?{' '}
          </Text>
          <LinkText
            fontSize={1}
            color="blue"
            onClick={() => {
              history.push('/auth/register');
            }}
          >
            Join!
          </LinkText>
        </MainSection>
      </Wrapper>
    </AuthLayout>
  );
}

export default Login;
