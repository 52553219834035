import React from 'react';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import styled from 'styled-components';

import { Box, Button, InputFeedback } from 'components/core';
import { InputField } from 'components/form';

import API from 'services/api';

const MainSection = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const validationSchema = object().shape({
  password: string()
    .required('Please enter a password')
    .min(6, 'Please enter a password 6 digits or longer'),
  newPassword: string()
    .required('Please enter a password')
    .min(6, 'Please enter a password 6 digits or longer'),
});

class ChangePasswordForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setStatus } = actions;
    setSubmitting(true);

    try {
      await API.changePassword(values);
      setStatus({
        message: 'Password has changed successfully',
      });
    } catch (err) {
      setStatus({
        message: 'There was problem changing password',
      });
    }

    setSubmitting(false);
  };

  renderForm = ({ isValid, isSubmitting, status }) => {
    return (
      <Form>
        <MainSection>
          <Box mr={[0, 4]} flex={['unset', 1]}>
            <Field
              component={InputField}
              name="password"
              type="password"
              label="Current Password"
            />
          </Box>
          <Box mr={[0, 4]} flex={['unset', 1]}>
            <Field
              component={InputField}
              name="newPassword"
              type="password"
              label="New Password"
            />
          </Box>
          <Button
            type="submit"
            disabled={!isValid || isSubmitting}
            variant="danger"
            flex={['unset', 1]}
          >
            Reset Password
          </Button>
        </MainSection>
        {status && status.message && (
          <InputFeedback> {status.message} </InputFeedback>
        )}
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{ password: '', newPassword: '' }}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
        render={this.renderForm}
      />
    );
  }
}

export default ChangePasswordForm;
