import React from 'react';

import { Box, DialogBox } from 'components/core';
import Player from './Player';

function PlayerModal({ show, category, data, onClose, ...props }) {
  return (
    <DialogBox
      show={show}
      onClickOutside={onClose}
      hasClose={false}
      backDropColor="backDrop"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        minWidth={12}
        minHeight={12}
      >
        <Player data={data} category={category} />
      </Box>
    </DialogBox>
  );
}

export default PlayerModal;
