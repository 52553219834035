import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button } from 'components/core';
import {
  CalendarHeader,
  CalendarHeaderSingle,
  DateView,
  TaskView,
} from 'components/calendar';

import CalendarActions, { CalendarSelectors } from 'redux/CalendarRedux';
import { ProfileSelectors } from 'redux/ProfileRedux';

import { convertProgramsToTask } from 'utils/transform';
import useViewport from 'hooks/useViewport';

function Calendar({
  year,
  month,
  date,
  setCalendarDay,
  goPrev,
  goNext,
  onDone,
  programs,
  ...props
}) {
  const [mode, setMode] = useState('date');
  const { width } = useViewport();

  const tasksMap = convertProgramsToTask(programs);

  const onChangeMode = (newMode) => {
    setMode(newMode);
  };

  const onSelectDay = (dateInfo, tasks) => {
    setCalendarDay(dateInfo);
    setMode('list');
  };

  const onPrev = () => {
    goPrev(mode === 'date');
  };

  const onNext = () => {
    goNext(mode === 'date');
  };

  if (width < 768) {
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="flex-start"
        {...props}
      >
        <CalendarHeaderSingle
          year={year}
          date={date}
          month={month}
          onPrev={onPrev}
          onNext={onNext}
        />
        <DateView
          densed
          tasksMap={tasksMap}
          onSelect={onSelectDay}
          year={year}
          month={month}
          date={date}
        />
        <TaskView year={year} date={date} month={month} tasksMap={tasksMap} />
        <Button my={2} variant="secondary" onClick={onDone}>
          Done
        </Button>
      </Box>
    );
  }

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      alignItems="flex-start"
      {...props}
    >
      <CalendarHeader
        year={year}
        date={date}
        month={month}
        mode={mode}
        onDone={onDone}
        onChangeMode={onChangeMode}
        onPrev={onPrev}
        onNext={onNext}
      />
      {mode === 'date' && (
        <DateView
          tasksMap={tasksMap}
          onSelect={onSelectDay}
          year={year}
          month={month}
        />
      )}
      {mode !== 'date' && (
        <TaskView year={year} date={date} month={month} tasksMap={tasksMap} />
      )}
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  year: CalendarSelectors.selectCurrentYear(state),
  month: CalendarSelectors.selectCurrentMonth(state),
  date: CalendarSelectors.selectCurrentDate(state),
  programs: ProfileSelectors.selectCurrentActiveList(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCalendarDay: (data) =>
    dispatch(CalendarActions.setCurrentCalendarDay(data)),
  goNext: (isMonth) => dispatch(CalendarActions.setCalendarGoNext(isMonth)),
  goPrev: (isMonth) => dispatch(CalendarActions.setCalendarGoPrev(isMonth)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Calendar);
