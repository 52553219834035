import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';

import { Box, Title1 } from 'components/core';
import { PlanSelect, SubscriptionButtons } from 'components/others';
import { DefaultLoader, LoadingContainer } from 'components/loading';
import { LightGoalForm, StripeForm } from 'containers';

import SubscriptionActions, {
  SubscriptionSelectors,
} from 'redux/SubscriptionRedux';

import history from 'utils/history';
import BackgroundImage from 'assets/girl.png';
import BgGradient from 'assets/gradient-bg.svg';
import { colors } from 'styles/theme';
import API from 'services/api';
import { getStripeVendorInstance } from 'services/stripe';
import Analytics, { EVENTS } from 'services/analytics';

const Wrapper = styled(Box)`
  min-width: 100vw;
  min-height: calc(100vh - 96px);
  background-size: cover;
  background: url(${BackgroundImage}) no-repeat,
    url(${BgGradient}) no-repeat #080808;
  background-position: top left;
  @media (max-width: 768px) {
    background-position: top center;
  }
  position: relative;
`;

const InnerWrapper = styled(Box)`
  padding: 40px 0;
  margin: 0 auto;
  max-width: 1180px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isFullWidth ? 'column' : 'row')};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 0;
  }
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0;

  ${(props) =>
    props.isFullWidth
      ? css`
          width: 100%;
        `
      : css`
          width: 540px;

          @media (max-width: 768px) {
            width: 320px;
          }
        `}
`;

export const Divider = styled(Box)`
  background-color: ${colors.text};
  opacity: 0.6;
  height: 1px;
  align-self: stretch;
`;

function SubscriptionPage({
  goal,
  setGoal,
  clearSubscription,
  isLoading,
  individualPrice,
  loadSubscriptionProgram,
  stripeAccount,
  isSubscribing,
  subscribeToProgram,
  paymentDescription,
  match: {
    params: { programId },
  },
}) {
  const [action, setAction] = useState(null);

  const PLANS = [
    {
      name: 'individual',
      title: 'Individual Programs',
      description: paymentDescription.split('\n').filter((p) => p),
      price: individualPrice
        ? Number(individualPrice / 100).toFixed(2)
        : 'Free',
    },
  ];

  useEffect(() => {
    if (!programId) {
      history.replace('/');
    } else {
      loadSubscriptionProgram(programId);
      Analytics.track(EVENTS.SUBSCRIPTION_FORM_VIEWED, {
        programId,
      });
    }
    return () => {
      clearSubscription();
    };
  }, [programId, loadSubscriptionProgram, clearSubscription]);

  const handleSubscribe = async (paymentToken) => {
    if (individualPrice) {
      await API.purchaseProgam(programId, {
        paymentToken,
      });
    }

    subscribeToProgram({
      goal,
      action,
    });
  };

  return (
    <Wrapper pt={8} pb={[4, 8]} px={2}>
      {!isLoading && (
        <InnerWrapper isFullWidth={individualPrice === 0}>
          <ContentWrapper isFullWidth={individualPrice === 0}>
            <LightGoalForm mb={6} goal={goal} onChange={setGoal} />
            <Divider mb={6} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              alignSelf="stretch"
              minHeight={['unset', 264]}
              mb={6}
            >
              <PlanSelect value="individual" plans={PLANS} />
            </Box>
          </ContentWrapper>
          {individualPrice > 0 ? (
            <ContentWrapper ml={8}>
              <Title1 color="contrastText" mb={3}>
                Pay with card
              </Title1>
              <Divider mb={6} width={0.5} />
              <Elements stripe={getStripeVendorInstance(stripeAccount)}>
                <StripeForm
                  onSubmit={handleSubscribe}
                  setAction={setAction}
                  price={individualPrice}
                />
              </Elements>
            </ContentWrapper>
          ) : (
            <SubscriptionButtons
              setAction={setAction}
              handleSubmit={handleSubscribe}
              alignSelf="flex-end"
              alignItems="flex-end"
            />
          )}
        </InnerWrapper>
      )}

      {(isSubscribing || isLoading) && (
        <LoadingContainer>
          <DefaultLoader variant="secondary" />
        </LoadingContainer>
      )}
    </Wrapper>
  );
}

const mapStatesToProps = (state) => ({
  goal: SubscriptionSelectors.selectGoal(state),
  isLoading: SubscriptionSelectors.selectIsLoading(state),
  isSubscribing: SubscriptionSelectors.selectIsSubscribing(state),
  individualPrice: SubscriptionSelectors.selectIndividualPrice(state),
  stripeAccount: SubscriptionSelectors.selectProgramStripeAccount(state),
  paymentDescription: SubscriptionSelectors.selectPaymentDescription(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadSubscriptionProgram: (programId) =>
    dispatch(SubscriptionActions.setSubscriptionProgramLoadRequest(programId)),
  clearSubscription: () => dispatch(SubscriptionActions.setClearSubscription()),
  setGoal: (data) => dispatch(SubscriptionActions.setSubscriptionGoal(data)),
  subscribeToProgram: (data) =>
    dispatch(SubscriptionActions.setSubscriptionMakeRequest(data)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(SubscriptionPage);
