import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import SvgIcon from './SvgIcon';

import { colors } from 'styles/theme';

const Icon = styled(SvgIcon)`
  path {
    fill: ${(props) => (props.selected ? colors.main : colors.line)};
  }
`;

const Button = styled.button`
  width: 72px;
  height: 72px;
  border-radius: 1px;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  border: 1px solid ${(props) => (props.selected ? colors.main : colors.line)};

  ${space}
`;

const PSelectButton = ({ selected, size, name, ...props }) => {
  return (
    <Button selected={selected} {...props}>
      <Icon
        name={`program-${name}`}
        width={size}
        height={size}
        selected={selected}
      />
    </Button>
  );
};

export default PSelectButton;
