import React from 'react';
import styled from 'styled-components';
import { ReactComponent as KeyImage } from 'assets/svg/key.svg';
import { ReactComponent as SupportImage } from 'assets/svg/Join.svg';

import { Box } from 'components/core';

const HeaderContainer = styled(Box)`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 96px;
`;

const KeyIcon = styled(KeyImage)`
  margin-right: 20px;
`;

const SupportIcon = styled(SupportImage)`
  margin-left: 20px;
`;

const Header = (props) => {
  return (
    <HeaderContainer {...props}>
      <KeyIcon />
      <SupportIcon />
    </HeaderContainer>
  );
};

export default Header;
