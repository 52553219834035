import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setSearchProgramsRequest: null,
  setSearchPrograms: ['programs'],
  setSearchQuery: ['q'],
  setSearchSort: ['sort'],
  setSearchLoading: ['loading'],
  setSearchCategory: ['category', 'subCategory'],
});

export const SearchTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  loading: false,
  programs: [],
  error: null,
  q: '',
  sort: 'subscriptionCount',
  category: '',
  subCategory: '',
};

/* ------- Selectors --------- */
export const SearchSelectors = {
  selectPrograms: (state) => state.search.programs,
  selectQuery: (state) => state.search.q,
  selectSort: (state) => state.search.sort,
  selectCategory: (state) => state.search.category,
  selectSubCategory: (state) => state.search.subCategory,
  selectLoading: (state) => state.search.loading,
};

/* -------- Reducers ---------- */
export const setSearchPrograms = (state, { programs }) => {
  return {
    ...state,
    programs,
  };
};

export const setSearchQuery = (state, { q }) => {
  return {
    ...state,
    q,
  };
};

export const setSearchSort = (state, { sort }) => {
  return {
    ...state,
    sort,
  };
};

export const setSearchLoading = (state, { loading }) => {
  return {
    ...state,
    loading,
  };
};

export const setSearchCategory = (state, { category, subCategory }) => {
  return {
    ...state,
    category,
    subCategory
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SEARCH_PROGRAMS]: setSearchPrograms,
  [Types.SET_SEARCH_QUERY]: setSearchQuery,
  [Types.SET_SEARCH_SORT]: setSearchSort,
  [Types.SET_SEARCH_CATEGORY]: setSearchCategory,
  [Types.SET_SEARCH_LOADING]: setSearchLoading,
});
