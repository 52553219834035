import { put, call, select } from 'redux-saga/effects';
import SearchActions, { SearchSelectors } from './SearchRedux';

import API from '../services/api';
import { transformProgram } from '../utils/transform';
import Analytics, { EVENTS } from 'services/analytics';

export function* searchPrograms() {
  yield put(SearchActions.setSearchLoading(true));
  let sort = yield select(SearchSelectors.selectSort);
  const q = yield select(SearchSelectors.selectQuery);
  const category = yield select(SearchSelectors.selectCategory);
  const subCategory = yield select(SearchSelectors.selectSubCategory);

  if (sort === 'all') {
    sort = undefined;
  } else if (sort !== 'weightChange') {
    sort = `-${sort}`;
  }

  try {
    const searchParams = {
      sort,
      q,
      limit: 100,
      category: category ? category : undefined,
      subCategory: subCategory ? subCategory : undefined,
    };

    Analytics.track(EVENTS.SEARCH_TAKEN, {
      searchParams,
    });

    const searchPrograms = yield call(API.searchPrograms, {
      sort,
      q,
      limit: 100,
      category: category ? category : undefined,
      subCategory: subCategory ? subCategory : undefined,
    });

    yield put(
      SearchActions.setSearchPrograms(searchPrograms.map(transformProgram))
    );
  } catch (err) {}

  yield put(SearchActions.setSearchLoading(false));
}
