import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, Body2 } from 'components/core';
import { ConfirmModal, NoticeModal } from 'components/others';
import { ProgramCreateCard } from 'containers/programs';
import { MasterHelperModal } from 'modals';

import CreateActions, { CreateSelectors } from 'redux/CreateRedux';
import AppActions, { AppSelectors } from 'redux/AppRedux';

import { isProgramDraftInvalid } from 'utils/form';

const ACTION_TYPE = {
  FINALIZE: 'final',
  SAVE: 'save',
};

const TITLES = {
  [ACTION_TYPE.FINALIZE]: (createType) => {
    return createType !== 'new'
      ? 'Would you like to finalize this program?'
      : 'Would you like to save and begin this program now?';
  },
  [ACTION_TYPE.SAVE]: () =>
    'Would you like to save and begin this program later?',
};

const ERROR_NOTICE_TYPES = {
  CANT_DELETE_SECTION: 'cant_delete_section',
};

const ERROR_NOTICES = {
  [ERROR_NOTICE_TYPES.CANT_DELETE_SECTION]:
    'You may need to have at least 3 categories',
};

function CreateProgram({
  createType,
  program,
  setFormData,
  toggleModal,
  setForEditing,
  setFinalize,
  moveUpSection,
  moveDownSection,
  deleteProgrmItem,
  deleteProgramSection,
  isMasterVisited,
  setAppSettings,

  createInfo,
  sectionCount,
  creatError,
  setCreateError,
  ...props
}) {
  const [show, setAlertShow] = useState(false);
  const [showMaster, setShowMaster] = useState(false);
  const [actionType, setActionType] = useState(ACTION_TYPE.FINALIZE);

  const [selectedRow, setSelectedRow] = useState(null);

  const [showNotice, setNoticeShow] = useState(false);
  const [noticeType, setNoticeType] = useState(
    ERROR_NOTICE_TYPES.CANT_DELETE_SECTION
  );

  const confirmTitle = TITLES[actionType](createType);
  const noticeTitle = ERROR_NOTICES[noticeType];

  const onAdd = (section, sectionIndex, day) => {
    const formValue = {
      category: section,
      value: {},
    };

    if (section === 'bmi') {
      formValue.category = 'weight';
      formValue.value = {
        type: 'bmi',
      };
    } else if (section === 'weight') {
      formValue.value = {
        type: 'weight',
      };
    }

    setForEditing({ sectionIndex, day });
    setFormData(formValue);

    if (sectionIndex === 0 && !isMasterVisited) {
      setShowMaster(true);
    } else {
      toggleModal(true);
    }
  };

  const onView = (category, value, sectionIndex, day) => {
    const formValue = {
      category,
      value,
    };

    setFormData(formValue);
    toggleModal(true);
    setForEditing({ sectionIndex, day });
  };

  const onDelete = (category, value, sectionIndex, day) => {
    deleteProgrmItem(sectionIndex, day);
  };

  const onFinalize = () => {
    const invalidMsg = isProgramDraftInvalid(createInfo, createType);
    if (invalidMsg) {
      setCreateError({ message: invalidMsg });
      return;
    }
    setActionType(ACTION_TYPE.FINALIZE);
    setAlertShow(true);
  };

  const onSave = () => {
    const invalidMsg = isProgramDraftInvalid(createInfo, createType);

    if (invalidMsg) {
      setCreateError({ message: invalidMsg });
      return;
    }

    setActionType(ACTION_TYPE.SAVE);
    setAlertShow(true);
  };

  const onDismissMasterHelper = () => {
    setShowMaster(false);
    toggleModal(true);
    setAppSettings({
      visitMaster: true,
    });
  };

  const onDismissAlert = () => {
    setAlertShow(false);
  };

  const onConfirm = () => {
    setFinalize(actionType);
    setAlertShow(false);
  };

  const onSelectRow = (newRow) => {
    setSelectedRow(newRow);
  };

  const onMoveUpRow = () => {
    if (!selectedRow) {
      return;
    }

    if (selectedRow <= 1) {
      return;
    }

    moveUpSection(selectedRow);
    setSelectedRow(selectedRow - 1);
  };

  const onMoveDownRow = () => {
    if (!selectedRow) {
      return;
    }

    if (selectedRow >= sectionCount - 1) {
      return;
    }
    moveDownSection(selectedRow);
    setSelectedRow(selectedRow + 1);
  };

  const onDeleteRow = (sectionIndex) => {
    if (sectionCount <= 3) {
      setNoticeType(ERROR_NOTICE_TYPES.CANT_DELETE_SECTION);
      setNoticeShow(true);
    }

    if (!sectionIndex) {
      return;
    }

    if (sectionIndex === selectedRow) {
      setSelectedRow(selectedRow - 1);
    }
    deleteProgramSection(sectionIndex);
  };

  return (
    <Box {...props}>
      {creatError && (
        <Body2 color="red" mb={4}>
          {creatError.message || 'Error occured'}
        </Body2>
      )}
      <ProgramCreateCard
        mb={6}
        createType={createType}
        onAdd={onAdd}
        onView={onView}
        program={program}
        selectedRow={selectedRow}
        onFinalize={onFinalize}
        onSelectRow={onSelectRow}
        onMoveUpRow={onMoveUpRow}
        onMoveDownRow={onMoveDownRow}
        onDelete={onDelete}
        onDeleteRow={onDeleteRow}
        onSave={onSave}
      />
      <ConfirmModal
        show={show}
        text={confirmTitle}
        onCancel={onDismissAlert}
        onOk={onConfirm}
      />
      <NoticeModal
        show={showNotice}
        text={noticeTitle}
        onOk={() => setNoticeShow(false)}
      />
      <MasterHelperModal show={showMaster} onOk={onDismissMasterHelper} />
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  createInfo: CreateSelectors.selectCreateInfo(state),
  program: CreateSelectors.selectProgramData(state),
  creatError: CreateSelectors.selectError(state),
  createType: CreateSelectors.selectCreateMode(state),
  sectionCount: CreateSelectors.selectSectionCount(state),
  isMasterVisited: AppSelectors.selectIstMasterVisited(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCreateError: (data) => dispatch(CreateActions.setCreateError(data)),
  setFinalize: (data) => dispatch(CreateActions.setCreateFinalizeRequest(data)),
  setFormData: (data) => dispatch(CreateActions.setCreatePopupData(data)),
  setForEditing: (data) => dispatch(CreateActions.setCreatePopupPosition(data)),
  toggleModal: (show) => dispatch(CreateActions.setCreatePopupShow(show)),
  moveUpSection: (show) => dispatch(CreateActions.setCreateMoveUpSection(show)),
  moveDownSection: (show) =>
    dispatch(CreateActions.setCreateMoveDownSection(show)),
  deleteProgrmItem: (sectionIndex, day) =>
    dispatch(CreateActions.setCreateItemDelete(sectionIndex, day)),
  deleteProgramSection: (sectionIndex) =>
    dispatch(CreateActions.setCreateSectionDelete(sectionIndex)),
  setAppSettings: (value) => dispatch(AppActions.setAppSettings(value)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CreateProgram);
