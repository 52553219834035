import { createReducer, createActions } from 'reduxsauce';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import { calcBmiUSC } from 'services/calc';
import { USER_ACCESS_LEVEL } from 'services/terms';
import PlaceHolderAvatar from 'assets/Avatar.png';

const { Types, Creators } = createActions({
  setLoggedIn: ['token'],
  setLoggedOut: null,
  setUserLoadRequest: null,
  setRefreshAuthRequest: null,
  setUser: ['user'],
  setUpdateUserWeight: ['weight'],
  receiveNewNotification: ['data'],
  setReadNotifications: null,
});

export const AuthTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  token: null,
  me: null,
};

/* ------- Selectors --------- */
export const AuthSelectors = {
  selectIsLoggedIn: (state) => !!state.auth.token,
  selectCurrentUser: (state) => state.auth.me,
  selectProfile: (state) => get(state, 'auth.me.profile', {}),
  selectBmi: (state) => calcBmiUSC(get(state, 'auth.me.profile', {})),
  selectRank: (state) => parseInt(get(state, 'auth.me.ranking', 0)) + 1,
  selectUserId: (state) => get(state, 'auth.me._id'),
  selectUserGender: (state) => get(state, 'auth.me.profile.gender', 'male'),
  selectUserAvatar: (state) =>
    get(state, 'auth.me.profile.avatar', PlaceHolderAvatar),
  selectIsOnboarded: (state) => !!get(state, 'auth.me.profile.username'),
  selectIsPremiumUser: (state) =>
    get(state, 'auth.me.accessLevel') === USER_ACCESS_LEVEL.PREMIUM,
  selectIsVendorUser: (state) => !!get(state, 'auth.me.vendor'),
  selectUserEmail: (state) => get(state, 'auth.me.email'),
  selectUserRole: (state) => get(state, 'auth.me.role'),
  selectHasNotification: (state) =>
    get(state, 'auth.me.hasUnreadNotification', false),
};

/* -------- Reducers ---------- */
export const setLoggedIn = (state, { token }) => {
  return {
    ...state,
    token,
  };
};

export const setLoggedOut = () => {
  return {
    ...INITIAL_STATE,
  };
};

export const setUser = (state, { user }) => {
  if (user) {
    const username = get(user, 'profile.username', '').substr(0, 8);
    set(user, 'profile.username', username);

    const ranking = get(user, 'ranking', 0);
    if (ranking <= 0) {
      set(user, 'ranking', 0);
    }
  }

  return {
    ...state,
    me: user,
  };
};

export const receiveNewNotification = (state, { data }) => {
  const { me } = state;
  return {
    ...state,
    me: {
      ...me,
      hasUnreadNotification: true,
    },
  };
};

export const setReadNotifications = (state, { data }) => {
  const { me } = state;
  return {
    ...state,
    me: {
      ...me,
      hasUnreadNotification: false,
    },
  };
};

export const setUpdateUserWeight = (state, { weight }) => {
  const { me } = state;
  return {
    ...state,
    me: {
      ...me,
      profile: {
        ...me.profile,
        weight,
      },
    },
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOGGED_IN]: setLoggedIn,
  [Types.SET_LOGGED_OUT]: setLoggedOut,
  [Types.SET_USER]: setUser,
  [Types.RECEIVE_NEW_NOTIFICATION]: receiveNewNotification,
  [Types.SET_READ_NOTIFICATIONS]: setReadNotifications,
  [Types.SET_UPDATE_USER_WEIGHT]: setUpdateUserWeight,
});
