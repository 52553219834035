import React, { useState } from 'react';
import { Box, Button, Checkbox, Body2 } from 'components/core';
import { MasterUpload } from 'components/upload';

import { getIsValid, generateUploadKey } from 'utils/form';

const isValid = getIsValid(['assetType', 'assetUrl', 'thumbUrl']);

function MasterForm({
  userId,
  userGender,
  userAvatar,
  position,
  onSubmit,
  allowRepeat = true,
  value: initialValue = {},
  ...props
}) {
  const { day } = position || { day: 1 };
  const uploadKey = generateUploadKey(userId, 'master', 0, day);
  const [assetType, setAssetType] = useState(initialValue.assetType || 'image');
  const [assetUrl, setAssetUrl] = useState(initialValue.assetUrl || '');
  const [thumbUrl, setThumb] = useState(initialValue.thumbUrl || '');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRepeated, setRepeated] = useState(false);

  const onSubmitForm = () => {
    const submitValue = {
      assetType,
      assetUrl,
      thumbUrl,
    };

    if (!isValid(submitValue)) {
      onSubmit(null);
      return;
    }
    onSubmit(submitValue, isRepeated);
  };

  const onUpload = ({ assetUrl, type, fileKey }) => {
    setAssetType(type);
    setAssetUrl(assetUrl);
    if (type === 'image') {
      setThumb(`https://bcult.imgix.net/${fileKey}`);
    } else {
      setThumb(userAvatar);
    }
    setIsDisabled(false);
  };

  const onReset = () => {
    setAssetType('');
    setAssetUrl('');
    setThumb('');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      alignItems="center"
      justifyContent="flex-start"
      p={5}
      {...props}
    >
      <MasterUpload
        assetType={assetType}
        assetUrl={assetUrl}
        thumbUrl={thumbUrl}
        mb={6}
        uploadKey={uploadKey}
        onUpload={onUpload}
        onReset={onReset}
        onUploadProgress={(isUploading) => {
          setIsDisabled(isUploading);
        }}
      />
      {allowRepeat && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          alignSelf={['unset', 'stretch']}
          mb={[2, 6]}
        >
          <Checkbox
            variant="tick"
            size="sm"
            pt={`2px`}
            mr={1}
            checked={isRepeated}
            onChange={() => setRepeated(!isRepeated)}
          />
          <Body2>Repeat</Body2>
        </Box>
      )}
      <Button variant="secondary" onClick={onSubmitForm} disabled={isDisabled}>
        OK
      </Button>
    </Box>
  );
}

export default MasterForm;
