import React from 'react';
import styled from 'styled-components';
import { Box, ProfileText, SvgIcon, Tooltip } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';
import { Animal } from 'components/rank';
import { colors } from 'styles/theme';

import PlaceHolderAvatar from 'assets/Avatar.png';

const FollowIcon = styled(SvgIcon)`
  path {
    fill: ${(props) => (props.isLight ? colors.contrastText : colors.text)};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  position: relative
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1px solid ${colors.line};
  border-radius: 2px;
`;

const InnerWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SocialBox = styled(Box)`
  position: absolute;
  background: ${colors.white};
  width: 100%;
  height: 48px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -6px;
  @media (max-width: 768px) {
    height: 30px;
  }
`;

const CustomLink = styled.a`
  padding: 6px;
  border: 1px solid ${colors.line};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 10px;

  @media (max-width: 768px) {
    height: 20px;
    width: 20px;

    margin-right: 4px;
    padding: 2px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

const FollowLink = styled(CustomLink)`
  svg {
    margin-top: 2px;
  }
  @media (max-width: 768px) {
    height: 20px;
    width: 20px;

    svg {
      width: 15px;
      height: 15px;
      margin-top: 2px;
    }
  }
`;

const AnimalAvatar = styled(Animal)`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 6px;
  bottom: 44px;
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    right: 0px;
    bottom: 24px;
    border-radius: 12px;
  }
`;

const ProfileAvatar = ({
  size = 280,
  profile,
  rank,
  showSocial = true,
  isFollowing = false,
  isPublic = false,
  onFollow,
  loading = false,
  ...props
}) => {
  const { avatar, username } = profile;

  const avatarUrl = (avatar || PlaceHolderAvatar).replace(
    'bcult.s3.us-east-2.amazonaws.com',
    'bcult.imgix.net'
  );

  let profileImage = `${avatarUrl}?w=262&h=212&fit=crop`;

  return (
    <Wrapper p={2} size={size} {...props}>
      <InnerWrapper>
        <Image src={profileImage} alt={username} />
        {isPublic && <AnimalAvatar value={rank} alt="rank" />}

        <SocialBox px={[0, 2]}>
          <ProfileText>{username}</ProfileText>
          <Box display="flex" position="relative">
            {loading && (
              <LoadingContainer>
                <DefaultLoader size={24} />
              </LoadingContainer>
            )}
            {profile.facebook && (
              <CustomLink target="_blank" href={profile.facebook}>
                <SvgIcon name="facebook" />
              </CustomLink>
            )}
            {profile.instagram && (
              <CustomLink
                target="_blank"
                href={`https://instagram.com/${profile.instagram}`}
              >
                <SvgIcon name="instagram" />
              </CustomLink>
            )}
            {isPublic && (
              <FollowLink
                onClick={() => (isFollowing ? onFollow(false) : onFollow(true))}
                disabled={isFollowing}
                style={{
                  cursor: 'pointer',
                  marginRight: 0,
                  background: isFollowing ? colors.text : 'transparent',
                }}
              >
                <Tooltip
                  animation="scale"
                  delay={[300, 0]}
                  duration={50}
                  content={isFollowing ? 'unfollow' : 'follow'}
                  theme="light-border"
                  placement="bottom"
                >
                  <span>
                    <FollowIcon name="follow" isLight={isFollowing} />
                  </span>
                </Tooltip>
              </FollowLink>
            )}
          </Box>
        </SocialBox>
      </InnerWrapper>
    </Wrapper>
  );
};

export default ProfileAvatar;
