import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from 'components/core';
import { LoadingContainer, DefaultLoader } from 'components/loading';
import { UserDetail } from 'containers/user';
import API from 'services/api';
import Analytics, { EVENTS } from 'services/analytics';

import OtherUserActions, { OtherUserSelectors } from 'redux/OtherUserRedux';
import { AuthSelectors } from 'redux/AuthRedux';

function UserDetailPage({ isLoggedIn, loading, setLoading, setUser, user }) {
  const params = useParams();

  useEffect(() => {
    return () => {
      setUser(null);
    };
  }, [setUser]);

  useEffect(() => {
    if (params.userId) {
      Analytics.track(EVENTS.OTHER_PROFILE_VIEWED, {
        targetUserId: params.userId,
        isAnonymous: !isLoggedIn,
      });
      (async () => {
        setLoading(true);
        const data = await API.getUserDetail(params.userId, [
          'programs',
          'followers',
          'subscribers',
          'following',
        ]);
        setUser(data);
        setLoading(false);
      })();
    }
  }, [params.userId, setUser, setLoading, isLoggedIn]);

  return (
    <Box alignSelf="stretch" py={8} px={2}>
      {loading && (
        <LoadingContainer>
          <DefaultLoader />
        </LoadingContainer>
      )}
      {user && <UserDetail key={params.userId} user={user} />}
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  loading: OtherUserSelectors.selectLoading(state),
  user: OtherUserSelectors.selectPublicUser(state),
  isLoggedIn: AuthSelectors.selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) =>
    dispatch(OtherUserActions.setPublicUserLoading(isLoading)),
  setUser: (data) => dispatch(OtherUserActions.setPublicUserData(data)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(UserDetailPage);
