import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Input, InputFeedback } from 'components/core';

// Todo: error status
function InputField(props) {
  const { field, label, form, renderAddon, InputComponent, ...rest } = props;
  const formError = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const hasError = !!(touched && formError);

  return (
    <React.Fragment>
      <InputComponent
        placeholder={label}
        error={hasError}
        disabled={form.isSubmitting}
        {...field}
        {...rest}
      />
      {hasError && <InputFeedback>{formError}</InputFeedback>}
    </React.Fragment>
  );
}

InputField.propTypes = {
  InputComponent: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
};

InputField.defaultProps = {
  InputComponent: Input,
};

export default InputField;
