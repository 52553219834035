import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Box } from 'components/core';
import { Animal, ProgressItem } from 'components/rank';
import { colors } from 'styles/theme';

import { AuthSelectors } from 'redux/AuthRedux';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1px solid ${colors.line};
  border-radius: 2px;
  cursor: pointer;
`;

const InnerWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const AnimalBox = styled(Animal)`
  width: 100%;
  flex: 1;
`;

const IndicatorBox = styled(Box)`
  width: 100%;
  height: ${(props) => props.barSize}px;
  background: ${colors.text};
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RankItem = styled(ProgressItem)`
  width: 16px;
  height: 40%;

  margin: ${(props) => props.barSize / 12}px;
`;

const SelectedItem = styled(ProgressItem)`
  width: 16px;
  height: 80%;
`;

const rankOptions = Array(12)
  .fill(0)
  .map((item, index) => index + 1);

const RankAvatar = ({ value, size = 280, barSize = 48, ...props }) => {
  const [showDetail, setShowDetail] = useState(false);

  const toggleShowDetail = () => {
    if (size < 280) {
      return;
    }
    setShowDetail(!showDetail);
  };

  return (
    <Wrapper p={2} size={size} {...props} onClick={toggleShowDetail}>
      <InnerWrapper>
        <AnimalBox value={value} showText={showDetail} />
        <IndicatorBox barSize={barSize} px={[0.5, 0, 0, 4]}>
          {rankOptions.map((rank) => {
            if (value === rank) {
              return <SelectedItem key={rank} value={rank} />;
            }

            const rankValue = value > rank ? rank : 0;
            return <RankItem barSize={barSize} key={rank} value={rankValue} />;
          })}
        </IndicatorBox>
      </InnerWrapper>
    </Wrapper>
  );
};

const mapStatesToProps = (state) => ({
  value: AuthSelectors.selectRank(state),
});

export default connect(mapStatesToProps)(RankAvatar);
