import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Box,
  Select,
  BInput,
  SvgIcon,
  Button,
  IconButton,
} from 'components/core';

import { GOAL_OPTIONS } from 'services/terms';

const TargetIcon = styled(SvgIcon)`
  width: 90px;
  height: 90px;

  @media (max-width: 768px) {
    margin-top: 20px;
    width: 45px;
    height: 45px;
  }
`;

const INPUT_PLACEHOLDERS = {
  bmi: '',
  weight: 'LBS',
};

function GoalForm({ goal = {}, onSubmit, ...props }) {
  const [goalType, setGoalType] = useState('weight');
  const initialValue = goal[goalType];
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const onChangeType = ({ value: newGoalType }) => {
    setGoalType(newGoalType);
  };

  const onChangeValue = (e) => {
    const { value } = e.target;

    setValue(value);
  };

  const onReset = () => {
    setGoalType('');
    setValue('');
  };

  const onSubmitGoal = () => {
    if (!goalType || !value) {
      return;
    }

    if (onSubmit) {
      onSubmit({
        goalType,
        value,
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignSelf="stretch"
      alignItems="flex-start"
      justifyContent="flex-start"
      {...props}
    >
      <TargetIcon name="target" />

      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="flex-start"
        p={3}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="flex-start"
          mb={[2, 4]}
        >
          <Select
            placeholder="Goal Type"
            value={goalType}
            options={GOAL_OPTIONS}
            onChange={onChangeType}
            mr={[2, 6]}
          />
          <BInput
            value={value}
            type="number"
            placeholder={INPUT_PLACEHOLDERS[goalType]}
            onChange={onChangeValue}
            mr={[2, 6]}
          />
          <IconButton borderWidth={0} onClick={onReset}>
            <SvgIcon name="close" />
          </IconButton>
        </Box>
        <Button variant="secondary" onClick={onSubmitGoal}>
          {initialValue ? 'Update' : 'Insert'} Goal
        </Button>
      </Box>
    </Box>
  );
}

export default GoalForm;
