import React from 'react';
import styled from 'styled-components';
import { Box, SvgIcon, Tooltip } from 'components/core';
import { colors } from 'styles/theme';

const Icon = styled(SvgIcon)`
  path {
    fill: ${(props) => (props.selected ? 'white' : colors.blueGray)};
  }
`;

const Button = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  display: flex;
  flex-direction: column
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.selected ? colors.blueGray : colors.background};
  outline: 0;
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.selected ? colors.blue : colors.blueGray)};
`;

const IconRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SelectButton = ({ selected, name, tooltipContent, ...props }) => {
  return (
    <Tooltip
      key={name}
      animation="scale"
      delay={[300, 0]}
      duration={50}
      content={tooltipContent}
      theme="light-border"
      placement="bottom"
    >
      <Button selected={selected} {...props}>
        <Icon name={name} selected={selected} />
      </Button>
    </Tooltip>
  );
};

const IconSelect = ({ value, onChange, options, tooltips, ...props }) => {
  const handleClick = (name) => {
    if (onChange) onChange(name);
  };

  return (
    <IconRow {...props}>
      {options.map((name, index) => (
        <SelectButton
          key={name}
          p={name !== 'gender-american-binary' ? 2 : 0}
          mr={4}
          name={name}
          tooltipContent={tooltips[index]}
          selected={value === name}
          onClick={() => handleClick(name, index)}
        />
      ))}
    </IconRow>
  );
};

export default IconSelect;
