import React from 'react';
import styled, { css } from 'styled-components';
import { Box, IconButton, SvgIcon, Title1Bold, Body1 } from 'components/core';
import { colors } from 'styles/theme';

const SliderButton = styled(IconButton)`
  height: 24px;
  width: 24px;
`;

const SliderIcon = styled(SvgIcon)`
  width: 18px;
  height: 24px;
  path {
    stroke-width: 2;
  }
`;

const ButtonIcon = styled(SvgIcon)`
  width: 72px;
  height: 72px;
  cursor: pointer;
  path {
    fill: ${(props) => (props.selected ? colors.main : colors.line)};
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }

  ${(props) =>
    props.name === 'program-new' &&
    css`
      margin-left: 10px;
    `}
`;

const CircleIcon = styled(SvgIcon)`
  width: 12px;
  height: 12px;
`;

function SectionSwitch({ index, onChange, options, sectionOptions, ...props }) {
  const currentOption = options[index];
  const sameCategoryOptions = options.filter(
    (o) => o.category === currentOption.category
  );

  return (
    <Box {...props}>
      <Box display="flex" flexDirection="row" alignItems="center" mb={6}>
        <Title1Bold mr={4} width={0}>
          {currentOption.category}
        </Title1Bold>
        <SliderButton
          mr={1}
          borderWidth={0}
          onClick={() =>
            onChange((index - 1 + options.length) % options.length)
          }
        >
          <SliderIcon name="chevron-left" />
        </SliderButton>
        <CircleIcon name="full-moon" />
        <SliderButton
          borderWidth={0}
          onClick={() => onChange((index + 1) % options.length)}
        >
          <SliderIcon name="chevron-right" />
        </SliderButton>
      </Box>

      <Box
        display="flex"
        justifyContent={['center', 'flex-start']}
        {...sectionOptions}
      >
        {sameCategoryOptions.map((o, index) => (
          <Box
            key={o.label}
            display="flex"
            alignItems="center"
            flexDirection="column"
            mr={index === 2 ? 0 : 8}
          >
            <ButtonIcon
              name={o.icon}
              width={72}
              height={72}
              mb={2}
              selected={o.index === currentOption.index}
              onClick={() => onChange(o.index)}
            />
            <Body1 color="main">{o.label}</Body1>
            <Title1Bold color="main">{o.value}</Title1Bold>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default SectionSwitch;
