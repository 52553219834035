import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Box,
  Body2,
  Body2Dark,
  BTextArea,
  BInput,
  Button,
  InputFeedback,
} from 'components/core';
import { AvatarUpload } from 'components/upload';

import AuthActions, { AuthSelectors } from 'redux/AuthRedux';

import API from 'services/api';
// import { getNameService } from 'lib/NameService';
// import EggImage from 'assets/IconEgg.png';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

function GeneralSettings({ logout, currentUser, updateUser, ...props }) {
  const { profile, email, id: userId } = currentUser;
  const { username, description: profileDescription } = profile;

  const [newName] = useState(username);
  const [description, setDescription] = useState(profileDescription || '');
  const [instagram, setInstagram] = useState(profile.instagram || '');
  const [facebook, setFacebook] = useState(profile.facebook || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [instaError, setInstaError] = useState(null);

  const handleLogout = () => {
    logout();
  };

  const handleInstaChange = (val) => {
    setInstagram(val);
    if (/[^0-9a-zA-Z\-_]/.test(val)) {
      setInstaError('Please enter valid handle without @ or \\.');
      return false;
    } else {
      setInstaError(null);
    }
    return true;
  };

  const handleSaveProfile = async () => {
    if (!handleInstaChange(instagram) || instaError) return;

    setIsSubmitting(true);

    const newProfile = {
      profile: {
        ...currentUser.profile,
        username: newName,
        description,
        facebook,
        instagram,
      },
    };
    const newUser = {
      ...currentUser,
      ...newProfile,
    };

    await API.updateProfile(newProfile);

    updateUser(newUser);

    setIsSubmitting(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      alignItems="flex-start"
      justifyContent="flex-start"
      {...props}
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignSelf="stretch"
        alignItems="flex-start"
        justifyContent="flex-start"
        mt={8}
      >
        <AvatarUpload
          currentUser={currentUser}
          mb={6}
          uploadKey={userId}
          onUpload={(newUser) => updateUser(newUser)}
        />

        {/* <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          alignSelf="stretch"
          width={200}
          ml={[0, 8]}
        > */}
        {/* <Body2 mb={1}>Bcult Name</Body2>
          <Box display="flex" flexDirection="row" alignItems="center" mb={4}>
            <Body2Dark mr={4}>{newName}</Body2Dark>
            <РесурсContainer type="button" onClick={handleRefreshName}>
              <Image src={EggImage} alt="Ресурс" />
            </РесурсContainer>
          </Box> */}

        {/* </Box> */}

        <Box
          display="flex"
          flex={1}
          alignSelf="stretch"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          ml={[0, 8]}
        >
          <Body2 mb={4}>About</Body2>
          <TextArea
            value={description}
            disabled={isSubmitting}
            placeholder="About me..."
            onChange={(e) => setDescription(e.target.value)}
            mb={4}
          />
          <BInput
            value={instagram}
            disabled={isSubmitting}
            onChange={(e) => handleInstaChange(e.target.value)}
            placeholder="your-instagram-id"
            mb={4}
          />
          {instaError && <InputFeedback>{instaError}</InputFeedback>}
          <BInput
            mb={4}
            value={facebook}
            disabled={isSubmitting}
            onChange={(e) => setFacebook(e.target.value)}
            placeholder="https://www.facebook.com/your-user-name"
          />
          <Body2 mb={4}>Email</Body2>
          <Body2Dark mb={4} style={{ wordBreak: 'break-all' }}>
            {email}
          </Body2Dark>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            my={4}
          >
            <Button variant="secondary" onClick={handleSaveProfile} mr={[2, 4]}>
              Save
            </Button>

            <Button variant="secondary" onClick={handleLogout}>
              Log out
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  currentUser: AuthSelectors.selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.setLoggedOut()),
  updateUser: (newUser) => {
    dispatch(AuthActions.setUser(newUser));
  },
});

export default connect(mapStatesToProps, mapDispatchToProps)(GeneralSettings);
