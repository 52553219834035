import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string';

import { Box } from 'components/core';
import { ProfileViewSelect } from 'components/others';
import { CalendarPopup } from 'containers/calendar';

import useViewport from 'hooks/useViewport';

import {
  CalendarBox,
  BmiProfile,
  RankAvatar,
  ProfileAvatar,
  AboutView,
  ProfileTitle,
} from 'containers/profile';

import { ProgressView } from 'containers';
import { Community } from 'containers/user';
import { ProfileProgramList } from 'containers/programs';
import { SubmissionModal } from 'modals';
import history from 'utils/history';

import ProfileActions, { ProfileSelectors } from 'redux/ProfileRedux';
import AppActions, { AppSelectors } from 'redux/AppRedux';
import AuthActions from 'redux/AuthRedux';

import { AuthSelectors } from 'redux/AuthRedux';

const PROFILE_VIEWS = ['new', 'completed', 'current', 'progress', 'share'];

const getCategorySize = (width) => {
  if (width > 1024) {
    return {
      size: 280,
      itemSize: 24,
      barSize: 48,
      selectSize: 160,
    };
  }

  if (width > 540) {
    return {
      size: 180,
      selectSize: 110,
      itemSize: 16,
      barSize: 32,
    };
  }
  return {
    size: 110,
    selectSize: 70,
    itemSize: 12,
    barSize: 14,
  };
};

function ProfilePage({
  isNewProgramSubmitted,
  setAppSettings,
  currentView,
  setCurrentView,
  loadMyPrograms,
  profile,
  currentUser,
  location,
  readNotifications,
  match: {
    params: { tab },
  },
}) {
  const { width } = useViewport();
  const parsed = QueryString.parse(location.search);
  const { size, itemSize, barSize, selectSize } = getCategorySize(width);

  useEffect(() => {
    loadMyPrograms();
  }, [loadMyPrograms]);

  useEffect(() => {
    setCurrentView(tab || 'current');
    if (tab === 'share') {
      readNotifications();
    }
  }, [setCurrentView, tab, readNotifications]);

  const [show, setShow] = useState(parsed.open && parsed.open === 'calendar');

  const onShowCalendar = () => {
    setShow(true);
  };

  const onHideCalendar = () => {
    setShow(false);
  };

  const onChangeType = (value) => {
    history.push(`/profile/${value}`);
  };

  const renderView = () => {
    switch (currentView) {
      case 'new':
        return <ProfileProgramList currentView="created" />;
      case 'completed':
        return <ProfileProgramList currentView="completed" />;
      case 'current':
        return <ProfileProgramList currentView="current" />;
      case 'progress':
        return <ProgressView />;
      case 'share':
        return <Community user={currentUser} />;
      default:
    }
    return null;
  };

  return (
    <Box alignSelf="stretch" pt={8} pb={8} px={2}>
      <Box
        display="flex"
        flexDirection="row"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="space-between"
        mb={8}
      >
        <CalendarBox size={size} itemSize={itemSize} onClick={onShowCalendar} />
        <RankAvatar size={size} barSize={barSize} />
        <BmiProfile size={size} />
        {width > 768 && <ProfileAvatar size={size} profile={profile} />}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignSelf="stretch"
        alignItems="space-between"
        mb={6}
      >
        <ProfileViewSelect
          options={PROFILE_VIEWS}
          value={currentView}
          onChange={onChangeType}
          size={selectSize}
          flex={1}
        />
        {width > 768 && <AboutView width={size} ml={6} profile={profile} />}
      </Box>

      <ProfileTitle currentView={currentView} />

      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="flex-start"
        justifyContent="flex-start"
        mb={8}
        py={6}
      >
        {renderView()}
      </Box>

      <SubmissionModal
        show={isNewProgramSubmitted}
        onOk={() => {
          setAppSettings({
            newProgramSubmitted: false,
          });
        }}
      />

      <CalendarPopup show={show} onClickOutside={onHideCalendar} />
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  currentUser: AuthSelectors.selectCurrentUser(state),
  profile: AuthSelectors.selectProfile(state),
  currentView: ProfileSelectors.selectProfileView(state),
  isNewProgramSubmitted: AppSelectors.selectIsNewProgramSubmitted(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentView: (data) => dispatch(ProfileActions.setProfileView(data)),
  loadMyPrograms: () => dispatch(ProfileActions.setLoadMyProgramsRequest()),
  setAppSettings: (value) => dispatch(AppActions.setAppSettings(value)),
  readNotifications: (value) =>
    dispatch(AuthActions.setReadNotifications(value)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProfilePage);
