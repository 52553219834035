import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Box, DialogBox, BTextArea, Button } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';

import API from 'services/api';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

function PaymentDescriptionModal({ show, text, programId, onOk, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');

  const onSubmitRating = async () => {
    if (!programId) {
      return;
    }

    setIsLoading(true);
    try {
      await API.updateProgram(programId, {
        paymentDescription: description,
      });
    } catch (err) {}

    setIsLoading(false);
    onOk();
  };

  useEffect(() => {
    setDescription(text);
  }, [programId, text]);

  return (
    <DialogBox show={show} onClickOutside={onOk}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        m={8}
      >
        <TextArea
          mb={8}
          value={description}
          placeholder="Please add payment description here. Each line will be different bullet point on program subscription page."
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button variant="secondary" onClick={onSubmitRating}>
          OK
        </Button>

        {isLoading && (
          <LoadingContainer>
            <DefaultLoader />
          </LoadingContainer>
        )}
      </Box>
    </DialogBox>
  );
}

export default PaymentDescriptionModal;
