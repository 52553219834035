/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Box,
  Title1,
  BInput,
  BTextArea,
  DialogBox,
  PSelectButton,
  SvgIcon,
} from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';
import { ChooseCreateModal } from 'modals';

import { FeaturedTypeSelect, MainSwitch, NoticeModal } from 'components/others';
import { SetPriceModal } from 'modals';

import {
  InsertTemplateForm,
  GoalForm,
  CompoundCategoryField,
  CreateProgram,
  ProgramForm,
} from 'containers';
import { ALL_IMAGES } from 'styles/images';

import CreateActions, { CreateSelectors } from 'redux/CreateRedux';
import SettingsActions from 'redux/SettingsRedux';
import AppActions, { AppSelectors } from 'redux/AppRedux';
import { AuthSelectors } from 'redux/AuthRedux';

import history from 'utils/history';
import confirm from 'lib/confirm';
import Analytics, { EVENTS } from 'services/analytics';

const ERROR_NOTICE_TYPES = {
  CANT_COMPLETE_NOTICE: 'cant_complete_notice',
};

const ERROR_NOTICES = {
  [ERROR_NOTICE_TYPES.CANT_COMPLETE_NOTICE]:
    'There is still time and/or components remaining for this program.',
};

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

const Divider = styled(Box)`
  opacity: 0.4;
  height: 1px;
  align-self: stretch;
`;

const InfoImage = styled(SvgIcon)`
  cursor: pointer;
  width: 14px;
  height: 14px;
`;

const TypeRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const ButtonRow = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const CREATE_TYPE_OPTIONS = ['new', 'completed'];

function CreateProgramPage({
  createType,
  setCreateType,
  title,
  setTitle,
  description,
  setDescription,
  categoryFilter,
  setCategoryFilter,
  goal,
  setGoal,
  formType,
  formData,
  setFormData,
  show,
  toggleModal,
  offerToGold,
  setOfferToGold,
  usedTemplates,
  insertTemplate,
  insertFormData,
  setForEditing,
  position,
  userId,
  isFirstCreate,
  setAppSettings,
  goldPrice,
  setGoldPrice,
  isLoading,
  isVendorUser,

  resetAll,
  setSettingsView,
}) {
  const [showHelperModal, setShowHelperModal] = useState(!isFirstCreate);
  const [showNotice, setNoticeShow] = useState(false);
  const [showGoldModal, setGoldModalShow] = useState(false);
  const [noticeType] = useState(ERROR_NOTICE_TYPES.CANT_COMPLETE_NOTICE);

  const noticeTitle = ERROR_NOTICES[noticeType];

  useEffect(() => {
    if (!isFirstCreate) {
      setAppSettings({
        visitCreated: true,
      });
    }
    return () => {
      resetAll();
    };
  }, [isFirstCreate, setAppSettings]);

  const { selectedIndex, day } = position || {};

  const onRequireBilling = async () => {
    if (
      await confirm('Would you like to set up your account for price setting?')
    ) {
      setSettingsView('billing');
      history.push('/settings');
    }
  };

  const onChangeType = (value) => {
    setCreateType(value);
  };

  const onDissmissNotice = () => {
    setNoticeShow(false);
  };

  const onDismissModal = () => {
    if (formType !== 'master') {
      toggleModal(false);
      setForEditing(null);
    }
  };

  const onSelectFeaturedType = (featuredType) => {
    setFormData({
      category: featuredType,
      value: {},
    });
    setForEditing(null);
    toggleModal(true);
  };

  const onSubmitForm = (values, isRepeat) => {
    if (values) {
      Analytics.track(EVENTS.PROGRAM_ITEM_ADDED, {
        programId: 'new',
        formType,
        data: {
          ...formData,
          ...values,
        },
        position,
        isRepeat,
        isCurrent: false,
      });

      insertFormData(
        formType,
        {
          ...formData,
          ...values,
        },
        position,
        isRepeat
      );
    }

    toggleModal(false);
  };

  const onChangeOfferToGold = (value) => {
    if (!value) {
      setOfferToGold(false);
      setGoldPrice(0);
      return;
    }

    if (!isVendorUser) {
      onRequireBilling();
      return;
    }
    setOfferToGold(true);
    setGoldModalShow(true);
  };

  const onShowGold = () => {
    if (!offerToGold) {
      return;
    }
    setGoldModalShow(true);
  };

  const onConfirmPrice = (newPrice) => {
    setGoldPrice(newPrice);
    setGoldModalShow(false);
  };

  const onSubmitTemplate = (template) => {
    insertTemplate(template);
    Analytics.track(EVENTS.PROGRAM_TEMPLATE_ADDED, {
      programId: 'new',
      template,
    });
  };

  return (
    <Box alignSelf="stretch" position="relative" pt={9} pb={8} px={2}>
      <Title1 mb={4}>Create Program</Title1>

      <TypeRow mb={4} key={Math.random()}>
        {CREATE_TYPE_OPTIONS.map((name, index) => (
          <ButtonRow key={name} mr={4}>
            <PSelectButton
              mb={2}
              size={50}
              name={name}
              selected={createType === name}
              onClick={() => onChangeType(name, index)}
            />
          </ButtonRow>
        ))}
        <InfoImage name="info" onClick={() => setShowHelperModal(true)} />
      </TypeRow>

      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignSelf="stretch"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          alignSelf="stretch"
          mb={4}
        >
          <BInput
            value={title}
            placeholder="Program title"
            mb={4}
            onChange={(e) => setTitle(e.target.value)}
          />
          <CompoundCategoryField
            value={categoryFilter}
            onChange={(value) => setCategoryFilter(value)}
          />
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          alignSelf="stretch"
          mb={4}
          ml={[0, 4]}
        >
          <TextArea
            value={description}
            placeholder="Program description..."
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
      </Box>
      <FeaturedTypeSelect
        alignSelf="stretch"
        onSelect={onSelectFeaturedType}
        py={4}
        mb={2}
      />
      <Divider bg="text" m={4} mx={0}></Divider>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Box flex={1} mr={[0, 4]}>
          {createType === 'new' && <GoalForm goal={goal} onSubmit={setGoal} />}
          <InsertTemplateForm
            onSubmit={onSubmitTemplate}
            disabledTemplates={usedTemplates}
          />
        </Box>
        <Box
          flex={1}
          ml={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={ALL_IMAGES.gold}
            alt="golden program"
            onClick={onShowGold}
            style={{ cursor: offerToGold ? 'pointer' : 'auto' }}
          />
          <MainSwitch
            disabled={createType !== 'completed'}
            selected={offerToGold}
            onChange={onChangeOfferToGold}
          />
        </Box>
      </Box>
      <CreateProgram mb={4} />
      <DialogBox
        show={show}
        onClickOutside={onDismissModal}
        hasClose={formType !== 'master'}
      >
        <ProgramForm
          key={`${formType}-${selectedIndex}-${day}`}
          userId={userId}
          category={formType}
          value={formData}
          position={position}
          onSubmit={onSubmitForm}
        />
      </DialogBox>
      <ChooseCreateModal
        show={showHelperModal}
        options={CREATE_TYPE_OPTIONS}
        value={createType}
        onOk={() => setShowHelperModal(false)}
        onChange={onChangeType}
      />
      <NoticeModal
        show={showNotice}
        text={noticeTitle}
        onOk={onDissmissNotice}
        onCnacel={onDissmissNotice}
      />
      <SetPriceModal
        show={showGoldModal}
        value={goldPrice}
        onCancel={() => setGoldModalShow(false)}
        onOk={onConfirmPrice}
      />
      {isLoading && (
        <LoadingContainer>
          <DefaultLoader />
        </LoadingContainer>
      )}
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  createType: CreateSelectors.selectCreateMode(state),
  goal: CreateSelectors.selectGoal(state),
  formType: CreateSelectors.selectPopupCategory(state),
  formData: CreateSelectors.selectFormValue(state),
  show: CreateSelectors.selectIsPopupShow(state),
  offerToGold: CreateSelectors.selectOfferToGold(state),
  usedTemplates: CreateSelectors.selectUsedTemplates(state),
  position: CreateSelectors.selectPopupPosition(state),
  goldPrice: CreateSelectors.selectGoldPrice(state),
  isFirstCreate: AppSelectors.selectIsFirstCreate(state),

  title: CreateSelectors.selectTitle(state),
  description: CreateSelectors.selectDescription(state),
  categoryFilter: CreateSelectors.selectCategoryFilter(state),
  isLoading: CreateSelectors.selectIsLoading(state),
  isVendorUser: AuthSelectors.selectIsVendorUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCreateType: (data) => dispatch(CreateActions.setCreateMode(data)),
  setGoal: (data) => dispatch(CreateActions.setCreateGoal(data)),
  setGoldPrice: (data) => dispatch(CreateActions.setCreatePrice(data)),
  setFormData: (data) => dispatch(CreateActions.setCreatePopupData(data)),
  toggleModal: (show) => dispatch(CreateActions.setCreatePopupShow(show)),
  setOfferToGold: (offerToGold) =>
    dispatch(CreateActions.setCreateOfferToGold(offerToGold)),
  setTitle: (title) => dispatch(CreateActions.setCreateTitle(title)),
  setCategoryFilter: (categoryFilter) =>
    dispatch(CreateActions.setCreateCategoryFilter(categoryFilter)),
  setDescription: (description) =>
    dispatch(CreateActions.setCreateDescription(description)),
  insertTemplate: (template) =>
    dispatch(CreateActions.setCreateInsertTemplate(template)),
  insertFormData: (category, value, position, isRepeat) =>
    dispatch(
      CreateActions.setCreateInsertFormData(category, value, position, isRepeat)
    ),
  setForEditing: (data) => dispatch(CreateActions.setCreatePopupPosition(data)),
  setAppSettings: (value) => dispatch(AppActions.setAppSettings(value)),

  resetAll: () => dispatch(CreateActions.setCreateResetAll()),

  setSettingsView: (data) => dispatch(SettingsActions.setSettingsView(data)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CreateProgramPage);
