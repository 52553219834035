import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Body2 } from 'components/core';
import {
  isValidRank,
  getRankText,
  rankBackground,
  rankAnimals,
} from 'services/rankHelpers';
import { ALL_IMAGES } from 'styles/images';

const InvalidImage = styled(Box)`
  background: #6b6b6b;
`;

const ImageBox = styled(Box)`
  background: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${(props) =>
    props.blendMode &&
    css`
      mix-blend-mode: luminosity;
    `};
  flex: 1;
  align-self: stretch;

  ${(props) =>
    props.hasFilter &&
    css`
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    `};
`;

const ColorBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background: ${(props) => props.background};
`;

const TextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
`;

const TextDescription = ({ value }) => {
  const { animalValue, level } = getRankText(value);

  const helpText = `You are currently a ${level} level ${animalValue}.`;
  return (
    <TextBox bg="clear" pt={6} px={4}>
      <Body2 textAlign="center">{helpText}</Body2>
      <Body2 textAlign="center" mb={4}>
        Increase your rank by:
      </Body2>
      <Body2 textAlign="center">-Creating Programs</Body2>
      <Body2 textAlign="center">-Completing Programs</Body2>
      <Body2 textAlign="center">-Sharing your progress</Body2>
      <Body2 textAlign="center">-Bcult Master</Body2>
    </TextBox>
  );
};

function RankAnimal({ value, showText = false, ...props }) {
  const isValid = isValidRank(value);

  if (!isValid) {
    return <InvalidImage {...props} />;
  }

  const backgroundInfo = rankBackground[value - 1];

  const { background, hasFilter, blendMode } = backgroundInfo || {};

  return (
    <ColorBox background={background} {...props}>
      {showText ? (
        <TextDescription value={value} />
      ) : (
        <ImageBox
          src={ALL_IMAGES[rankAnimals[value - 1]]}
          blendMode={blendMode}
          hasFilter={hasFilter}
        />
      )}
    </ColorBox>
  );
}

export default RankAnimal;
