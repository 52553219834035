import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import { ReactComponent as Settings } from 'assets/svg/Settings.svg';
import { ReactComponent as Info } from 'assets/svg/Info.svg';

import { ReactComponent as Balance } from 'assets/svg/Balance.svg';
import { ReactComponent as BmiShared } from 'assets/svg/BmiShared.svg';

import { ReactComponent as CalendarDate } from 'assets/svg/CalendarDate.svg';
import { ReactComponent as CalendarDateSelected } from 'assets/svg/CalendarDateSelected.svg';
import { ReactComponent as CalendarList } from 'assets/svg/CalendarList.svg';
import { ReactComponent as CalendarListSelected } from 'assets/svg/CalendarListSelected.svg';

import { ReactComponent as ChevronDown } from 'assets/svg/ChevronDown.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/ChevronUp.svg';
import { ReactComponent as ChevronLeft } from 'assets/svg/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/ChevronRight.svg';
import { ReactComponent as Close } from 'assets/svg/Close.svg';
import { ReactComponent as DeleteFill } from 'assets/svg/DeleteFill.svg';
import { ReactComponent as Facebook } from 'assets/svg/Facebook1.svg';
import { ReactComponent as GAMale } from 'assets/svg/GAMale.svg';
import { ReactComponent as GAFemale } from 'assets/svg/GAFemale.svg';
import { ReactComponent as GABinary } from 'assets/svg/GABinary.svg';
import { ReactComponent as GEMale } from 'assets/svg/GEMale.svg';
import { ReactComponent as GEFemale } from 'assets/svg/GEFemale.svg';
import { ReactComponent as GEBinary } from 'assets/svg/GEBinary.svg';
import { ReactComponent as KeyPointer } from 'assets/svg/KeyPointer.svg';
import { ReactComponent as InsertTemplate } from 'assets/svg/InsertTemplate.svg';
import { ReactComponent as Instagram } from 'assets/svg/Instagram.svg';

import { ReactComponent as ProgramNew } from 'assets/svg/ProgramNew.svg';
import { ReactComponent as ProgramCompleted } from 'assets/svg/ProgramCompleted.svg';
import { ReactComponent as ProgramCurrent } from 'assets/svg/ProgramCurrent.svg';
import { ReactComponent as ProgramProgress } from 'assets/svg/ProgramProgress.svg';
import { ReactComponent as ProgramShare } from 'assets/svg/ProgramShare.svg';

import { ReactComponent as PlayFilled } from 'assets/svg/PlayFilled.svg';

import { ReactComponent as Rating1 } from 'assets/svg/Rating1.svg';
import { ReactComponent as Rating2 } from 'assets/svg/Rating2.svg';
import { ReactComponent as Rating3 } from 'assets/svg/Rating3.svg';
import { ReactComponent as Rating4 } from 'assets/svg/Rating4.svg';
import { ReactComponent as Search } from 'assets/svg/Search.svg';
import { ReactComponent as Target } from 'assets/svg/Target.svg';
import { ReactComponent as FitWellness } from 'assets/svg/fits/Wellness.svg';
import { ReactComponent as FitDiet } from 'assets/svg/fits/Diet.svg';
import { ReactComponent as FitStrength } from 'assets/svg/fits/Strength.svg';
import { ReactComponent as FitFitness } from 'assets/svg/fits/Fitness.svg';
import { ReactComponent as FitSupplement } from 'assets/svg/fits/Supplement.svg';
import { ReactComponent as FitWeight } from 'assets/svg/fits/Weight.svg';
import { ReactComponent as FitMaster } from 'assets/svg/fits/Master.svg';
import { ReactComponent as FitBmi } from 'assets/svg/fits/BMI.svg';

import { ReactComponent as IndicatorLeft } from 'assets/svg/profile/IndicatorLeft.svg';
import { ReactComponent as IndicatorRight } from 'assets/svg/profile/IndicatorRight.svg';

import { ReactComponent as JoinMain } from 'assets/svg/JoinMain.svg';
import { ReactComponent as JoinDown } from 'assets/svg/JoinDown.svg';
import { ReactComponent as JoinCircle } from 'assets/svg/JoinCircle.svg';

import { ReactComponent as ToolbarJoin } from 'assets/svg/Join.svg';
import { ReactComponent as ToolbarFinalize } from 'assets/svg/ToolbarFinalize.svg';
import { ReactComponent as ToolbarSave } from 'assets/svg/ToolbarSave.svg';
import { ReactComponent as ToolbarGold } from 'assets/svg/ToolbarGold.svg';
import { ReactComponent as ToolbarEdit } from 'assets/svg/ToolbarEdit.svg';
import { ReactComponent as ToolbarPromote } from 'assets/svg/ToolbarPromote.svg';
import { ReactComponent as ToolbarReset } from 'assets/svg/ToolbarReset.svg';
import { ReactComponent as ToolbarDelete } from 'assets/svg/ToolbarDelete.svg';
import { ReactComponent as ToolbarComplete } from 'assets/svg/ToolbarComplete.svg';

import { ReactComponent as AdminFeatured } from 'assets/svg/AdminFeatured.svg';
import { ReactComponent as AdminApprove } from 'assets/svg/AdminApprove.svg';
import { ReactComponent as AdminDecline } from 'assets/svg/AdminDecline.svg';

import { ReactComponent as ShareFacebook } from 'assets/svg/ShareFacebook.svg';
import { ReactComponent as ShareTwitter } from 'assets/svg/ShareTwitter.svg';
import { ReactComponent as ShareEmail } from 'assets/svg/ShareEmail.svg';
import { ReactComponent as ShareClipboard } from 'assets/svg/ShareClipboard.svg';
import { ReactComponent as ShareOutline } from 'assets/svg/ShareOutline.svg';
import { ReactComponent as HeartFill } from 'assets/svg/HeartFill.svg';
import { ReactComponent as Heart } from 'assets/svg/Heart.svg';

import { ReactComponent as Tick } from 'assets/svg/Tick.svg';

import { ReactComponent as DownArrow } from 'assets/svg/adhoc/down-arrow.svg';
import { ReactComponent as UpArrow } from 'assets/svg/adhoc/up-arrow.svg';

import { ReactComponent as FullMoon } from 'assets/svg/FullMoon.svg';

import { ReactComponent as Plus } from 'assets/svg/Plus.svg';
import { ReactComponent as Follow } from 'assets/svg/Follow.svg';
import { ReactComponent as Featured } from 'assets/svg/Featured.svg';

const SvgIcon = ({ name, isClickable, isLight, ...props }) => {
  switch (name) {
    case 'info':
      return <Info {...props} />;
    case 'settings':
      return <Settings {...props} />;
    case 'balance':
      return <Balance {...props} />;
    case 'bmi-shared':
      return <BmiShared {...props} />;

    case 'calendar-date':
      return <CalendarDate {...props} />;
    case 'calendar-date-selected':
      return <CalendarDateSelected {...props} />;
    case 'calendar-list':
      return <CalendarList {...props} />;
    case 'calendar-list-selected':
      return <CalendarListSelected {...props} />;

    case 'chevron-down':
      return <ChevronDown {...props} />;
    case 'chevron-up':
      return <ChevronUp {...props} />;
    case 'chevron-left':
      return <ChevronLeft {...props} />;
    case 'chevron-right':
      return <ChevronRight {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'delete':
      return <DeleteFill {...props} />;
    case 'facebook':
      return <Facebook {...props} />;

    case 'fit-strength':
      return <FitStrength {...props} />;
    case 'fit-fitness':
      return <FitFitness {...props} />;
    case 'fit-supplement':
      return <FitSupplement {...props} />;
    case 'fit-diet':
      return <FitDiet {...props} />;
    case 'fit-weight':
      return <FitWeight {...props} />;
    case 'fit-wellness':
      return <FitWellness {...props} />;
    case 'fit-master':
      return <FitMaster {...props} />;
    case 'fit-bmi':
      return <FitBmi {...props} />;

    case 'gender-american-male':
      return <GAMale {...props} />;
    case 'gender-american-female':
      return <GAFemale {...props} />;
    case 'gender-american-binary':
      return <GABinary {...props} />;
    case 'gender-egyptian-male':
      return <GEMale {...props} />;
    case 'gender-egyptian-female':
      return <GEFemale {...props} />;
    case 'gender-egyptian-binary':
      return <GEBinary {...props} />;

    case 'join-main':
      return <JoinMain {...props} />;
    case 'join-down':
      return <JoinDown {...props} />;
    case 'join-circle':
      return <JoinCircle {...props} />;

    case 'key-pointer':
      return <KeyPointer {...props} />;

    case 'insert-template':
      return <InsertTemplate {...props} />;
    case 'instagram':
      return <Instagram {...props} />;

    case 'indicator-left':
      return <IndicatorLeft {...props} />;
    case 'indicator-right':
      return <IndicatorRight {...props} />;

    case 'toolbar-join':
      return <ToolbarJoin {...props} />;
    case 'toolbar-finalize':
      return <ToolbarFinalize {...props} />;
    case 'toolbar-save':
      return <ToolbarSave {...props} />;
    case 'toolbar-gold':
      return <ToolbarGold {...props} />;
    case 'toolbar-edit':
      return <ToolbarEdit {...props} />;
    case 'toolbar-promote':
      return <ToolbarPromote {...props} />;
    case 'toolbar-complete':
      return <ToolbarComplete {...props} />;
    case 'toolbar-reset':
      return <ToolbarReset {...props} />;
    case 'toolbar-delete':
      return <ToolbarDelete {...props} />;

    case 'admin-heart':
      return <Heart {...props} />;
    case 'admin-heart-fill':
      return <HeartFill {...props} />;
    case 'admin-featured':
      return <AdminFeatured {...props} />;
    case 'admin-approve':
      return <AdminApprove {...props} />;
    case 'admin-decline':
      return <AdminDecline {...props} />;

    case 'share-facebook':
      return <ShareFacebook {...props} />;
    case 'share-twitter':
      return <ShareTwitter {...props} />;
    case 'share-email':
      return <ShareEmail {...props} />;
    case 'share-clipboard':
      return <ShareClipboard {...props} />;
    case 'share-outline':
      return <ShareOutline {...props} />;
    case 'heart-fill':
      return <HeartFill {...props} />;
    case 'heart':
      return <Heart {...props} />;

    case 'program-new':
      return <ProgramNew {...props} />;
    case 'program-completed':
    case 'note':
      return <ProgramCompleted {...props} />;
    case 'program-current':
      return <ProgramCurrent {...props} />;
    case 'program-progress':
      return <ProgramProgress {...props} />;
    case 'program-share':
      return <ProgramShare {...props} />;

    case 'play-filled':
      return <PlayFilled {...props} />;

    case 'rating-1':
      return <Rating1 {...props} />;
    case 'rating-2':
      return <Rating2 {...props} />;
    case 'rating-3':
      return <Rating3 {...props} />;
    case 'rating-4':
      return <Rating4 {...props} />;

    case 'search':
      return <Search {...props} />;
    case 'target':
      return <Target {...props} />;
    case 'tick':
      return <Tick {...props} />;

    case 'down-arrow':
      return <DownArrow {...props} />;
    case 'up-arrow':
      return <UpArrow {...props} />;

    case 'full-moon':
      return <FullMoon {...props} />;
    case 'follow':
      return <Follow {...props} />;

    case 'featured':
      return <Featured {...props} />;

    default:
  }

  return null;
};

const styledSvg = styled(SvgIcon)`
  ${space};
`;

export default styledSvg;
