import Pusher from 'pusher-js';
import AuthActions from 'redux/AuthRedux';

const pusherInstance = new Pusher(`${process.env.REACT_APP_PUSHER_APP_KEY}`, {
  cluster: `${process.env.REACT_APP_PUSHER_CLUSTER_ID}`,
});

const EVENT_TYPE = {
  SUBSCRIBE: 'subscribe',
  FOLLOW: 'FOLLOW',
};

let channelInstance = null;
let currentChannelId = '';

let currentReduxStore;

export const initEventService = (reduxStore) => {
  currentReduxStore = reduxStore;
};

export const subscribeToContent = () => {
  channelInstance.bind(EVENT_TYPE.FOLLOW, function (data) {
    console.log(EVENT_TYPE.SUBSCRIBE, data.message);

    currentReduxStore.dispatch(AuthActions.receiveNewNotification(data));
  });

  channelInstance.bind(EVENT_TYPE.SUBSCRIBE, function (data) {
    console.log(EVENT_TYPE.SUBSCRIBE, data.message);
    currentReduxStore.dispatch(AuthActions.receiveNewNotification(data));
  });
};

export const subscribeToChannel = (userId) => {
  channelInstance = pusherInstance.subscribe(userId);
  currentChannelId = userId;

  subscribeToContent();
};

export const unsubscribeFromChannel = (userId) => {
  if (channelInstance) {
    channelInstance.unbind();
  }

  if (currentChannelId) {
    pusherInstance.unsubscribe(currentChannelId);

    channelInstance = null;
    currentChannelId = '';
  }
};
