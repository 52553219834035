import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ConfirmModal } from 'components/others';
import theme from 'styles/theme';

const Root = styled.div`
  font-family: Montserrat;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
`;

const Style = createGlobalStyle`
  * { box-sizing: border-box; }
  body{ margin:0; color: #E5E5E5; }
`;

const Confirm = ({ show, proceed, options }) => (
  <ThemeProvider theme={theme}>
    <Root>
      <Style />
      <ConfirmModal
        show={show}
        onOk={() => proceed(true)}
        onCancel={() => proceed(false)}
        text={options.text}
      />
    </Root>
  </ThemeProvider>
);

const askConfirm = createConfirmation(confirmable(Confirm));

export default function confirm(text) {
  return askConfirm({ options: { text } });
}
