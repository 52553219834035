import { createReducer, createActions } from 'reduxsauce';
const { Types, Creators } = createActions({
  setPublicUserData: ['data'],
  setPublicUserLoading: ['isLoading'],
});

export const OtherUserTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  data: null,
  isLoading: false,
};

/* ------- Selectors --------- */
export const OtherUserSelectors = {
  selectPublicUser: (state) => state.otherUser.data,
  selectLoading: (state) => state.otherUser.isLoading,
};

/* -------- Reducers ---------- */
export const setPublicUserData = (state, { data }) => {
  return {
    ...state,
    data,
  };
};

export const setPublicUserLoading = (state, { isLoading }) => {
  return {
    ...state,
    isLoading,
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PUBLIC_USER_DATA]: setPublicUserData,
  [Types.SET_PUBLIC_USER_LOADING]: setPublicUserLoading,
});
