import { select, put, call } from 'redux-saga/effects';
import { ProfileSelectors } from './ProfileRedux';
import CreateActions, { CreateSelectors } from './CreateRedux';
import AppActions from './AppRedux';
import { AuthSelectors } from './AuthRedux';

import { calcBmiUSC, calcWeightFromBmi } from 'services/calc';
import { transformCreateProgramForApi } from 'utils/transform';
import { getErrors } from 'utils/err';
import API from '../services/api';
import history from 'utils/history';
import { PROGRAM_STATUS, PROGRAM_TYPE } from '../services/terms';
import Analytics, { EVENTS } from 'services/analytics';

function getEventFromState(programInfo, isEditing, isDraft) {
  const { createMode } = programInfo;

  const type = createMode === 'new' ? 'NEW' : 'COMPLETED';
  let action = 'CREATED';
  if (!isDraft) {
    action = 'FINALIZED';
  } else if (isEditing) {
    action = 'SAVED';
  }

  return `PROGRAM_${type}_${action}`;
}

export function* initEditFromExistingProgram(action) {
  const { programId } = action;

  const myPrograms = yield select(ProfileSelectors.selectAllMyPrograms);
  const programInfo = myPrograms.find((item) => item.id === programId);

  if (!programInfo) {
    history.goBack();
    return;
  }

  const {
    title,
    description,
    type,
    category,
    subCategory,
    duration,
    price,
    program: programData,
  } = programInfo;

  const {
    status,
    startDate,
    endDate,
    sectionids,
    currentDay,
    data,
    ...program
  } = programData;

  const createMode =
    status === PROGRAM_STATUS.COMPLETED || type === PROGRAM_TYPE.FULL
      ? 'completed'
      : 'new';

  // strip action id

  const newData = data.map((sectionData) => {
    const newSectionData = {};
    Object.keys(sectionData).forEach((day) => {
      const { id, record, ...itemData } = sectionData[day] || {};

      newSectionData[day] = itemData;
    });

    return newSectionData;
  });

  const dataToUpdateBatch = {
    createMode,
    title,
    description,
    categoryFilter: {
      category,
      subCategory,
      duration,
    },
    program: {
      ...program,
      data: newData,
      price,
      offerToGold: !!price,
    },
    programId,
  };

  yield put(CreateActions.setCreateInitFromExistingSuccess(dataToUpdateBatch));
}

export function* createFinalize(action) {
  const { mode } = action;
  const isDraft = mode !== 'final';
  let profileView = !isDraft ? 'current' : 'new';

  yield put(CreateActions.setCreateLoading(true));
  const state = yield select();

  const { programId, title } = state.create;
  const isEditing = !!programId;

  const values = transformCreateProgramForApi(state.create, isEditing, isDraft);
  const analyticsEventName = getEventFromState(
    state.create,
    isEditing,
    isDraft
  );

  try {
    let newProgram;
    if (isEditing) {
      newProgram = yield call(API.updateProgram, programId, values);
    } else {
      newProgram = yield call(API.createProgram, values);
    }

    const { _id: newProgramId, status } = newProgram;

    if (!isDraft && status !== PROGRAM_STATUS.COMPLETED) {
      yield call(API.startProgram, newProgramId);
    }
    if (status === PROGRAM_STATUS.COMPLETED) {
      profileView = 'completed';
      yield put(
        AppActions.setAppSettings({
          newProgramSubmitted: true,
        })
      );
    }

    Analytics.track(EVENTS[analyticsEventName], {
      programId: newProgramId,
      title,
    });

    yield put(CreateActions.setCreateFinalizeSuccess());
    yield put(CreateActions.setCreateLoading(false));

    history.push(`/profile/${profileView}`);
  } catch (err) {
    const error = getErrors(err);
    yield put(CreateActions.setCreateError(error));
    yield put(CreateActions.setCreateLoading(false));
  }
}

export function* createSetGoal(action) {
  const profile = yield select(AuthSelectors.selectProfile);
  const { height } = profile;
  const state = yield select();
  const { programId } = state.create;

  const { goal } = action || {};
  if (!goal) {
    Analytics.track(EVENTS.PROGRAM_GAOL_REMOVED, {
      programId: programId ? programId : 'new',
    });
    yield put(CreateActions.setCreateGoalSuccess({}));
  }
  const { goalType, value } = goal;

  let bmi, weight;

  if (goalType === 'bmi') {
    bmi = value;
    weight = calcWeightFromBmi({ height, bmi });
  } else {
    weight = value;
    bmi = calcBmiUSC({ height, weight });
  }

  Analytics.track(EVENTS.PROGRAM_GAOL_SET, {
    programId: programId ? programId : 'new',
    goal: { bmi, weight },
  });

  yield put(CreateActions.setCreateGoalSuccess({ bmi, weight }));
}

export function* watchSettingFormData(action) {
  const { category, value, position } = action;

  if (category !== 'bmi' && category !== 'weight') {
    return;
  }

  const programDraft = yield select(CreateSelectors.selectProgramData);
  const { sections } = programDraft;

  const { type, value: itemValue } = value || {};

  const newCategory = type === 'bmi' ? 'weight' : 'bmi';

  const sectionIndex = sections.findIndex((item) => item === newCategory);

  if (sectionIndex === -1) {
    return;
  }

  const profile = yield select(AuthSelectors.selectProfile);
  const { height } = profile;
  const { day = 1 } = position || {};

  const newPosition = { sectionIndex, day };

  let newVal;
  if (type === 'bmi') {
    const weight = calcWeightFromBmi({ height, bmi: itemValue });
    newVal = {
      ...value,
      type: 'weight',
      value: weight,
    };
  } else {
    const bmi = calcBmiUSC({ height, weight: itemValue });
    newVal = {
      ...value,
      type: 'bmi',
      value: bmi,
    };
  }

  yield put(
    CreateActions.setCreateInsertAddonFormData(newCategory, newVal, newPosition)
  );
}
