import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Select, SvgIcon, IconButton } from 'components/core';

import { TEMPLATES_OPTIONS } from 'services/templates';

const IconBox = styled(Box)`
  width: 90px;

  @media (max-width: 768px) {
    width: 45px;
  }
`;

const TemplateIcon = styled(SvgIcon)`
  width: 72px;

  @media (max-width: 768px) {
    width: 36px;
  }
`;

function InsertTemplateForm({ onSubmit, disabledTemplates = [], ...props }) {
  const [templateType, setTemplateType] = useState('');

  const enabledTemplates = TEMPLATES_OPTIONS.filter(
    (item) => !disabledTemplates.includes(item.value)
  );

  const disabled = !enabledTemplates || !enabledTemplates.length;

  const onChangeType = ({ value: newGoalType }) => {
    setTemplateType(newGoalType);
  };

  const onReset = () => {
    setTemplateType('');
  };

  const onSubmitTemplate = () => {
    if (!templateType) {
      return;
    }

    if (onSubmit) {
      const template = enabledTemplates.find(
        (item) => item.value === templateType
      );
      onSubmit(template);
      setTemplateType('');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignSelf="stretch"
      alignItems="center"
      justifyContent="flex-start"
      {...props}
    >
      <IconBox pl={[1, 3]}>
        <TemplateIcon name="insert-template" />
      </IconBox>

      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="flex-start"
        p={3}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="flex-start"
          mb={[2, 4]}
        >
          <Select
            disabled={disabled}
            key={templateType}
            flex={1}
            placeholder="Type"
            value={templateType}
            options={enabledTemplates}
            onChange={onChangeType}
            mr={[2, 6]}
          />
          <IconButton borderWidth={0} onClick={onReset}>
            <SvgIcon name="close" />
          </IconButton>
        </Box>
        <Button
          variant="secondary"
          onClick={onSubmitTemplate}
          disabled={disabled}
        >
          Insert Template
        </Button>
      </Box>
    </Box>
  );
}

export default InsertTemplateForm;
