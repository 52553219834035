import React from 'react';
import styled from 'styled-components';
import { Box, Title1 } from 'components/core';
import { colors } from 'styles/theme';

import {
  ProgramToolbar,
  ProgramProfile,
  ProgramGraph,
  ProgramStat,
  ProgramShare,
} from 'components/program';
import { GraphWrapper } from 'components/program/CommonStyled';
import { PROGRAM_TYPE } from 'services/terms';

const Wrapper = styled(Box)`
  border-radius: 2px;
  border: 1px solid ${colors.text};
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const CurrentProgramCard = ({
  subscribedFrom,
  type,
  title,
  user,
  description,
  stats,
  program,
  onView,
  onAdd,
  onComplete,
  onReset,
  onFinalize,
  onViewSummary,
  onViewRating,
  shareImage,
  isRestarted,
  ...props
}) => {
  const editable = !subscribedFrom && type !== PROGRAM_TYPE.FULL;
  const isProgramStarted = !!program.startDate;

  const toolbarOptions = !isProgramStarted
    ? [
        {
          name: 'finalize',
          help: 'Start Now',
        },
      ]
    : [
        {
          name: 'reset',
          help: 'Restart',
        },
        {
          name: 'complete',
          help: 'Complete',
        },
      ];

  const handleSelectTool = (name) => {
    switch (name) {
      case 'reset':
        onReset();
        return;
      case 'finalize':
        onFinalize();
        return;
      case 'complete':
        onComplete();
        return;
      default:
    }
  };

  return (
    <GraphWrapper
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="stretch"
      {...props}
    >
      <Box
        alignSelf="stretch"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        mb={2}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="flex-end"
        >
          <Title1 color="text" as="a" name={props.id}>
            {title}
          </Title1>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
        >
          <ProgramProfile {...user} description={description} />
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="flex-end"
          >
            {subscribedFrom && !isRestarted && (
              <ProgramStat
                {...stats}
                onClickStat={onViewSummary}
                onClickRating={onViewRating}
              />
            )}
            <ProgramToolbar
              options={toolbarOptions}
              onSelect={handleSelectTool}
            />
          </Box>
        </Box>
      </Box>
      <Wrapper>
        <ProgramGraph
          viewOnly={!isProgramStarted}
          hightlightCurrentDay={isProgramStarted}
          editable={editable}
          type={type}
          onView={onView}
          onAdd={onAdd}
          flex={1}
          {...program}
        />
      </Wrapper>
      <ProgramShare
        {...program}
        shareImage={shareImage}
        user={user}
        likes={props.likes}
        programId={props.id}
        description={description}
      />
    </GraphWrapper>
  );
};

export default CurrentProgramCard;
