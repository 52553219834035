import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { DefaultLoader } from 'components/loading';
import { Box, Title1Bold, IconButton, SvgIcon } from 'components/core';

import ReportActions, { ReportSelectors } from 'redux/ReportRedux';

const PROFILE_VIEW_TITLES = {
  new: 'Created Programs',
  completed: 'Completed Programs',
  current: 'Current Programs',
  progress: 'Progress',
  // share: 'Community',
};

const SliderButton = styled(IconButton)`
  height: 24px;
  width: 24px;
`;

const Icon = styled(SvgIcon)`
  width: 18px;
  height: 24px;
  path {
    stroke-width: 2;
  }
`;

const CircleIcon = styled(SvgIcon)`
  width: 12px;
  height: 12px;
`;

function ProfileTitle({
  currentView,
  currentSlide,
  setCurrentSlide,
  isReportLoading,
}) {
  const title = PROFILE_VIEW_TITLES[currentView];

  const isReportView = currentView === 'progress';

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      mb={6}
    >
      <Title1Bold mr={4} width={0}>
        {title}
      </Title1Bold>
      {isReportView && (
        <Box
          mr={4}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <SliderButton
            mr={1}
            borderWidth={0}
            onClick={() => {
              setCurrentSlide((currentSlide + 1) % 2);
            }}
          >
            <Icon name="chevron-left" />
          </SliderButton>
          <CircleIcon name="full-moon" />
          <SliderButton
            borderWidth={0}
            onClick={() => {
              setCurrentSlide((currentSlide + 1) % 2);
            }}
          >
            <Icon name="chevron-right" />
          </SliderButton>
        </Box>
      )}
      {isReportLoading && <DefaultLoader size={40} />}
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  isReportLoading: ReportSelectors.selectLoading(state),
  currentSlide: ReportSelectors.selectCurrentSlide(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSlide: (slide) =>
    dispatch(ReportActions.setReportCurrentSlide(slide)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProfileTitle);
