const baseColors = {
  blue: '#3A559F',
  gray: '#1B1E31',
  darkGray: '#101324',
  blueGray: '#3B4061',
  lightGray: '#5D5D5D',
  black: '#000000',
  white: '#ffffff',
  red: '#EF6969',
  green: '#88D857',
};

export const colors = {
  ...baseColors,
  inputBg: '#FAFAFA',
  main: '#1B1E31',
  text: '#5D5D5D',
  contrastText: '#F7F7F7',
  line: '#DCDCDC',
  background: '#F7F7F7',
  clear: 'rgba(0,0,0,0)',
  backDrop: 'rgba(0, 0, 0, 0.5)',
  selection: 'rgba(0, 0, 0, 0.07)',
  bmiObese: '#EF6969',
  bmiOver: '#EED75E',
  bmiIdeal: '#88D857',
  bmiUnder: '#607CE2',
};

const theme = {
  fontSizes: [14, 16, 18, 24, 32, 40],
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 48, 64, 128, 256],
  colors: {
    ...colors,
  },
  buttons: {
    primary: {
      color: colors.white,
      backgroundColor: colors.blueGray,
      border: `1px solid ${colors.blueGray}`,
      '&:disabled': {
        color: colors.blueGray,
        backgroundColor: colors.main,
      },
    },
    secondary: {
      color: colors.contrastText,
      backgroundColor: colors.main,
      border: `1px solid ${colors.main}`,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    secondaryPlus: {
      color: colors.contrastText,
      backgroundColor: colors.text,
      border: `1px solid ${colors.text}`,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    third: {
      color: colors.text,
      backgroundColor: 'transparent',
      border: `1px solid ${colors.text}`,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    forth: {
      color: colors.line,
      backgroundColor: 'transparent',
      border: `1px solid ${colors.line}`,
      '&:disabled': {
        backgroundColor: colors.lightGray,
      },
    },
    danger: {
      backgroundColor: colors.red,
      color: colors.white,
      border: `1px solid ${colors.red}`,
    },
    contrast: {
      color: colors.text,
      backgroundColor: colors.line,
      border: `1px solid ${colors.text}`,
    },
    facebook: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#3A559F',
      backgroundColor: 'transparent',
      border: 0,
    },
  },
  buttonSizes: {
    large: {
      width: 216,
      height: 48,
    },
    medium: {
      width: 175,
      height: 48,
    },
    small: {
      width: 80,
      height: 32,
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  iconButtonSizes: {
    large: {
      width: 64,
      height: 64,
    },
    medium: {
      width: 40,
      height: 40,
    },
    join: {
      width: 104,
      height: 64,
    },
    toolbar: {
      width: 48,
      height: 48,
    },
  },
};

export default theme;
