import React from 'react';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import styled from 'styled-components';

import { Box, Button, InputFeedback, BcultLogo } from 'components/core';
import { InputField } from 'components/form';

import API from 'services/api';

const Wrapper = styled(Box)`
  width: 592px;
  min-height: 368px;

  width: 592px;
  max-width: 592px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 5px;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const MainSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const validationSchema = object().shape({
  email: string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
});

class ResetPassword extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setStatus } = actions;
    setSubmitting(true);

    try {
      await API.resetPassword(values);
    } catch (err) {
      console.error(err);
    }
    setStatus({
      message: 'Reset password request sent',
    });
    setSubmitting(false);
  };

  renderForm = ({ isValid, isSubmitting, status }) => {
    return (
      <Form>
        <Wrapper>
          <BcultLogo />
          <MainSection mt={10}>
            <Field
              component={InputField}
              mb={4}
              name="email"
              type="email"
              label="Email Address"
            />
            {status && status.message && (
              <InputFeedback color="darkGray"> {status.message} </InputFeedback>
            )}
            <Button type="submit" disabled={!isValid || isSubmitting} width={1}>
              Enter
            </Button>
          </MainSection>
        </Wrapper>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{ email: '' }}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
        render={this.renderForm}
      />
    );
  }
}

export default ResetPassword;
