import { loadStripe } from '@stripe/stripe-js';
const STRIPE_PUBLIC_KEY = `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`;

let stripeInstance = null;

export const initStripe = async () => {
  stripeInstance = await loadStripe(STRIPE_PUBLIC_KEY);
};

export const getStripeInstance = () => {
  return stripeInstance;
};

export const getStripeVendorInstance = (stripeAccount) => {
  return loadStripe(STRIPE_PUBLIC_KEY, { stripeAccount });
};

export const getMainStripeInstance = () => {
  return loadStripe(STRIPE_PUBLIC_KEY);
};
