import styled from 'styled-components';
import {
  color,
  border,
  space,
  fontSize,
  width,
  display,
  flexbox,
} from 'styled-system';

import { colors } from 'styles/theme';

const BInput = styled.input`
  appearance: input;
  display: block;
  width: 100%;
  margin: 0;
  font-family: inherit;

  ${border}
  ${space}
  ${fontSize}
  ${color}
  ${width}
  ${display}
  ${flexbox}

  border: 0;
  outline: 0;
  box-shadow: inset 0px 1px 1px 0px
      ${(props) => (props.error ? colors.red : colors.line)},
    inset 0px 0px 1px 1px ${(props) => (props.error ? colors.red : colors.line)};

  background-color: ${(props) => (props.disabled ? colors.line : colors.clear)};

  &::placeholder {
    color: ${colors.text};
    opacity: 0.8;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

BInput.displayName = 'BInput';
BInput.propTypes = {
  ...border.propTypes,
  ...space.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...display.propTypes,
};

BInput.defaultProps = {
  color: 'text',
  fontSize: 2,
  borderRadius: 1,
  lineHiehgt: '22px',
  p: 3,
};

export default BInput;
