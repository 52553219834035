import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, DialogBox } from 'components/core';
import {
  DraftProgramCard,
  CompletedProgramCard,
  CurrentProgramCard,
} from 'containers/programs';
import { ConfirmModal, NoticeModal } from 'components/others';
import {
  RatingFormModal,
  RatingModal,
  SummaryModal,
  SetPriceModal,
  PromoteModal,
} from 'modals';
import { TrackingPlayerModal } from 'components/player';
import { ProgramForm } from 'containers';

import ProfileActions, { ProfileSelectors } from 'redux/ProfileRedux';
import { AuthSelectors } from 'redux/AuthRedux';
import SettingsActions from 'redux/SettingsRedux';

import API from 'services/api';
import {
  transformProgram,
  transformProgramForAnalytics,
} from 'utils/transform';
import Analytics, { EVENTS } from 'services/analytics';
import { PROGRAM_STATUS, PROGRAM_TYPE } from 'services/terms';
import history from 'utils/history';
import { getErrors } from 'utils/err';
import confirm from 'lib/confirm';

import useViewport from 'hooks/useViewport';

const ACTION_TYPE = {
  FINALIZE: 'final',
  RESET: 'rest',
  COMPLETE: 'complete',
  DELETE: 'delete',
};

const TITLES = {
  [ACTION_TYPE.FINALIZE]: 'Would you like to begin this program now?',
  [ACTION_TYPE.RESET]: 'Would you like to restart this program now?',
  [ACTION_TYPE.COMPLETE]: 'Would you like to complete this program now?',
  [ACTION_TYPE.DELETE]: 'Would you like to delete this program?',
};

const ERROR_NOTICE_TYPES = {
  CANT_COMPLETE_NOTICE: 'cant_complete_notice',
};

const ERROR_NOTICES = {
  [ERROR_NOTICE_TYPES.CANT_COMPLETE_NOTICE]:
    'There is still time and/or components remaining for this program.',
};

function ProfileProgramList({
  currentView,
  isVendorUser,
  programs,
  setCurrentView,
  updateMyProgram,
  deleteMyProgram,
  addMyProgram,
  addRecord,
  isPremiumUser,
  setSettingsView,
  ...props
}) {
  const { width } = useViewport();

  const isSmallView = width < 768;

  const [show, setAlertShow] = useState(false);
  const [showNotice, setNoticeShow] = useState(false);
  const [showGoldModal, setGoldModalShow] = useState(false);
  const [showPromoteModal, setPromoteModalShow] = useState(false);

  const [actionType, setActionType] = useState(ACTION_TYPE.FINALIZE);
  const [noticeTitle, setNoticeTitle] = useState(
    ERROR_NOTICES[ERROR_NOTICE_TYPES.CANT_COMPLETE_NOTICE]
  );

  const [actionItem, setActionItem] = useState(null);
  const [goldPrice, setGoldPrice] = useState(0);
  const [promoPlan, setPromoPlan] = useState(null);

  const [currentRating, setCurrentRating] = useState(1);
  const [currentProgramId, setCurrentProgramId] = useState('');
  const [currentStats, setCurrentStats] = useState(1);

  const [showSummary, setShowSummary] = useState(false);
  const [showRatingView, setShowRatingView] = useState(false);
  const [showRatingForm, setShowRatingForm] = useState(false);

  const [itemData, setItemData] = useState(false);
  const [itemDataCategory, setItemDataCategory] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formInfo, setFormInfo] = useState(null);
  const [position, setPosition] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmTitle = TITLES[actionType];

  const { category: formType, value: formData } = formInfo || {};

  const onRequireBilling = async () => {
    if (
      await confirm('Would you like to set up your account for price setting?')
    ) {
      setSettingsView('billing');
      history.push('/settings');
    }
  };

  const onDismissFormModal = () => {
    if (formType !== 'master') {
      setShowForm(false);
      setFormInfo(null);
    }
  };

  const onDissmissNotice = () => {
    setNoticeShow(false);
  };

  const onSubmitForm = (values) => {
    if (values) {
      const { programId, sectionId, day } = position;

      Analytics.track(EVENTS.PROGRAM_ITEM_ADDED, {
        programId,
        formType,
        data: {
          ...formData,
          ...values,
        },
        position,
        isCurrent: true,
      });

      const { type } = values;
      addRecord(programId, {
        ...values,
        sectionType: formType === 'weight' && type === 'bmi' ? 'bmi' : formType,
        sectionId,
        day,
      });
    }
    setShowForm(false);
    setFormInfo(null);
  };

  const onEdit = (programInfo) => {
    const { id: programId } = programInfo;
    history.push(`/edit/${programId}`);
  };

  const onFinalize = (programInfo) => {
    setActionType(ACTION_TYPE.FINALIZE);
    setActionItem(programInfo);
    setAlertShow(true);
  };

  const onReset = (programInfo) => {
    setActionType(ACTION_TYPE.RESET);
    setActionItem(programInfo);
    setAlertShow(true);
  };

  const onDelete = (programInfo) => {
    setActionType(ACTION_TYPE.DELETE);
    setActionItem(programInfo);
    setAlertShow(true);
  };

  const onPromote = (programInfo) => {
    const { promotion } = programInfo;

    if (!isPremiumUser && !promotion) {
      history.push(`/promote/${programInfo.id}`);
      return;
    }

    setPromoPlan(promotion);
    setActionItem(programInfo);
    setPromoteModalShow(true);
  };

  const onGold = (programInfo) => {
    if (!isVendorUser) {
      onRequireBilling();
      return;
    }
    const { price } = programInfo;
    setGoldPrice(price);
    setActionItem(programInfo);
    setGoldModalShow(true);
  };

  const onComplete = (programInfo) => {
    const {
      program: { dayCount, currentDay },
    } = programInfo;

    if (dayCount <= currentDay) {
      setActionType(ACTION_TYPE.COMPLETE);
      setActionItem(programInfo);
      setAlertShow(true);
    } else {
      setNoticeTitle(ERROR_NOTICES[ERROR_NOTICE_TYPES.CANT_COMPLETE_NOTICE]);
      setNoticeShow(true);
    }
  };

  const onTrackComplete = (newRecordId) => {
    if (!itemData) {
      return;
    }

    setItemData({
      ...itemData,
      record: newRecordId,
    });
  };

  const onView = (
    programInfo,
    category,
    value,
    sectionIndex,
    day,
    currentDay
  ) => {
    const { id: programId, program, status } = programInfo;

    const trackable = status === PROGRAM_STATUS.CURRENT && +day <= +currentDay;

    const { sectionIds } = program;
    const sectionId = sectionIds[sectionIndex];

    setItemData(value);
    setPosition({ programId, sectionIndex, day, sectionId, trackable });
    setItemDataCategory(category);
  };

  const onAdd = (programInfo, category, sectionIndex, day, currentDay) => {
    const { id: programId, program } = programInfo;
    const { sectionIds } = program;
    const sectionId = sectionIds[sectionIndex];

    const formValue = {
      category,
      value: {},
    };

    if (category === 'bmi') {
      formValue.category = 'weight';
      formValue.value = {
        type: 'bmi',
      };
    } else if (category === 'weight') {
      formValue.value = {
        type: 'weight',
      };
    }

    setFormInfo(formValue);
    setPosition({ programId, sectionIndex, day, sectionId });
    setShowForm(true);
  };

  const onViewSummary = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    setCurrentProgramId(programId);
    setCurrentStats(stats);
    setShowSummary(true);
  };

  const onViewRating = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    const { avgRating } = stats || {};
    setCurrentProgramId(programId);
    setCurrentRating(avgRating);
    setShowRatingView(true);
  };

  const onDismissAlert = () => {
    setAlertShow(false);
    setActionItem(null);
  };

  const onConfirmPrice = async (newPrice) => {
    if (newPrice === goldPrice) {
      return;
    }
    setGoldPrice(newPrice);
    setIsSubmitting(true);
    const { id: programId } = actionItem;
    try {
      const newProgram = await API.setPriceToProgram(programId, {
        price: newPrice,
      });
      const transformed = transformProgram(newProgram);
      updateMyProgram(transformed);
    } catch (err) {
      console.log(err);
    }

    setIsSubmitting(false);
  };

  const onConfirmPromote = async (newPlan) => {
    if (promoPlan && promoPlan.promotionType === newPlan) {
      setPromoteModalShow(false);
      return;
    }

    setIsSubmitting(true);

    const { id: programId } = actionItem;
    try {
      let newProgram;
      if (newPlan === 'cancel') {
        newProgram = await API.demoteProgram(programId);
      } else {
        newProgram = await API.promoteProgram(programId, newPlan);
      }

      const transformed = transformProgram(newProgram);
      updateMyProgram(transformed);
      setIsSubmitting(false);
      setPromoPlan(null);
    } catch (err) {
      setIsSubmitting(false);
      throw err;
    }

    setPromoteModalShow(false);
  };

  const onConfirm = async () => {
    if (actionType === ACTION_TYPE.FINALIZE) {
      const { id: programId, isSubscribedFromOther, type } = actionItem;
      if (isSubscribedFromOther) {
        const newProgram = await API.restartProgram(programId);
        const transformed = transformProgram(newProgram);
        updateMyProgram(transformed);
        Analytics.track(EVENTS.PROGRAM_UPDATED, {
          by: 'self',
          ...transformProgramForAnalytics(transformed),
          actionType,
        });
        setCurrentView('current');
      } else if (type !== PROGRAM_TYPE.FULL) {
        const newProgram = await API.startProgram(programId);
        const transformed = transformProgram(newProgram);
        updateMyProgram(transformed);
        Analytics.track(EVENTS.PROGRAM_NEW_FINALIZED, {
          programId,
          title: transformed.title,
        });
        setCurrentView('current');
      } else {
        const newProgram = await API.updateProgram(programId, {
          status: PROGRAM_STATUS.COMPLETED,
        });
        const transformed = transformProgram(newProgram);
        updateMyProgram(transformed);
        Analytics.track(EVENTS.PROGRAM_COMPLETED_FINALIZED, {
          programId,
          title: transformed.title,
        });
        setCurrentView('completed');
      }
    } else if (actionType === ACTION_TYPE.RESET) {
      const { id: programId, status } = actionItem;

      if (status === PROGRAM_STATUS.CURRENT) {
        const newProgram = await API.restartProgram(programId);
        const transformed = transformProgram(newProgram);
        updateMyProgram(transformed);
        Analytics.track(EVENTS.PROGRAM_UPDATED, {
          by: 'self',
          ...transformProgramForAnalytics(transformed),
          actionType,
        });
      } else {
        const newProgram = await API.subscribeProgram(programId);
        const transformed = transformProgram(newProgram);
        const startedProgram = await API.restartProgram(transformed.id);
        const transformedStarted = transformProgram(startedProgram);
        setCurrentView('current');
        addMyProgram(transformedStarted);
        Analytics.track(EVENTS.PROGRAM_CLONED, {
          by: 'self',
          ...transformProgramForAnalytics(transformedStarted),
          actionType,
        });
      }
    } else if (actionType === ACTION_TYPE.COMPLETE) {
      const { id: programId, isRestarted, subscribedFrom } = actionItem;
      const newProgram = await API.completeProgram(programId);
      const transformed = transformProgram(newProgram);
      updateMyProgram(transformed);
      setAlertShow(false);
      setActionItem(null);

      Analytics.track(EVENTS.PROGRAM_UPDATED, {
        by: 'self',
        ...transformProgramForAnalytics(transformed),
        actionType,
      });

      if (!isRestarted) {
        // SHOW RATING
        setCurrentProgramId(subscribedFrom ? subscribedFrom : programId);
        setCurrentRating(4);
        setShowRatingForm(true);
      }
    } else if (actionType === ACTION_TYPE.DELETE) {
      const { id: programId } = actionItem;
      try {
        await API.deleteProgram(programId);
        deleteMyProgram(programId);
        Analytics.track(EVENTS.PROGRAM_UPDATED, {
          by: 'self',
          ...transformProgramForAnalytics(actionItem),
          actionType,
        });
        setAlertShow(false);
        setActionItem(null);
      } catch (err) {
        setAlertShow(false);
        setActionItem(null);

        const error = getErrors(err);
        const { message = 'You can not delete this program' } = error || {};

        setNoticeTitle(message);
        setNoticeShow(true);

        console.log(JSON.stringify(err), error);
      }
    }

    setAlertShow(false);
    setActionItem(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="stretch"
      {...props}
    >
      {programs.map((programInfo) => {
        const { status } = programInfo;
        if (status === PROGRAM_STATUS.COMPLETED) {
          return (
            <CompletedProgramCard
              isSmallView={isSmallView}
              key={programInfo.id}
              mb={6}
              {...programInfo}
              onReset={() => onReset(programInfo)}
              onPromote={() => onPromote(programInfo)}
              onGold={() => onGold(programInfo)}
              onEdit={() => onEdit(programInfo)}
              onDelete={() => onDelete(programInfo)}
              onViewSummary={() => onViewSummary(programInfo)}
              onViewRating={() => onViewRating(programInfo)}
              onView={(category, value, sectionIndex, day, currentDay) =>
                onView(
                  programInfo,
                  category,
                  value,
                  sectionIndex,
                  day,
                  currentDay
                )
              }
            />
          );
        } else if (
          status === PROGRAM_STATUS.CURRENT ||
          programInfo.subscribedFrom
        ) {
          return (
            <CurrentProgramCard
              key={programInfo.id}
              mb={6}
              {...programInfo}
              onView={(category, value, sectionIndex, day, currentDay) =>
                onView(
                  programInfo,
                  category,
                  value,
                  sectionIndex,
                  day,
                  currentDay
                )
              }
              onAdd={(category, sectionIndex, day, currentDay) =>
                onAdd(programInfo, category, sectionIndex, day, currentDay)
              }
              onFinalize={() => onFinalize(programInfo)}
              onReset={() => onReset(programInfo)}
              onComplete={() => onComplete(programInfo)}
              onViewSummary={() => onViewSummary(programInfo)}
              onViewRating={() => onViewRating(programInfo)}
            />
          );
        }

        return (
          <DraftProgramCard
            key={programInfo.id}
            mb={6}
            {...programInfo}
            onFinalize={() => onFinalize(programInfo)}
            onEdit={() => onEdit(programInfo)}
            onDelete={() => onDelete(programInfo)}
            onView={(category, value, sectionIndex, day, currentDay) =>
              onView(
                programInfo,
                category,
                value,
                sectionIndex,
                day,
                currentDay
              )
            }
          />
        );
      })}
      <ConfirmModal
        show={show}
        text={confirmTitle}
        onCancel={onDismissAlert}
        onOk={onConfirm}
      />
      <NoticeModal
        show={showNotice}
        text={noticeTitle}
        onOk={onDissmissNotice}
        onCnacel={onDissmissNotice}
      />
      <SetPriceModal
        isLoading={isSubmitting}
        show={showGoldModal}
        value={goldPrice}
        onCancel={() => setGoldModalShow(false)}
        onOk={onConfirmPrice}
      />
      {showPromoteModal && (
        <PromoteModal
          isLoading={isSubmitting}
          show={showPromoteModal}
          defaultPromotion={promoPlan}
          isPremiumUser={isPremiumUser}
          onCancel={() => setPromoteModalShow(false)}
          onOk={onConfirmPromote}
        />
      )}
      <RatingFormModal
        show={showRatingForm}
        programId={currentProgramId}
        rating={currentRating}
        onOk={(newProgramId) => {
          setShowRatingForm(false);
        }}
      />
      <RatingModal
        show={showRatingView}
        programId={currentProgramId}
        rating={currentRating}
        onOk={() => setShowRatingView(false)}
      />
      <SummaryModal
        show={showSummary}
        programId={currentProgramId}
        stats={currentStats}
        onOk={() => setShowSummary(false)}
      />
      <TrackingPlayerModal
        position={position}
        show={!!itemData}
        data={itemData}
        category={itemDataCategory}
        onTrackComplete={onTrackComplete}
        onClose={() => setItemData(null)}
      />
      <DialogBox
        show={showForm}
        onClickOutside={onDismissFormModal}
        hasClose={formType !== 'master'}
      >
        {formType && (
          <ProgramForm
            allowRepeat={false}
            category={formType}
            value={formData}
            position={position}
            onSubmit={onSubmitForm}
          />
        )}
      </DialogBox>
    </Box>
  );
}

const mapStatesToProps = (state, { currentView = 'created' }) => ({
  isPremiumUser: AuthSelectors.selectIsPremiumUser(state),
  programs: ProfileSelectors.selectProfileProgramList(state, currentView),
  isVendorUser: AuthSelectors.selectIsVendorUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentView: (data) => dispatch(ProfileActions.setProfileView(data)),
  updateMyProgram: (data) => dispatch(ProfileActions.updateMyProgram(data)),
  deleteMyProgram: (data) => dispatch(ProfileActions.deleteMyProgram(data)),
  addMyProgram: (data) => dispatch(ProfileActions.addMyProgram(data)),
  addRecord: (programId, data) =>
    dispatch(ProfileActions.setRecordRequest(programId, data)),
  setSettingsView: (data) => dispatch(SettingsActions.setSettingsView(data)),
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ProfileProgramList);
