export function getCroppedImg(image, crop, fileName) {
  const desiredWidth = 1200;
  const desiredHeight = 630;

  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = desiredWidth;
  canvas.height = desiredHeight;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    desiredWidth,
    desiredHeight
  );

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob.name = fileName;
        resolve(blob);
      },
      'image/jpeg',
      1
    );
  });
}
