import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Box, DialogBox, Body2, Button, Checkbox } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';

const LINE1 =
  'I would like to submit my program for paid subscription to Bcult members at the below price (USD).';

const OPTIONS = [
  {
    name: 'plan-A',
    price: 359,
    description: '3.59 - Simple Program',
  },
  {
    name: 'plan-B',
    price: 659,
    description: '6.59 - Intermediate Program',
  },
  {
    name: 'plan-C',
    price: 959,
    description: '9.59 - Complex Program',
  },
  {
    name: 'free',
    price: 0,
    description: 'Free - Build a following',
  },
];

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PromoTitle = styled(Body2)`
  max-width: 550px;
`;

const findPlanItem = (price) => {
  const plan = OPTIONS.find((item) => item.price === price);
  if (plan) {
    return plan.name;
  }

  return 'free';
};

const findPlanPrice = (name) => {
  const plan = OPTIONS.find((item) => item.name === name);
  if (plan) {
    return plan.price;
  }

  return 0;
};

function SetPriceModal({ isLoading, show, value, onCancel, onOk, ...props }) {
  const [mode, setMode] = useState(findPlanItem(value));

  useEffect(() => {
    setMode(findPlanItem(value));
  }, [value]);

  const handleClick = (name) => {
    setMode(name);
  };

  const handleOnSubmit = () => {
    if (isLoading) {
      return;
    }
    if (onOk) {
      onOk(findPlanPrice(mode));
    }
  };

  const handleCancel = () => {
    if (isLoading) {
      return;
    }

    onCancel();
  };

  return (
    <DialogBox show={show} onClickOutside={handleCancel}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        m={[5, 8]}
      >
        <PromoTitle textAlign="center">{LINE1}</PromoTitle>

        <Wrapper mt={8}>
          {OPTIONS.map((item) => {
            const { name, description } = item;
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                key={name}
                mb={2}
              >
                <Checkbox
                  checked={mode === name}
                  onChange={() => handleClick(name)}
                />
                <Body2 ml={4} textAlign="left">
                  {description}
                </Body2>
              </Box>
            );
          })}
        </Wrapper>

        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={8}
        >
          <Button variant="secondary" mx={4} onClick={handleOnSubmit}>
            OK
          </Button>
        </Box>

        {isLoading && (
          <LoadingContainer>
            <DefaultLoader />
          </LoadingContainer>
        )}
      </Box>
    </DialogBox>
  );
}

export default SetPriceModal;
