import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box } from 'components/core';
import { ChatForm, ChatFormModal } from 'components/chat';
import { DefaultLoader } from 'components/loading';
import { ConfirmModal } from 'components/others';
import ChatMessage from './ChatMessage';

import { colors } from 'styles/theme';
import { AuthSelectors } from 'redux/AuthRedux';
import API from 'services/api';

const ChatContainer = styled(Box)`
  align-self: stretch;
  border: 1px solid ${colors.text};
  box-sizing: border-box;
  border-radius: 1px;
  padding: 16px;
  margin-top: 16px;
  margin-left: 80px;
`;

function ChatView({ programId }) {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const user = useSelector(AuthSelectors.selectCurrentUser);

  const handleDelete = async () => {
    try {
      await API.deleteChatMessage(programId, deletingId);
      setMessages(messages.filter((m) => m._id !== deletingId));
      setDeletingId(null);
    } catch (e) {}
  };

  const handleUpdateReply = (id, replies) => {
    setMessages(
      messages.map((m) =>
        m._id === id
          ? {
              ...m,
              replies,
            }
          : m
      )
    );
  };

  const handleSave = async (message) => {
    if (editingItem) {
      await API.updateChatMessage(programId, editingItem._id, message);
      setMessages(
        messages.map((m) =>
          m._id === editingItem._id
            ? {
                ...m,
                message,
              }
            : m
        )
      );
      setEditingItem(null);
    } else {
      const data = await API.createChatMessage(programId, message);
      setMessages([{ ...data, user }, ...messages]);
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const data = await API.getChatMessages(programId);
        setMessages(data);
      } catch (e) {}
      setLoading(false);
    };

    if (programId) {
      load();
    }
  }, [programId]);

  return (
    <ChatContainer>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <DefaultLoader />
        </Box>
      ) : (
        messages.map((m) => (
          <ChatMessage
            key={m._id}
            id={m._id}
            currentUser={user}
            user={m.user}
            message={m.message}
            replies={m.replies}
            createdAt={m.createdAt}
            programId={programId}
            isEditable={user.role === 'admin' || user._id === m.user._id}
            onUpdateReply={(replies) => handleUpdateReply(m._id, replies)}
            onEdit={() => setEditingItem(m)}
            onDelete={() => setDeletingId(m._id)}
          />
        ))
      )}
      {user && <ChatForm onSubmit={handleSave} />}
      {editingItem && (
        <ChatFormModal
          show
          defaultValue={editingItem.message}
          buttonText="Update"
          onSubmit={handleSave}
          onCancel={() => setEditingItem(null)}
        />
      )}
      <ConfirmModal
        show={!!deletingId}
        text="Are you sure you want to delete this message?"
        onCancel={() => setDeletingId(null)}
        onOk={handleDelete}
      />
    </ChatContainer>
  );
}

ChatView.propTypes = {
  programId: PropTypes.string.isRequired,
};

export default ChatView;
