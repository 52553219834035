import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Collapsible, SvgIcon, IconButton } from 'components/core';
import { colors } from 'styles/theme';

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: -16px;
  right: -16px;
  width: unset;
  height: unset;
  padding: 0;

  @media (max-width: 768px) {
    ${(props) =>
      props.categoryClose &&
      css`
        top: -16px;
        right: -10px;
      `}
  }
`;

const PlusIcon = styled(SvgIcon)`
  path {
    stroke: white;
    fill: none;
  }
`;

const PlusButton = styled(Box)`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.isMasterThread ? colors.red : colors.text)};
  }
`;

export const FitPlusButton = ({ isMasterThread, isCurrentDay, ...props }) => {
  let bg = isMasterThread ? 'red' : 'text';

  if (!isCurrentDay) {
    bg = 'line';
  }

  return (
    <PlusButton {...props} isMasterThread={isMasterThread} bg={bg}>
      <PlusIcon name="plus" />
    </PlusButton>
  );
};

export const EmptyBox = styled(Box)`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FitBox = styled(EmptyBox)`
  position: relative;
  box-shadow: 0px 4px 10px rgba(118, 107, 85, 0.54);
  cursor: pointer;
`;

export const FitImage = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(118, 107, 85, 0.54);
`;

export const FitOverlay = styled(EmptyBox)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const FitIcon = styled(SvgIcon)`
  width: 34px;
  height: 34px;
  path {
    fill: ${colors.text};
  }
  cursor: pointer;
`;

export const GraphWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
`;

export const GraphMain = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
  width: 100%;
`;

export const ItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const CategoryContainer = styled(ItemsContainer)`
  width: 104px;
  border-right: 1px solid ${colors.text};
  padding-top: 40px;

  @media (max-width: 768px) {
    width: 70px;
  }
`;

export const DaysRow = styled(Box)`
  height: 40px;
  border-bottom: 1px solid ${colors.text};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const DayItemWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${(props) =>
    props.selected ? colors.selection : colors.clear};
`;

export const ItemWrapper = styled(DayItemWrapper)`
  height: 103px;
`;

export const FitnessRow = styled(Box)`
  height: 104px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: ${(props) => (props.selected ? '#f0f0f0' : colors.clear)};
`;

export const FitnessAddRow = styled(FitnessRow)`
  justify-content: flex-start;
`;

export const DayWrapper = styled(Box)`
  width: 56px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CollapsibleWrapper = styled(Collapsible)`
  border: 1px solid ${colors.text};
  border-bottom: 0;
  max-width: ${(props) => (props.densed ? '920px' : '1244px')};
  transition: all 0.3s;
  overflow-x: ${(props) => (props.densed ? 'hidden' : 'visible')};
  align-self: stretch;
`;

export const Divider = styled(Box)`
  background-color: ${colors.line};
  opacity: 0.8;
  height: 1px;
  align-self: stretch;
`;
