import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Box, SearchInput, Select, Dropdown } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';
import { FeaturedProgramList } from 'containers';
import SearchActions, { SearchSelectors } from 'redux/SearchRedux';

import { CATEGORIES, ALL_CATEGORIES } from 'services/terms';
import Analytics, { EVENTS } from 'services/analytics';

import { ALL_IMAGES } from 'styles/images';

const Image = styled.img`
  width: 100%;
`;

const SORT_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'featured', label: 'Featured' },
  { value: 'avgRating', label: 'Rating' },
  { value: 'subscriptionCount', label: 'Subscribers' },
  { value: 'weightChange', label: 'Weight lost' },
];

function Home({
  searchQuery,
  setSearchQuery,
  searchSort,
  setSearchSort,
  category,
  setSearchCategory,
  subCategory,
  isLoading,
}) {
  useEffect(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const onChangeSort = ({ value }) => {
    setSearchSort(value);
  };

  const handleChangeCategory = (options, selectedCategory) => {
    const { value } = options;
    if (value === subCategory && selectedCategory === category) {
      setSearchCategory('', '');
      Analytics.track(EVENTS.CATEGORY_SEARCH, {
        category,
        subCategory,
      });
    } else {
      setSearchCategory(selectedCategory, value);
      Analytics.track(EVENTS.CATEGORY_SEARCH, {
        category: selectedCategory,
        subCategory: value,
      });
    }
  };

  const handleOnClickEgg = () => {
    Analytics.track(EVENTS.CLICKED_EGG_CREATE);
  };

  return (
    <Box alignSelf="stretch" py={8} px={2}>
      <Box
        display="flex"
        flexDirection="row"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="space-between"
        mb={8}
      >
        <SearchInput
          flex={1}
          mr={6}
          value={searchQuery}
          onSearch={(value) => setSearchQuery(value)}
        />
        <Select
          placeholder="sort"
          options={SORT_OPTIONS}
          value={searchSort}
          onChange={onChangeSort}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="space-evenly"
        mb={8}
      >
        <Dropdown
          key={CATEGORIES.DIET}
          value={subCategory}
          image={ALL_IMAGES[`${CATEGORIES.DIET}1`]}
          options={ALL_CATEGORIES[CATEGORIES.DIET]}
          onChange={(option) => handleChangeCategory(option, CATEGORIES.DIET)}
        />
        <Dropdown
          key={CATEGORIES.FITNESS}
          value={subCategory}
          image={ALL_IMAGES[`${CATEGORIES.FITNESS}1`]}
          options={ALL_CATEGORIES[CATEGORIES.FITNESS]}
          onChange={(option) =>
            handleChangeCategory(option, CATEGORIES.FITNESS)
          }
        />
        <Link to="/create" onClick={handleOnClickEgg}>
          <Image src={ALL_IMAGES.bigEgg} alt="create program" />
        </Link>
        <Dropdown
          key={CATEGORIES.WELLNESS}
          value={subCategory}
          image={ALL_IMAGES[`${CATEGORIES.WELLNESS}1`]}
          options={ALL_CATEGORIES[CATEGORIES.WELLNESS]}
          leftSide={false}
          onChange={(option) =>
            handleChangeCategory(option, CATEGORIES.WELLNESS)
          }
        />
        <Dropdown
          key={CATEGORIES.BEHAVIORAL}
          value={subCategory}
          image={ALL_IMAGES[`${CATEGORIES.BEHAVIORAL}1`]}
          options={ALL_CATEGORIES[CATEGORIES.BEHAVIORAL]}
          leftSide={false}
          onChange={(option) =>
            handleChangeCategory(option, CATEGORIES.BEHAVIORAL)
          }
        />
      </Box>

      <FeaturedProgramList mb={6} />
      {isLoading && (
        <LoadingContainer>
          <DefaultLoader />
        </LoadingContainer>
      )}
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setSearchQuery: (q) => dispatch(SearchActions.setSearchQuery(q)),
  setSearchSort: (sort) => dispatch(SearchActions.setSearchSort(sort)),
  setSearchCategory: (cat, sub) =>
    dispatch(SearchActions.setSearchCategory(cat, sub)),
});

const mapStatesToProps = (state) => ({
  searchSort: SearchSelectors.selectSort(state),
  searchQuery: SearchSelectors.selectQuery(state),
  isLoading: SearchSelectors.selectLoading(state),
  category: SearchSelectors.selectCategory(state),
  subCategory: SearchSelectors.selectSubCategory(state),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Home);
