import React from 'react';
import { UNIT_OPTIONS } from 'services/terms';
import CommonBarChart from './CommonBarChart';
import RepSetBarChart from './RepSetBarChart';

const RANGES = [
  [0, 10],
  [10, 30],
  [30, 100],
  [100, 250],
];
const Y_LABELS = [250, 100, 30, 10];

function StrengthChart({ sectionType, unit, ...props }) {
  if (!UNIT_OPTIONS.strength.find((u) => u.value === unit)) {
    return null;
  }

  if (unit === 'reps-sets') {
    return (
      <RepSetBarChart
        sectionType={sectionType}
        colorOptions={['Under', 'Ideal', 'Over', 'Obese']}
        {...props}
      />
    );
  }

  return (
    <CommonBarChart
      sectionType={sectionType}
      unit={unit}
      yLabels={Y_LABELS}
      ranges={RANGES}
      yAxisLabel="Loads (lbs)"
      colorOptions={['Under', 'Ideal', 'Over', 'Obese']}
      {...props}
    />
  );
}

export default StrengthChart;
