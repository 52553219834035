import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Box, Body2, Caption2, SvgIcon, Tooltip } from 'components/core';
import { colors } from 'styles/theme';
import { getBmiStat, formatBmiValue } from 'services/calc';

import { AuthSelectors } from 'redux/AuthRedux';

// const BMI_HEIGHTS = [68, 68, 51, 77];
const BMI_HEIGHTS = [26, 26, 19, 29];
const BMI_POS = [0, 26, 52, 71];

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1px solid ${colors.line};
  border-radius: 2px;
  cursor: pointer;
  position: relative;
`;

const InnerWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BmiRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => (props.showDetail ? 0.7 : 0.5)};
`;

const IndicatorRow = styled(BmiRow)`
  opacity: 1;
  position: absolute;
  justify-content: center;
  top: ${(props) => `${BMI_POS[props.pos]}%`};
`;

const CaptionBox = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
`;

const CaptionBox1 = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid ${colors.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const colorOptions = ['Obese', 'Over', 'Ideal', 'Under'];

const Indicator = ({ size, value, bmiColor, pos = 0, height }) => {
  return (
    <IndicatorRow bg="clear" pos={pos} height={`${height}%`}>
      {size >= 280 && <SvgIcon name="indicator-left" />}
      <CaptionBox bg={`bmi${bmiColor}`}>
        <Caption2 color="white">{formatBmiValue(value)}</Caption2>
      </CaptionBox>

      {size >= 280 && <SvgIcon name="indicator-right" />}
    </IndicatorRow>
  );
};

const BmiProfile = ({ value, size = 280, ...props }) => {
  const bmiColor = getBmiStat(value);

  const colorIndex = colorOptions.findIndex((item) => item === bmiColor);

  const [showDetail, setShowDetail] = useState(false);

  const toggleShowDetail = () => {
    if (size < 280) {
      return;
    }
    setShowDetail(!showDetail);
  };

  return (
    <Tooltip
      animation="scale"
      delay={[300, 0]}
      duration={50}
      content={'body mass index'}
      theme="light-border"
      placement="bottom"
    >
      <Wrapper p={2} size={size} {...props} onClick={toggleShowDetail}>
        <InnerWrapper>
          {colorOptions.map((name, index) => (
            <BmiRow
              px={2}
              key={name}
              bg={`bmi${name}`}
              height={`${BMI_HEIGHTS[index]}%`}
              showDetail={showDetail}
            >
              {showDetail && index !== colorIndex && <Body2>{name}</Body2>}
              {showDetail && index === colorIndex && (
                <Caption2 color="main">{name}</Caption2>
              )}
              {showDetail && index === colorIndex && (
                <CaptionBox1 bg="clear">
                  <Caption2 color="main">{formatBmiValue(value)}</Caption2>
                </CaptionBox1>
              )}
            </BmiRow>
          ))}
          {!showDetail && (
            <Indicator
              size={size}
              value={value}
              bmiColor={bmiColor}
              pos={colorIndex}
              height={BMI_HEIGHTS[colorIndex]}
            />
          )}
        </InnerWrapper>
      </Wrapper>
    </Tooltip>
  );
};

const mapStatesToProps = (state) => ({
  value: AuthSelectors.selectBmi(state),
});

export default connect(mapStatesToProps)(BmiProfile);
