import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setReportDataLoadRequest: null,
  setReportData: ['data'],
  setReportLoading: ['loading'],
  setReportCurrentSlide: ['currentSlide'],
});

export const ReportTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  loading: false,
  data: {},
  currentSlide: 0,
};

/* ------- Selectors --------- */
export const ReportSelectors = {
  selectData: (state) => state.report.data,
  selectLoading: (state) => state.report.loading,
  selectCurrentSlide: (state) => state.report.currentSlide
};

/* -------- Reducers ---------- */
export const setReportData = (state, { data }) => {
  return {
    ...state,
    data,
  };
};

export const setCurrentSlide = (state, { currentSlide }) => {
  return {
    ...state,
    currentSlide,
  };
};

export const setReportLoading = (state, { loading }) => {
  return {
    ...state,
    loading,
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_REPORT_DATA]: setReportData,
  [Types.SET_REPORT_LOADING]: setReportLoading,
  [Types.SET_REPORT_CURRENT_SLIDE]: setCurrentSlide,
});
