import pick from 'lodash-es/pick';
import moment from 'moment';
import isEmpty from 'lodash-es/isEmpty';
import startCase from 'lodash-es/startCase';
import { CATEGORIES } from '../services/terms';

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

const mains3Domain = S3_DOMAIN.split('.')[0];

const S3_ACCELERATION_DOMAIN = `${mains3Domain}.s3-accelerate.amazonaws.com`;

export const getIsValid = (requiredFields = []) => (values) => {
  const isInvalid = requiredFields.some((field) => !values[field]);

  return !isInvalid;
};

const formFields = {
  master: ['assetType', 'assetUrl', 'thumbUrl'],
  diet: [
    'assetType',
    'assetUrl',
    'thumbUrl',
    'type',
    'value',
    'note',
    'carbs',
    'fat',
    'proteins',
    'other',
  ],
  fitness: [
    'assetType',
    'assetUrl',
    'thumbUrl',
    'type',
    'value',
    'subType',
    'unit',
    'other',
    'note',
  ],
  strength: [
    'assetType',
    'assetUrl',
    'thumbUrl',
    'type',
    'note',
    'weight',
    'sets',
    'reps',
    'subType',
    'value',
    'other',
  ],
  supplement: ['assetType', 'assetUrl', 'thumbUrl', 'note', 'other'],
  weight: ['assetType', 'assetUrl', 'thumbUrl', 'type', 'note', 'value'],
  bmi: ['assetType', 'assetUrl', 'thumbUrl', 'type', 'note', 'value'],
  wellness: [
    'assetType',
    'assetUrl',
    'thumbUrl',
    'type',
    'note',
    'value',
    'other',
  ],
};

const MEASURES = ['value', 'reps', 'sets', 'carbs', 'fat', 'proteins'];

const MEASURE_LABEL = {
  carbs: 'Carbs',
  proteins: 'Protein',
  reps: 'Reps',
  sets: 'Sets',
  fat: 'Fat',
};

export const getUnitLabel = (sectionType, unit) => {
  if (sectionType === CATEGORIES.DIET) {
    return 'Calories';
  }
  if (sectionType === CATEGORIES.WELLNESS) {
    return 'Minutes';
  }

  if (sectionType === CATEGORIES.STRENGTH) {
    return 'Loads(lbs)';
  }

  if (sectionType === CATEGORIES.FITNESS) {
    if (unit === 'miles') {
      return 'Miles';
    }
    return 'Minutes';
  }

  return '';
};

const getMeasureLabel = (measureProp) => {
  return MEASURE_LABEL[measureProp] || '';
};

export const getSummary = (category, data) => {
  if (!data) return '';
  const { type, value = '', subType = '' } = data || {};
  switch (category) {
    case 'wellness':
    case 'diet':
      return `${startCase(type)} ${value}`;
    case 'fitness':
    case 'strength':
      return `${startCase(type)} (${subType}): ${value}`;
    default:
  }
};

export const getSummaryLines = (category, data) => {
  let summaryTitle = '';
  let summaryLines = [];
  if (!data) {
    return {
      summaryTitle,
      summaryLines,
    };
  }
  const { type, other, unit, subType = '' } = data || {};

  if (category === 'strength' && subType) {
    summaryTitle = `${startCase(type)} (${subType})`;
  } else if (type === 'other' && other) {
    summaryTitle = other;
  } else {
    summaryTitle = startCase(type);
  }

  MEASURES.forEach((measureProp) => {
    const value = data[measureProp];
    if (!data[measureProp]) {
      return;
    }

    if (measureProp === 'value') {
      summaryLines.push(`${value} ${getUnitLabel(category, unit)}`);
      return;
    }

    summaryLines.push(`${value} ${getMeasureLabel(measureProp)}`);
  });

  return {
    summaryTitle,
    summaryLines,
  };
};

export const getFormValueGenerator = (category) => (values) => {
  const formValues = pick(values, formFields[category]);
  return formValues;
};

export const generateUploadKey = (userId, section, sectionIndex, day) => {
  const timestamp = moment().unix();
  return `${userId}-${section}-${sectionIndex}-${day}-${timestamp}`;
};

export const generateAssetUrl = (uploadKey) => {
  return `${S3_DOMAIN}/${uploadKey}`;
};

export const generateVideoAssetUrl = (uploadKey) => {
  return `${S3_ACCELERATION_DOMAIN}/${uploadKey}`;
};

export const getAssetType = (fileType) => {
  if (fileType.startsWith('image')) {
    return 'image';
  } else if (fileType.startsWith('video')) {
    return 'video';
  }
  return 'pdf';
};

export const isImgix = (url) => {
  if (!url) return;
  return url.startsWith('https://bcult.imgix.net');
};

export const isOngoingDraftInvalid = ({
  title,
  description,
  categoryFilter,
  program,
}) => {
  // program: {
  //   goal: {},
  //   sections: [...DEFAULT_SECTIONS],
  //   offerToGold: false,
  //   data: [{}, {}, {}],
  //   templates: [],
  // },

  if (!program) {
    return 'Unknown Error';
  }

  if (!title) {
    return 'Please provide title';
  }

  if (!categoryFilter) {
    return 'Please select category, length and type';
  }

  const { category, duration, subCategory } = categoryFilter;

  if (!category || !duration || !subCategory) {
    return 'Please select category, length and type';
  }

  const { sections, data } = program;

  if (!sections || !data) return 'Please upload items';
  if (sections.length !== data.length)
    return 'Something went wrong, Please try again later';

  const hasData = data.some((item) => !isEmpty(item));

  if (!hasData) {
    return 'Please upload items';
  }

  return false;
};

export const isProgramDraftInvalid = (
  programInfo,

  mode = 'new'
) => {
  return isOngoingDraftInvalid(programInfo);
};
