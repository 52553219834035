import React from 'react';
import styled from 'styled-components';

import { Box, Text, LinkText } from 'components/core';
import { AuthLayout } from 'containers/layout';
import ResetPasswordForm from 'containers/ResetPasswordForm';

import history from 'utils/history';

const Wrapper = styled(Box)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 100vh;
`;

const MainSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 50px;

  border: 1px solid rgba(220, 220, 220, 0.5);
  border-radius: 2px;
`;

function ResetPassword() {
  return (
    <AuthLayout>
      <Wrapper>
        <ResetPasswordForm />
        <MainSection mt={10}>
          <Text color="blue" fontSize={1} ml={1} mr={1}>
            Have an account?{' '}
          </Text>
          <LinkText
            fontSize={1}
            color="blue"
            onClick={() => {
              history.push('/auth/login');
            }}
          >
            Log In
          </LinkText>
        </MainSection>
      </Wrapper>
    </AuthLayout>
  );
}

export default ResetPassword;
