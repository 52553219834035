import React from 'react';
import { Box, IconButton, SvgIcon, Tooltip } from 'components/core';

const ACTION_TYPE = {
  FINALIZE: 'final',
  SAVE: 'save',
};

function SubscriptionButtons({
  isSubmitting,
  setAction,
  handleSubmit,
  ...rest
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      mt={9}
      {...rest}
    >
      <Tooltip
        animation="scale"
        delay={[300, 0]}
        duration={300}
        content="Begin later"
        theme="light-border"
        placement="top"
      >
        <IconButton
          size="join"
          type="button"
          disabled={isSubmitting}
          onClick={() => {
            setAction(ACTION_TYPE.SAVE);
            handleSubmit();
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <SvgIcon name="join-main" width={48} height={48} />
            <SvgIcon name="join-circle" ml={2} />
          </Box>
        </IconButton>
      </Tooltip>
      <Tooltip
        animation="scale"
        delay={[300, 0]}
        duration={300}
        content="Begin now"
        theme="light-border"
        placement="top"
      >
        <IconButton
          size="join"
          ml={4}
          disabled={isSubmitting}
          type="button"
          onClick={() => {
            setAction(ACTION_TYPE.FINALIZE);
            handleSubmit();
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <SvgIcon name="join-main" width={48} height={48} />
            <SvgIcon name="join-down" ml={2} />
          </Box>
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default SubscriptionButtons;
