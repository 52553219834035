import styled from 'styled-components';
import {
  border,
  space,
  color,
  layout,
  flexbox,
  position,
  fontSize
} from 'styled-system';

const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0
  },
  space,
  color,
  layout,
  flexbox,
  border,
  position,
  fontSize
);

Box.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...layout.propTypes,
  ...flexbox.propTypes,
  ...border.propTypes,
  ...position.propTypes,
  ...fontSize.propTypes
};

export default Box;
