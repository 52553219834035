import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Body2, SvgIcon } from 'components/core';

import { colors } from 'styles/theme';

const Wrapper = styled(Box)`
  width: 176px;
  position: relative;
  box-shadow: inset 0px 1px 1px 0px
      ${(props) => (props.error ? colors.red : colors.line)},
    inset 0px 0px 1px 1px
      ${(props) => (props.error ? colors.red : colors.line)};
  outline: 0;
  cursor: pointer;
`;

const OptionWrapper = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${colors.line};
  box-sizing: border-box;
  border-radius: 2px;
  z-index: 99;
`;

const Option = styled(Box)`
  cursor: pointer;
  height: 48px;
  background: ${(props) => (props.selected ? colors.blueGray : colors.background)};
  &:hover {
    background: ${(props) => (props.selected ? colors.blueGray : colors.line)};
  }
`;

const Placeholder = styled(Body2)`
  opacity: 0.8;
  user-select: none;
`;

const Icon = styled(SvgIcon)`
  position: absolute;
  top: 19px;
  right: 12px;
  path {
    stroke: ${colors.blueGray};
  }
`;

const getLabel = (options, value) => {
  const found = options.find((item) => item.value === value);

  const { label } = found || {};

  return label || '';
};

function DarkSelect({
  placeholder = 'select',
  options = [],
  tabIndex = 0,
  onChange,
  value,
  disabled,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const currentLabel = getLabel(options, value);

  const onClickOption = (option) => {
    if (onChange) {
      onChange(option);
    }
  };

  const onClick = () => {
    if (disabled) {
      return;
    }
    setOpen(!open);
  };

  const onBlur = () => {
    setOpen(false);
  };

  return (
    <Wrapper
      p={3}
      bg="background"
      tabIndex={tabIndex}
      onClick={onClick}
      onBlur={onBlur}
      {...props}
    >
      {!value ? (
        <Placeholder>{placeholder}</Placeholder>
      ) : (
        <Body2 color="blueGray">{currentLabel}</Body2>
      )}
      <Icon name="chevron-down" />
      {open && (
        <OptionWrapper>
          {options.map((option) => {
            const { value: optionValue, label: optionLabel } = option;
            const isSelected = value === optionValue;
            return (
              <Option
                p={3}
                key={optionValue}
                selected={isSelected}
                onClick={() => onClickOption(option)}
              >
                <Body2 color={isSelected ? 'contrastText' : 'blueGray'}>
                  {optionLabel}
                </Body2>
              </Option>
            );
          })}
        </OptionWrapper>
      )}
    </Wrapper>
  );
}

export default DarkSelect;
