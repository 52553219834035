import React, { useMemo, useCallback, useState } from 'react';
import get from 'lodash-es/get';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Box, Body2 } from 'components/core';

import API from 'services/api';
import { colors } from 'styles/theme';
import { getAssetType } from 'utils/form';

import PlaceHolderAvatar from 'assets/Avatar.png';

const PreviewContainer = styled(Box)`
  width: 280px;
  min-height: 280px;
  position: relative;
  padding: 0;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PreviewOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  opacity: 0.4;
`;

const ImagePreview = styled.img`
  width: 280px;
`;

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'center',
  padding: 0,
  borderWidth: 1,
  borderRadius: 6,
  borderColor: colors.text,
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '280px',
  height: '72px',
  marginTop: '12px',
};

const activeStyle = {
  borderColor: colors.text,
  backgroundColor: colors.line,
};

const acceptStyle = {
  borderColor: colors.text,
};

const rejectStyle = {
  borderColor: colors.red,
};

function AvatarUpload({
  currentUser,
  uploadKey,
  onUpload,
  onUploadProgress,
  ...props
}) {
  const [preview, setPreview] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const avatar = get(currentUser, 'profile.avatar') || PlaceHolderAvatar;

  const uploadFile = useCallback(
    async (fileToUpload) => {
      setIsUploading(true);
      if (onUploadProgress) {
        onUploadProgress(true);
      }

      const type = getAssetType(fileToUpload.type);

      const fileKey = `${uploadKey}-${fileToUpload.name}`;

      if (type === 'image') {
        setPreview(URL.createObjectURL(fileToUpload));
      }

      const uploadAssetUrl = await API.uploadFile(fileToUpload, fileKey);
      const newProfile = {
        profile: {
          ...currentUser.profile,
          avatar: uploadAssetUrl,
        },
      };

      const newUser = {
        ...currentUser,
        ...newProfile,
      };

      await API.updateProfile(newProfile);

      if (onUpload) {
        onUpload(newUser);
      }

      setIsUploading(false);
      setPreview('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsUploading, uploadKey, onUpload, onUploadProgress]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles || !acceptedFiles.length) {
        return;
      }
      const file = acceptedFiles[0];
      uploadFile(file);
    },
    [uploadFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      alignItems="center"
      {...props}
    >
      <PreviewContainer>
        <ImagePreview src={preview ? preview : avatar} />
        {isUploading && <PreviewOverlay />}
      </PreviewContainer>
      {!isUploading && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Body2>Drag or upload photo here</Body2>
        </div>
      )}
    </Box>
  );
}

export default AvatarUpload;
