import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getIn } from 'formik';
import { Input, InputFeedback, SvgIcon } from 'components/core';
import debounce from 'lodash-es/debounce';
import API from 'services/api';
import { DefaultLoader } from 'components/loading';
import { colors } from 'styles/theme';

const AddonContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
`;

const CheckMark = styled(SvgIcon)`
  path {
    fill: ${colors.green};
  }
`;

// Todo: error status
class EmailField extends React.Component {
  state = {
    isSubmitting: false,
  };

  checkEmail = async (value) => {
    const { field, form } = this.props;
    const formError = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
    const hasError = !!(touched && formError);

    // only check if the field passes Yup email validation first
    if (hasError) {
      return;
    }

    this.setState({ isSubmitting: true });
    try {
      const { success } = await API.checkEmailIsUnique(value);
      if (!success) {
        this.props.form.setFieldError(field.name, 'Email is already used');
      }
    } catch (err) {
      this.props.form.setFieldError(field.name, 'Email is already used');
    }
    this.setState({ isSubmitting: false });
  };

  debouncedCheckEmail = debounce(this.checkEmail, 300); // 300ms debounce

  handleChange = (e) => {
    this.props.field.onChange(e); // this will let Formik's default validation take place
    this.debouncedCheckEmail(e.target.value);
  };

  handleBlur = (e) => {
    this.props.field.onBlur(e); // this will let Formik's default validation take place
    this.debouncedCheckEmail(e.target.value);
  };

  render() {
    const { field, label, form, renderAddon, ...rest } = this.props;
    const formError = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
    const hasError = !!(touched && formError);

    return (
      <React.Fragment>
        <Input
          placeholder={label}
          error={hasError}
          disabled={form.isSubmitting}
          {...field}
          {...rest}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
        {hasError && <InputFeedback>{formError}</InputFeedback>}
        <AddonContainer>
          {this.state.isSubmitting && <DefaultLoader size={20} />}
          {!this.state.isSubmitting && touched && !hasError && (
            <CheckMark name="tick" />
          )}
        </AddonContainer>
      </React.Fragment>
    );
  }
}

EmailField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
};

export default EmailField;
