import { put, call, select } from 'redux-saga/effects';
import ProfileActions, { ProfileSelectors } from './ProfileRedux';
import AuthActions, { AuthSelectors } from './AuthRedux';

import API from '../services/api';
import { calcBmiUSC, calcWeightFromBmi } from 'services/calc';
import { transformProgram, convertProgramItemForApi } from '../utils/transform';
import { getCurrentDateOfProgram, isDateToday } from '../utils/date';

export function* loadAllMyPrograms() {
  const user = yield call(API.getMe);
  yield put(AuthActions.setUser(user));
  const allMyPrograms = yield call(API.getAllMyPrograms);
  yield put(ProfileActions.setMyPrograms(allMyPrograms.map(transformProgram)));
}

export function* addRecord(actions) {
  const { programId, data } = actions;
  const dataForApi = convertProgramItemForApi(data);
  let { sectionType, type, value } = dataForApi;

  if (sectionType === 'bmi' || sectionType === 'weight') {
    const profile = yield select(AuthSelectors.selectProfile);
    const { height } = profile;
    const { sectionId, ...weightData } = dataForApi;
    let bmiDataForApi;
    let weightDataForApi;
    if (type === 'bmi') {
      bmiDataForApi = {
        ...weightData,
        sectionType: 'bmi',
        type: 'bmi',
      };
      const weight = calcWeightFromBmi({ height, bmi: value });
      weightDataForApi = {
        ...weightData,
        sectionType: 'weight',
        type: 'weight',
        value: weight,
      };
    } else {
      weightDataForApi = {
        ...weightData,
        sectionType: 'weight',
        type: 'weight',
      };
      const bmi = calcBmiUSC({ height, weight: value });
      bmiDataForApi = {
        ...weightData,
        sectionType: 'bmi',
        type: 'bmi',
        value: bmi,
      };
    }
    try {
      yield call(API.createRecord, programId, weightDataForApi);
      const affectedPrograms = yield call(
        API.createRecord,
        programId,
        bmiDataForApi
      );
      yield put(
        ProfileActions.batchUpdateMyPrograms(
          affectedPrograms.map(transformProgram)
        )
      );

      const programs = yield select(ProfileSelectors.selectAllMyPrograms);

      // check if updated bmi/weight is for today
      const targetProgram = programs.find((item) => item.id === programId);
      if (targetProgram) {
        const {
          program: { startDate },
        } = targetProgram;

        const targetDate = getCurrentDateOfProgram(
          startDate,
          weightDataForApi.day
        );
        if (isDateToday(targetDate)) {
          yield put(AuthActions.setUpdateUserWeight(weightDataForApi.value));
        }
      }
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      const affectedPrograms = yield call(
        API.createRecord,
        programId,
        dataForApi
      );
      yield put(
        ProfileActions.batchUpdateMyPrograms(
          affectedPrograms.map(transformProgram)
        )
      );
    } catch (err) {
      console.log(err);
    }
  }

  yield put(ProfileActions.setProfileLoading(false));
}
