import filter from 'lodash-es/filter';
import get from 'lodash-es/get';

export default function getOptions(user) {
  const OPTIONS = [
    {
      index: 0,
      label: 'Created',
      title: 'Created Programs',
      value: filter(user.programs, (p) => !p.subscribedFrom).length,
      category: 'Program',
      icon: 'program-new',
      loadData: async () => filter(user.programs, (p) => !p.subscribedFrom),
    },
    {
      index: 1,
      label: 'Completed',
      title: 'Completed Programs',
      value: filter(
        user.programs,
        (p) => p.subscribedFrom && p.status === 'completed'
      ).length,
      category: 'Program',
      icon: 'program-completed',
      loadData: async () =>
        filter(
          user.programs,
          (p) => p.subscribedFrom && p.status === 'completed'
        ),
    },
    {
      index: 2,
      label: 'In-Progress',
      title: 'Current Programs',
      value: filter(
        user.programs,
        (p) => p.subscribedFrom && p.status === 'active'
      ).length,
      category: 'Program',
      icon: 'program-progress',
      loadData: async () =>
        filter(user.programs, (p) => p.subscribedFrom && p.status === 'active'),
    },
    {
      index: 3,
      label: 'Followers',
      title: 'Followers',
      value: get(user, 'stats.followerCount', 0),
      category: 'Community',
      icon: 'program-share',
      loadData: async () => get(user, 'followers', []),
    },
    {
      index: 4,
      label: 'Subscribers',
      title: 'Subscribers',
      value: get(user, 'stats.programSubscriberCount', 0),
      category: 'Community',
      icon: 'program-share',
      loadData: async () => get(user, 'subscribers', []),
    },
    {
      index: 5,
      label: 'Following',
      title: 'Following',
      value: get(user, 'stats.followingCount', 0),
      category: 'Community',
      icon: 'program-share',
      loadData: async () => get(user, 'following', []),
    },
  ];

  return OPTIONS;
}
