import React, { useRef } from 'react';
import Chart, { defaults } from 'react-chartjs-2';
import get from 'lodash-es/get';

import ChartContainer from './ChartContainer';
import { UNIT_OPTIONS } from 'services/terms';

import { colors } from 'styles/theme';
import { convertToRangedScale } from 'services/graph';

// Disable animating charts by default.
defaults.global.animation = false;

const HEIGHT = 350;
const STEP_SIZE = 0.5;
const COLOR_OPTIONS = ['Under', 'Ideal', 'Over', 'Obese'];
const TICK_LABELS = {
  bmi: ['Obese', 'Over', 'Ideal', 'Under'],
  kg: ['140 kg', '90kg', '70kg', '50kg'],
  lbs: ['300 lbs', '200 lbs', '150 lbs', '100 lbs'],
};
const RANGE_COUNT = 4;
const RANGES = {
  bmi: [
    [0, 18.5],
    [18.5, 25],
    [25, 30],
    [30, 43],
  ],
  kg: [
    [0, 50],
    [50, 70],
    [70, 90],
    [90, 140],
  ],
  lbs: [
    [0, 100],
    [100, 150],
    [150, 200],
    [200, 300],
  ],
};
const REGION_HEIGHT = HEIGHT / RANGE_COUNT;
const TICK_SIZES = [REGION_HEIGHT, REGION_HEIGHT, REGION_HEIGHT, REGION_HEIGHT];

const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const WeightChart = ({ recordData, currentUnit }) => {
  const chartRef = useRef();
  const unit = UNIT_OPTIONS.weight.find((w) => w.value === currentUnit)
    ? currentUnit
    : 'bmi';
  const unitObj = UNIT_OPTIONS.weight.find((w) => w.value === unit);

  const weightData = get(recordData, 'weight');
  const xLabels = get(weightData, 'labels', DAYS);
  const graphData = get(weightData, 'data', {
    bmi: [],
    lbs: [],
    kg: [],
  });
  const dataPoints = graphData[unit];
  const ranges = RANGES[unit];
  const normalizedData = dataPoints.map((d) => convertToRangedScale(d, ranges));

  const pointRadius = dataPoints.map((bmi, index) => {
    if (index === 0 || index === dataPoints.length - 1) {
      return 20;
    }
    return 0;
  });

  const pointColors = normalizedData.map((d, index) => {
    if (index === 0 || index === normalizedData.length - 1) {
      const value = COLOR_OPTIONS[Math.floor(d / 25)];
      return colors[`bmi${value}`];
    }

    return colors.white;
  });

  const data = {
    labels: xLabels,
    datasets: [
      {
        data: normalizedData,
        pointRadius: pointRadius,
        pointHoverRadius: pointRadius,
        pointBorderWidth: 2,
        borderColor: colors.white,
        backgroundColor: pointColors,
        fill: false,
        steppedLine: true,
        spanGaps: false,
      },
    ],
  };

  const options = {
    responsive: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: 100, // always 100
            min: 0, // always 0
            stepSize: STEP_SIZE,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            max: 15,
            min: 1,
            stepSize: 1,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 0,
        bottom: 0,
      },
    },
    animation: {
      onComplete: function () {
        let ctx = this.chart.ctx;
        let chart = this;

        ctx.fillStyle = colors.white;
        ctx.font = '500 18px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        let datasets = this.config.data.datasets;
        datasets.forEach(function (dataset, i) {
          chart.getDatasetMeta(i).data.forEach(function (p, j) {
            if (j === 0 || j === graphData.bmi.length - 1) {
              ctx.fillText(graphData[unit][j], p._model.x, p._model.y + 10);
            }
          });
        });
      },
    },
  };

  return (
    <ChartContainer
      title={unitObj.label}
      tickSizes={TICK_SIZES}
      xLabels={xLabels}
      yLabels={TICK_LABELS[unit]}
    >
      <Chart
        ref={chartRef}
        type="line"
        width={1062}
        height={HEIGHT}
        data={data}
        options={options}
      />
    </ChartContainer>
  );
};

export default WeightChart;
