import React from 'react';
import styled from 'styled-components';
import InputGroup from './InputGroup';
import BInput from './BInput';
import SvgIcon from './SvgIcon';

const Icon = styled(SvgIcon)`
  position: absolute;
  top: 12px;
  left: 16px;
`;

function SearchInput({ value, onSearch, ...props }) {
  const onChange = (e) => {
    const { value } = e.target;

    if (onSearch) {
      onSearch(value);
    }
  };
  return (
    <InputGroup {...props}>
      <Icon name="search" />
      <BInput
        value={value}
        placeholder="search"
        p={3}
        pl={9}
        onChange={onChange}
      />
    </InputGroup>
  );
}

export default SearchInput;
