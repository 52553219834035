import { put } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import AppActions from 'redux/AppRedux';
import AuthActions from './AuthRedux';

function isTokenValid(token) {
  if (!token) {
    return false;
  }

  try {
    const decoded = jwtDecode(token);
    const { exp } = decoded;

    return moment(exp).isAfter(moment());
  } catch (er) {
    return false;
  }
}

export function* startup() {
  const authToken = localStorage.getItem('authToken');
  const user = JSON.parse(localStorage.getItem('bUser'));

  if (isTokenValid(authToken) && user) {
    yield put(AuthActions.setUser(user));
    yield put(AuthActions.setLoggedIn(authToken));
  }
  yield put(AppActions.setLoaded(true));
}
