/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Box, Title1, BInput, BTextArea, DialogBox } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';

import { FeaturedTypeSelect, MainSwitch } from 'components/others';
import { SetPriceModal } from 'modals';

import {
  InsertTemplateForm,
  GoalForm,
  CompoundCategoryField,
  CreateProgram,
  ProgramForm,
} from 'containers';
import { ALL_IMAGES } from 'styles/images';

import CreateActions, { CreateSelectors } from 'redux/CreateRedux';
import SettingsActions from 'redux/SettingsRedux';
import { AuthSelectors } from 'redux/AuthRedux';

import history from 'utils/history';
import confirm from 'lib/confirm';
import Analytics, { EVENTS } from 'services/analytics';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

const Divider = styled(Box)`
  opacity: 0.4;
  height: 1px;
  align-self: stretch;
`;

function EditProgramPage({
  createType,
  title,
  setTitle,
  description,
  setDescription,
  categoryFilter,
  setCategoryFilter,
  goal,
  setGoal,
  formType,
  formData,
  setFormData,
  show,
  toggleModal,
  offerToGold,
  setOfferToGold,
  usedTemplates,
  insertTemplate,
  insertFormData,
  setForEditing,
  position,
  userId,
  goldPrice,
  setGoldPrice,
  isLoading,
  isVendorUser,

  loadProgram,
  match: {
    params: { programId },
  },
  resetAll,
  setSettingsView,
}) {
  useEffect(() => {
    loadProgram(programId);
    return () => {
      resetAll();
    };
  }, [loadProgram, programId]);

  const [showGoldModal, setGoldModalShow] = useState(false);
  const { selectedIndex, day } = position || {};

  const onRequireBilling = async () => {
    if (
      await confirm('Would you like to set up your account for price setting?')
    ) {
      setSettingsView('billing');
      history.push('/settings');
    }
  };

  const onDismissModal = () => {
    if (formType !== 'master') {
      toggleModal(false);
      setForEditing(null);
    }
  };

  const onSelectFeaturedType = (featuredType) => {
    setFormData({
      category: featuredType,
      value: {},
    });
    setForEditing(null);
    toggleModal(true);
  };

  const onSubmitForm = (values) => {
    if (values) {
      Analytics.track(EVENTS.PROGRAM_ITEM_ADDED, {
        programId,
        formType,
        data: {
          ...formData,
          ...values,
        },
        position,
        isRepeat: false,
        isCurrent: false,
      });
      insertFormData(
        formType,
        {
          ...formData,
          ...values,
        },
        position
      );
    }

    toggleModal(false);
  };

  const onChangeOfferToGold = (value) => {
    if (!value) {
      setOfferToGold(false);
      setGoldPrice(0);
      return;
    }

    if (!isVendorUser) {
      onRequireBilling();
      return;
    }
    setOfferToGold(true);
    setGoldModalShow(true);
  };

  const onShowGold = () => {
    if (!offerToGold) {
      return;
    }
    setGoldModalShow(true);
  };

  const onConfirmPrice = (newPrice) => {
    setGoldPrice(newPrice);
    setGoldModalShow(false);
  };

  const onSubmitTemplate = (template) => {
    insertTemplate(template);
    Analytics.track(EVENTS.PROGRAM_TEMPLATE_ADDED, {
      programId: 'new',
      template,
    });
  };

  return (
    <Box alignSelf="stretch" position="relative" pt={9} pb={8} px={2}>
      <Title1 mb={4}>Edit Program</Title1>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignSelf="stretch"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          alignSelf="stretch"
          mb={4}
        >
          <BInput
            value={title}
            placeholder="Program title"
            mb={4}
            onChange={(e) => setTitle(e.target.value)}
          />
          <CompoundCategoryField
            value={categoryFilter}
            onChange={(value) => setCategoryFilter(value)}
          />
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          alignSelf="stretch"
          mb={4}
          ml={[0, 4]}
        >
          <TextArea
            value={description}
            placeholder="Program description..."
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
      </Box>
      <FeaturedTypeSelect
        alignSelf="stretch"
        onSelect={onSelectFeaturedType}
        py={4}
        mb={2}
      />
      <Divider bg="text" m={4} mr={0}></Divider>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Box flex={1} mr={[0, 4]}>
          <GoalForm goal={goal} onSubmit={setGoal} />
          <InsertTemplateForm
            onSubmit={onSubmitTemplate}
            disabledTemplates={usedTemplates}
          />
        </Box>
        <Box
          flex={1}
          ml={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={ALL_IMAGES.gold}
            alt="golden program"
            onClick={onShowGold}
            style={{ cursor: offerToGold ? 'pointer' : 'auto' }}
          />
          <MainSwitch
            disabled={createType !== 'completed'}
            selected={offerToGold}
            onChange={onChangeOfferToGold}
          />
        </Box>
      </Box>
      <CreateProgram mb={4} />
      <DialogBox
        show={show}
        onClickOutside={onDismissModal}
        hasClose={formType !== 'master'}
      >
        <ProgramForm
          key={`${formType}-${selectedIndex}-${day}`}
          userId={userId}
          category={formType}
          value={formData}
          position={position}
          onSubmit={onSubmitForm}
        />
      </DialogBox>
      <SetPriceModal
        show={showGoldModal}
        value={goldPrice}
        onCancel={() => setGoldModalShow(false)}
        onOk={onConfirmPrice}
      />
      {isLoading && (
        <LoadingContainer>
          <DefaultLoader />
        </LoadingContainer>
      )}
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  createType: CreateSelectors.selectCreateMode(state),
  goal: CreateSelectors.selectGoal(state),
  formType: CreateSelectors.selectPopupCategory(state),
  formData: CreateSelectors.selectFormValue(state),
  show: CreateSelectors.selectIsPopupShow(state),
  offerToGold: CreateSelectors.selectOfferToGold(state),
  usedTemplates: CreateSelectors.selectUsedTemplates(state),
  position: CreateSelectors.selectPopupPosition(state),
  goldPrice: CreateSelectors.selectGoldPrice(state),

  title: CreateSelectors.selectTitle(state),
  description: CreateSelectors.selectDescription(state),
  categoryFilter: CreateSelectors.selectCategoryFilter(state),
  isLoading: CreateSelectors.selectIsLoading(state),
  isVendorUser: AuthSelectors.selectIsVendorUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadProgram: (programId) =>
    dispatch(CreateActions.setCreateInitFromExistingRequest(programId)),
  setCreateType: (data) => dispatch(CreateActions.setCreateMode(data)),
  setGoal: (data) => dispatch(CreateActions.setCreateGoal(data)),
  setGoldPrice: (data) => dispatch(CreateActions.setCreatePrice(data)),
  setFormData: (data) => dispatch(CreateActions.setCreatePopupData(data)),
  toggleModal: (show) => dispatch(CreateActions.setCreatePopupShow(show)),
  setOfferToGold: (offerToGold) =>
    dispatch(CreateActions.setCreateOfferToGold(offerToGold)),
  setTitle: (title) => dispatch(CreateActions.setCreateTitle(title)),
  setCategoryFilter: (categoryFilter) =>
    dispatch(CreateActions.setCreateCategoryFilter(categoryFilter)),
  setDescription: (description) =>
    dispatch(CreateActions.setCreateDescription(description)),
  insertTemplate: (template) =>
    dispatch(CreateActions.setCreateInsertTemplate(template)),
  insertFormData: (category, value, position, isRepeat) =>
    dispatch(
      CreateActions.setCreateInsertFormData(category, value, position, isRepeat)
    ),
  setForEditing: (data) => dispatch(CreateActions.setCreatePopupPosition(data)),
  resetAll: () => dispatch(CreateActions.setCreateResetAll()),
  setSettingsView: (data) => dispatch(SettingsActions.setSettingsView(data)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(EditProgramPage);
