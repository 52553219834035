/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Title1Bold, Box } from 'components/core';
import { PlayerModal } from 'components/player';
import { SearchProgramCard } from 'containers/programs';
import ProgramHelmet from 'containers/program/ProgramHelmet';
import { DefaultLoader } from 'components/loading';
import { RatingModal, SummaryModal } from 'modals';
import history from 'utils/history';
import { transformProgram } from 'utils/transform';

import { AuthSelectors } from 'redux/AuthRedux';
import Analytics, { EVENTS, SCREEN } from 'services/analytics';

function ProgramListView({
  title,
  loadData,
  isLoggedIn,
  onRegister,
  topProgram,
}) {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [itemData, setItemData] = useState(false);
  const [itemDataCategory, setItemDataCategory] = useState(false);
  const [currentRating, setCurrentRating] = useState(1);
  const [currentStats, setCurrentStats] = useState(1);
  const [currentProgramId, setCurrentProgramId] = useState('');
  const [showSummary, setShowSummary] = useState(false);
  const [showRatingView, setShowRatingView] = useState(false);

  const handleJoin = (programInfo) => {
    if (!isLoggedIn) {
      onRegister();
      return;
    }

    const { id: programId } = programInfo;
    history.push(`/subscription/${programId}`);
  };

  const handleOnView = (programInfo, category, value, sectionIndex, day) => {
    const {
      id: programId,
      isSubscribed,
      program: { data },
      price,
    } = programInfo;

    Analytics.track(EVENTS.PROGRAM_ITEM_CLICKED, {
      from: SCREEN.OTHER_PROFILE,
      programId,
      title,
      category,
      day,
    });

    if (+day !== 1 && !isSubscribed && price) {
      handleJoin(programInfo);
      return;
    }

    const sectionData = data[sectionIndex];
    const itemData = sectionData[day];
    const { thumbUrl } = itemData;

    if (!thumbUrl) {
      return;
    }

    setItemData(itemData);
    setItemDataCategory(category);
  };

  const onViewSummary = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    setCurrentProgramId(programId);
    setCurrentStats(stats);
    setShowSummary(true);
  };

  const onViewRating = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    const { avgRating } = stats || {};
    setCurrentProgramId(programId);
    setCurrentRating(avgRating);
    setShowRatingView(true);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await loadData();
      setPrograms(data.map(transformProgram));
      setLoading(false);
    })();
  }, []);

  let filteredPrograms = programs;
  if (topProgram) {
    filteredPrograms = [
      topProgram,
      ...programs.filter((p) => p.id !== topProgram.id),
    ];
  }

  return (
    <Box>
      {topProgram && <ProgramHelmet program={topProgram} />}
      <Title1Bold mb={5}>{title}</Title1Bold>
      {loading && (
        <Box display="flex" p={4} justifyContent="center">
          <DefaultLoader />
        </Box>
      )}
      {filteredPrograms.map((programInfo) => (
        <SearchProgramCard
          key={programInfo.id}
          mb={6}
          onView={(category, value, sectionIndex, day) =>
            handleOnView(programInfo, category, value, sectionIndex, day)
          }
          onJoin={() => handleJoin(programInfo)}
          onViewSummary={() => onViewSummary(programInfo)}
          onViewRating={() => onViewRating(programInfo)}
          {...programInfo}
        />
      ))}
      <PlayerModal
        show={!!itemData}
        data={itemData}
        category={itemDataCategory}
        onClose={() => setItemData(null)}
      />
      <RatingModal
        show={showRatingView}
        programId={currentProgramId}
        rating={currentRating}
        onOk={() => setShowRatingView(false)}
      />
      <SummaryModal
        show={showSummary}
        programId={currentProgramId}
        stats={currentStats}
        onOk={() => setShowSummary(false)}
      />
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  isLoggedIn: AuthSelectors.selectIsLoggedIn(state),
});

export default connect(mapStatesToProps)(ProgramListView);
