import React from 'react';
import { Box, Body2, Title3, Title1Bold } from 'components/core';

const P = Body2.withComponent('p');
P.defaultProps = {
  fontSize: 0,
};
const H1 = Title1Bold.withComponent('h1');
const H2 = Title3.withComponent('h2');

function Terms() {
  return (
    <Box color="black" p={[2, 6]}>
      <H1>BCult Terms of Service</H1>
      <P>
        <strong>Last Updated: October 14, 2020</strong>
      </P>
      <P>
        <strong>Please read these Terms of Service carefully.</strong>
      </P>
      <P>
        The Terms of Service (“TOS”) is between you (“you”, “your”, and “user”)
        and BCult Corp., and its subsidiaries and affiliates, (“BCult”, “we”,
        “us” and “our”) and governs your access and use of the Services.
        “Services” means any platform, website, application, mobile application,
        materials, content, products or services offered by BCult and its third
        party service providers.
      </P>
      <P>The TOS contain important information about your legal rights.</P>
      <P>
        <strong>
          THE TOS IS A LEGAL AGREEMENT BETWEEN YOU AND BCULT AND GOVERNS YOUR
          USE OF THE SERVICES. YOUR USE OF THE SERVICES SIGNIFIES THAT YOU HAVE
          READ AND AGREE TO THESE TOS AND BCULT’S PRIVACY NOTICE LOCATED AT
          https://bcult.com/privacy ("PRIVACY NOTICE"), BCULT COMMUNITY
          STANDARDS LOCATED HERE https://bcult.com/community (“COMMUNITY
          STANDARDS”) AND ANY OTHER RELATED POLICIES, COMMUNITY STANDARDS OR
          GUIDELINES MADE AVAILABLE BY BCULT. IF YOU ARE AN INDIVIDUAL AGREEING
          TO THE TOS ON BEHALF OF A LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE
          THE LEGAL AUTHORITY TO BIND SUCH ENTITY. IF YOU DO NOT AGREE TO THESE
          TOS, DO NOT USE THE SERVICES.
        </strong>
      </P>
      <P>
        As further described in these TOS (and without limitation), you
        acknowledge the following:
      </P>
      <ul>
        <li>
          Your use of the Services is at your sole risk, and you should consult
          with a physician or other healthcare professional before commencing
          fitness, health, strength training or wellness activities through the
          Services. YOU AGREE THAT UNDER NO CIRCUMSTANCES DOES BCULT OR ITS
          AFFILIATES HAVE ANY RESPONSIBILITY OR LIABILITY TO YOU RELATED TO YOUR
          PARTICIPATION IN ANY FITNESS, HEALTH, STRENGTH TRAINING OR WELLNESS
          ACTIVITIES.
        </li>

        <li>
          DISPUTES ARISING BETWEEN YOU AND BCULT WILL BE RESOLVED BY BINDING
          ARBITRATION, AS MORE SPECIFICALLY SET FORTH IN THE DISPUTE RESOLUTION
          PROVISION. BY ACCEPTING THE TOS, YOU AND BCULT ARE EACH WAIVING THE
          RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION TO THE
          MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
        </li>
      </ul>
      <P>
        <strong>OVERVIEW</strong>
      </P>
      <P>
        BCult provides opportunities for you to access audio, video, programs,
        applications, software, photographs, graphics, branding and other
        information and materials on or through the Services (“Content”). The
        Services enable you to join a network of independent fitness, strength,
        wellness and health instructors who subscribe to our Services. The
        Services assist independent instructors in creating and sharing their
        content, and aids participants in identifying and viewing, digital
        health, fitness, strength training and wellness programs. The Services
        also provides a forum for people to connect, inform and inspire others.
        BCult allows users to launch original fitness, wellness and health
        content. The Services, and the content made available to users, is
        designed for informational purposes only. Participation in any
        activities, exercises, workouts, nutritional or wellness programs is
        voluntary. Exercise, strength training, health and wellness programs
        could result in injury. By voluntarily undertaking any fitness,
        strength, health or wellness program, you assume the risk of any
        resulting injury.
      </P>
      <P>
        YOU SHOULD NOT RELY ON ANY INFORMATION PROVIDED ON OR THROUGH THE
        SERVICE, INCLUDING USER CONTENT, AS A SUBSTITUTE FOR, NOR DOES IT
        REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS OR TREATMENT. PLEASE
        CONSULT WITH A PHYSICIAN BEFORE STARTING OR PERFORMING ANY EXERCISE,
        STRENGTH TRAINING, HEALTH OR WELLNESS PROGRAM. IT IS YOUR RESPONSIBILITY
        TO EVALUATE YOUR OWN MEDICAL AND PHYSICAL CONDITION AND TO INDEPENDENTLY
        DETERMINE WHETHER TO PERFORM, USE OR ADAPT ANY OF THE INFORMATION
        PROVIDED THROUGH THIS SERVICES. IF YOU HAVE ANY CONCERNS OR QUESTIONS
        ABOUT YOUR HEALTH OR THE CONTENT ON THE SERVICE, YOU SHOULD ALWAYS
        CONSULT WITH A PHYSICIAN OR OTHER HEALTH-CARE PROFESSIONAL. DO NOT
        DISREGARD, AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED ADVICE
        FROM YOUR HEALTH-CARE PROFESSIONAL BECAUSE OF SOMETHING YOU MAY HAVE
        VIEWED ON THE SERVICES. BCULT DOES NOT RECOMMEND OR ENDORSE ANY CONTENT,
        PRODUCTS, ADVICE OR INFORMATION THAT MAY BE AVAILABLE THROUGH THE
        SERVICES. THE USE OF ANY INFORMATION PROVIDED THROUGH THE SERVICES IS
        SOLELY AT YOUR OWN RISK.
      </P>
      <H2>
        <strong>USE OF THE SERVICE</strong>
      </H2>

      <P>
        <strong>Access to and Use of the Services. </strong>Subject to these
        TOS, you may access our website, create an account, and access some
        Content for free. However, as further described below, users also have
        the option to access other Content for a fee or on a subscription basis.
        BCult retains the right, in its sole discretion, to deny access to and
        use of the Services to anyone at any time and for any reason. While we
        use reasonable efforts to keep the Services accessible, the Services
        and/or your account may be unavailable from time to time. You understand
        and agree that there may be interruptions to the Services and/or access
        to your account due to circumstances within BCult’s control (i.e.:
        routine maintenance) as well as outside of BCult’s control. YOU ARE
        SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS OF THE
        SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE USE OF THE SERVICES IS AT
        YOUR OWN RISK.
      </P>
      <P>
        <strong>Service Eligibility.</strong> Our website and Services are not
        directed at children. To access and/or use the Services, you must be at
        least thirteen (13) years of age or older. You certify that you are at
        least 13 years old; if you are not, you may not register for or use the
        Services. If we learn that an individual under age 13 has access to or
        use of the Services, we will take steps to delete such account and all
        Content and other information related thereto. Please review our Privacy
        Notice regarding collection of information from children.
      </P>
      <H2>
        <strong>Your BCult Account</strong>. You may be required to create an
        account and specify a password in order to use the Services. To create
        an account, you must be at least thirteen (13) years of age, provide
        contact information, and submit any other form of authentication as
        determined by BCult in its sole discretion. You must provide truthful,
        accurate and current information in connection with your account. You
        are responsible for updating and correcting information you have
        submitted to create and/or maintain your account. Please don’t try to
        impersonate anyone else when you create your account. We may refuse to
        grant you an account with a name that impersonates someone else; or if
        the name is illegal, vulgar, offensive; or if it may be protected by
        trademark or other proprietary rights, as determined by BCult in its
        sole discretion.
      </H2>

      <P>
        You are solely responsible for maintaining the security of your account
        and the confidentiality of the password you use to access your account.
        You acknowledge and agree that BCult shall have no responsibility for
        any incident arising out of, or related to, your account or account
        settings. You are fully responsible for all activities that occur under
        the account and any other actions taken in connection with it. You must
        immediately notify BCult of any unauthorized use of your account,
        password and/or any other breach of security.
      </P>
      <P>
        If BCult, in its sole discretion, determines that you have acted
        inappropriately; violated any laws; infringed on anyone else’s rights;
        violated any provision of these TOS, Privacy Notice, Community Standards
        or any other guidelines or policies of BCult; or that your conduct or
        Content would tend to damage our reputation and goodwill; we reserve the
        right to take down your Content, suspend or terminate your account (or
        any part thereof), prohibit you from using the Services, and/or take any
        appropriate legal actions. If we delete your account for any of the
        foregoing reasons, you may not re-register for our Services. We may also
        block your email address and Internet protocol address to prevent
        further registration.
      </P>
      <P>
        <strong>Content You Post. </strong>We may provide opportunities for you
        and other users to post text, programs, photographs, videos, audio,
        graphics, branding, reviews, contact and location information, or other
        content (collectively “User Content”) on the Services. Once posted, your
        User Content cannot always be deleted. You acknowledge and agree that
        any User Content or communications you post on the Services may be seen
        and used by others, and is available to the public. You understand that
        by posting User Content or communications you have no expectation of
        privacy for such User Content or communications. IF YOU CHOOSE TO MAKE
        ANY OF YOUR PERSONAL INFORMATION OR OTHER USER CONTENT PUBLICLY
        AVAILABLE ON OR THROUGH THE SERVICES, YOU DO SO AT YOUR OWN RISK.
      </P>
      <P>
        You have sole responsibility for the accuracy, quality, integrity,
        legality, reliability, and appropriateness of all Content you post. You
        can only post User Content if you own all rights to that User Content,
        or if another rights holder has given you permission. You do not
        transfer ownership of your User Content simply by posting it.
      </P>
      <P>
        By posting, sharing, or uploading User Content on or through our
        Services, you hereby grant to BCult, its affiliates, partners,
        contractors, licensors and assigns, a worldwide, royalty-free,
        irrevocable, perpetual, nonexclusive, assignable, sublicensable, and
        transferable right and license to host, reproduce, encode, store, copy,
        transmit, publish, post, broadcast, display, publicly perform,
        distribute, adapt, translate, modify, create derivate works of, disclose
        and use your User Content. Without those rights, we could not offer our
        Services. Please note that this license continues even if you stop using
        our Services. User Content will continue to appear if you shared it with
        others and they have not deleted it. You agree to indemnify, release and
        hold us harmless from any liability, claims, actions, loss, harm,
        damage, injury, cost or expense arising out of any User Content you
        post.
      </P>
      <P>
        If you use any materials or Content covered by intellectual property
        rights that BCult has and makes available in our Services (for example,
        images, designs or videos), we retain all rights to our Content (but not
        yours).
      </P>
      <P>
        <strong>Instructor use of the Services. </strong> In using the Services,
        instructors and users may choose to post programmatic User Content, and
        in such case will be required, at a minimum, to provide through the
        Services the following: your name, address, phone number, email address,
        user name, password, and payment information. Instructors and users who
        post programmatic User Content for a fee may be required to enter into
        an agreement with BCult or the designated third party payment service
        provider(s) prior to receiving any revenue or charging any fees for User
        Content. You must comply with all provisions and requirements of the
        third party payment service provider. BCult reserves the right to remove
        any programmatic User Content for any reason or no reason at all, at its
        sole discretion, without liability.
      </P>
      <P>
        <strong>Participant use of the Services.</strong> In your use of the
        Services, you may create one (1) profile that enables you to conduct
        searches for fitness, health, wellness and other programs offered by
        independent instructors who subscribe to our Services, view instructor
        content and pay for individual programs or subscriptions, and in such
        case you will be required, at a minimum, to provide through the Services
        the following information: your name, address, phone number, email
        address, user name, password, debit or credit card and/or other billing
        information. You may also post a review or rate an instructor of a
        program.
      </P>
      <P>
        <strong>User Content Restrictions.</strong> User Content posted on the
        Services shall not contain inappropriate material as determined by BCult
        in its sole discretion, including but not limited to:
      </P>
      <ol>
        <li>
          Illegal content in violation of any applicable federal, state, local,
          or international laws or regulations, or violates the rights of any
          other person or entity;
        </li>

        <li>
          harmful, offensive, inaccurate, abusive or otherwise inappropriate
          language, including without limitation, bigotry, racism,
          discrimination, bullying, hatred, or profanity;
        </li>

        <li>
          violent, nude, partially nude, pornographic or sexually suggestive
          content;
        </li>

        <li>
          comments that do not address the content, reviews, ratings or contains
          no qualitative value;
        </li>

        <li>
          information about illegal activities, physical harm or injury to any
          group, individual, institution or property;
        </li>

        <li>
          personal attacks, physical confrontations and/or sexual harassment;
        </li>

        <li>
          advertising or other information commercial in nature, or
          inappropriate based on the applicable subject matter;
        </li>

        <li>
          language intended to impersonate other users (including names of other
          individuals) or offensive or inappropriate user names or signatures;
        </li>

        <li>
          viruses, trojan horses, worms, time bombs, cancelbots, or other
          computer programming routines that are intended to damage, interfere
          with, intercept or appropriate any system, data or personal
          information; and/or
        </li>

        <li>
          use a domain name or URL in your username or in your User Content
          without BCult prior written consent.
        </li>
      </ol>
      <P>
        <strong>Service-related Communications. </strong>You agree to receive
        certain communications in connection with the Services. When you use the
        Services or send communications to us, you are communicating with us or
        the other users electronically. You consent to receive electronically
        any communications related to your use of the Services. We may
        communicate with you by email or by posting notices on the Services. You
        acknowledge and agree that all agreements, notices, disclosures and
        other communications that we provide to you electronically satisfy any
        legal requirement that such communications be in writing. All notices
        from us intended for receipt by you shall be deemed delivered and
        effective when sent to the email address you provide to us. Please note
        that by submitting User Content, creating a user account or otherwise
        providing us with your email address, postal address or phone number,
        you are agreeing that we or our third-party service providers may
        contact you at that address or number in a manner consistent with our
        Privacy Notice. If you send us any communications, those communications
        will not be deemed confidential information.
      </P>
      <P>
        <strong>Feedback.</strong> You are not obligated to provide any
        information, suggestions, ideas, improvements, reviews, enhancements,
        recommendations, feature requests or other communications to BCult
        (“Feedback”). Feedback will not be confidential. If you choose to give
        us Feedback, we reserve the right to reproduce, use, disclose,
        distribute, or otherwise act on your Feedback without any obligation to
        you.
      </P>
      <P>
        <strong>Verification</strong>. You understand that BCult acts only as a
        social networking and marketing platform, and technical interface
        between people that desire to connect, inform and inspire others on
        fitness, health and wellness related topics and programs. BCult does not
        itself verify the qualifications or backgrounds of users, nor does it
        evaluate or control in any ongoing manner exchanges between users. BCult
        does not verify independent instructors. Any opinions or statements
        expressed by a user are those of the user alone, and are not to be
        attributed to BCult. BCult cannot and does not assume responsibility for
        the accuracy, completeness, safety, reliability, timeliness,
        innocuousness, legality or applicability of anything said, written,
        posted, displayed or otherwise made available by any user, nor does
        BCult assume any responsibility for a user’s conduct. Please use
        caution, common sense, and practice safe networking and connecting when
        using BCult.
      </P>
      <P>
        <strong>Your Conduct and Responsibilities.</strong> In addition to the
        other terms set forth in these TOS and Privacy Notice, or any additional
        guidelines or policies made available by BCult, you must comply with the
        following:
      </P>
      <ol>
        <li>
          You hereby represent and warrant to BCult that (i) all information
          provided to BCult by you is true, complete and accurate in all
          respects, and (ii) you are authorized to submit information to BCult;
        </li>

        <li>
          BCult is authorized by you to rely upon the truthfulness, completeness
          and accuracy of your Content in order to serve the other users;
        </li>

        <li>
          You shall not modify, disassemble, reverse engineer or otherwise
          reduce to human-perceivable form all or any part of the Service;
        </li>

        <li>
          You may not access all or any part of the Services in order to build a
          product or service which competes with the Service;
        </li>

        <li>
          You shall not copy or imitate part or all of the design, layout, or
          look-and-feel of the Services or individual sections of it, in any
          form or media;
        </li>

        <li>
          You are only authorized to view and use small portions of the Content
          on the Services for your informational, non-commercial use, provided
          that you do not remove any copyright notices or other proprietary
          notices from the Service;
        </li>

        <li>
          You are responsible for the accuracy, legality and quality of the
          Content that you submit and will not violate the intellectual property
          rights of anyone;
        </li>

        <li>
          You will not post any User Content or communications that contain
          payment card or banking information;
        </li>

        <li>
          You agree to act in accordance with all applicable laws, rules and
          regulations;
        </li>

        <li>
          You will not upload, post, or otherwise make available any unsolicited
          or unauthorized advertising, promotional materials, “junk mail,”
          “spam,” or any other forms of solicitation;
        </li>

        <li>
          You shall not imply that user Content is in any way sponsored and/or
          endorsed by BCult;
        </li>

        <li>
          You agree not to intentionally hold BCult, its affiliates, employees,
          or directors up to public scorn, ridicule or defamation;
        </li>

        <li>
          You will use commercially reasonable efforts to prevent unauthorized
          access to or use of the Services, and notify BCult promptly of any
          such unauthorized access or use;
        </li>

        <li>
          You will not attempt to do any of the following: access data not
          intended for you, monitor the Services for data gathering purposes or
          interfere with the Services in relation to any user in any manner; and
        </li>

        <li>
          You shall not use the Services for any unlawful purpose or solicit
          others to perform or participate in any unlawful acts.
        </li>
      </ol>
      <P>
        BCult<strong> </strong>shall not be subject to any obligations of
        confidentiality regarding any User Content or any other information or
        materials submitted through the Services except as otherwise specified
        in this TOS and Privacy Notice; or as set forth in any additional terms
        and conditions relating to specific products or services utilized by
        BCult in conjunction with the Service; or as otherwise required by law.
        The commercial use, reproduction, transmission, disclosure or
        distribution of any information, software or other material available
        through the Services without the prior written consent of BCult is
        strictly prohibited.
      </P>
      <P>
        <strong>User Content Posted By Others. </strong>We are not responsible
        for, and do not endorse, User Content posted by any user of the
        Services, including but not limited to, independent instructors.
        Accordingly, we may not be held liable, directly or indirectly, for any
        loss or damage caused to you in connection with any User Content posted
        by a user. As part of your use of the Services, you may obtain personal
        information from other users. This personal information shall only be
        used in connection with the purposes set forth in the Services and/or
        for BCult-related communications, unless otherwise approved by the user
        in writing. BCult has not granted you a license to use the User Content
        posted by others for unsolicited messages or unauthorized activities.
      </P>
      <P>
        <strong>THIRD PARTY SERVICES AND LINKS.</strong>
      </P>
      <P>
        BCult may use third party service providers, products and services to
        provide the Services (“Third Party Services”). BCult does not control or
        endorse any Third Party Services. The Services may provide links to
        third-party websites (“Linked Sites”), and Linked Sites are provided for
        your convenience only. BCult does not control or endorse Linked Sites,
        is not responsible or liable for the content therein nor the accuracy,
        validity or reliability of any information, data, opinions, advice
        and/or statements contained within such Linked Sites. BCult is also not
        responsible for any goods or services sold on Linked Sites, and we do
        not control or endorse any products or services advertised thereon. The
        Services may contain ads from third parties. We do not control or
        endorse any products or services being advertised. You should carefully
        review the terms of service and privacy statements of any Linked Sites.
        You will need to make your own independent judgment regarding your
        interaction with Linked Sites. Your use is at your own risk. BCult
        <strong> </strong>reserves the right to terminate any Linked Sites, or
        other link and/or linking program at any time in its sole discretion.
      </P>
      <P>
        <strong>USER MARKETING.</strong>
      </P>
      <P>
        A user may use the promotion feature and market their User Content
        within the Services wherein you may choose to have BCult feature your
        program on or through the Services. Users may be charged a fee for such
        marketing as specified below and such fee shall be due and payable
        weekly in advance.
      </P>

      <table>
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Master Subscription</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cost</td>
            <td>$2.99/week</td>
            <td>$5.99/week</td>
            <td>10.99/week</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Top of homepage algorithm boost</td>
            <td>1X more frequently at top of homepage</td>
            <td>2X</td>
            <td>5X</td>
            <td>5X</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <P>
        <strong>FEES</strong>
      </P>
      <P>
        <strong>Fee Per Class</strong>. Certain designated programs charge a fee
        per program at the instructor’s discretion. We charge an administrative
        fee on each and every fee per class. The administrative fee is a
        percentage of the total fee designated by the instructor. The standard
        percentage for most classes is forty percent (40%). If you choose to
        purchase a program, you authorize us to charge you for the fee per
        class, plus all additional charges such as administrative fee, taxes,
        cancellation and/or late fees.
      </P>
      <P>
        <strong>Subscription</strong>. To access premium programs, you will need
        to purchase a subscription (“Master Subscription”). By purchasing a
        Master Subscription, you will get unlimited access to premium content
        for as long as your Master Subscription remains active.  Please note
        that you may also be charged for any individual classes that charge a
        fee per class, and all associated costs, even if you have a Master
        Subscription. A Master Subscription is usable by only one user. If you
        choose to purchase a Master Subscription, you authorize us to charge you
        for the then-current monthly fee for such Master Subscription. You also
        authorize us to charge you any other fees you may incur in connection
        with your use of the Services, such as any administrative, applicable
        taxes, cancellation and/or late fees. At any time, we reserve the right
        at our sole discretion to change the fees related to the Master
        Subscription upon notice to you. Any price changes will take effect on
        your next billing cycle. Note that even if you do not use the Services
        during the Master Subscription period or access any Content, you will be
        responsible for the applicable fee related to the Master Subscription
        until your Master Subscription is canceled or terminated. You may cancel
        a Master Subscription by sending an email to
        <span bb="text-decoration: underline">support@BCult.com</span>.
      </P>
      <P>
        <strong>Payment</strong>.  If your Master Subscription is activated on
        any day other than the first day of the month, you will be billed
        prorata for the remaining portion of the first month in which you
        activated your Master Subscription. Unless your Master Subscription is
        canceled or terminated prior to the start of the renewal month, your
        Master Subscription will automatically renew each month and you will be
        billed on the first day of the month of each month. At any time, we
        reserve the right at our sole discretion to adjust the timing of our
        billing. If we change the timing of our billing, we will make
        adjustments to the amounts we charge you in a given cycle, as
        appropriate. Any such changes will be communicated to you in advance. If
        you do not cancel your Master Subscription, you will be deemed to have
        accepted any changes to price or billing terms. If you do not agree with
        a price or billing change, you have the right to reject the change by
        terminating the Services or canceling your Master Subscription prior to
        the change going into effect.  Fees are nonrefundable.
      </P>
      <P>
        <strong>Payment Methods</strong>. In the event you purchase programs or
        enter into a Master Subscription, you will be asked by BCult, or an
        authorized third party service provider, to provide certain payment
        information, including without limitation, your full name, billing
        address, telephone number, email address, and credit card or PayPal
        information. You agree to provide us, or our third party service
        provider, with accurate, complete and current information and agree to
        comply with all terms and conditions related to payment.  You may edit
        your payment method information by logging onto the Services and editing
        your account settings. If a payment is not successfully settled due to
        expiration, insufficient funds or otherwise, you nonetheless will remain
        responsible for any uncollected amounts and additional fees as a result,
        including collection fees, interest, court costs and attorneys’ fees.
        You authorize us to continue billing the payment method, or any other
        payment method you have provided, including in the event you attempt to
        create a new account, or reactivate the unsettled account. This may
        result in a change to your payment billing dates. If we cannot charge
        your account, we reserve the right, but are not obligated, to suspend or
        terminate your account and access to our Services or any portion
        thereof.
      </P>
      <P>
        Payments to Instructor. In the event you create User Content and elect
        to charge a fee, we will enable your account for managed payments
        provided that you have established a satisfactory payments account with
        the designated third party payment services provider. You will be
        required to provide the third party payment services provider with
        information about you and your business to meet certain compliance
        requirements including those involving identity verification, anti-money
        laundering and sanctions screening as required by applicable law. The
        third party payments service providers may perform risk assessments,
        compliance checks, verify identity, and validate payment methods. We may
        send personal data associated with you and your account to the third
        party payments service providers. You will be required to provide bank
        account information on a U.S. based checking account so that the third
        party payment services provider can link your account allowing the third
        party payment services provider to pay you. Payments may be withheld
        pending receipt of all information or in the event you have not met
        their compliance requirements.
      </P>
      <P>
        <strong>Promotions/Master Class</strong>. We may offer promotions or
        free trials of “Master Subscription”. A free trial provides you access
        to featured programs for a period of time, with specific details
        provided when you sign up for the offer. In order to sign up for a free
        trial, you may need to provide us with your preferred payment method.
        You will not be charged until the free trial period ends. Unless you
        cancel before the end of the free trial, or unless otherwise stated,
        your access to the featured programs will automatically continue and you
        will be billed the applicable fees for “Master Subscription” using the
        payment method you provided. All incurred charges are final and
        nonrefundable.
      </P>
      <P>
        <strong>PROPRIETARY RIGHTS AND LICENSES</strong>
      </P>
      <P>
        <strong>License.</strong> The Services and Content are proprietary to
        BCult, our affiliates and licensors. By using the Services and Content,
        and subject to your compliance with these TOS: (a) BCult grants you a
        limited, personal, nontransferable, nonexclusive, revocable right
        (without the right to sublicense) to access and use the Service and
        Content; and (b) you agree not to reproduce, distribute, create
        derivative works from, publicly display, publicly perform, license,
        sublicense, sell and/or re-sell the Services, or any Content, software,
        products and/or services obtained from or through the Services.
      </P>
      <P>
        <strong>Ownership and Reservation of Rights. </strong>All right, title
        and interest in its intellectual property, including but not limited to
        the Services and the Content, remains the exclusive property of BCult or
        its licensors. You acknowledge and agree that the Services and the
        Content contain proprietary and confidential information of BCult. The
        Services, including the software and the Content, are copyrighted and
        protected by the laws of the United States. No rights are granted to you
        hereunder, except for the limited license granted above. BCult’s name
        and logo, or any other trademarks or service marks of BCult, may not be
        copied, imitated and/or used, without BCult’s prior written permission.
      </P>
      <P>
        <strong>DMCA COPYRIGHT POLICY AND COPYRIGHT AGENT</strong>
      </P>
      <P>
        BCult respects the intellectual property rights of others and expects
        you to do the same. The notification process outlined here is consistent
        with the process suggested by the Digital Millennium Copyright Act
        (“DMCA”) (which can be found at the U.S. Copyright Office Web Site,
        <span bb="text-decoration: underline">http://www.copyright.gov</span>
        ).
      </P>
      <P>
        We reserve the right to block access to and/or attempt to remove
        material that we believe in good faith to be copyrighted material that
        has been illegally distributed by you and/or other third parties, and to
        remove and discontinue service to repeat offenders.
      </P>
      <P>
        We require the following information in your notice of copyright
        infringement:
      </P>
      <ol>
        <li>
          a physical or electronic signature of the owner of the copyright (or a
          person authorized to act on behalf of the owner);
        </li>

        <li>
          sufficient detail about the copyrighted work claimed to have been
          infringed;
        </li>

        <li>
          identification of the URL or other specific location on our websites
          that contains the material that you claim infringes your copyright;
        </li>

        <li>your name, mailing address, telephone number and email address;</li>

        <li>
          a statement by you that you have a good faith belief that such
          disputed use of the copyright materials is not authorized by the
          copyright owner, its agent, or the law; and
        </li>

        <li>
          a statement that the information in the notice is accurate, and you
          attest under penalty of perjury, that you are the copyright owner or
          that you are authorized to act on behalf of the copyright owner.
          <P>
            Your notice may be sent to our Copyright Agent at BCult, Attn:
            Copyright Agent, 600 N. Broad Street, Suite 5, #3318, Middletown, DE
            19709.
          </P>
        </li>
      </ol>
      <P>
        The Copyright Agent will not attempt to remove content from the Services
        in response to phone or email notifications, since a valid DMCA notice
        must be signed, under penalty of perjury, by the copyright owner or by a
        person authorized to act on his or her behalf. The Copyright Agent
        should be contacted only if you believe that your work has been used or
        copied in a way that constitutes copyright infringement and that such
        infringement is occurring on the Services. All other inquiries directed
        to the Copyright Agent will not be responded to. If a user believes that
        their content was removed or disabled by mistake or misidentification,
        the user may send us a written counter-notification.
      </P>
      <P>
        <strong>TERMINATION </strong>
      </P>
      <P>
        BCult reserves the right, in its sole discretion, to immediately
        terminate your account, access to the Services, or any portion thereof,
        or any and all Content, without liability and without prior notice.
        Cause for such termination, as determined by BCult in its sole
        discretion, includes, but is not limited to: (i) breach or violation of
        the TOS, the Privacy Notice, or other agreements, policies or guidelines
        made available by BCult; (ii) engagement in an improper, illegal or
        fraudulent activity; (iii) requests by law enforcement or other
        government agencies; (iv) a request by you (self-initiated account
        deletions); (v) discontinuance or material modification to the Services
        or any part thereof; (vi) unexpected technical or security issues or
        problems; (vii) extended periods of inactivity; and/or (viii) nonpayment
        of any fees owed by you in connection with the Services, if applicable.
        BCult may also suspend or terminate your account, access to the Services
        and/or disable or remove any and all Content or materials for any reason
        or no reason whatsoever. Termination of your account includes: (a)
        removal of access to all offerings within the Service; (b) deletion of
        your password and all related information, files and Content associated
        with your account; and (c) barring of further use of the Services. All
        terminations shall be made at BCult’s sole discretion. BCult shall not
        be liable to you or any third party for any termination of your account
        or access to the Services or Content.
      </P>
      <P>
        You may terminate this TOS at any time for any reason by notifying BCult
        in writing at support@BCult.com. It is your responsibility to make sure
        that BCult receives your notice of termination as such termination will
        not be effective until written notice has been received by BCult.
      </P>
      <P>
        <strong>INDEMNIFICATION </strong>
      </P>
      <P>
        You shall indemnify, defend and hold harmless BCult, and its affiliates,
        directors, officers, agents, employees, contractors, partners and
        licensors from and against any and all claims, demands, proceedings,
        losses, costs, damages, awards, fees, expenses, or liabilities of any
        nature, including attorney’s fees (“Claim”) arising out of or relating
        to (i) Content you submit, post, transmit, create, share or otherwise
        make available through the Service; (ii) your use of the Service; (iii)
        your violation of any law; (iv) any breach of these TOS or the Privacy
        Notice; (v) your fraudulent acts, intentional misconduct, and/or
        negligence; (vi) your violation of any third party’s rights; or (vii)
        any fitness, health, strength training, or wellness activities whether
        provided to you, performed by you or created and shared by you.
      </P>
      <P>
        <strong>DISCLAIMER OF WARRANTIES</strong>
      </P>
      <P>
        WE PROVIDE OUR SERVICES USING A COMMERCIALLY REASONABLE LEVEL OF CARE.
        HOWEVER, WE DO NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT, USER CONTENT,
        THE SPECIFIC FUNCTION OF THE SERVICE, THE QUALITY OF THE SERVICES OR
        THEIR RELIABILITY, AVAILABILITY OR ABILITY TO MEET YOUR REQUIREMENTS. WE
        DO NOT WARRANT THAT THE SERVICES WILL BE ERROR-FREE. ACCORDINGLY, YOU
        UNDERSTAND THAT YOUR USE OF THE SERVICES, CONTENT AND USER CONTENT IS AT
        YOUR SOLE RISK, AND THE SERVICE, INCLUDING THE CONTENT, IS PROVIDED ON
        AN “AS IS” AND “AS AVAILABLE” BASIS.
      </P>
      <P>
        BCULT AND ITS AFFILIATES, DIRECTORS, OFFICERS, CONTRACTORS, AGENTS AND
        LICENSORS, EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND ABOUT THE
        SERVICE, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO,
        IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, QUIET ENJOYMENT,
        MERCHANTABILITY, AND/OR FITNESS FOR A PARTICULAR PURPOSE, ACCURACY,
        COURSE OF DEALING, USAGE AND/OR TRADE PRACTICE. BCULT EXPRESSLY
        DISCLAIMS ANY AND ALL REPRESENTATIONS OR WARRANTIES RELATING TO ANY
        THIRD PARTY MATERIALS. YOU WILL LOOK SOLELY TO THE THIRD PARTY PROVIDER
        OF THE THIRD PARTY MATERIALS FOR ANY WARRANTY RELATED ISSUES OR OTHER
        CLAIMS RELATED THERETO.
      </P>
      <P>
        <strong>LIMITATION OF LIABILITY</strong>
      </P>
      <P>
        YOU AGREE THAT UNDER NO CIRCUMSTANCES DOES BCULT OR ITS AFFILIATES HAVE
        ANY RESPONSIBILITY OR LIABILITY TO YOU RELATED TO YOUR PARTICIPATION IN
        ANY FITNESS, HEALTH, STRENGTH TRAINING OR WELLNESS ACTIVITIES. BCULT IS
        NOT RESPONSIBLE OR LIABLE FOR USERS ACTS OR OMMISSIONS, INCLUDING ANY
        DAMAGES OF ANY KIND, WHETHER PHYSICAL INJURY, DEATH OR PROPERTY DAMAGE,
        INCURRED AS A RESULT OF SUCH ACTS OR OMISSIONS.
      </P>
      <P>
        EXCEPT WHERE PROHIBITED, BCULT AND ITS AFFILIATES, DIRECTORS, OFFICERS,
        EMPLOYEES, PARTNERS, CONTRACTORS AND LICENSORS, SHALL NOT BE LIABLE FOR
        ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR
        EXEMPLARY DAMAGES ARISING FROM YOUR USE OF THE SERVICES OR ANY THIRD
        PARTY’S USE OF THE SERVICES. THESE EXCLUSIONS INCLUDE, WITHOUT
        LIMITATION, DAMAGES FOR LOST PROFITS, LOST DATA, COMPUTER FAILURE,
        GOODWILL, LOSS OF USE, OR THE VIOLATION OF YOUR RIGHTS BY ANY THIRD
        PARTY, EVEN IF BCULT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
        AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY UPON WHICH THE CLAIM IS
        BASED. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
        FOR CONSEQUENTIAL AND/OR INCIDENTAL DAMAGES. ACCORDINGLY, BCULT’S
        LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. YOUR SOLE
        AND EXCLUSIVE REMEDY SHALL BE THE RIGHT TO DISCONTINUE YOUR USE OF THE
        SERVICES.
      </P>
      <P>
        <strong>DISPUTE RESOLUTION</strong>
      </P>
      <P>
        <strong>Disputes. </strong>We want to address your concerns without
        filing a formal legal case. Before filing a claim against BCult, you
        agree to try to resolve the dispute informally by contacting us at
        support@BCult.com. We will try to resolve the dispute by contacting you
        via email, but if we cannot resolve the dispute within thirty (30) days
        of submission, you and/or BCult agree to resolve any claims related to
        this TOS through final and binding arbitration, except as set forth
        under the Exceptions to Agreement to Arbitrate section or the Opt-Out
        Section, as described below.
      </P>
      <P>
        <strong>Opt-Out</strong>.<strong> </strong>You can opt-out and decline
        this agreement to arbitrate by contacting BCult within thirty (30) days
        from the date that you first became subject to this arbitration
        provision (i.e.: the date you initially accepted this TOS). You must
        write us at BCult, Attn: Opt-Out Arbitration, 600 N. Broad Street, Suite
        5, #3318, Middletown, DE 19709. If you opt out, neither you nor BCult
        can require the other to participate in an arbitration proceeding.
      </P>
      <P>
        <strong>Arbitration Procedures. </strong>Except in the event the claim
        meets the requirements set forth in the ‘Exceptions to Agreement to
        Arbitrate’ section below or if you opt out of arbitration, all claims
        shall be settled by binding arbitration in accordance with the
        commercial arbitration rules, in effect at the time the proceedings
        begin, of the American Arbitration Association. Any such controversy or
        claim shall be arbitrated on an individual basis, and shall not be
        consolidated in any arbitration with any claim or controversy of any
        other party. All information relating to or disclosed by any party in
        connection with the arbitration of any disputes shall be treated by the
        parties, their representatives, and the arbitrator as proprietary
        business information and shall not be disclosed without prior written
        authorization of the disclosing party. The arbitration shall be held in
        Chicago, Illinois or any other location we agree to. Each party shall
        bear the burden of its own attorney’s fees incurred in connection with
        any arbitration proceedings.
      </P>
      <P>
        <strong>Exceptions to Agreement to Arbitrate. </strong>Either you and/or
        BCult may assert claims, if they qualify, in small claims court in
        Chicago, Illinois. BCult may also bring a lawsuit solely for injunctive
        relief to stop unauthorized use or abuse of the Services, breach of
        BCult’s confidential information, or intellectual property infringement
        (for example, trademark, trade secret, copyright and/or patent rights)
        without first engaging in arbitration or the informal dispute resolution
        process described above.
      </P>
      <P>
        <strong>Judicial forum for disputes</strong>. In the event that the
        agreement to arbitrate is found not to apply to you or your claim, you
        and BCult agree that any judicial proceeding (other than small claims
        actions) will be brought in the federal or state courts of Illinois.
        Both you and BCult consent to the foregoing venue.
      </P>
      <H2>
        <strong>CHANGES AND MODIFICATIONS</strong>
      </H2>

      <P>
        We reserve the right to modify our Services, these TOS or Privacy Notice
        at any time with or without notice to you. For example, we may add or
        remove functionality or features, and we may suspend or stop a
        particular feature. We also reserve the right to charge a fee for any of
        our features at any time so check back regularly. If you do not agree to
        the changes, you should stop using the Services. By continuing to use or
        log in to the Services after any changes have been made, you indicate
        your agreement to the changes, including any revised TOS. We will update
        the “Last Updated” date above when we update these TOS.
      </P>
      <P>
        <strong>
          MISCELLANEOUS
          <br />
        </strong>
        These TOS are governed by and construed in accordance with the laws of
        the State of Illinois, without regard to its conflict of laws rules. If
        you do not comply with these TOS, and we do not take action right away,
        this does not mean that we are giving up any rights that we may have,
        including the right to take action in the future. If a particular
        provision in these TOS is not enforceable, that will not affect any
        other provision herein.
      </P>
      <P>
        <strong>CONTACTING</strong> <strong>US</strong>
      </P>
      <P>
        If you would like to request additional information regarding this TOS,
        the Privacy Notice or for any questions, please contact us as at
        support@BCult.com.
      </P>
    </Box>
  );
}

export default Terms;
