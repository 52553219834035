import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Box, Text, LinkText } from 'components/core';
import { AuthLayout } from 'containers/layout';
// import InviteForm from 'containers/InviteForm';
import OnboardingForm from 'containers/OnboardingForm';

import { AppSelectors } from 'redux/AppRedux';

import history from 'utils/history';
import { load as loadFacebook } from 'services/facebook';

const Wrapper = styled(Box)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const MainSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 464px;
  height: 50px;

  @media (max-width: 768px) {
    width: 320px;
  }

  border: 1px solid rgba(220, 220, 220, 0.5);
  border-radius: 2px;

  margin-bottom: 50px;
`;

function Register({ isInvited }) {
  useEffect(() => {
    loadFacebook();
  }, []);

  return (
    <AuthLayout>
      <Wrapper>
        <OnboardingForm />
        {/* {isInvited ? <OnboardingForm /> : <InviteForm />} */}
        <MainSection>
          <Text color="blue" ml={1} mr={1}>
            Already have an account?{' '}
          </Text>
          <LinkText
            color="blue"
            onClick={() => {
              history.push('/auth/login');
            }}
          >
            Sign in
          </LinkText>
        </MainSection>
      </Wrapper>
    </AuthLayout>
  );
}

const mapStatesToProps = (state) => ({
  isInvited: AppSelectors.selectInvitePassed(state),
});

export default connect(mapStatesToProps)(Register);
