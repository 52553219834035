import React from 'react';
import startCase from 'lodash-es/startCase';
import { UNIT_OPTIONS } from 'services/terms';
import CommonBarChart from './CommonBarChart';
import RepSetBarChart from './RepSetBarChart';

const RANGES = {
  minutes: [
    [0, 10],
    [10, 30],
    [30, 60],
    [60, 120],
  ],
  miles: [
    [0, 0.5],
    [0.5, 3],
    [3, 10],
    [10, 25],
  ],
};
const Y_LABELS = {
  minutes: [120, 60, 30, 10],
  miles: [25, 10, 3, 0.5],
};
const COLOR_OPTIONS = ['Under', 'Ideal', 'Over', 'Obese'];

function FitnessChart({ sectionType, unit, ...props }) {
  if (!UNIT_OPTIONS.fitness.find((u) => u.value === unit)) {
    return null;
  }

  if (unit === 'reps-sets') {
    return (
      <RepSetBarChart
        sectionType={sectionType}
        colorOptions={COLOR_OPTIONS}
        {...props}
      />
    );
  }

  return (
    <CommonBarChart
      sectionType={sectionType}
      unit={unit}
      yLabels={Y_LABELS[unit]}
      ranges={RANGES[unit]}
      yAxisLabel={startCase(unit)}
      colorOptions={COLOR_OPTIONS}
      {...props}
    />
  );
}

export default FitnessChart;
