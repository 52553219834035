import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import HeightField from './HeightField';

// Todo: error status
function HeightFormField(props) {
  const { field, label, form, renderAddon, ...rest } = props;
  const formError = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const hasError = !!(touched && formError);

  return (
    <HeightField
      error={hasError}
      disabled={form.isSubmitting}
      {...field}
      {...rest}
      onChange={(val) => {
        form.setFieldValue(field.name, val);
      }}
    />
  );
}

HeightFormField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
};

export default HeightFormField;
