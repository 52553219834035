import React from 'react';
import { FitText, SvgIcon, Tooltip } from 'components/core';
import { getBmiStat } from 'services/calc';
import { isImgix } from 'utils/form';

import {
  EmptyBox,
  FitBox,
  FitImage,
  FitIcon,
  FitOverlay,
  CloseButton,
} from './CommonStyled';

export const EditableBox = ({
  value,
  isEditing,
  onDelete,
  children,
  ...props
}) => (
  <FitBox {...props}>
    {isEditing && (
      <CloseButton borderWidth={0} onClick={onDelete}>
        <SvgIcon name="delete" />
      </CloseButton>
    )}
    {children}
  </FitBox>
);

export const BmiItem = ({ value, ...props }) => {
  const bmiStat = getBmiStat(value);
  return (
    <EditableBox bg={`bmi${bmiStat}`} {...props}>
      <Tooltip
        animation="scale"
        delay={[300, 0]}
        duration={50}
        content={'bmi'}
        theme="light-border"
        placement="bottom"
      >
        <span>
          <FitText color="darkGray">{value}</FitText>
        </span>
      </Tooltip>
    </EditableBox>
  );
};

export const WeightItem = ({ value, ...props }) => {
  return (
    <EditableBox {...props}>
      <Tooltip
        animation="scale"
        delay={[300, 0]}
        duration={50}
        content={'lbs'}
        theme="light-border"
        placement="bottom"
      >
        <span>
          <FitText color="darkGray">{value}</FitText>
        </span>
      </Tooltip>
    </EditableBox>
  );
};

const FitnessItem = ({
  category,
  data,
  onClick,
  onDelete,
  isEditing,
  ...props
}) => {
  const handleDelete = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (onDelete) {
      onDelete();
    }
  };

  if (!data) {
    return <EmptyBox {...props}></EmptyBox>;
  }

  if (category === 'bmi') {
    return (
      <BmiItem
        {...data}
        isEditing={isEditing}
        onDelete={handleDelete}
        onClick={onClick}
        {...props}
      />
    );
  }

  if (category === 'weight') {
    return (
      <WeightItem
        {...data}
        isEditing={isEditing}
        onDelete={handleDelete}
        onClick={onClick}
        {...props}
      />
    );
  }

  const { thumbUrl, assetType } = data;

  if (thumbUrl) {
    let url = isImgix(thumbUrl) ? `${thumbUrl}?w=56&h=56&fit=crop` : thumbUrl;
    return (
      <EditableBox
        isEditing={isEditing}
        onDelete={handleDelete}
        onClick={onClick}
        {...props}
      >
        <FitImage src={url} alt="fit" />
        {assetType === 'video' && (
          <FitOverlay>
            <SvgIcon name="play-filled" />
          </FitOverlay>
        )}
      </EditableBox>
    );
  }

  return (
    <EditableBox
      isEditing={isEditing}
      onDelete={handleDelete}
      onClick={onClick}
      {...props}
    >
      <FitIcon name={`fit-${category}`} alt="fit" />
    </EditableBox>
  );
};

export default FitnessItem;
