import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import styled from 'styled-components';

import { Box, Button, Body1 } from 'components/core';
import { InputField } from 'components/form';

import AppActions from 'redux/AppRedux';
import API from 'services/api';
import { colors } from 'styles/theme';

const Wrapper = styled(Box)`
  width: 592px;
  min-height: 272px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`;

const BorderWrapper = styled(Box)`
  flex: 1;
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid ${colors.blue};
  box-sizing: border-box;
  border-radius: 2px;

  padding-left: 94px;
  padding-right: 94px;
`;

const validationSchema = object().shape({
  code: string().required('Please enter your code'),
});

class InviteForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  };

  handleSubmit = async (values, actions) => {
    const { onSubmit } = this.props;
    const { setSubmitting, setErrors } = actions;
    setSubmitting(true);

    try {
      const result = await API.checkInviteCode(values);
      if (result) {
        onSubmit();
      } else {
        setErrors({
          code: 'Please enter a valid code',
        });
      }
    } catch (err) {
      setErrors({
        code: err.message,
      });
    }
    setSubmitting(false);
  };

  renderForm = ({ isValid, isSubmitting }) => {
    return (
      <Form>
        <Wrapper bg="main">
          <BorderWrapper m={2} p={6}>
            <Body1 textAlign="center" mb={6} color="white">
              Bcult is currently invitation only. Enter your invite code below
            </Body1>
            <Field component={InputField} mb={4} name="code" type="text" />
            <Button type="submit" disabled={!isValid || isSubmitting}>
              Next
            </Button>
          </BorderWrapper>
        </Wrapper>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{ code: '' }}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
        render={this.renderForm}
        isInitialValid={false}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: () => {
    dispatch(AppActions.setInvitePassed(true));
  },
});

export default connect(null, mapDispatchToProps)(InviteForm);
