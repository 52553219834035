import React from 'react';
import styled from 'styled-components';
import { Box } from 'components/core';

import { Rating1, Rating2, Rating3, Rating4 } from './RatingIcon';

const ICON_SIZE = {
  small: [
    {
      width: 9,
      height: 24,
    },
    {
      width: 16,
      height: 24,
    },
    {
      width: 20,
      height: 24,
    },
    {
      width: 25,
      height: 24,
    },
  ],
  big: [
    {
      width: 25,
      height: 64,
    },
    {
      width: 42,
      height: 64,
    },
    {
      width: 52,
      height: 64,
    },
    {
      width: 64,
      height: 64,
    },
  ],
};

const IconRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: ${(props) => (props.size === 'small' ? '110px' : '392px')};
  height: ${(props) => (props.size === 'small' ? '24px' : '64px')};

  @media (max-width: 768px) {
    width: ${(props) => (props.size === 'small' ? '110px' : '100%')};
  }
`;

const Rating = ({
  size = 'small',
  value,
  disabled = false,
  onChange,
  onClick,
  ...props
}) => {
  const handleClick = (name) => {
    if (onChange) onChange(name);
  };

  const handleClickRow = () => {
    if (onClick) onClick();
  };

  const ICON_SET = ICON_SIZE[size];

  return (
    <IconRow {...props} size={size} onClick={handleClickRow}>
      <Rating1
        disabled={disabled}
        selected={value === 1}
        onClick={() => handleClick(1)}
        {...ICON_SET[0]}
      />
      <Rating2
        disabled={disabled}
        selected={value === 2}
        onClick={() => handleClick(2)}
        {...ICON_SET[1]}
      />
      <Rating3
        disabled={disabled}
        selected={value === 3}
        onClick={() => handleClick(3)}
        {...ICON_SET[2]}
      />
      <Rating4
        disabled={disabled}
        selected={value === 4}
        onClick={() => handleClick(4)}
        {...ICON_SET[3]}
      />

      {/* {RATING_OPTIONS.map((rating) => (
        <RatingIcon
          disabled={disabled}
          key={`rating-${rating}`}
          name={`rating-${rating}`}
          selected={value === rating}
          onClick={() => handleClick(rating)}
          {...ICON_SET[rating - 1]}
        />
      ))}
      <FullMoonIcon
        key={`rating-${4}`}
        name={`rating-${4}`}
        selected={value === 4}
        onClick={() => handleClick(4)}
        disabled={disabled}
        {...ICON_SET[3]}
      /> */}
    </IconRow>
  );
};

export default Rating;
