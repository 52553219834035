import React, { useState } from 'react';

import { Box, DialogBox } from 'components/core';
import { AdminProgramCard } from 'containers/programs';
import { ConfirmModal } from 'components/others';
import {
  RatingFormModal,
  RatingModal,
  SummaryModal,
  SetPriceModal,
  PaymentDescriptionModal,
} from 'modals';
import { TrackingPlayerModal } from 'components/player';

import API from 'services/api';
import {
  transformProgram,
  transformProgramForAnalytics,
} from 'utils/transform';
import { PROGRAM_STATUS } from 'services/terms';
import Analytics, { EVENTS } from 'services/analytics';
import ShareUploadForm from 'containers/program/ShareUploadForm';

const ACTION_TYPE = {
  DELETE: 'delete',
  APPROVE: 'approve',
  DECLINE: 'decline',
  FEATURED: 'featured',
  UNFEATURED: 'unfeatured',
};

const TITLES = {
  [ACTION_TYPE.DELETE]: 'Would you like to delete this program?',
  [ACTION_TYPE.APPROVE]: 'Would you like to approve this program?',
  [ACTION_TYPE.DECLINE]: 'Would you like to decline this program?',
  [ACTION_TYPE.FEATURED]: 'Would you like to set this program as featured?',
  [ACTION_TYPE.UNFEATURED]:
    'Would you like to unset this program from featured?',
};

export function AdminProgramList({
  currentView,
  programs,
  updateMyProgram,
  deleteMyProgram,
  ...props
}) {
  const [show, setAlertShow] = useState(false);
  const [showGoldModal, setGoldModalShow] = useState(false);

  const [actionType, setActionType] = useState(ACTION_TYPE.APPROVE);

  const [actionItem, setActionItem] = useState(null);
  const [goldPrice, setGoldPrice] = useState(0);

  const [currentRating, setCurrentRating] = useState(1);
  const [paymentDescription, setPaymentDescription] = useState(1);
  const [currentProgramId, setCurrentProgramId] = useState('');
  const [currentStats, setCurrentStats] = useState(1);
  const [shareImg, setShareImg] = useState('');

  const [showSummary, setShowSummary] = useState(false);
  const [showRatingView, setShowRatingView] = useState(false);
  const [showRatingForm, setShowRatingForm] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const [itemData, setItemData] = useState(false);
  const [itemDataCategory, setItemDataCategory] = useState(false);
  const [position, setPosition] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmTitle = TITLES[actionType];

  const onDelete = (programInfo) => {
    setActionType(ACTION_TYPE.DELETE);
    setActionItem(programInfo);
    setAlertShow(true);
  };

  const onGold = (programInfo) => {
    const { price } = programInfo;
    setGoldPrice(price);
    setActionItem(programInfo);
    setGoldModalShow(true);
  };

  const onTrackComplete = (newRecordId) => {
    if (!itemData) {
      return;
    }

    setItemData({
      ...itemData,
      record: newRecordId,
    });
  };

  const onView = (
    programInfo,
    category,
    value,
    sectionIndex,
    day,
    currentDay
  ) => {
    const { id: programId, program, status } = programInfo;

    const trackable = status === PROGRAM_STATUS.CURRENT && +day <= +currentDay;

    const { sectionIds } = program;
    const sectionId = sectionIds[sectionIndex];

    setItemData(value);
    setPosition({ programId, sectionIndex, day, sectionId, trackable });
    setItemDataCategory(category);
  };

  const onViewSummary = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    setCurrentProgramId(programId);
    setCurrentStats(stats);
    setShowSummary(true);
  };

  const onViewRating = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    const { avgRating } = stats || {};
    setCurrentProgramId(programId);
    setCurrentRating(avgRating);
    setShowRatingView(true);
  };

  const onViewPaymentDescription = (programInfo) => {
    const { id: programId, paymentDescription: text } = programInfo;
    setCurrentProgramId(programId);
    setPaymentDescription(text);
    setShowPayment(true);
  };

  const onAddShareImage = (programInfo) => {
    const { id: programId, shareImage } = programInfo;
    setCurrentProgramId(programId);
    setShareImg(shareImage);
    setShowShare(true);
  };

  const onDismissAlert = () => {
    setAlertShow(false);
    setActionItem(null);
  };

  const onConfirmPrice = async (newPrice) => {
    if (newPrice === goldPrice) {
      return;
    }
    setGoldPrice(newPrice);
    setIsSubmitting(true);
    const { id: programId } = actionItem;
    try {
      const newProgram = await API.setPriceToProgram(programId, {
        price: newPrice,
      });
      const transformed = transformProgram(newProgram);
      updateMyProgram(transformed);
    } catch (err) {
      console.log(err);
    }

    setGoldModalShow(false);
    setIsSubmitting(false);
  };

  const handleAction = (newActionType, newActionInfo) => {
    setActionType(newActionType);
    setActionItem(newActionInfo);
    setAlertShow(true);
  };

  const onConfirm = async () => {
    if (actionType === ACTION_TYPE.DELETE) {
      const { id: programId } = actionItem;
      try {
        await API.deleteProgram(programId);
        deleteMyProgram(programId);
        Analytics.track(EVENTS.PROGRAM_DELETED, {
          by: 'admin',
          ...transformProgramForAnalytics(actionItem),
        });
        setAlertShow(false);
        setActionItem(null);
      } catch (err) {
        setAlertShow(false);
        setActionItem(null);
        // const error = getErrors(err);

        console.log(JSON.stringify(err));
      }
    } else {
      const { id: programId } = actionItem;
      const payload = {};
      if (actionType === ACTION_TYPE.APPROVE) {
        payload.approved = true;
      } else if (actionType === ACTION_TYPE.DECLINE) {
        payload.approved = false;
      } else if (actionType === ACTION_TYPE.FEATURED) {
        payload.featured = true;
      } else if (actionType === ACTION_TYPE.UNFEATURED) {
        payload.featured = false;
      }

      try {
        const newProgram = await API.updateProgram(programId, payload);
        const transformed = transformProgram(newProgram);

        Analytics.track(EVENTS.PROGRAM_UPDATED, {
          by: 'admin',
          ...transformProgramForAnalytics(transformed),
          actionType,
        });

        updateMyProgram(transformed);
        setAlertShow(false);
        setActionItem(null);
      } catch (err) {
        setAlertShow(false);
        setActionItem(null);

        console.error(err);
      }
    }

    setAlertShow(false);
    setActionItem(null);
  };

  const onShareUpload = async (newUrl) => {
    setIsSubmitting(true);
    try {
      const newProgram = await API.updateProgram(currentProgramId, {
        shareImage: newUrl,
      });
      const transformed = transformProgram(newProgram);
      updateMyProgram(transformed);
    } catch (err) {
      console.log(err);
    }

    // setShowShare(false);
    setShareImg(newUrl);
    setIsSubmitting(false);
  };

  return (
    <Box {...props}>
      {programs.map((programInfo) => {
        return (
          <AdminProgramCard
            key={programInfo.id}
            mb={6}
            {...programInfo}
            onGold={() => onGold(programInfo)}
            onAction={(newAction) => handleAction(newAction, programInfo)}
            onDelete={() => onDelete(programInfo)}
            onAddShareImage={() => onAddShareImage(programInfo)}
            onViewSummary={() => onViewSummary(programInfo)}
            onViewRating={() => onViewRating(programInfo)}
            onViewPaymentDescription={() =>
              onViewPaymentDescription(programInfo)
            }
            onView={(category, value, sectionIndex, day, currentDay) =>
              onView(
                programInfo,
                category,
                value,
                sectionIndex,
                day,
                currentDay
              )
            }
          />
        );
      })}
      <ConfirmModal
        show={show}
        text={confirmTitle}
        onCancel={onDismissAlert}
        onOk={onConfirm}
      />
      <SetPriceModal
        isLoading={isSubmitting}
        show={showGoldModal}
        value={goldPrice}
        onCancel={() => setGoldModalShow(false)}
        onOk={onConfirmPrice}
      />
      <RatingFormModal
        show={showRatingForm}
        programId={currentProgramId}
        rating={currentRating}
        onOk={(newProgramId) => {
          setShowRatingForm(false);
        }}
      />
      <RatingModal
        show={showRatingView}
        programId={currentProgramId}
        rating={currentRating}
        onOk={() => setShowRatingView(false)}
      />
      <PaymentDescriptionModal
        show={showPayment}
        programId={currentProgramId}
        text={paymentDescription}
        onOk={() => setShowPayment(false)}
      />
      <SummaryModal
        show={showSummary}
        programId={currentProgramId}
        stats={currentStats}
        onOk={() => setShowSummary(false)}
      />
      <TrackingPlayerModal
        position={position}
        show={!!itemData}
        data={itemData}
        category={itemDataCategory}
        onTrackComplete={onTrackComplete}
        onClose={() => setItemData(null)}
      />
      {showShare && (
        <DialogBox
          show={showShare}
          onClickOutside={() => setShowShare(false)}
          hasClose={false}
        >
          <ShareUploadForm
            id={currentProgramId}
            initialUrl={shareImg}
            isLoading={isSubmitting}
            onCancel={() => setShowShare(false)}
            onSubmit={onShareUpload}
          />
        </DialogBox>
      )}
    </Box>
  );
}

export default AdminProgramList;
