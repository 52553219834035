import React from 'react';
import styled from 'styled-components';
import { Box, SvgIcon, IconButton, Tooltip } from 'components/core';
import { ProgramGraph } from 'components/program';
import { colors } from 'styles/theme';
import { PROGRAM_TYPE } from 'services/terms';

export const Divider = styled(Box)`
  background-color: ${colors.text};
  opacity: 0.8;
  height: 1px;
  align-self: stretch;
`;

const Wrapper = styled(Box)`
  border-radius: 2px;
  border: 1px solid ${colors.text};
`;

const ProgramCreateCard = ({
  createType,
  program,
  onAdd,
  onView,
  onFinalize,
  onDelete,
  onSave,
  onSelectRow,
  selectedRow,
  onMoveUpRow,
  onMoveDownRow,
  onDeleteRow,
  ...props
}) => {
  const isFullMode = createType !== 'new';

  return (
    <Wrapper {...props}>
      <ProgramGraph
        isEditing
        onAdd={onAdd}
        onView={onView}
        {...program}
        type={isFullMode ? PROGRAM_TYPE.FULL : PROGRAM_TYPE.ONGOING}
        onSelectRow={onSelectRow}
        selectedRow={selectedRow}
        onDelete={onDelete}
        onDeleteRow={onDeleteRow}
      />
      <Divider />

      <Box
        display="flex"
        flexDirection="row"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="space-between"
        height={64}
        py={2}
        px={3}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          height={64}
          py={2}
          pr={3}
        >
          {selectedRow && (
            <IconButton
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              borderWidth={1}
              mr={3}
              onClick={onMoveDownRow}
            >
              <SvgIcon name="down-arrow" />
            </IconButton>
          )}

          {selectedRow && (
            <IconButton
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              borderWidth={1}
              mr={3}
              onClick={onMoveUpRow}
              disabled={selectedRow <= 1}
            >
              <SvgIcon name="up-arrow" />
            </IconButton>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          height={64}
        >
          <Tooltip
            animation="scale"
            delay={[300, 0]}
            duration={300}
            content={isFullMode ? 'Finalize' : 'Save and begin now'}
            theme="light-border"
            placement="top"
          >
            <span>
              <IconButton
                size="toolbar"
                borderWidth={0}
                mr={3}
                onClick={onFinalize}
                p={0}
              >
                <SvgIcon name="toolbar-finalize" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            animation="scale"
            delay={[300, 0]}
            duration={300}
            content={
              isFullMode ? 'Save and finalize later' : 'Save and begin later'
            }
            theme="light-border"
            placement="top"
          >
            <span>
              <IconButton size="toolbar" borderWidth={0} onClick={onSave} p={0}>
                <SvgIcon name="toolbar-save" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default ProgramCreateCard;
