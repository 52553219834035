import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Select, InputFeedback } from 'components/core';

function SelectField(props) {
  const { field, label, form, renderAddon, ...rest } = props;
  const formError = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const handleChange = (newVal) => {
    form.setFieldTouched(field.name);
    form.setFieldValue(field.name, newVal.value);
  };

  const hasError = !!(touched && formError);

  return (
    <React.Fragment>
      <Select
        placeholder={label}
        error={hasError}
        disabled={form.isSubmitting}
        value={field.value}
        {...field}
        {...rest}
        onChange={handleChange}
      />
      {hasError && <InputFeedback>{formError}</InputFeedback>}
    </React.Fragment>
  );
}

SelectField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
};

export default SelectField;
