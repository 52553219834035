import React from 'react';
import { Box, DialogBox, Body1, Button } from 'components/core';

function NoticeModal({ show, text, onOk, onCancel, okText = 'Ok', ...props }) {
  return (
    <DialogBox show={show} onClickOutside={onCancel || onOk} hasClose={false}>
      <Box {...props} m={[5, 8]}>
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
        >
          <Body1 textAlign="center">{text}</Body1>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={6}
        >
          <Button variant="secondary" mx={4} onClick={onOk}>
            {okText}
          </Button>
        </Box>
      </Box>
    </DialogBox>
  );
}

export default NoticeModal;
