import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash-es/get';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import styled from 'styled-components';

import {
  Box,
  Button,
  Body6Dark,
  InputFeedback,
  BcultLogo,
} from 'components/core';
import { InputField } from 'components/form';

import AuthActions from 'redux/AuthRedux';
import API from 'services/api';
import { colors } from 'styles/theme';
import { getErrors } from 'utils/err';

import FacebookImage from 'assets/Facebook.png';
import { login as loginWithFacebook, generatePayload } from 'services/facebook';
import Analytics, { EVENTS } from 'services/analytics';

const Image = styled.img`
  margin-right: 8px;
`;

const Wrapper = styled(Box)`
  width: 592px;
  max-width: 592px;
  min-height: 368px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 5px;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const OrContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const Divider = styled(Box)`
  background-color: ${colors.line};
  opacity: 0.6;
  height: 1px;
`;

const MainSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const ForgotLink = styled(Link)`
  color: ${colors.text};
  font-size: 11px;
`;

const validationSchema = object().shape({
  email: string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  password: string()
    .required('Please enter a password')
    .min(6, 'Please enter a password 6 digits or longer'),
});

class LoginForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    setFacebook: PropTypes.func,
  };

  componentDidMount() {
    Analytics.track(EVENTS.LOGIN_FORM_VIEWED);
  }

  handleFacebookLogin = async (setSubmitting, setErrors) => {
    const { onSubmit, setFacebook } = this.props;
    setSubmitting(true);
    try {
      const response = await loginWithFacebook();
      const values = generatePayload(response);

      const data = await API.loginWithSocial(values);
      onSubmit(data);
    } catch (err) {
      // @TODO if modal, openup register form
      const respData = get(err, 'response.data');
      if (respData && respData.email && respData.facebookId) {
        const newState = {
          facebookToken: respData.token,
          email: respData.email,
          facebookId: respData.facebookId,
        };

        setFacebook(newState);
      }
    }

    setSubmitting(false);
  };

  handleSubmit = async (values, actions) => {
    const { onSubmit } = this.props;
    const { setSubmitting, setStatus } = actions;
    setSubmitting(true);

    try {
      const data = await API.loginWithEmail(values);
      onSubmit(data);
    } catch (err) {
      const error = getErrors(err);
      setStatus({
        message: error.message || 'Invalid credentials',
      });
    }
    setSubmitting(false);
  };

  renderForm = ({
    isValid,
    isSubmitting,
    setSubmitting,
    setErrors,
    status,
  }) => {
    return (
      <Form>
        <Wrapper>
          <BcultLogo />
          <MainSection mt={10}>
            <Field
              component={InputField}
              mb={4}
              name="email"
              type="email"
              label="Email Address"
            />
            <Field
              component={InputField}
              mb={4}
              name="password"
              type="password"
              label="Password"
            />
            {status && status.message && (
              <InputFeedback> {status.message} </InputFeedback>
            )}
            <Button type="submit" disabled={!isValid || isSubmitting} width={1}>
              Enter
            </Button>
            <Box display="flex" justifyContent="flex-end" width={1} mt={2}>
              <ForgotLink to="/auth/reset-password">
                Forgot Password?
              </ForgotLink>
            </Box>

            <OrContainer py={2} mt={6} mb={4}>
              <Divider flex={1} />
              <Body6Dark color="line" px={4}>
                OR
              </Body6Dark>
              <Divider flex={1} />
            </OrContainer>

            <Button
              type="button"
              disabled={isSubmitting}
              variant="facebook"
              fontSize={1}
              size="facebook"
              onClick={() => this.handleFacebookLogin(setSubmitting, setErrors)}
            >
              <Image src={FacebookImage} alt="facebook" />
              Login with Facebook
            </Button>
          </MainSection>
        </Wrapper>
      </Form>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
        render={this.renderForm}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: ({ token, user }) => {
    dispatch(AuthActions.setUser(user));
    dispatch(AuthActions.setLoggedIn(token));
  },
});

export default connect(null, mapDispatchToProps)(LoginForm);
