import React from 'react';
import styled from 'styled-components';
import { Box, Caption2, Body2, SvgIcon } from 'components/core';
import { Link } from 'react-router-dom';
import { colors } from 'styles/theme';
import Avatar from './Avatar';

const CustomLink = styled.a`
  padding: 10px;
  border: 1px solid ${colors.line};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const ProgramProfile = ({
  densed = false,
  name = '',
  description = '',
  avatar,
  allowShare = false,
  profile,
  id,
  ...props
}) => {
  let shortDescription = description.substr(0, 70);

  if (shortDescription.length < description.length) {
    shortDescription = `${shortDescription}...`;
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      flex="unset"
      alignSelf="stretch"
      {...props}
    >
      <Link to={`/users/${id}`}>
        <Avatar mr={[2, 6]} name={name} {...avatar} />
      </Link>
      <Box mr={[2, 6]} maxWidth={450}>
        <Caption2 as={Link} to={`/users/${id}`}>
          {name}
        </Caption2>
        <Body2>{densed ? shortDescription : description}</Body2>
      </Box>
      {allowShare && (
        <Box
          mr={[0, 6]}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="flex-end"
          minWidth={`82px`}
        >
          {profile.facebook && (
            <CustomLink target="_blank" href={profile.facebook}>
              <SvgIcon name="facebook" />
            </CustomLink>
          )}
          {profile.instagram && (
            <CustomLink
              target="_blank"
              href={`https://instagram.com/${profile.instagram}`}
            >
              <SvgIcon name="instagram" />
            </CustomLink>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProgramProfile;
