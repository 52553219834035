import React, { useRef } from 'react';
import get from 'lodash-es/get';
import Chart from 'react-chartjs-2';
import ChartContainer from './ChartContainer';

import { colors } from 'styles/theme';
import { convertToRangedScale } from 'services/graph';

const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const HEIGHT = 350;
const STEP_SIZE = 0.5;
const RANGE_COUNT = 4;
const REGION_HEIGHT = HEIGHT / RANGE_COUNT;
const TICK_SIZES = [REGION_HEIGHT, REGION_HEIGHT, REGION_HEIGHT, REGION_HEIGHT];
const Y_LABELS = ['50', '30', '5', '1'];
const RANGES = [
  [0, 1],
  [1, 5],
  [5, 30],
  [30, 50],
];

const RepSetBarChart = ({
  isBigGraph,
  containerSize,
  recordData,
  sectionType,
  ...props
}) => {
  const chartRef = useRef();

  const sectionData = get(recordData, sectionType);
  const xLabels = get(sectionData, 'labels', DAYS);
  const graphData = get(sectionData, 'data', []);
  const infoData = get(sectionData, 'info', {});
  const { title } = infoData;

  const datasets = [];

  graphData.forEach((s) => {
    const sets = s.dataValues.map((d) => d.sets);
    const reps = s.dataValues.map((d) => d.reps);

    datasets.push({
      dataValues: sets,
      label: `${s.label} (sets)`,
    });
    datasets.push({
      dataValues: reps,
      label: `${s.label} (reps)`,
    });
  });

  const data = {
    labels: xLabels,
    datasets: datasets.map((dataset, index) => {
      return {
        data: dataset.dataValues.map((v, i) =>
          v === 0
            ? undefined
            : index % 2
            ? convertToRangedScale(v, RANGES) -
              convertToRangedScale(datasets[index - 1].dataValues[i], RANGES)
            : convertToRangedScale(v, RANGES)
        ),
        stack: `stack ${Math.floor(index / 2)}`,
        label: dataset.label,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        fill: false,
        steppedLine: true,
        spanGaps: false,
        minHeight: 5,
      };
    }),
  };

  const options = {
    responsive: false,
    legend: {
      display: false,
    },
    hover: {
      animationDuration: 0,
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: () => null,
        label: function (tooltipItem, data, i) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }

          label +=
            datasets[tooltipItem.datasetIndex].dataValues[tooltipItem.index];
          return label;
        },
      },
    },
    title: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: 100,
            min: 0,
            stepSize: STEP_SIZE,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            max: 15,
            min: 1,
            stepSize: 1,
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: -10,
      },
    },
    animation: {
      easing: 'linear',
      onComplete: function () {
        var ctx = this.chart.ctx;
        var chartInstance = this.chart;

        ctx.font = '500 14px Arial';
        ctx.textBaseline = 'bottom';

        const totalDataSetLength = this.data.datasets.length;
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            if (data > 0 && totalDataSetLength > 0) {
              ctx.save();
              ctx.translate(bar._model.x, bar._model.y);
              ctx.fillStyle = colors.text;
              ctx.textAlign = 'center';
              ctx.fillText(i % 2 ? 'R' : 'S', 0, 9);
              ctx.restore();

              ctx.save();
              ctx.beginPath();
              ctx.strokeStyle = colors.text;
              ctx.arc(bar._model.x, bar._model.y, 10, 0, 2 * Math.PI);
              ctx.stroke();
              ctx.restore();
            }
          });
        });
      },
    },
  };

  return (
    <ChartContainer
      title="Sets/Reps"
      tickSizes={TICK_SIZES}
      xLabels={xLabels}
      yLabels={Y_LABELS}
      bottomTitle={title}
      {...props}
    >
      <Chart
        ref={chartRef}
        type="bar"
        width={1070}
        height={350}
        data={data}
        options={options}
      />
    </ChartContainer>
  );
};

export default RepSetBarChart;
