import styled from 'styled-components';
import { Box } from 'components/core';

const LoadingContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default LoadingContainer;
