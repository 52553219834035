import React from 'react';
import VideoPlayer from './VideoPlayer';
import ImagePlayer from './ImagePlayer';

function Player({ data, category, ...props }) {
  const { assetType, thumbUrl, assetUrl } = data || {};

  const renderPlayer = () => {
    switch (assetType) {
      case 'video':
        return <VideoPlayer src={assetUrl} thumb={thumbUrl} {...props} />;
      case 'image':
        return <ImagePlayer src={assetUrl} category={category} {...props} />;
      default:
    }

    return null;
  };
  if (data) {
    return renderPlayer();
  }

  return null;
}

export default Player;
