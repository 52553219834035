import React from 'react';
import styled from 'styled-components';
import { Box, Collapsible, ProfileText, Body2, Body4 } from 'components/core';
import { colors } from 'styles/theme';

export const Divider = styled(Box)`
  background-color: ${colors.line};
  opacity: 0.8;
  height: 1px;
  align-self: stretch;
`;

const Wrapper = styled(Box)`
  width: ${props => props.width}px;
  border: 1px solid ${colors.line};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${props => props.width}px;
`;

const TitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 40px;
`;

const TextWrapper = styled(Box)`
  align-self: stretch;
`;

const StyledCollapsible = styled(Collapsible)`
  width: ${props => props.width}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

function AboutView({ profile, height, width, collapsible, ...props }) {
  const { username, description } = profile;
  const content = (
    <ContentWrapper width={width}>
      <TitleWrapper>
        <Body2 mr={1}>About</Body2>
        <ProfileText>{username}</ProfileText>
      </TitleWrapper>
      <Divider />
      <TextWrapper p={2} minHeight={height - 26 - 41}>
        <Body4>{description}</Body4>
      </TextWrapper>
    </ContentWrapper>
  );

  return (
    <Wrapper width={width} {...props}>
      {collapsible ? <StyledCollapsible width={width} maxHeight={height - 26} darkMode>
        {content}
      </StyledCollapsible> : content}
    </Wrapper>
  );
}

AboutView.defaultProps = {
  height: 160,
  width: 280,
  collapsible: true,
};

export default AboutView;
