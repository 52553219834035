import React from 'react';
import { Box, Title1 } from 'components/core';
import styled from 'styled-components';
import { colors } from 'styles/theme';

import {
  ProgramToolbar,
  ProgramProfile,
  ProgramGraph,
} from 'components/program';
import { GraphWrapper } from 'components/program/CommonStyled';

import { PROGRAM_TYPE } from 'services/terms';

const Wrapper = styled(Box)`
  border-radius: 2px;
  border: 1px solid ${colors.text};
`;

const DraftProgramCard = ({
  title,
  user,
  description,
  stats,
  type,
  program,
  onFinalize,
  onEdit,
  onDelete,
  onView,
  ...props
}) => {
  const toolbarOptions =
    type === PROGRAM_TYPE.FULL
      ? [
          {
            name: 'edit',
            help: 'Edit',
          },
          {
            name: 'gold',
            help: 'Offer gold',
          },
          {
            name: 'promote',
            help: 'Promote',
          },
          {
            name: 'finalize',
            help: 'Finalize',
          },
          {
            name: 'delete',
            help: 'Delete',
          },
        ]
      : [
          {
            name: 'edit',
            help: 'Edit',
          },
          {
            name: 'finalize',
            help: 'Finalize',
          },
          {
            name: 'delete',
            help: 'Delete',
          },
        ];

  const handleSelectTool = (name) => {
    switch (name) {
      case 'edit':
        onEdit();
        return;
      case 'delete':
        onDelete();
        return;
      case 'finalize':
        onFinalize();
        return;
      default:
    }
  };

  return (
    <GraphWrapper {...props}>
      <Box mb={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="flex-end"
        >
          <Title1 color="text" as="a" name={props.id}>
            {title}
          </Title1>
        </Box>

        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <ProgramProfile {...user} description={description} />
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="flex-end"
          >
            <ProgramToolbar
              options={toolbarOptions}
              onSelect={handleSelectTool}
            />
          </Box>
        </Box>
      </Box>
      <Wrapper>
        <ProgramGraph type={type} {...program} onView={onView} />
      </Wrapper>
    </GraphWrapper>
  );
};

export default DraftProgramCard;
