import React, { useState } from 'react';
import styled from 'styled-components';
import isObject from 'lodash-es/isObject';
import Box from './Box';
import { Body2 } from './Typography';
import SvgIcon from './SvgIcon';

import { colors } from 'styles/theme';

const Wrapper = styled(Box)`
  position: relative;
  box-shadow: inset 0px 1px 1px 0px
      ${(props) => (props.error ? colors.red : colors.line)},
    inset 0px 0px 1px 1px ${(props) => (props.error ? colors.red : colors.line)};
  outline: 0;
  cursor: pointer;
`;

const OptionWrapper = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${colors.background};
  border: 1px solid ${colors.text};
  box-sizing: border-box;
  border-radius: 2px;
  z-index: 99;
  max-height: 200px;
  overflow-y: auto;
`;

const Option = styled(Box)`
  cursor: pointer;
  min-height: 48px;
  background: ${(props) => (props.selected ? colors.text : colors.background)};
  &:hover {
    background: ${(props) => (props.selected ? colors.text : colors.line)};
  }
`;

const Placeholder = styled(Body2)`
  opacity: 0.8;
  user-select: none;
`;

const Icon = styled(SvgIcon)`
  position: absolute;
  top: 19px;
  right: 12px;
`;

const getLabel = (options, value) => {
  const found = options.find((item) => item.value === value);

  const { label } = found || {};

  return label || value;
};

function Select({
  placeholder = 'select',
  options = [],
  tabIndex = 0,
  onChange,
  value,
  color,
  disabled,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const currentLabel = getLabel(options, value);

  const onClickOption = (option) => {
    if (onChange) {
      onChange(option);
    }
  };

  const onClick = () => {
    if (disabled) {
      return;
    }
    setOpen(!open);
  };

  const onBlur = () => {
    setOpen(false);
  };

  return (
    <Wrapper
      p={3}
      bg="clear"
      width="176px"
      tabIndex={tabIndex}
      onClick={onClick}
      onBlur={onBlur}
      {...props}
    >
      {!value ? (
        <Placeholder>{placeholder}</Placeholder>
      ) : (
        <Body2 color={color}>{currentLabel}</Body2>
      )}
      <Icon name="chevron-down" />
      {open && (
        <OptionWrapper>
          {options.map((option) => {
            let optionValue;
            let optionLabel;
            if (isObject(option)) {
              optionValue = option.value;
              optionLabel = option.label;
            } else {
              optionValue = option;
              optionLabel = option;
            }
            const isSelected = value === optionValue;
            return (
              <Option
                p={3}
                key={optionValue}
                selected={isSelected}
                onClick={() => onClickOption(option)}
              >
                <Body2 color={isSelected ? 'contrastText' : 'text'}>
                  {optionLabel}
                </Body2>
              </Option>
            );
          })}
        </OptionWrapper>
      )}
    </Wrapper>
  );
}

export default Select;
