import React, { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash-es/get';
import upperFirst from 'lodash-es/upperFirst';
import { useDispatch } from 'react-redux';
import {
  Box,
  Body2Dark,
  CalendarText,
  SvgIcon,
  Checkbox,
} from 'components/core';
import { ConfirmModal } from 'components/others';
import { DefaultLoader } from 'components/loading';

import ProfileActions from 'redux/ProfileRedux';
import API from 'services/api';

import { colors } from 'styles/theme';

import { getSummaryLines } from 'utils/form';
import NoteView from './NoteView';

const Wrapper = styled(Box)`
  border-top: 1px solid ${colors.text};
  display: flex;
  flex-direction: row;
  align-self: stretch;
  @media (max-width: 768px) {
    flex-direction: column;
    flex: 1;
  }
`;

const SideBar = styled(Box)`
  width: 376px;
  border-right: 1px solid ${colors.text};
  height: 521px;
  overflow-y: scroll;

  @media (max-width: 768px) {
    overflow-y: scroll;
    width: 100%;
    height: unset;
    border-bottom: 1px solid ${colors.text};
    border-right: 0;
  }
`;

const MainContent = styled(Box)`
  flex: 1;
  position: relative;

  display: flex;
  flex-direction: column;
`;

const TaskItem = styled(Box)`
  display: flex;
  align-items: center;
  height: 50px;
  color: ${(props) => (props.selected ? colors.contrastText : colors.text)};
  background: ${(props) => (props.selected ? colors.text : colors.transparent)};
  cursor: pointer;
`;

const NoteIconContainer = styled(Box)`
  position: absolute;
  right: 20px;
  bottom: 10px;
  cursor: pointer;

  path {
    fill: ${(props) => (props.selected ? colors.main : colors.text)};
  }
`;

const CONFIRM_TITLE = {
  complete: 'Are you sure you want to mark this task as complete?',
  incomplete: 'Are you sure you want to mark this task as incomplete?',
};

function TaskView({
  tasksMap,
  year: yearNum,
  month: monthNum,
  date: dateNum,
  ...props
}) {
  const path = `${yearNum}-${monthNum}.${dateNum}`;
  const taskList = get(tasksMap, path, []);
  const [show, setShow] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const selectedTask = taskList[selectedIndex];

  const { summaryTitle, summaryLines } = getSummaryLines(
    get(selectedTask, 'category'),
    selectedTask
  );
  const note = get(selectedTask, 'note');

  const noSummary = !summaryLines.length && !note;

  const handleTaskCompleteChange = async () => {
    if (!show || loading) return;
    setLoading(true);

    try {
      let promise;
      if (show.record) {
        promise = API.deleteRecord(show.programId, show.record);
      } else {
        promise = API.completeAction(show.programId, show.sectionId, show.id);
      }
      setShow(null); // immediately close modal before waiting for promise
      const resp = await promise;
      dispatch(
        ProfileActions.updateProgramAction(
          show.programId,
          show.sectionId,
          show.day,
          {
            record: resp.record,
          }
        )
      );
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <Wrapper mt={4} {...props}>
      <SideBar>
        {taskList.map((item, index) => {
          const { category, id } = item;
          return (
            <TaskItem
              key={id}
              selected={index === selectedIndex}
              onClick={() => setSelectedIndex(index)}
              px={3}
            >
              <Box mr={2}>
                {loading ? (
                  <DefaultLoader size={20} />
                ) : (
                  <Checkbox
                    variant="tick"
                    size="sm"
                    checked={!!item.record}
                    onChange={() => setShow(item)}
                  />
                )}
              </Box>
              {upperFirst(category)}
            </TaskItem>
          );
        })}
      </SideBar>
      <MainContent px={[2, 7]} py={[2, 5]}>
        {!selectedTask && (
          <NoteView year={yearNum} month={monthNum} date={dateNum} />
        )}

        {!noSummary && (
          <Box p={6}>
            {summaryTitle && <Body2Dark mb={4}>{summaryTitle}</Body2Dark>}
            {summaryLines.map((summaryLine, index) => (
              <CalendarText key={`${summaryLine}_${index}`} pl={4} mb={4}>
                {summaryLines.length > 1 ? '-' : ''} {summaryLine}
              </CalendarText>
            ))}
            {note && <Body2Dark mb={4}>Note</Body2Dark>}
            {note && <CalendarText pl={4}>{note}</CalendarText>}
          </Box>
        )}
        <NoteIconContainer
          selected={selectedIndex === -1}
          onClick={() => setSelectedIndex(-1)}
        >
          <SvgIcon name="note" width={34} height={34} />
        </NoteIconContainer>
      </MainContent>
      <ConfirmModal
        show={!!show}
        text={show && CONFIRM_TITLE[show.record ? 'incomplete' : 'complete']}
        onCancel={() => setShow(null)}
        onOk={handleTaskCompleteChange}
      />
    </Wrapper>
  );
}

export default TaskView;
