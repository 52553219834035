import React, { useMemo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Box, Body1, IconButton, SvgIcon } from 'components/core';
import { Player } from 'components/player';
import { ProgressLoader } from 'components/loading';

import API from 'services/api';
import { colors } from 'styles/theme';
import { getAssetType, isImgix } from 'utils/form';

const PreviewContainer = styled(Box)`
  position: relative;
  min-height: 223px;
  padding: 0;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PreviewOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VideoPreview = styled(Box)`
  min-height: 223px;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImagePreview = styled.img`
  height: 223px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'center',
  padding: 0,
  borderWidth: 1,
  borderRadius: 6,
  borderColor: colors.text,
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: '223px',
};

const activeStyle = {
  borderColor: colors.text,
  backgroundColor: colors.line,
};

const acceptStyle = {
  borderColor: colors.text,
};

const rejectStyle = {
  borderColor: colors.red,
};

function MasterUpload({
  uploadKey,
  onUpload,
  onUploadProgress,
  thumbUrl,
  assetType,
  assetUrl,
  onReset,
  ...props
}) {
  const [preview, setPreview] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const resetUpload = () => {
    setPreview('');
    if (onReset) {
      onReset();
    }
    if (onUploadProgress) {
      onUploadProgress(false);
    }
  };

  const uploadCallback = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  const uploadFile = useCallback(
    async (fileToUpload) => {
      setIsUploading(true);
      setUploadProgress(0);
      if (onUploadProgress) {
        onUploadProgress(true);
      }

      const type = getAssetType(fileToUpload.type);

      const fileKey = `${uploadKey}-${fileToUpload.name}`;

      if (type === 'image') {
        setPreview(URL.createObjectURL(fileToUpload));
      } else {
        setPreview(type);
      }

      const uploadAssetUrl = await API.uploadFile(
        fileToUpload,
        fileKey,
        uploadCallback
      );
      if (onUpload) {
        onUpload({ assetUrl: uploadAssetUrl, type, fileKey });
      }

      setIsUploading(false);
      setPreview('');
    },
    [setIsUploading, uploadKey, onUpload, onUploadProgress]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles || !acceptedFiles.length) {
        return;
      }
      const file = acceptedFiles[0];
      uploadFile(file);
    },
    [uploadFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*, video/*, application/pdf', onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      alignItems="center"
      {...props}
    >
      {!preview && !thumbUrl && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Body1>Drag or upload a file here</Body1>
        </div>
      )}
      {preview && (
        <PreviewContainer>
          <CloseButton borderWidth={0} onClick={resetUpload}>
            <SvgIcon name="delete" />
          </CloseButton>
          {isUploading && (
            <PreviewOverlay>
              <ProgressLoader
                value={uploadProgress}
                text={`${uploadProgress}%`}
              />
            </PreviewOverlay>
          )}
        </PreviewContainer>
      )}
      {thumbUrl && (
        <PreviewContainer>
          {assetType === 'video' && !isUploading ? (
            <VideoPreview>
              <Player
                data={{
                  assetUrl,
                  assetType,
                  thumbUrl,
                }}
                category="preview"
              />
            </VideoPreview>
          ) : (
            <ImagePreview
              src={isImgix(thumbUrl) ? `${thumbUrl}?h=223` : thumbUrl}
            />
          )}
          <CloseButton borderWidth={0} onClick={resetUpload}>
            <SvgIcon name="delete" />
          </CloseButton>
        </PreviewContainer>
      )}
    </Box>
  );
}

export default MasterUpload;
