import styled from 'styled-components';
import {
  color,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  space,
  textAlign,
  zIndex,
  position,
  system,
} from 'styled-system';

const Text = styled('div')`
  ${space}
  ${display}
  ${fontSize}
  ${fontWeight}
  ${letterSpacing}
  ${lineHeight}
  ${color}
  ${textAlign}
  ${display}
  ${zIndex}
  ${position}
   ${system({
     textTransform: true,
   })}
`;
Text.propTypes = {
  ...space.propTypes,
  ...display.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
};

Text.defaultProps = {
  fontSize: 2,
  color: 'text',
};

export default Text;
