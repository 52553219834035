import { put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import SettingsActions from './SettingsRedux';
import AuthActions from './AuthRedux';

import API from '../services/api';

export function* loadSettings() {
  yield put(SettingsActions.setSettingsLoading(true));
  const user = yield call(API.getMe);
  yield put(AuthActions.setUser(user));

  yield put(
    SettingsActions.setPrivacySettings({
      privacyCompletedGoesPublic: get(
        user,
        'profile.privacyCompletedGoesPublic',
        false
      ),
      privacyProgramsPrivate: get(
        user,
        'profile.privacyProgramsPrivate',
        false
      ),
    })
  );

  yield put(
    SettingsActions.setNotificationSettings({
      notifyNewFollowers: get(user, 'profile.notifyNewFollowers', false),
      notifyNewSubscribers: get(user, 'profile.notifyNewSubscribers', false),
      notifyInterestPrograms: get(
        user,
        'profile.notifyInterestPrograms',
        false
      ),
      notifyNewProgramsFromFollowing: get(
        user,
        'profile.notifyNewProgramsFromFollowing',
        false
      ),
      notifyProgramTipsImprovement: get(
        user,
        'profile.notifyProgramTipsImprovement',
        false
      ),
    })
  );

  yield put(SettingsActions.setSettingsLoading(false));
}
