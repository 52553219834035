import React, { useState } from 'react';
import { Box } from 'components/core';

import SectionSwitch from '../UserDetail/SectionSwitch';
import UserListView from '../UserDetail/UserListView';
import getDetailOptions from './getDetail';

function Community({ user }) {
  const [index, setIndex] = useState(0);
  const options = getDetailOptions(user);
  const currentOption = options[index];

  return (
    <Box width={1}>
      <SectionSwitch
        options={options}
        index={index}
        onChange={setIndex}
        width={1}
        sectionOptions={{ justifyContent: 'space-around' }}
        my={3}
      />
      <UserListView key={currentOption.index} {...currentOption} />
    </Box>
  );
}

export default Community;
