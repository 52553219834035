import styled from 'styled-components';
import {
  border,
  space,
  color,
  layout,
  flexbox,
  position,
  fontSize,
} from 'styled-system';

const Label = styled.label(
  space,
  color,
  layout,
  flexbox,
  border,
  position,
  fontSize
);

Label.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...layout.propTypes,
  ...flexbox.propTypes,
  ...border.propTypes,
  ...position.propTypes,
  ...fontSize.propTypes,
};

export default Label;
