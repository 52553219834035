import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box } from 'components/core';
import { SearchProgramCard } from 'containers/programs';
import { PlayerModal } from 'components/player';

import { RatingModal, SummaryModal } from 'modals';

import { SearchSelectors } from 'redux/SearchRedux';

import history from 'utils/history';
import { transformProgramForAnalytics } from 'utils/transform';
import Analytics, { EVENTS, SCREEN } from 'services/analytics';
import { AuthSelectors } from 'redux/AuthRedux';

function FeaturedProgramList({ programs, isPremium, ...props }) {
  const [itemData, setItemData] = useState(false);
  const [itemDataCategory, setItemDataCategory] = useState(false);

  const [currentRating, setCurrentRating] = useState(1);
  const [currentStats, setCurrentStats] = useState(1);
  const [currentProgramId, setCurrentProgramId] = useState('');

  const [showSummary, setShowSummary] = useState(false);
  const [showRatingView, setShowRatingView] = useState(false);

  const handleJoin = (programInfo) => {
    const { id: programId } = programInfo;
    Analytics.track(EVENTS.JOIN_CLICKED, {
      from: SCREEN.SEARCH,
      ...transformProgramForAnalytics(programInfo),
    });
    if (programInfo.featured && !isPremium) {
      history.push('/settings/premium');
    } else {
      history.push(`/subscription/${programId}`);
    }
  };

  const handleOnView = (programInfo, category, value, sectionIndex, day) => {
    const {
      id: programId,
      isSubscribed,
      program: { data },
      price,
      title,
    } = programInfo;

    Analytics.track(EVENTS.PROGRAM_ITEM_CLICKED, {
      from: SCREEN.SEARCH,
      programId,
      title,
      category,
      day,
    });

    if (+day !== 1 && !isSubscribed && price) {
      history.push(`/subscription/${programId}`);
      return;
    }

    const sectionData = data[sectionIndex];
    const itemData = sectionData[day];
    const { thumbUrl } = itemData;

    if (!thumbUrl) {
      return;
    }

    setItemData(itemData);
    setItemDataCategory(category);
  };

  const onViewSummary = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    setCurrentProgramId(programId);
    setCurrentStats(stats);
    setShowSummary(true);
  };

  const onViewRating = async (programInfo) => {
    const { id: programId, stats } = programInfo;
    const { avgRating } = stats || {};
    setCurrentProgramId(programId);
    setCurrentRating(avgRating);
    setShowRatingView(true);
  };

  return (
    <Box {...props}>
      {programs.map((programInfo) => (
        <SearchProgramCard
          key={programInfo.id}
          mb={6}
          onView={(category, value, sectionIndex, day) =>
            handleOnView(programInfo, category, value, sectionIndex, day)
          }
          onJoin={() => handleJoin(programInfo)}
          onViewSummary={() => onViewSummary(programInfo)}
          onViewRating={() => onViewRating(programInfo)}
          {...programInfo}
        />
      ))}
      <PlayerModal
        show={!!itemData}
        data={itemData}
        category={itemDataCategory}
        onClose={() => setItemData(null)}
      />
      <RatingModal
        show={showRatingView}
        programId={currentProgramId}
        rating={currentRating}
        onOk={() => setShowRatingView(false)}
      />
      <SummaryModal
        show={showSummary}
        programId={currentProgramId}
        stats={currentStats}
        onOk={() => setShowSummary(false)}
      />
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  programs: SearchSelectors.selectPrograms(state),
  isPremium: AuthSelectors.selectIsPremiumUser(state),
});

export default connect(mapStatesToProps)(FeaturedProgramList);
