import React from 'react';
import PropTypes from 'prop-types';
import { Box, DialogBox, Body1 } from 'components/core';
import ChatForm from './ChatForm';

function ChatFormModal({ show, onCancel, title, ...rest }) {
  return (
    <DialogBox show={show} onClickOutside={onCancel} hasClose={false}>
      <Box p={[2, 8]}>
        <Body1 textAlign="center" mb={[2, 8]}>
          {title}
        </Body1>
        <ChatForm onCancel={onCancel} {...rest} />
      </Box>
    </DialogBox>
  );
}

ChatFormModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
};

ChatFormModal.defaultProps = {
  title: 'Edit Message',
};

export default ChatFormModal;
