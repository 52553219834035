import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import { colors } from 'styles/theme';

const Button = styled.button`
  border-radius: 1px;
  border: ${(props) =>
    `solid 1px ${props.selected ? colors.text : colors.line}`};
  color: ${(props) => (props.selected ? colors.text : colors.line)};
  outline: 0;
  cursor: pointer;
  min-height: 48px;

  background: transparent;

  ${space}
  ${layout}
`;

const OptionButton = ({
  selected,
  name,
  minWidth = 48,
  children,
  ...props
}) => {
  return (
    <Button mr={4} selected={selected} {...props} minWidth={minWidth}>
      {children}
    </Button>
  );
};

export default OptionButton;
