import React from 'react';
import styled from 'styled-components';

import { Box } from 'components/core';

const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const MainContainer = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1230px;
`;

const AuthLayout = ({ children }) => {
  return (
    <Wrapper>
      <MainContainer>{children}</MainContainer>
    </Wrapper>
  );
};

export default AuthLayout;
