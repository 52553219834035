import React from 'react';
import { Helmet } from 'react-helmet';

function ProgramHelmet({ program }) {
  const url = window.location.href;
  const title = `${program.title} - BCULT`;
  const description =
    program.description ||
    'Bcult is the largest influencer-created fitness platform in the world';
  const defaultImage = `${process.env.REACT_APP_APP_URL}/share.png`;
  const image = program.shareImage || defaultImage;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@bcult_official" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@bcult_official" />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:domain" content="bcult.com" />
    </Helmet>
  );
}

export default ProgramHelmet;
