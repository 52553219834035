import BigEgg from 'assets/BigEgg.png';
import Gold from 'assets/Gold.png';
import Diet from 'assets/category/Diet.png';
import Behavioral from 'assets/category/Behavioral.png';
import Fitness from 'assets/category/Fitness.png';
import Wellness from 'assets/category/Wellness.png';
import Weight from 'assets/category/Weight.png';
import Strength from 'assets/category/Strength.png';

import Behavioral1 from 'assets/png/Behavior.png';
import Fitness1 from 'assets/png/Fitness.png';
import Diet1 from 'assets/png/Diet.png';
import Wellness1 from 'assets/png/Wellness.png';

import Supplement from 'assets/category/Supplement.png';

import Beetle from 'assets/animals/Beetle.png';
import Cheetah from 'assets/animals/Cheetah.png';
import Condor from 'assets/animals/Condor.png';
import Eagle from 'assets/animals/Eagle.png';
import Gorilla from 'assets/animals/Gorilla.png';
import Grasshopper from 'assets/animals/Grasshopper.png';
import LemonShark from 'assets/animals/LemonShark.png';
import Lion from 'assets/animals/Lion.png';
import Mantis from 'assets/animals/Mantis.png';
import Salmon from 'assets/animals/Salmon.png';
import Seahorse from 'assets/animals/Seahorse.png';
import Wolf from 'assets/animals/Wolf.png';

export const ALL_IMAGES = {
  bigEgg: BigEgg,
  gold: Gold,
  diet1: Diet1,
  behavioral1: Behavioral1,
  fitness1: Fitness1,
  wellness1: Wellness1,
  diet: Diet,
  behavioral: Behavioral,
  fitness: Fitness,
  wellness: Wellness,
  cardio: Fitness,
  weight: Weight,
  bmi: Weight,
  strength: Strength,
  supplement: Supplement,
  Beetle: Beetle,
  Cheetah: Cheetah,
  Condor: Condor,
  Eagle: Eagle,
  Gorilla: Gorilla,
  Grasshopper: Grasshopper,
  LemonShark: LemonShark,
  Lion: Lion,
  Mantis: Mantis,
  Salmon: Salmon,
  Seahorse: Seahorse,
  Wolf: Wolf,
};
