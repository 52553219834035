import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setSettingsView: ['view'],
  setNotificationSettings: ['notification'],
  setPrivacySettings: ['privacy'],
  setLoadSettingsRequest: null,
  setSettingsLoading: ['isLoading'],
});

export const SettingsTypes = Types;
export default Creators;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  view: 'general',
  privacy: {
    privacyCompletedGoesPublic: false,
    privacyProgramsPrivate: false,
  },
  notification: {
    notifyNewFollowers: false,
    notifyNewSubscribers: false,
    notifyInterestPrograms: false,
    notifyNewProgramsFromFollowing: false,
    notifyProgramTipsImprovement: false,
  },
  isLoading: true,
};

/* ------- Selectors --------- */
export const SettingsSelectors = {
  selectSettingsView: (state) => state.settings.view,
  selectPrivacySettings: (state) => state.settings.privacy,
  selectNotificationSettings: (state) => state.settings.notification,
  selectIsSettingsLoading: (state) => state.settings.isLoading,
};

/* -------- Reducers ---------- */
export const setSettingsView = (state, { view }) => {
  return {
    ...state,
    view,
  };
};

export const setNotificationSettings = (state, { notification }) => {
  return {
    ...state,
    notification: {
      ...state.notification,
      ...notification,
    },
  };
};

export const setPrivacySettings = (state, { privacy }) => {
  return {
    ...state,
    privacy: {
      ...state.privacy,
      ...privacy,
    },
  };
};

export const setSettingsLoading = (state, { isLoading }) => {
  return {
    ...state,
    isLoading,
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SETTINGS_VIEW]: setSettingsView,
  [Types.SET_SETTINGS_LOADING]: setSettingsLoading,
  [Types.SET_NOTIFICATION_SETTINGS]: setNotificationSettings,
  [Types.SET_PRIVACY_SETTINGS]: setPrivacySettings,
});
