import React from 'react';
import { Box, DialogBox, Body2, Button } from 'components/core';

function ConfirmModal({
  show,
  text,
  onOk,
  onCancel,
  okText = 'Yes',
  cancelText = 'No',
  ...props
}) {
  return (
    <DialogBox show={show} onClickOutside={onCancel} hasClose={false}>
      <Box {...props} m={[5, 8]}>
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
        >
          <Body2 textAlign="center">{text}</Body2>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={6}
        >
          <Button variant="secondary" mx={4} onClick={onOk}>
            {okText}
          </Button>
          {cancelText && (
            <Button variant="third" mx={4} onClick={onCancel}>
              {cancelText}
            </Button>
          )}
        </Box>
      </Box>
    </DialogBox>
  );
}

export default ConfirmModal;
