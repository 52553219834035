import React from 'react';
import styled from 'styled-components';
import { Box, SvgIcon, Body2, Tooltip } from 'components/core';
import { Rating } from 'components/others';

const CenterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BmiOption = styled(CenterContainer)`
  cursor: pointer;
`;

const ProgramStat = ({
  avgRating = 3,
  subscriptionCount = 23.6,
  weightChange = 0,
  onClickRating,
  onClickStat,
  ...props
}) => {
  return (
    <CenterContainer
      {...props}
      flex={[1, 'unset']}
      justifyContent={'flex-start'}
    >
      <Rating value={avgRating} onClick={onClickRating} />
      <BmiOption mr={[0, 4]} onClick={onClickStat}>
        <Body2 mr={2}>{subscriptionCount}</Body2>
        <Tooltip
          animation="scale"
          delay={[300, 0]}
          duration={50}
          content={'Subscribers'}
          theme="light-border"
          placement="bottom"
        >
          <span>
            <SvgIcon name="bmi-shared" mr={2} />
          </span>
        </Tooltip>
        <Body2 mr={2}>{weightChange}</Body2>
        <Tooltip
          animation="scale"
          delay={[300, 0]}
          duration={50}
          content={'Weight lost'}
          theme="light-border"
          placement="bottom"
        >
          <span>
            <SvgIcon name="balance" />
          </span>
        </Tooltip>
      </BmiOption>
    </CenterContainer>
  );
};

export default ProgramStat;
