import React from 'react';
import {
  MasterForm,
  StrengthForm,
  FitnessForm,
  WeightForm,
  DietForm,
  WellnessForm,
  SupplementForm,
} from './program';
import { connect } from 'react-redux';
import { AuthSelectors } from 'redux/AuthRedux';

function ProgramForm({ category = 'fitness', userAvatar, ...props }) {
  switch (category) {
    case 'master':
      return <MasterForm {...props} userAvatar={userAvatar}/>;
    case 'bmi':
      return <WeightForm {...props} />;
    case 'weight':
      return <WeightForm {...props} />;
    case 'diet':
      return <DietForm {...props} />;
    case 'wellness':
      return <WellnessForm {...props} />;
    case 'supplement':
      return <SupplementForm {...props} />;
    case 'fitness':
      return <FitnessForm {...props} />;
    case 'strength':
      return <StrengthForm {...props} />;
    default:
  }

  return (
    <WeightForm
      {...props}
    />
  );
}

const mapStatesToProps = (state) => ({
  userId: AuthSelectors.selectUserId(state),
  userGender: AuthSelectors.selectUserGender(state),
  userAvatar: AuthSelectors.selectUserAvatar(state),
});

export default connect(mapStatesToProps)(ProgramForm);
