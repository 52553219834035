import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Title2, Body6, SvgIcon, Body4 } from 'components/core';
import { Avatar } from 'components/program';
import { transformUser } from 'utils/transform';
import { colors } from 'styles/theme';
import moment from 'moment';

const MessageBody = styled(Body6)`
  white-space: pre-line;
  break-word: break-all;
`;

const EditButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 40px;
  width: 32px;
  height: 32px;
  display: flex;
  border: 1px solid ${colors.text};
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    top: 58px;
    right: unset;
    left: -8px;
  }
`;

const DeleteButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 0;
  @media (max-width: 768px) {
    top: 58px;
    right: unset;
    left: 28px;
  }
  width: 32px;
  height: 32px;
  background-color: ${colors.red};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(SvgIcon)`
  path {
    fill: ${colors.white};
  }
`;

function BaseChatMessage({
  user,
  message,
  createdAt,
  children,
  onEdit,
  onDelete,
  isEditable,
  ...rest
}) {
  const u = transformUser(user);
  return (
    <Box position="relative" display="flex" {...rest}>
      <Link to={`/users/${u.id}`}>
        <Avatar name={u.name} {...u.avatar} />
      </Link>
      <Box ml={3} mt={1} width={1} pr={[0, '80px']}>
        <Box display="flex" alignItems="center" mb={1}>
          <Title2 as={Link} to={`/users/${u.id}`}>
            {u.name}
          </Title2>
          <Body4 ml={4}>{moment(createdAt).fromNow(true)}</Body4>
        </Box>
        <MessageBody>{message}</MessageBody>
        {children}
      </Box>
      {isEditable && (
        <EditButton onClick={onEdit}>
          <SvgIcon name="toolbar-edit" width={24} />
        </EditButton>
      )}
      {isEditable && (
        <DeleteButton onClick={onDelete}>
          <Icon name="toolbar-delete" />
        </DeleteButton>
      )}
    </Box>
  );
}

BaseChatMessage.propTypes = {
  user: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default BaseChatMessage;
