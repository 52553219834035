import React, { useState } from 'react';
import { Box, Title1 } from 'components/core';

import {
  ProgramToolbar,
  ProgramProfile,
  ProgramGraph,
  ProgramStat,
  AdminToolbar,
} from 'components/program';

import {
  GraphWrapper,
  CollapsibleWrapper,
} from 'components/program/CommonStyled';

const AdminProgramCard = ({
  subscribedFrom,
  title,
  user,
  description,
  stats,
  type,
  paymentDescription,
  program,
  onPromote,
  onReset,
  onGold,
  isRestarted,
  price,
  onViewSummary,
  onViewRating,
  onAddShareImage,
  onViewPaymentDescription,
  onAction,
  onDelete,
  onView,
  featured,
  approved,
  shareImage,
  ...props
}) => {
  const [densed, setDensed] = useState(true);

  const toolbarOptions = [
    {
      name: 'gold',
      help: 'Offer gold',
      selected: price !== 0,
    },
    {
      name: 'edit',
      help: 'Edit Payment Description',
      selected: !!paymentDescription,
    },
  ];

  const adminOptions = [
    {
      name: shareImage ? 'heart-fill' : 'heart',
      help: 'Share Image',
      selected: !!shareImage,
    },
    {
      name: !featured ? 'featured' : 'unfeatured',
      icon: 'featured',
      help: 'Featured',
      selected: featured,
    },
    {
      name: 'approve',
      help: 'Approve',
      selected: approved,
    },
    {
      name: 'decline',
      help: 'Decline',
      selected: !approved,
    },
  ];

  const handleSelectTool = (name) => {
    switch (name) {
      case 'heart':
      case 'heart-fill':
        onAddShareImage();
        return;
      case 'edit':
        onViewPaymentDescription();
        return;
      case 'gold':
        onGold();
        return;
      default:
        onAction(name);
    }
  };

  return (
    <GraphWrapper {...props}>
      <Box mb={2}>
        <Title1 color="text">{title}</Title1>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <ProgramProfile {...user} description={description} />
          <Box
            flex={1}
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end">
              <ProgramToolbar
                options={toolbarOptions}
                onSelect={handleSelectTool}
              />
              <ProgramStat
                {...stats}
                onClickStat={onViewSummary}
                onClickRating={onViewRating}
              />
            </Box>
            <AdminToolbar options={adminOptions} onSelect={handleSelectTool} />
          </Box>
        </Box>
      </Box>
      <CollapsibleWrapper
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        initialOpen={densed}
        onExpand={() => setDensed(!densed)}
      >
        <ProgramGraph type={type} {...program} onView={onView} viewOnly />
      </CollapsibleWrapper>
    </GraphWrapper>
  );
};

export default AdminProgramCard;
