import React from 'react';
import { Box, IconButton, SvgIcon, Tooltip } from 'components/core';

const ProgramToolbar = ({ options = [], onSelect, ...props }) => {
  const handleSelect = (name) => {
    if (onSelect) {
      onSelect(name);
    }
  };
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="flex-end"
      {...props}
    >
      {options.map((toolbarOption) => {
        const { name, help, selected, icon } = toolbarOption;
        return (
          <Tooltip
            key={name}
            animation="scale"
            delay={[300, 0]}
            duration={300}
            content={help || name}
            theme="light-border"
            placement="bottom"
          >
            <IconButton
              mx={1}
              p={0}
              key={name}
              size="large"
              borderColor={selected ? 'main' : 'line'}
              borderWidth={1}
              onClick={() => handleSelect(name)}
            >
              <SvgIcon name={`admin-${icon || name}`} width={62} height={62} />
            </IconButton>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default ProgramToolbar;
