import React from 'react';
import PropTypes from 'prop-types';
import Box from './Box';
import Button from './Button';

function Tab({ options, tab, onChange }) {
  return (
    <Box display="flex" alignItems="center">
      {options.map(opt => (
        <Button
          key={opt.value}
          variant={tab === opt.value ? 'secondaryPlus' : 'third'}
          size="medium"
          onClick={() => onChange(opt.value)}
          mx={1}
        >
          {opt.label}
        </Button>
      ))}
    </Box>
  )
}

Tab.propTypes = {
  options: PropTypes.array,
  tab: PropTypes.string,
  onChange: PropTypes.func
};

export default Tab;
