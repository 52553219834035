import { createReducer, createActions } from 'reduxsauce';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import { getFormValueGenerator } from 'utils/form';
import { swapArr } from 'utils/misc';

const { Types, Creators } = createActions({
  setCreateInitFromExistingRequest: ['programId'],
  setCreateInitFromExistingSuccess: ['data'],

  setCreateResetAll: null,
  setCreateMode: ['createMode'],
  setCreateTitle: ['title'],
  setCreateDescription: ['description'],
  setCreateCategoryFilter: ['categoryFilter'],
  setCreateGoal: ['goal'],
  setCreatePrice: ['price'],
  setCreateGoalSuccess: ['goal'],
  setCreatePopupData: ['popup'],
  setCreatePopupShow: ['isPopupShow'],
  setCreatePopupPosition: ['position'],
  setCreateOfferToGold: ['offerToGold'],

  setCreateInsertTemplate: ['template'],
  setCreateInsertFormData: ['category', 'value', 'position', 'isRepeat'],
  setCreateInsertAddonFormData: ['category', 'value', 'position'],
  setCreateFinalizeRequest: ['mode'],
  setCreateFinalizeSuccess: null,
  setCreateMoveUpSection: ['sectionIndex'],
  setCreateMoveDownSection: ['sectionIndex'],
  setCreateItemDelete: ['sectionIndex', 'day'],
  setCreateSectionDelete: ['sectionIndex'],

  setCreateLoading: ['isLoading'],
  setCreateError: ['error'],
});

export const ProgramCreateTypes = Types;
export default Creators;

const DEFAULT_SECTIONS = ['master', 'weight', 'bmi'];

// TODO: sync day count with graph and backend
const MAX_DAY_COUNT = 15;

/* ------- Initial State --------- */
export const INITIAL_STATE = {
  error: false,
  createMode: 'new',
  isLoading: false,
  title: '',
  description: '',
  categoryFilter: {
    category: '',
    subCategory: '',
    duration: '',
  },
  program: {
    goal: {},
    sections: [...DEFAULT_SECTIONS],
    offerToGold: false,
    price: 0,
    data: [{}, {}, {}],
    templates: [],
    dayCount: 1,
  },
  popup: {
    category: '',
    value: {},
  },
  isPopupShow: false,
  position: null,
  programId: '',
};

/* ------- Selectors --------- */
export const CreateSelectors = {
  selectCreateInfo: (state) => state.create,
  selectCreateMode: (state) => state.create.createMode,
  selectGoal: (state) => state.create.program.goal,
  selectProgramData: (state) => state.create.program,
  selectFormValue: (state) => get(state, 'create.popup.value'),
  selectPopupCategory: (state) => get(state, 'create.popup.category'),
  selectIsPopupShow: (state) => state.create.isPopupShow,
  selectOfferToGold: (state) => state.create.program.offerToGold,
  selectGoldPrice: (state) => state.create.program.price,
  selectUsedTemplates: (state) => state.create.program.templates,
  selectPopupPosition: (state) => state.create.position,
  selectTitle: (state) => state.create.title,
  selectDescription: (state) => state.create.description,
  selectCategoryFilter: (state) => state.create.categoryFilter,
  selectSectionCount: (state) => state.create.program.sections.length,
  selectIsLoading: (state) => state.create.isLoading,
  selectError: (state) => state.create.error,
};

/* -------- Reducers ---------- */
export const setCreateMode = (state, { createMode }) => {
  if (createMode === 'new') {
    return {
      ...state,
      createMode,
      program: {
        ...state.program,
        offerToGold: false,
        price: 0,
      },
    };
  }
  return {
    ...state,
    createMode,
    program: {
      ...state.program,
      goal: {},
    },
  };
};

export const setCreatePopupData = (state, { popup }) => {
  return {
    ...state,
    popup: { ...popup },
  };
};

export const setCreatePopupShow = (state, { isPopupShow }) => {
  return {
    ...state,
    isPopupShow,
  };
};

export const setCreatePopupPosition = (state, { position }) => {
  return {
    ...state,
    position,
  };
};

export const setCreateOfferToGold = (state, { offerToGold }) => {
  return {
    ...state,
    program: {
      ...state.program,
      offerToGold,
    },
  };
};

export const setCreateGoalSuccess = (state, { goal }) => {
  return {
    ...state,
    program: {
      ...state.program,
      goal,
    },
  };
};

export const setCreateInsertTemplate = (state, { template }) => {
  const { program } = state;
  const { sections, templates, data: programData } = program;
  const { data: templateData, value, countDays, category } = template;

  const newSections = [...sections];
  const newTemplates = [...templates, value];

  const getForm = getFormValueGenerator(category);

  let defaultSectionIndex = -1;

  if (DEFAULT_SECTIONS.includes(category)) {
    defaultSectionIndex = DEFAULT_SECTIONS.findIndex(
      (item) => item === category
    );
  }

  const sectionData =
    defaultSectionIndex === -1 ? {} : { ...programData[defaultSectionIndex] };

  countDays.forEach((day) => {
    sectionData[day] = getForm(templateData);
  });

  const newData = [...programData];

  if (defaultSectionIndex === -1) {
    newData.push(sectionData);
    newSections.push(category);
  } else {
    newData[defaultSectionIndex] = sectionData;
  }

  return {
    ...state,
    program: {
      ...program,
      sections: newSections,
      templates: newTemplates,
      data: newData,
      position: null,
    },
  };
};

export const setCreateInsertFormData = (
  state,
  { category, value, position, isRepeat }
) => {
  const { program } = state;
  const { sections, data: programData } = program;

  const newSections = [...sections];

  let sectionIndex = -1;
  let day = 1;

  if (position) {
    sectionIndex = position.sectionIndex;
    day = position.day || day;
  }

  let realCategory = category;
  const { type } = value;

  if (category === 'weight' || category === 'bmi') {
    realCategory = type;

    sectionIndex = sections.findIndex((item) => item === realCategory);
  }

  if (sectionIndex === -1 && DEFAULT_SECTIONS.includes(realCategory)) {
    const defaultSectionIndex = sections.findIndex(
      (item) => item === realCategory
    );

    sectionIndex = defaultSectionIndex;
  }

  const getForm = getFormValueGenerator(realCategory);

  const sectionData =
    sectionIndex === -1 ? {} : { ...programData[sectionIndex] };

  sectionData[day] = getForm(value);

  if (isRepeat) {
    //merge data with

    for (let i = 1; i <= MAX_DAY_COUNT; i += 1) {
      if (+day === +i || sectionData[i]) {
        continue;
      }

      sectionData[i] = { ...sectionData[day] };
    }
  }

  const newData = [...programData];

  if (sectionIndex === -1) {
    newData.push(sectionData);
    newSections.push(category);
  } else {
    newData[sectionIndex] = sectionData;
  }

  return {
    ...state,
    program: {
      ...program,
      sections: newSections,
      data: newData,
    },
    popup: null,
  };
};

export const setCreateFinalize = () => {
  return cloneDeep(INITIAL_STATE);
};

export const setCreateResetAll = () => {
  return cloneDeep(INITIAL_STATE);
};

export const setCreateTitle = (state, { title }) => {
  return {
    ...state,
    title,
  };
};

export const setCreateCategoryFilter = (state, { categoryFilter }) => {
  return {
    ...state,
    categoryFilter,
  };
};

export const setCreateDescription = (state, { description }) => {
  return {
    ...state,
    description,
  };
};

export const setCreateLoading = (state, { isLoading }) => {
  return {
    ...state,
    isLoading,
  };
};

export const setCreateError = (state, { error }) => {
  return {
    ...state,
    error,
  };
};

export const setClearError = (state) => {
  return {
    ...state,
    error: null,
  };
};

export const setCreatePrice = (state, { price }) => {
  return {
    ...state,
    price,
  };
};

export const setCreateMoveUpSection = (state, { sectionIndex }) => {
  const { program } = state;
  const { sections, data: programData } = program;

  if (sectionIndex <= 1) {
    return state;
  }

  return {
    ...state,
    program: {
      ...program,
      sections: swapArr(sections, sectionIndex, sectionIndex - 1),
      data: swapArr(programData, sectionIndex, sectionIndex - 1),
    },
    popup: null,
  };
};

export const setCreateMoveDownSection = (state, { sectionIndex }) => {
  const { program } = state;
  const { sections, data: programData } = program;

  if (sectionIndex >= sections.length - 1) {
    return state;
  }

  return {
    ...state,
    program: {
      ...program,
      sections: swapArr(sections, sectionIndex, sectionIndex + 1),
      data: swapArr(programData, sectionIndex, sectionIndex + 1),
    },
    popup: null,
  };
};

export const setCreateItemDelete = (state, { sectionIndex, day }) => {
  const { program } = state;
  const { data: programData } = program;

  if (sectionIndex < 0) {
    return state;
  }

  const sectionData = programData[sectionIndex];

  if (!sectionData) {
    return state;
  }

  const { [day]: itemToDelete, ...newSectionData } = sectionData;

  return {
    ...state,
    program: {
      ...program,
      data: programData.map((currentSectionData, index) =>
        index === sectionIndex ? newSectionData : currentSectionData
      ),
    },
    popup: null,
  };
};

export const setCreateSectionDelete = (state, { sectionIndex }) => {
  const { program } = state;
  const { sections, data: programData } = program;

  if (sectionIndex < 1) {
    return state;
  }

  if (sections.length <= 3) {
    return state;
  }

  return {
    ...state,
    program: {
      ...program,
      sections: sections.filter((item, index) => index !== sectionIndex),
      data: programData.filter((item, index) => index !== sectionIndex),
    },
    popup: null,
  };
};

export const setCreateInitFromExistingSuccess = (state, { data }) => {
  return {
    ...cloneDeep(INITIAL_STATE),
    ...data,
  };
};

/* -------- Hookup Reducers to Types -------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CREATE_MODE]: setCreateMode,
  [Types.SET_CREATE_TITLE]: setCreateTitle,
  [Types.SET_CREATE_CATEGORY_FILTER]: setCreateCategoryFilter,
  [Types.SET_CREATE_DESCRIPTION]: setCreateDescription,
  [Types.SET_CREATE_GOAL_SUCCESS]: setCreateGoalSuccess,
  [Types.SET_CREATE_PRICE]: setCreatePrice,
  [Types.SET_CREATE_POPUP_POSITION]: setCreatePopupPosition,
  [Types.SET_CREATE_POPUP_DATA]: setCreatePopupData,
  [Types.SET_CREATE_POPUP_SHOW]: setCreatePopupShow,
  [Types.SET_CREATE_OFFER_TO_GOLD]: setCreateOfferToGold,
  [Types.SET_CREATE_INSERT_TEMPLATE]: setCreateInsertTemplate,
  [Types.SET_CREATE_INSERT_FORM_DATA]: setCreateInsertFormData,
  [Types.SET_CREATE_INSERT_ADDON_FORM_DATA]: setCreateInsertFormData,
  [Types.SET_CREATE_FINALIZE_REQUEST]: setClearError,
  [Types.SET_CREATE_FINALIZE_SUCCESS]: setCreateFinalize,
  [Types.SET_CREATE_LOADING]: setCreateLoading,
  [Types.SET_CREATE_ERROR]: setCreateError,

  [Types.SET_CREATE_MOVE_UP_SECTION]: setCreateMoveUpSection,
  [Types.SET_CREATE_MOVE_DOWN_SECTION]: setCreateMoveDownSection,
  [Types.SET_CREATE_ITEM_DELETE]: setCreateItemDelete,
  [Types.SET_CREATE_SECTION_DELETE]: setCreateSectionDelete,

  [Types.SET_CREATE_INIT_FROM_EXISTING_SUCCESS]: setCreateInitFromExistingSuccess,

  [Types.SET_CREATE_RESET_ALL]: setCreateResetAll,
});
