import React, { useState, useEffect } from 'react';
import { Box, Text, LinkText } from 'components/core';
import styled from 'styled-components';

import OnboardingForm from 'containers/OnboardingForm';
import LoginForm from 'containers/LoginForm';

import { colors } from 'styles/theme';

const MODAL_OPEN_CLASS = 'body--modal-open';

const Wrapper = styled(Box)`
  position: relative;
  z-index: 100;
`;

const Modal = styled(Box)`
  display: flex;
  background-color: rgba(139, 149, 173, 0.5);
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: opacity 0.4s ease-out, background-color 0.4s ease-out;
  position: fixed;
  z-index: 99999;

  opacity: ${(props) => (props.show ? 1 : 0)};

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ModalContainer = styled(Box)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1008px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
  background: ${colors.white};
  border-radius: 2px;

  overflow-y: scroll;
  max-height: 532px;

  @media (max-width: 768px) {
    max-width: 342px;
  }
`;

const ContentWrapper = styled(Box)`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const MainSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 50px;

  @media (max-width: 768px) {
    max-width: 320px;
  }

  border: 1px solid rgba(220, 220, 220, 0.5);
  border-radius: 2px;

  margin: auto;
  margin-top: 24px;
`;

function RegisterModal({ show, onCancel, onOk, ...props }) {
  const [loginMode, setLoginMode] = useState(true);
  const [facebook, setFacebook] = useState(null);

  const handleFacebook = (data) => {
    setFacebook(data);
    setLoginMode(false);
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add(MODAL_OPEN_CLASS);
    } else {
      document.body.classList.remove(MODAL_OPEN_CLASS);
    }

    return () => {
      document.body.classList.remove(MODAL_OPEN_CLASS);
    };
  }, [show]);

  return (
    <Wrapper>
      <Modal show={show} onClick={onOk}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ModalContainer {...props}>
            <ContentWrapper>
              {loginMode ? (
                <LoginForm mt={6} setFacebook={handleFacebook} />
              ) : (
                <OnboardingForm mt={6} facebook={facebook} />
              )}
              <MainSection>
                <Text color="blue" fontSize={1} ml={1} mr={1}>
                  {loginMode
                    ? `Don’t have an account?`
                    : `Already have an account?`}
                </Text>
                <LinkText
                  fontSize={1}
                  color="blue"
                  onClick={() => {
                    setLoginMode(!loginMode);
                  }}
                >
                  {loginMode ? `Join!` : `Signin`}
                </LinkText>
              </MainSection>
            </ContentWrapper>
          </ModalContainer>
        </div>
      </Modal>
    </Wrapper>
  );
}

export default RegisterModal;
