import styled from 'styled-components';
import Text from './Text';

export const LogoText = styled(Text)`
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-decoration: none;
`;

export const Title1 = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Title1Bold = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Title2 = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const Title3 = styled(Text)`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
`;

export const Caption1 = styled(Text)`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
`;

export const Body1 = styled(Text)`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Body1Bold = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Caption2 = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Caption3 = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const Body2 = styled(Text)`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Body2Dark = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Body2DarkCondensed = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Body2Bold = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`;

export const Body3 = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

export const Body4 = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

export const Body4Bold = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
`;

export const Body5 = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
`;

export const Body6 = styled(Text)`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
`;

export const Body6Dark = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const FitText = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`;

export const ProfileText = styled(Text)`
  font-family: Augusta Two;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
`;

export const CalendarText = styled(Text)`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const PopHeading = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 49px;
`;
