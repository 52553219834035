import React, { useState } from 'react';
import { Box, IconButton, SvgIcon, Menu, Tooltip } from 'components/core';
import { useSelector } from 'react-redux';
import {
  load as loadFacebook,
  share as shareFacebook,
} from 'services/facebook';
import { share as shareTwitter } from 'services/twitter';
import { share as shareEmail } from 'services/email';
import { copyToClipboard } from 'services/clipboard';
import { AuthSelectors } from 'redux/AuthRedux';
import API from 'services/api';
import Analytics, { EVENTS, addUtmParmas } from 'services/analytics';

const SHARE_OPTIONS = [
  { label: 'facebook', value: 'facebook', icon: 'share-facebook' },
  { label: 'twitter', value: 'twitter', icon: 'share-twitter' },
  { label: 'email', value: 'email', icon: 'share-email' },
  { label: 'clipboard', value: 'clipboard', icon: 'share-clipboard' },
];

function ProgramShare({ programId, description, shareImage, likes, user }) {
  const userId = useSelector(AuthSelectors.selectUserId);
  const [liked, setLiked] = useState(likes.includes(userId));
  const likeCount = likes.length + liked - likes.includes(userId);
  const url = `${process.env.REACT_APP_APP_URL}/users/${user.id}/programs/${programId}`;

  const handleChange = async (val) => {
    const utmConvertedUrl = addUtmParmas(url, val);

    if (val === 'facebook') {
      await loadFacebook();
      await shareFacebook(utmConvertedUrl, description);
    } else if (val === 'twitter') {
      await shareTwitter(utmConvertedUrl, description);
    } else if (val === 'email') {
      await shareEmail(`${description} \n${utmConvertedUrl}`);
    } else if (val === 'clipboard') {
      copyToClipboard(utmConvertedUrl, shareImage);
    }

    if (val !== 'clipboard') {
      Analytics.track(EVENTS.PROGRAM_SHARED, {
        programId,
        utmConvertedUrl,
        type: val,
      });
    }
  };

  const handleLike = async () => {
    try {
      await API.toggleLikeProgram(programId);
      setLiked(!liked);

      Analytics.track(EVENTS.PROGRAM_LIKED, {
        programId,
        value: !liked,
      });
    } catch (e) {}
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      width={1}
      mt={3}
    >
      <Menu
        title="Share"
        options={SHARE_OPTIONS}
        onChange={handleChange}
        minWidth={320}
      >
        <IconButton borderWidth={0} mr={4} size={40}>
          <SvgIcon name="share-outline" />
        </IconButton>
      </Menu>
      <Tooltip
        animation="scale"
        delay={[300, 0]}
        duration={300}
        content={`${likeCount} like${likeCount > 1 ? 's' : ''}`}
        theme="light-border"
        placement="top"
      >
        <IconButton borderWidth={0} size={40} onClick={handleLike}>
          <SvgIcon name={liked ? 'heart-fill' : 'heart'} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default ProgramShare;
