export const share = (description) => {
  const a = document.createElement('a');
  const url = `mailto:?subject=Check+out+this+program&body=${encodeURIComponent(
    description
  )}`;

  if (!a.click) {
    //for Internet explorer
    window.location = url;
    return;
  }

  a.setAttribute('href', url);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
};
