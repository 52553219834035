import React from 'react';
import styled from 'styled-components';
import SvgIcon from './SvgIcon';

import { colors } from 'styles/theme';
import Label from './Label';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.size === 'sm' ? 16 : 24)}px;
  height: ${(props) => (props.size === 'sm' ? 16 : 24)}px;
  background: ${(props) =>
    props.variant === 'secondary' ? colors.clear : colors.white};
  border: ${(props) => (props.variant === 'tick' ? 1 : 2)}px solid
    ${(props) => (props.variant === 'secondary' ? colors.line : colors.text)};
  padding: 2px;
  border-radius: 1px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px ${colors.contrastText};
  }
`;

const InnerBox = styled.div`
  width: ${(props) => (props.size === 'sm' ? 8 : 16)}px;
  height: ${(props) => (props.size === 'sm' ? 8 : 16)}px;

  background: ${(props) =>
    props.variant === 'secondary' ? colors.line : colors.text};
  border-radius: 1px;
  transition: all 150ms;
  opacity: ${(props) => (props.checked ? 1 : 0)};
`;

const TickContainer = styled.div`
  left: 30%;
  top: -30%;
  position: absolute;
`;

const Checkbox = ({ checked, variant, size, ...props }) => (
  <Label {...props}>
    <CheckboxContainer size={size}>
      <HiddenCheckbox checked={!!checked} readOnly />
      <StyledCheckbox variant={variant} size={size}>
        {variant === 'tick' ? (
          <TickContainer>{!!checked && <SvgIcon name="tick" />}</TickContainer>
        ) : (
          <InnerBox size={size} checked={checked} variant={variant} />
        )}
      </StyledCheckbox>
    </CheckboxContainer>
  </Label>
);

export default Checkbox;
