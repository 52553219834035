import React from 'react';
import { Box, Select } from 'components/core';

import {
  CATEGORY_OPTIONS,
  ALL_CATEGORIES,
  LENGTH_OPTIONS,
} from 'services/terms';

function CompoundCategoryField({
  value: initialValue = {},
  onChange,
  ...props
}) {
  const {
    category = CATEGORY_OPTIONS[0].value,
    duration = LENGTH_OPTIONS[0].value,
    subCategory = '',
  } = initialValue;
  const TYPE_OPTIONS = ALL_CATEGORIES[category];

  const submitChange = ({ category, subCategory, duration }) => {
    if (onChange) {
      onChange({
        category,
        subCategory,
        duration,
      });
    }
  };

  const onChangeCategory = ({ value: newCategory }) => {
    if (category === newCategory) {
      return;
    }

    submitChange({
      category: newCategory,
      subCategory: '',
      duration: '',
    });
  };

  const onChangeSubCategory = ({ value: newSubCategory }) => {
    if (subCategory === newSubCategory) {
      return;
    }

    submitChange({
      category,
      subCategory: newSubCategory,
      duration,
    });
  };

  const onChangeLength = ({ value: newLength }) => {
    submitChange({
      category,
      subCategory,
      duration: newLength,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignSelf="stretch"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <Select
        placeholder="Category"
        value={category}
        options={CATEGORY_OPTIONS}
        onChange={onChangeCategory}
      />
      <Select
        placeholder="Length"
        value={duration}
        onChange={onChangeLength}
        options={LENGTH_OPTIONS}
      />
      <Select
        key={`${category}_type`}
        placeholder="Type"
        onChange={onChangeSubCategory}
        value={subCategory}
        options={TYPE_OPTIONS}
      />
    </Box>
  );
}

export default CompoundCategoryField;
