export const getBmiStat = (bmi) => {
  if (bmi >= 30) {
    return 'Obese';
  }

  if (bmi >= 25) {
    return 'Over';
  }

  if (bmi >= 18.5) {
    return 'Ideal';
  }

  return 'Under';
};

export const getInchesFromFeet = ({ feet, inches }, isZeroIndex = true) => {
  return feet * 12 + Number(isZeroIndex ? Number(inches) - 1 : inches);
};

export const getInchesCm = (cmValule) => {
  return Number((cmValule / 2.54).toFixed(2));
};

export const getLbsFromKg = (kgValue) => {
  return Math.round(kgValue * 2.205);
};

export const getKgFromLbs = (lbValue) =>
  Math.round(lbValue * 0.453592 * 10) / 10;

/**
 * HEIGHT = INCH
 * WIEGHT= LBS
 */

export const calcBmiUSC = ({ height = 1, weight }) => {
  return Math.floor((703 * weight) / (height * height));
};

/**
 * HEIGHT = INCH
 * WIEGHT= LBS
 */

export const calcWeightFromBmi = ({ height = 1, bmi }) => {
  return Math.floor((bmi * height * height) / 703);
};

export const formatBmiValue = (value) => {
  if (value > 18.5) {
    return Math.ceil(value);
  }

  return Math.floor(value);
};
