/** CATEGORY */

export const DIET_OPTIONS = [
  { value: 'fasting', label: 'Fasting' },
  { value: 'detox', label: 'Detox' },
  { value: 'macro', label: 'Macro' },
  { value: 'keto', label: 'Keto' },
  { value: 'mediterranean', label: 'Mediterranean' },
  { value: 'vegetarian', label: 'Vegetarian' },
];

export const FITNESS_SUBCATEGORIES = {
  HIIT: 'hiit',
  CARDIO: 'cardio',
  STRENGTH: 'strength',
  LOW_IMPACT: 'low-impact',
  TONE: 'tone',
};

export const FITNESS_OPTIONS = [
  { value: FITNESS_SUBCATEGORIES.HIIT, label: 'HIIT' },
  { value: FITNESS_SUBCATEGORIES.CARDIO, label: 'Cardio' },
  { value: FITNESS_SUBCATEGORIES.STRENGTH, label: 'Strength' },
  { value: FITNESS_SUBCATEGORIES.LOW_IMPACT, label: 'Low impact' },
  { value: FITNESS_SUBCATEGORIES.TONE, label: 'Tone' },
];

export const FITNESS_OPTIONS_FOR_PROGRESS_GRAPH = [
  { value: 'hiit', label: 'HIIT' },
  { value: 'jogging', label: 'Jogging' },
  { value: 'biking', label: 'Biking' },
  { value: 'swimming', label: 'Swimming' },
  { value: 'class', label: 'Class' },
  { value: 'other', label: 'Other Cardio' },
];

export const WELLNESS_SUBCATEGORIES = {
  YOGA: 'yoga',
  MEDITATION: 'meditation',
  PILATES: 'pilates',
  STRETCHING: 'stretching',
};

export const WELLNESS_OPTIONS = [
  { value: WELLNESS_SUBCATEGORIES.YOGA, label: 'Yoga' },
  { value: WELLNESS_SUBCATEGORIES.MEDITATION, label: 'Meditation' },
  { value: WELLNESS_SUBCATEGORIES.PILATES, label: 'Pilates' },
  { value: WELLNESS_SUBCATEGORIES.STRETCHING, label: 'Stretching' },
];

export const BEHAVIORAL_OPTIONS = [
  { value: 'food-aversion', label: 'Food aversion' },
  { value: 'small-meal-programming', label: 'Small meal programming' },
  { value: 'intuitive-eating', label: 'Intuitive Eating' },
  { value: 'habit-breaker', label: 'Habit breaker' },
];

export const STRENGTH_SUBCATEGORIES = {
  BODY_WEIGHT: 'body-weight',
  MACHINE: 'machine',
  FREE_WEIGHTS: 'free-weights',
};

export const STRENGTH_OPTIONS = [
  { value: STRENGTH_SUBCATEGORIES.BODY_WEIGHT, label: 'Body weight' },
  { value: STRENGTH_SUBCATEGORIES.MACHINE, label: 'Machine' },
  { value: STRENGTH_SUBCATEGORIES.FREE_WEIGHTS, label: 'Free Weights' },
];

export const STRENGTH_TYPES = [
  'total-body',
  'arms',
  'legs',
  'butt',
  'core',
  'back',
];

export const CATEGORIES = {
  DIET: 'diet',
  WELLNESS: 'wellness',
  FITNESS: 'fitness',
  STRENGTH: 'strength',
  BEHAVIORAL: 'behavioral',
};

export const CATEGORY_OPTIONS = [
  { value: CATEGORIES.DIET, label: 'Diet' },
  { value: CATEGORIES.FITNESS, label: 'Fitness' },
  { value: CATEGORIES.WELLNESS, label: 'Wellness' },
  { value: CATEGORIES.STRENGTH, label: 'Strength' },
  { value: CATEGORIES.BEHAVIORAL, label: 'Behavioral' },
];

export const ALL_CATEGORIES = {
  [CATEGORIES.DIET]: DIET_OPTIONS.concat([{ value: 'other', label: 'Other' }]),
  [CATEGORIES.FITNESS]: FITNESS_OPTIONS.concat([
    { value: 'other', label: 'Other' },
  ]),
  [CATEGORIES.WELLNESS]: WELLNESS_OPTIONS.concat([
    { value: 'other', label: 'Other' },
  ]),
  [CATEGORIES.STRENGTH]: STRENGTH_OPTIONS.concat([
    { value: 'other', label: 'Other' },
  ]),
  [CATEGORIES.BEHAVIORAL]: BEHAVIORAL_OPTIONS.concat([
    { value: 'other', label: 'Other' },
  ]),
};

/** UNIT */

export const LENGTH_OPTIONS = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
];

export const GOAL_OPTIONS = [
  { value: 'bmi', label: 'BMI' },
  { value: 'weight', label: 'Weight' },
];

/** PROGRAM */

export const PROGRAM_TYPE = {
  ONGOING: 'ongoing',
  FULL: 'full',
};

export const PROGRAM_STATUS = {
  CURRENT: 'active',
  COMPLETED: 'completed',
  DRAFT: 'draft',
};

export const PROGRAM_ACTION_TYPES = [
  'weight',
  CATEGORIES.DIET,
  CATEGORIES.WELLNESS,
  CATEGORIES.FITNESS,
  CATEGORIES.STRENGTH,
];

/** FORM OPTIONS */

/** USER access level */

export const USER_ACCESS_LEVEL = {
  PREMIUM: 'premium',
  FREE: 'free',
};

/** UNIT */

export const UNIT_OPTIONS = {
  [CATEGORIES.FITNESS]: [
    { value: 'minutes', label: 'Minutes' },
    { value: 'miles', label: 'Miles' },
    { value: 'reps-sets', label: 'Reps/Sets' },
  ],
  [CATEGORIES.STRENGTH]: [
    { value: 'lbs', label: 'Loads (lbs)' },
    { value: 'reps-sets', label: 'Reps/Sets' },
  ],
  [CATEGORIES.DIET]: [
    { value: 'calories', label: 'Calories' },
    { value: 'protein', label: 'Protein (grams)' },
    { value: 'carbs', label: 'Carbs (grams)' },
    { value: 'fat', label: 'Fat (grams)' },
  ],
  weight: [
    { value: 'bmi', label: 'BMI' },
    { value: 'lbs', label: 'Weight (lbs)' },
    { value: 'kg', label: 'Weight (kg)' },
  ],
};
