import React from 'react';
import CommonBarChart from './CommonBarChart';

const RANGES = [
  [0, 10],
  [10, 30],
  [30, 60],
  [60, 120],
];
const Y_LABELS = [120, 60, 30, 10];

function WellnessChart({ sectionType, ...props }) {
  return (
    <CommonBarChart
      sectionType={sectionType}
      yLabels={Y_LABELS}
      ranges={RANGES}
      yAxisLabel="Minutes"
      colorOptions={['Under', 'Ideal', 'Over', 'Obese']}
      {...props}
    />
  );
}

export default WellnessChart;
