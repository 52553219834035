import React, { useMemo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';

import { Box, Body1, IconButton, Button, SvgIcon } from 'components/core';
import { ProgressLoader } from 'components/loading';

import 'react-image-crop/dist/ReactCrop.css';

import API from 'services/api';
import { colors } from 'styles/theme';
import { generateUploadKey } from 'utils/form';
import { getCroppedImg } from 'utils/crop';

const PreviewContainer = styled(Box)`
  position: relative;
  min-height: 223px;
  padding: 0;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PreviewOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const ImagePreview = styled.img`
  height: 223px;
`;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'center',
  padding: 0,
  borderWidth: 1,
  borderRadius: 6,
  borderColor: colors.text,
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: '223px',
};

const activeStyle = {
  borderColor: colors.text,
  backgroundColor: colors.line,
};

const acceptStyle = {
  borderColor: colors.text,
};

const rejectStyle = {
  borderColor: colors.red,
};

const RATIO = 1200 / 630;

function ShareUploadForm({
  id,
  onSubmit,
  onCancel,
  isLoading,
  initialUrl,
  ...props
}) {
  const [url, setUrl] = useState(initialUrl);
  const [isUploading, setIsUploading] = useState(false);
  const [imgObj, setImgObj] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [crop, setCrop] = useState(initialUrl ? { aspect: RATIO } : null);
  const uploadKey = generateUploadKey(id, 'share', 0, 0);

  const resetUpload = () => {
    setUrl('');
  };

  const uploadCallback = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  const onUpload = async () => {
    const fileToUpload = await getCroppedImg(imgObj, crop, id);

    setIsUploading(true);
    setUploadProgress(0);

    const fileKey = `${uploadKey}-${fileToUpload.name}`;

    const uploadAssetUrl = await API.uploadFile(
      fileToUpload,
      fileKey,
      uploadCallback
    );

    setIsUploading(false);

    setUrl(uploadAssetUrl);
    // submit something
    onSubmit(uploadAssetUrl);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles || !acceptedFiles.length) {
      return;
    }
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setUrl(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      alignItems="center"
      justifyContent="flex-start"
      p={5}
      {...props}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        {...props}
      >
        {!url && (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Body1>Drag or upload a file here</Body1>
          </div>
        )}
        {url && url === initialUrl && (
          <PreviewContainer>
            <CloseButton borderWidth={0} onClick={resetUpload}>
              <SvgIcon name="delete" />
            </CloseButton>
            <ImagePreview src={url} />
          </PreviewContainer>
        )}
        {url && url !== initialUrl && (
          <PreviewContainer>
            {isUploading ? (
              <PreviewOverlay>
                <ProgressLoader
                  value={uploadProgress}
                  text={`${uploadProgress}%`}
                />
              </PreviewOverlay>
            ) : (
              <ReactCrop
                src={url}
                crop={crop}
                onImageLoaded={(image) => {
                  setImgObj(image);
                  const wide = image.width > image.height * RATIO;
                  setCrop({
                    x: 0,
                    y: 0,
                    width: wide ? image.height * RATIO : image.width,
                    height: wide ? image.height : image.width / RATIO,
                    aspect: RATIO,
                  });
                  return false;
                }}
                onChange={(newCrop) => setCrop(newCrop)}
              />
            )}
            {!isUploading && (
              <CloseButton borderWidth={0} onClick={resetUpload}>
                <SvgIcon name="delete" />
              </CloseButton>
            )}
          </PreviewContainer>
        )}
      </Box>
      <Box display="flex" mt={4}>
        <Button
          variant="third"
          onClick={onCancel}
          disabled={isUploading || isLoading}
          mr={4}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          onClick={onUpload}
          disabled={
            !url || !crop || isUploading || isLoading || url === initialUrl
          }
        >
          Upload
        </Button>
      </Box>
    </Box>
  );
}

export default ShareUploadForm;
