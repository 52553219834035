import Tippy, { TippySingleton, useSingleton } from '@tippy.js/react';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/translucent.css';
import './styles.css';

const Tooltip = Tippy;

Tooltip.defaultProps = {};

export default Tooltip;
export const TooltipSingleton = TippySingleton;
export const useTooltipSingleton = useSingleton;
