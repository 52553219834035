export const TEMPLATES_OPTIONS = [
  {
    value: 'intermittent-2w',
    label: 'Intermittent fast - 2 weeks',
    countDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    category: 'diet',
    data: {
      type: 'fasting',
      note: '20/4, 20 hour fast with 4 hour nutrition window',
      weekdays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    },
  },
];
