import React from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';

import { Box, Title1 } from 'components/core';
import { PlanSelect } from 'components/others';
import { StripeForm } from 'containers';

import SettingsActions from 'redux/SettingsRedux';

import BackgroundImage from 'assets/girl.png';
import BgGradient from 'assets/gradient-bg.svg';
import { colors } from 'styles/theme';
import API from 'services/api';
import { getMainStripeInstance } from 'services/stripe';
import history from 'utils/history';
import Analytics, { EVENTS } from 'services/analytics';

const Wrapper = styled(Box)`
  min-width: 100vw;
  min-height: calc(100vh - 96px);
  background-size: cover;
  background: url(${BackgroundImage}) no-repeat,
    url(${BgGradient}) no-repeat #080808;
  background-position: top left;
  @media (max-width: 768px) {
    background-position: top center;
  }
  position: relative;
`;

const InnerWrapper = styled(Box)`
  padding: 40px 0;
  margin: 0 auto;
  width: 1180px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 540px;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

export const Divider = styled(Box)`
  background-color: ${colors.text};
  opacity: 0.6;
  height: 1px;
  align-self: stretch;
`;

const PLANS = [
  {
    name: 'master',
    title: 'Bcult Master',
    description: [
      'Unlimited premium programs',
      'Top of search promotion for all created programs',
      'Rank points double for each completed program',
    ],
    price: '29 / mo',
  },
];

function UpgradePremiumPage({ loadSettings }) {
  const handleSubscribe = async (paymentToken) => {
    await API.upgradeToPremium(paymentToken);
    Analytics.track(EVENTS.USER_UPGRADED_TO_MASTER);
    loadSettings();
    history.push('/settings');
  };

  return (
    <Wrapper pt={8} pb={8} px={2}>
      <InnerWrapper>
        <ContentWrapper>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignSelf="stretch"
            minHeight={['unset', 264]}
            mt={9}
            mb={9}
          >
            <PlanSelect value="master" plans={PLANS} />
          </Box>
        </ContentWrapper>
        <ContentWrapper ml={8}>
          <Title1 color="white" mb={3}>
            Pay with card
          </Title1>
          <Divider mb={6} width={0.5} />
          <Elements stripe={getMainStripeInstance()}>
            <StripeForm
              onSubmit={handleSubscribe}
              price={2900}
              buttonText="Join"
            />
          </Elements>
        </ContentWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(SettingsActions.setLoadSettingsRequest()),
});

export default connect(null, mapDispatchToProps)(UpgradePremiumPage);
