import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import get from 'lodash-es/get';
import every from 'lodash-es/every';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from 'components/core';
import { colors } from 'styles/theme';
import { ProfileSelectors } from 'redux/ProfileRedux';
import CalendarActions from 'redux/CalendarRedux';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1px solid ${colors.line};
  border-radius: 2px;
  cursor: pointer;
`;

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const CalendarItem = styled(Box)`
  border: ${(props) => (props.hasTasks ? 5 : 1)}px solid
    ${(props) => (props.isDisabled ? colors.line : colors.text)};
  background: ${(props) => (props.isComplete ? colors.text : 'transparent')};
  border-radius: ${(props) => (props.isToday ? 12 : 1)}px;

  width: ${(props) => props.itemSize}px;
  height: ${(props) => props.itemSize}px;
`;

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const weeks = [1, 2, 3, 4, 5, 6];

function CalendarBox({ size = 280, itemSize = 24, ...props }) {
  const monthStart = moment().startOf('month');
  const tasksMap = useSelector(ProfileSelectors.selectTaskMap);
  const dispatch = useDispatch();

  const handleDateClick = (date) => {
    if (size >= 280) {
      dispatch(CalendarActions.setCurrentCalendarDay({ date }));
    }
  };

  return (
    <Wrapper size={size} {...props}>
      {weeks.map((week) => (
        <Row key={week}>
          {days.map((day, dayIndex) => {
            const momentDate = monthStart
              .clone()
              .add(week - 1, 'week')
              .day(dayIndex, 'day');
            const dateNum = momentDate.date();
            const monthNum = momentDate.month();
            const yearNum = momentDate.year();

            const tasks =
              get(tasksMap, `${yearNum}-${monthNum}.${dateNum}`) || [];
            const isComplete =
              tasks.length > 0 && every(tasks, (t) => t.record);
            const isDisabled = !monthStart.isSame(momentDate, 'month');

            return (
              <CalendarItem
                itemSize={itemSize}
                key={day}
                hasTasks={tasks.length > 0}
                isComplete={isComplete}
                isToday={moment().isSame(momentDate, 'day')}
                isDisabled={isDisabled}
                onClick={
                  !isDisabled ? () => handleDateClick(dateNum) : undefined
                }
              />
            );
          })}
        </Row>
      ))}
    </Wrapper>
  );
}

export default CalendarBox;
