import get from 'lodash-es/get';
import API from 'services/api';

export default function getOptions(user) {
  const OPTIONS = [
    {
      index: 0,
      label: 'Followers',
      value: get(user, 'stats.followerCount', 0),
      category: 'Community',
      icon: 'program-share',
      loadData: () => API.getUserAssociations(user._id, 'followers'),
    },
    {
      index: 1,
      label: 'Subscribers',
      value: get(user, 'stats.programSubscriberCount', 0),
      category: 'Community',
      icon: 'program-share',
      loadData: () => API.getUserAssociations(user._id, 'subscribers'),
    },
    {
      index: 2,
      label: 'Following',
      value: get(user, 'stats.followingCount', 0),
      category: 'Community',
      icon: 'program-share',
      loadData: () => API.getUserAssociations(user._id, 'following'),
    },
  ];

  return OPTIONS;
}
