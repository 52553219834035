import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Picker, Emoji } from 'emoji-mart';
import { Box, Button, BTextArea } from 'components/core';
import useOutsideClick from 'hooks/useOutsideClick';

import 'emoji-mart/css/emoji-mart.css';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

const EmojiContainer = styled(Box)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const EmojiPickerContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const EmojiTrigger = styled(Box)`
  cursor: pointer;
  user-select: none;
`;

// @TODO double check emoji button
const Smiley = Emoji({
  set: 'apple',
  emoji: 'slightly_smiling_face',
  size: 24,
});

function ChatForm({ defaultValue, onSubmit, onCancel, buttonText }) {
  const [showPicker, setShowPicker] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const ref = useRef(null);
  const textRef = useRef(null);

  const handleAddEmoji = (e) => {
    const emoji = e.native;
    setValue(value + emoji);
    setShowPicker(false);
    textRef.current.focus();
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await onSubmit(value);
      setValue('');
    } catch (e) {
      console.error(e);
    }
    setSubmitting(false);
  };

  useOutsideClick(ref, () => {
    setShowPicker(false);
  });

  return (
    <Box position="relative">
      <TextArea
        ref={textRef}
        disabled={submitting}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Write your message here..."
      />
      <EmojiContainer>
        {showPicker ? (
          <EmojiPickerContainer ref={ref}>
            <Picker onSelect={handleAddEmoji} />
          </EmojiPickerContainer>
        ) : (
          <EmojiTrigger onClick={() => setShowPicker(true)}>
            {Smiley}
          </EmojiTrigger>
        )}
      </EmojiContainer>
      <Box
        mt={[2, 3]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          type="submit"
          variant="secondary"
          disabled={!value || submitting}
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
        {onCancel && (
          <Button ml={[2, 3]} type="button" variant="third" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Box>
    </Box>
  );
}

ChatForm.propTypes = {
  defaultValue: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  buttonText: PropTypes.string,
};

ChatForm.defaultProps = {
  defaultValue: '',
  buttonText: 'Submit',
};

export default ChatForm;
