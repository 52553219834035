import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';
import { Body2 } from './Typography';
import SvgIcon from './SvgIcon';
import IconButton from './IconButton';

import { colors } from 'styles/theme';

const Wrapper = styled(Box)`
  position: relative;
  outline: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  width: 176px;
`;

const OptionWrapper = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${colors.background};
  border: 1px solid ${colors.text};
  box-sizing: border-box;
  border-radius: 2px;
  z-index: 99;

  @media (max-width: 768px) {
    ${(props) =>
      props.leftSide
        ? css`
            left: 0;
            right: unset;
          `
        : css`
            left: unset;
            right: 0;
          `};
  }
`;

const Option = styled(Box)`
  cursor: pointer;
  min-height: 48px;
  background: ${(props) => (props.selected ? colors.text : colors.background)};

  &:hover {
    background: ${(props) => (props.selected ? colors.text : colors.line)};
  }
`;

const ExpandButton = styled(IconButton)`
  height: 24px;
  width: 100%;

  margin-top: -16px;
`;

const CloseButton = styled(Box)`
  height: 24px;
  width: 100%;
  background: ${colors.text};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  path {
    stroke: ${colors.contrastText};
  }
`;

const Image = styled.img`
  width: 100%;
`;

function Dropdown({
  value,
  dropdownlabel = 'dropdown',
  options = [],
  tabIndex = 0,
  onChange,
  image,
  leftSide = true,
  ...props
}) {
  const [open, setOpen] = useState(false);

  const onClickOption = (option) => {
    // const { value } = option;

    if (onChange) {
      onChange(option);
    }
  };

  const onClick = () => {
    setOpen(!open);
  };

  const onBlur = () => {
    setOpen(false);
  };

  return (
    <Wrapper
      bg="clear"
      tabIndex={tabIndex}
      onClick={onClick}
      onBlur={onBlur}
      {...props}
    >
      <Image src={image} alt={dropdownlabel} />
      <ExpandButton mt={2} fontSize={0} borderWidth={0}>
        <SvgIcon name="chevron-down" />
      </ExpandButton>
      {open && (
        <OptionWrapper leftSide={leftSide}>
          {options.map((option) => {
            const { value: optionValue, label: optionLabel } = option;
            const isSelected = value === optionValue;
            return (
              <Option
                p={3}
                key={optionValue}
                selected={isSelected}
                onClick={() => onClickOption(option)}
              >
                <Body2 color={isSelected ? 'contrastText' : 'text'}>
                  {optionLabel}
                </Body2>
              </Option>
            );
          })}
          <CloseButton>
            <SvgIcon name="chevron-up" />
          </CloseButton>
        </OptionWrapper>
      )}
    </Wrapper>
  );
}

export default Dropdown;
