import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import filter from 'lodash-es/filter';
import styled from 'styled-components';
import { Box, Title1, Caption2, SvgIcon } from 'components/core';
import { colors } from 'styles/theme';
import { Avatar } from 'components/program';
import { AboutView } from 'containers/profile';

const UserItemContainer = styled(Box)`
  border-radius: 1px;
  border: 1px solid ${colors.line};
  display: flex;
  align-items: center;
  padding: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px;
  }
`;

const Icon = styled(SvgIcon)`
  width: 40px;
  height: 40px;
  path {
    fill: ${colors.line};
  }
`;

function UserItem({ user, vewportWith }) {
  const isSmallWindow = vewportWith < 500;
  if (isSmallWindow) {
    return (
      <UserItemContainer mb={4}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignSelf="stretch"
          mb={4}
        >
          <Box flex={1}>
            <Link to={`/users/${user.id}`}>
              <Avatar name={user.name} {...user.avatar} size={100} mb={2} />
            </Link>
            <Caption2 as={Link} to={`/users/${user.id}`}>
              {user.name}
            </Caption2>
          </Box>
          <Box
            flexDirection="column"
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flex={1}
          >
            <Box mr={10} display="flex" alignItems="flex-start">
              <Icon name="program-new" width={40} height={40} mr={4} />
              <Title1>
                {filter(user.programs, (p) => !p.subscribedFrom).length}
              </Title1>
            </Box>
            <Box mr={10} display="flex" alignItems="flex-start">
              <Icon name="program-completed" width={40} height={40} mr={4} />
              <Title1>{user.stats.completedProgramCount || 0}</Title1>
            </Box>
            <Box display="flex" alignItems="flex-start">
              <Icon name="balance" width={40} height={40} mr={4} />
              <Title1>{Math.abs(user.stats.weightChange || 0)} lbs</Title1>
            </Box>
          </Box>
        </Box>
        <AboutView
          profile={user.profile}
          collapsible={false}
          width={vewportWith - 16}
        />
      </UserItemContainer>
    );
  }
  return (
    <UserItemContainer mb={4}>
      <Box mr={8}>
        <Link to={`/users/${user.id}`}>
          <Avatar name={user.name} {...user.avatar} size={100} mb={2} />
        </Link>
        <Caption2 as={Link} to={`/users/${user.id}`}>
          {user.name}
        </Caption2>
      </Box>
      <AboutView profile={user.profile} collapsible={false} width={400} />
      <Box
        p={6}
        flexDirection={['column', 'row']}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        <Box mr={10} display="flex" alignItems="center">
          <Icon name="program-new" width={40} height={40} mr={4} />
          <Title1>
            {filter(user.programs, (p) => !p.subscribedFrom).length}
          </Title1>
        </Box>
        <Box mr={10} display="flex" alignItems="center">
          <Icon name="program-completed" width={40} height={40} mr={4} />
          <Title1>{user.stats.completedProgramCount || 0}</Title1>
        </Box>
        <Box display="flex" alignItems="center">
          <Icon name="balance" width={40} height={40} mr={4} />
          <Title1>{Math.abs(user.stats.weightChange || 0)} lbs</Title1>
        </Box>
      </Box>
    </UserItemContainer>
  );
}

UserItem.propTypes = {
  user: PropTypes.object,
};

export default UserItem;
