import React from 'react';
import styled from 'styled-components';

import { Box } from 'components/core';
import { Header } from 'containers/nav';

const Wrapper = styled(Box)`
  min-width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-repeat: repeat-y;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderWrapper = styled(Box)`
  min-width: 100vw;
  height: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1230px;
`;

const HeaderContainer = styled(MainContainer)`
  flex-direction: row;
  flex: 1;
`;

const Page = styled(MainContainer)`
  flex: 1;
`;

const AppLayout = ({ children }) => (
  <Wrapper>
    <HeaderWrapper bg="main">
      <HeaderContainer>
        <Header />
      </HeaderContainer>
    </HeaderWrapper>
    <Page>{children}</Page>
  </Wrapper>
);

export default AppLayout;
