import { set, get, isObject } from 'lodash-es';
import PlaceHolderAvatar from 'assets/Avatar.png';
import { PROGRAM_TYPE, PROGRAM_STATUS } from '../services/terms';
import {
  getCurrentDayForProgram,
  getCurrentDateOfProgram,
} from '../utils/date';

/** analytcs */

export const transformProgramForAnalytics = (programInfo) => {
  const { id: programId, title, approved, featured, status } = programInfo;

  const createdUserId = get(programInfo, 'user.id');
  const createdUserName = get(programInfo, 'user.name');

  return {
    programId,
    title,
    approved,
    featured,
    createdUserId,
    createdUserName,
    status,
  };
};

/**
 * user
 */

export const transformUser = (itemApi) => {
  const { _id: id, profile, ranking, ...rest } = itemApi || {};

  const { username, avatar } = profile || {};

  const avatarUrl = (avatar || PlaceHolderAvatar).replace(
    'bcult.s3.us-east-2.amazonaws.com',
    'bcult.imgix.net'
  );

  let rank = parseInt(ranking) || 0;
  rank = rank + 1;

  return {
    id,
    name: String(username).substr(0, 8),
    avatar: {
      rank,
      url: avatarUrl,
    },
    profile,
    ...rest,
  };
};

/**
 * PROGRAMS
 */

export const convertProgramItemForApi = (item) => {
  const { assetType, assetUrl, thumbUrl, ...resetItem } = item;

  if (assetType && assetUrl) {
    return {
      asset: {
        assetType,
        url: assetUrl,
        thumbUrl,
      },
      ...resetItem,
    };
  }

  return resetItem;
};

const convertProgramItem = (itemApi) => {
  const { asset, _id: id, ...resetItem } = itemApi;
  const { url: assetUrl, ...restAsset } = asset || {};
  return {
    ...restAsset,
    ...resetItem,
    assetUrl,
    id,
  };
};

const genDayMap = (arr = []) => {
  const result = {};
  arr.forEach((item) => {
    const { day } = item;
    result[day] = convertProgramItem(item);
  });
  return result;
};

export const transformProgram = (apiResponse) => {
  const {
    _id: id,
    sections: allData,
    dayCount,
    goalBmi,
    goalWeight,
    status,
    startDate,
    endDate,
    createdBy,
    avgRating,
    subscriptionCount,
    weightChange,
    user: currentUserId,
    ...rest
  } = apiResponse;

  let isSubscribedFromOther = false;
  if (!isObject(currentUserId)) {
    isSubscribedFromOther = get(createdBy, '_id') !== currentUserId;
  }

  const currentDay = getCurrentDayForProgram(startDate);

  const user = transformUser(createdBy);

  const data = [];
  const sectionIds = [];
  const sections = allData.map((section) => {
    const { actions, sectionType, _id: sectionId } = section;
    const sectionData = genDayMap(actions);

    data.push(sectionData);
    sectionIds.push(sectionId);

    return sectionType;
  });

  return {
    id,
    stats: {
      avgRating: avgRating ? parseInt(avgRating) + 1 : 1,
      subscriptionCount,
      weightChange,
    },
    program: {
      sections,
      data,
      goal: {
        bmi: goalBmi,
        weight: goalWeight,
      },
      status,
      sectionIds,
      startDate,
      endDate,
      currentDay,
      dayCount,
    },
    ...rest,
    user,
    status,
    isSubscribedFromOther,
  };
};

export const transformCreateProgramForApi = (
  programInfo,
  isForEditing,
  isDraft
) => {
  const {
    createMode,
    title,
    description,
    categoryFilter,
    program,
    price,
  } = programInfo;

  const isOngoing = createMode === 'new';

  const { goal, sections, data } = program;
  const { bmi, weight } = goal || {};

  const allSections = sections.map((sectionType, sectionIndex) => {
    const sectionData = data[sectionIndex];

    const actions = Object.keys(sectionData).map((day) => {
      return {
        day: parseInt(day),
        ...convertProgramItemForApi(sectionData[day]),
      };
    });

    return {
      sectionType,
      actions,
    };
  });

  let type = undefined;

  if (!isForEditing) {
    type = isOngoing ? PROGRAM_TYPE.ONGOING : PROGRAM_TYPE.FULL;
  }

  let status = isOngoing ? undefined : PROGRAM_STATUS.COMPLETED;
  if (isDraft && !isOngoing) {
    status = PROGRAM_STATUS.DRAFT;
  }

  return {
    title,
    description: description ? description : undefined,
    ...categoryFilter,
    type,
    goalBmi: bmi ? bmi : undefined,
    goalWeight: weight ? weight : undefined,
    isPublic: true,
    sections: allSections,
    status,
    price: isOngoing ? undefined : price,
  };
};

/** rating */

export const transformRatingsItem = (apiResponse) => {
  const { user, description, score, _id: id } = apiResponse;

  let rating = parseInt(score) || 0;
  rating = rating + 1;

  return {
    user: transformUser(user),
    description,
    rating,
    id,
  };
};

export const transformSummaryItem = (apiResponse) => {
  const { user, change, _id: id } = apiResponse;

  return {
    user: transformUser(user),
    change,
    id,
  };
};

/** tasks */
const NON_TRACKABLE_TASKS = ['bmi', 'weight', 'master'];

export const convertProgramsToTask = (programs = []) => {
  const tasksMap = {};
  programs.forEach((programInfo) => {
    const { program } = programInfo;

    const { sections, data, startDate, sectionIds } = program;

    if (!startDate || !sections.length) {
      return;
    }

    sections.forEach((category, sectionIndex) => {
      if (NON_TRACKABLE_TASKS.includes(category)) {
        return;
      }

      const sectionData = data[sectionIndex];

      for (let day in sectionData) {
        if (sectionData.hasOwnProperty(day)) {
          const taskData = {
            ...sectionData[day],
            programId: programInfo.id,
            sectionId: sectionIds[sectionIndex],
            category,
          };

          const momentDate = getCurrentDateOfProgram(startDate, day).add(
            day - 1,
            'day'
          );

          const monthNum = momentDate.month();
          const yearNum = momentDate.year();
          const dateNum = momentDate.date();

          const path = `${yearNum}-${monthNum}.${dateNum}`;
          const taskArray = get(tasksMap, path);
          if (!taskArray) {
            set(tasksMap, path, [taskData]);
          } else {
            taskArray.push(taskData);
          }
        }
      }
    });
  });

  return tasksMap;
};
