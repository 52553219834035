import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Box from './Box';
import SvgIcon from './SvgIcon';
import { Caption2 } from './Typography';
import useOutsideClick from 'hooks/useOutsideClick';

import { colors } from 'styles/theme';

const Wrapper = styled(Box)`
  position: relative;
  outline: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

const OptionWrapper = styled(Box)`
  position: absolute;
  min-width: ${(props) => props.minWidth || 400}px;
  top: 100%;
  right: 0;
  background: ${colors.white};
  border: 1px solid ${colors.text};
  box-sizing: border-box;
  border-radius: 2px;
  z-index: 99;
  padding: 25px 40px;
`;

const TextWrapper = styled(Caption2)`
  text-transform: uppercase;
`;

const Option = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${colors.white};

  margin-top: 20px;
`;

function Menu({ options = [], onChange, children, title, minWidth, ...props }) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const onClickOption = (option, e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);

    if (onChange) {
      onChange(option.value);
    }
  };

  const onClick = () => {
    setOpen(!open);
  };

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  return (
    <Wrapper ref={ref} bg="clear" onClick={onClick} {...props}>
      {children}
      {open && (
        <OptionWrapper minWidth={minWidth}>
          <TextWrapper>{title}</TextWrapper>
          {options.map((option) => {
            return (
              <Option
                key={option.value}
                onClick={(e) => onClickOption(option, e)}
              >
                <SvgIcon name={option.icon} mr={4} size={40} />
                <TextWrapper>{option.label}</TextWrapper>
              </Option>
            );
          })}
        </OptionWrapper>
      )}
    </Wrapper>
  );
}

export default Menu;
