import {
  CATEGORIES,
  STRENGTH_SUBCATEGORIES,
  FITNESS_SUBCATEGORIES,
  WELLNESS_SUBCATEGORIES,
} from 'services/terms';

const CATEGORY_IMAGES = {
  male: {
    [CATEGORIES.STRENGTH]: {
      [STRENGTH_SUBCATEGORIES.BODY_WEIGHT]: 'male-strength-body-weight.png',
      [STRENGTH_SUBCATEGORIES.FREE_WEIGHTS]: 'male-strength-machine.png',
      [STRENGTH_SUBCATEGORIES.MACHINE]: 'male-strength-machine.png',
    },
    [CATEGORIES.FITNESS]: {
      [FITNESS_SUBCATEGORIES.HIIT]: 'male-fitness-cardio.png',
      [FITNESS_SUBCATEGORIES.CARDIO]: 'male-fitness-cardio.png',
      [FITNESS_SUBCATEGORIES.STRENGTH]: 'male-fitness-low-impact.png',
      [FITNESS_SUBCATEGORIES.LOW_IMPACT]: 'male-fitness-low-impact.png',
      [FITNESS_SUBCATEGORIES.TONE]: 'male-fitness-low-impact.png',
    },
    [CATEGORIES.WELLNESS]: {
      [WELLNESS_SUBCATEGORIES.YOGA]: 'male-wellness-yoga.png',
      [WELLNESS_SUBCATEGORIES.MEDITATION]: 'male-wellness-yoga.png',
      [WELLNESS_SUBCATEGORIES.STRETCHING]: 'male-wellness-yoga.png',
      [WELLNESS_SUBCATEGORIES.PILATES]: 'male-wellness-yoga.png',
    },
  },
  female: {
    [CATEGORIES.STRENGTH]: {
      [STRENGTH_SUBCATEGORIES.BODY_WEIGHT]: 'female-strength-body-weight.png',
      [STRENGTH_SUBCATEGORIES.FREE_WEIGHTS]: 'female-strength-machine.png',
      [STRENGTH_SUBCATEGORIES.MACHINE]: 'female-strength-machine.png',
    },
    [CATEGORIES.FITNESS]: {
      [FITNESS_SUBCATEGORIES.HIIT]: 'female-fitness-cardio.png',
      [FITNESS_SUBCATEGORIES.CARDIO]: 'female-fitness-cardio.png',
      [FITNESS_SUBCATEGORIES.STRENGTH]: 'female-fitness-hiit.png',
      [FITNESS_SUBCATEGORIES.LOW_IMPACT]: 'female-fitness-cardio.png',
      [FITNESS_SUBCATEGORIES.TONE]: 'female-fitness-cardio.png',
    },
    [CATEGORIES.WELLNESS]: {
      [WELLNESS_SUBCATEGORIES.YOGA]: 'female-wellness-yoga.png',
      [WELLNESS_SUBCATEGORIES.MEDITATION]: 'female-wellness-yoga.png',
      [WELLNESS_SUBCATEGORIES.STRETCHING]: 'female-wellness-stretching.png',
      [WELLNESS_SUBCATEGORIES.PILATES]: 'female-wellness-stretching.png',
    },
  },
};

const getImage = (category, subCategory, gender) => {
  if (category === 'bmi' || category === 'weight') {
    return 'weight.png';
  }

  if (category === 'supplement' || category === 'diet') {
    return `${category}.png`;
  }

  try {
    const image = CATEGORY_IMAGES[gender][category][subCategory];
    return image;
  } catch (e) {
    return '';
  }
};

export const getCategoryImageKey = (category, subCategory, gender = 'male') => {
  return `category/${getImage(category, subCategory, gender)}`;
};
