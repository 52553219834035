import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, Button, Body1, Body2, Body2Dark } from 'components/core';

import { AuthSelectors } from 'redux/AuthRedux';
import SettingsActions from 'redux/SettingsRedux';

import API from 'services/api';
import history from 'utils/history';
import confirm from 'lib/confirm';
import { getErrors } from 'utils/err';
import Analytics, { EVENTS } from 'services/analytics';

const VENDOR_REDICRECT_URI = `${process.env.REACT_APP_APP_URL}/settings/vendor`;
const STRIPE_CLIENT_ID = `${process.env.REACT_APP_STRIPE_CLIENT_ID}`;

function BillingSettings({
  isPremiumUser,
  isVendorUser,
  loadSettings,
  ...props
}) {
  const PLAN_TEXT = isPremiumUser ? 'Master Plan' : 'Free';
  const CTA_TEXT = isPremiumUser ? 'Cancel Premium Plan' : 'Upgrade To Master';
  const [error, setError] = useState('');

  const cancelPremium = async () => {
    try {
      await API.cancelPremium();
      Analytics.track(EVENTS.USER_DOWNGRADE_FROM_MASTER);
      loadSettings();
    } catch (e) {
      setError(
        getErrors(e).message || 'There was problem canceling premium plan.'
      );
    }
  };

  const handleChangePremium = async () => {
    if (!isPremiumUser) {
      history.push('/settings/premium');
    } else {
      if (await confirm('Are you sure you want to cancel premium plan?')) {
        cancelPremium();
      }
    }
  };

  const handleAddVendor = async () => {
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${STRIPE_CLIENT_ID}&redirect_uri=${VENDOR_REDICRECT_URI}`;
  };

  return (
    <Box {...props}>
      <Body1 my={4}>Account Plan</Body1>
      <Box display="flex" flexDirection="column" alignItems="flex-start" mb={8}>
        <Body2Dark mb={4}>{PLAN_TEXT}</Body2Dark>
        <Button variant="secondary" width={250} onClick={handleChangePremium}>
          {CTA_TEXT}
        </Button>
        {error && <Body2 color="red">{error}</Body2>}
      </Box>
      <Body1 my={4}>Seller</Body1>
      <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
        {isVendorUser && <Body2 mb={4}>Stripe account connected</Body2>}
        {!isVendorUser && (
          <Button variant="secondary" width={250} onClick={handleAddVendor}>
            Connect stripe account
          </Button>
        )}
      </Box>
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  isPremiumUser: AuthSelectors.selectIsPremiumUser(state),
  isVendorUser: AuthSelectors.selectIsVendorUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(SettingsActions.setLoadSettingsRequest()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(BillingSettings);
