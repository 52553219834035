import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Title2, SvgIcon } from 'components/core';
import { ConfirmModal } from 'components/others';
import { BaseChatMessage, ChatFormModal } from 'components/chat';
import API from 'services/api';

const ReplyToggle = styled(Title2)`
  cursor: pointer;
  user-select: none;
`;

const ChatMessageContainer = styled(Box)`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

function ChatMessage({
  programId,
  id,
  currentUser,
  user,
  message,
  replies,
  onUpdateReply,
  ...rest
}) {
  const count = replies.length;
  const [expand, setExpand] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const handleSave = async (message) => {
    if (editingItem._id !== 'new') {
      await API.updateChatMessage(programId, editingItem._id, message);
      onUpdateReply(
        replies.map((m) =>
          m._id === editingItem._id
            ? {
                ...m,
                message,
              }
            : m
        )
      );
    } else {
      const data = await API.replyMessage(programId, id, message);
      onUpdateReply([{ ...data, user: currentUser }, ...replies]);
    }
    setEditingItem(null);
    setExpand(true);
  };

  const handleDelete = async () => {
    try {
      await API.deleteChatMessage(programId, deletingId);
      onUpdateReply(replies.filter((m) => m._id !== deletingId));
      setDeletingId(null);
    } catch (e) {}
  };

  return (
    <ChatMessageContainer>
      <BaseChatMessage user={user} message={message} {...rest}>
        <Box>
          <Box display="flex" alignItems="center" mt={2}>
            {count > 0 && (
              <>
                <ReplyToggle onClick={() => setExpand(!expand)}>
                  <SvgIcon
                    name={expand ? 'chevron-up' : 'chevron-down'}
                    mr={2}
                  />
                  {count === 1 ? `${count} reply` : `${count} replies`}
                </ReplyToggle>
                <Title2 mx={3}>|</Title2>
              </>
            )}
            <ReplyToggle onClick={() => setEditingItem({ _id: 'new' })}>
              Reply Message
            </ReplyToggle>
          </Box>
          {expand && (
            <Box>
              {replies.map((r) => (
                <BaseChatMessage
                  mt={2}
                  key={r._id}
                  user={r.user}
                  message={r.message}
                  createdAt={r.createdAt}
                  isEditable={
                    currentUser.role === 'admin' ||
                    currentUser._id === r.user._id
                  }
                  onEdit={() => setEditingItem(r)}
                  onDelete={() => setDeletingId(r._id)}
                />
              ))}
            </Box>
          )}
        </Box>
      </BaseChatMessage>
      {editingItem && (
        <ChatFormModal
          show
          title={editingItem._id === 'new' ? 'Reply Message' : 'Edit Message'}
          defaultValue={editingItem.message}
          buttonText={editingItem._id === 'new' ? 'Submit' : 'Update'}
          onSubmit={handleSave}
          onCancel={() => setEditingItem(null)}
        />
      )}
      <ConfirmModal
        show={!!deletingId}
        text="Are you sure you want to delete this message?"
        onCancel={() => setDeletingId(null)}
        onOk={handleDelete}
      />
    </ChatMessageContainer>
  );
}

ChatMessage.propTypes = {
  id: PropTypes.string,
  programId: PropTypes.string,
  isEditable: PropTypes.bool,
  onUpdateReply: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  currentUser: PropTypes.object,
  user: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  replies: PropTypes.array,
};

export default ChatMessage;
