import React from 'react';
import styled from 'styled-components';
import { Box } from 'components/core';

import { ALL_IMAGES } from 'styles/images';

const ProgramRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const ImageWrapper = styled(Box)`
  max-width: 112px;
  max-height: 112px;
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
  cursor: pointer;
`;

const FEATURED_TYPES = [
  'fitness',
  'wellness',
  'supplement',
  'diet',
  'weight',
  'strength',
];

const FeaturedTypeSelect = ({ value, onSelect, ...props }) => {
  const handleClick = (name) => {
    if (onSelect) onSelect(name);
  };

  return (
    <ProgramRow {...props}>
      {FEATURED_TYPES.map((name, index) => (
        <ImageWrapper key={name} mx={name !== 'diet' ? 2 : 0}>
          <Image
            src={ALL_IMAGES[name]}
            onClick={() => handleClick(name, index)}
          />
        </ImageWrapper>
      ))}
    </ProgramRow>
  );
};

export default FeaturedTypeSelect;
