import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { AuthSelectors } from 'redux/AuthRedux';

import LogoImage from 'assets/Logo1.png';

import { Box, SvgIcon } from 'components/core';
import BgLinear from 'assets/linear-bg.svg';
import history from 'utils/history';

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Image = styled.img``;

// const ScrollIcon = styled(ScrollImage)`
//   margin-left: 20px;
// `;

const HeaderAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid #55bcf8;
  cursor: pointer;
`;

const BackImage = styled.img`
  width: 58px;
  height: 58px;
  min-width: 58px;
  position: absolute;
`;

const HeaderAvatarContainer = styled(Box)`
  width: 58px;
  height: 58px;
  min-width: 58px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationBadge = styled(Box)`
  position: absolute;
  right: 4px;
  bottom: 0px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: linear-gradient(
    342.65deg,
    #ffd84f 6.01%,
    #aa853d 54.53%,
    #d7bd61 83.11%
  );
`;

const MainHeader = ({ userAvatar, loggedIn, hasUnreadNotification, role }) => {
  const onNav = (route) => {
    history.push(`/${route}`);
  };

  return (
    <Box
      px={2}
      flex={1}
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
    >
      <StyledLink to="/">
        <Image src={LogoImage} alt="Bcult" />
      </StyledLink>
      {loggedIn && (
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          alignItems="center"
        >
          <HeaderAvatarContainer
            onClick={() => {
              onNav('profile');
            }}
          >
            <BackImage src={BgLinear} />
            <HeaderAvatar src={userAvatar} alt="Profile" />
            {hasUnreadNotification && <NotificationBadge></NotificationBadge>}
          </HeaderAvatarContainer>
          {role === 'ADMIN' && (
            <StyledLink to="/admin">
              <SvgIcon name="gender-american-male" ml={4} width={50} />
            </StyledLink>
          )}
          <Link to="/settings">
            <SvgIcon name="settings" ml={4} />
          </Link>
        </Box>
      )}
    </Box>
  );
};

const mapStatesToProps = (state) => ({
  loggedIn: AuthSelectors.selectIsLoggedIn(state),
  userAvatar: AuthSelectors.selectUserAvatar(state),
  role: AuthSelectors.selectUserRole(state),
  hasUnreadNotification: AuthSelectors.selectHasNotification(state),
});

export default connect(mapStatesToProps)(MainHeader);
