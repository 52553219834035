import React, { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import {
  Box,
  DialogBox,
  Body2,
  Body2Bold,
  Caption3,
  Body4,
  Body4Bold,
  Button,
  Checkbox,
} from 'components/core';
import confirm from 'lib/confirm';
import { colors } from 'styles/theme';
import { getErrors } from 'utils/err';
import { DefaultLoader, LoadingContainer } from 'components/loading';

const LINE1 = 'Would you like to promote your program?';
const LINE1_1 = 'Would you like to change your level of promotion?';
const LINE2 = '(Note, all Bcult Master members get free promotion)';
const LINE_CANCEL_1 = 'Would you like to cancel promotion for this program?';
const LINE_CANCEL_2 =
  'Would you like to cancel promotion for this program? If yes, you will have 1 promotion to apply to another program.';
const LINE_MASTER =
  'Would you like to apply a promotion to this program? If yes, you will deactivate any current promotions and have 0 remaining.';

const OPTIONS = [
  {
    plan: 'promo_1',
    description: (
      <Body4 as="span">
        <Body2 as="span">2.99</Body2> per week. Your program will be featured a{' '}
        <Body4Bold as="span">Search Boost</Body4Bold>.
      </Body4>
    ),
  },
  {
    plan: 'promo_2',
    description: (
      <Body4 as="span">
        <Body2 as="span">5.99</Body2> per week. Your program will be featured{' '}
        <Body4Bold as="span">2x Search Boost</Body4Bold>.
      </Body4>
    ),
  },
  {
    plan: 'promo_5',
    description: (
      <Body4 as="span">
        <Body2 as="span">10.99</Body2> per week. Your program will be featured{' '}
        <Body4Bold as="span">5x Search Boost</Body4Bold>.
      </Body4>
    ),
  },
  {
    plan: 'master',
    description: (
      <Body4 as="span">
        <Body2 as="span">Free</Body2> at{' '}
        <Body2Bold as="span">5x top of search</Body2Bold>{' '}
        <Body2 as="span">with Bcult Master</Body2>.
      </Body4>
    ),
  },
];

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CancelButton = styled(Body4)`
  display: inline-block;
  cursor: pointer;
  color: ${colors.red};
`;

function PromoteModal({
  show,
  isLoading,
  onOk,
  onCancel,
  defaultPromotion,
  isPremiumUser,
  ...props
}) {
  const [planId, setPlanId] = useState(
    defaultPromotion ? defaultPromotion.promotionType : null
  );
  const [error, setError] = useState('');
  const currentPromotionType = get(defaultPromotion, 'promotionType');

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    if (
      isPremiumUser &&
      currentPromotionType !== 'master' &&
      planId === 'master'
    ) {
      const result = await confirm(LINE_MASTER);
      if (!result) {
        return;
      }
    }

    try {
      await onOk(planId);
    } catch (e) {
      setError(getErrors(e).message || 'There was problem promoting program');
    }
  };

  const handleCancel = async () => {
    const result = await confirm(
      currentPromotionType === 'master' ? LINE_CANCEL_2 : LINE_CANCEL_1
    );

    if (result) {
      try {
        await onOk('cancel');
      } catch (e) {
        setError(
          getErrors(e).message || 'There was problem canceling promotion'
        );
      }
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }

    onCancel();
  };

  return (
    <DialogBox show={show} onClickOutside={handleClose}>
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        m={[5, 8]}
      >
        <Body2 textAlign="center">
          {currentPromotionType ? LINE1_1 : LINE1}
        </Body2>
        <Caption3 textAlign="center">{LINE2}</Caption3>

        <Wrapper mt={8}>
          {OPTIONS.map((item) => {
            const { plan, description } = item;
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                key={plan}
                mb={2}
              >
                <Checkbox
                  checked={planId === plan}
                  onChange={() => setPlanId(plan)}
                />
                <Box ml={2}>
                  {description}
                  {currentPromotionType && currentPromotionType === plan && (
                    <CancelButton ml={2} onClick={handleCancel}>
                      CANCEL
                    </CancelButton>
                  )}
                </Box>
              </Box>
            );
          })}
        </Wrapper>

        {error && <Body2 color="red">{error}</Body2>}

        <Box
          display="flex"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mt={8}
        >
          <Button
            variant="secondary"
            mx={4}
            onClick={handleSubmit}
            disabled={!planId || isLoading}
          >
            OK
          </Button>
        </Box>
      </Box>

      {isLoading && (
        <LoadingContainer>
          <DefaultLoader />
        </LoadingContainer>
      )}
    </DialogBox>
  );
}

export default PromoteModal;
