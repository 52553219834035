import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Box, Title1 } from 'components/core';
import { Animal, ProgressItem } from 'components/rank';

import useViewport from 'hooks/useViewport';

import { AuthSelectors } from 'redux/AuthRedux';
import { getRankText } from 'services/rankHelpers';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 900px;
`;

const IndicatorBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: ${(props) => props.size}px;
`;

const RankItem = styled(ProgressItem)`
  width: ${(props) => props.itemSize}px;
  height: 70%;
  border-radius: 1px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
`;

const SelectedItem = styled(Animal)`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
`;

const rankOptions = Array(12)
  .fill(0)
  .map((item, index) => index + 1);

const getCategorySize = (width) => {
  if (width > 1024) {
    return {
      itemSize: 32,
      size: 160,
    };
  }

  if (width > 540) {
    return {
      size: 110,
      itemSize: 26,
    };
  }
  return {
    size: 70,
    itemSize: 12,
  };
};

const RankProgressView = ({ value, ...props }) => {
  const { width } = useViewport();

  const { size, itemSize } = getCategorySize(width);

  const { animalValue, level } = getRankText(value);
  const helpText = `
  You are currently a ${level} level ${animalValue}. Your next rank is earned through program creation and follower engagement.
  `;
  return (
    <Wrapper p={2} {...props}>
      <InnerWrapper>
        <IndicatorBox px={4} height={size} mb={4}>
          {rankOptions.map((rank) => {
            if (value === rank) {
              return <SelectedItem key={rank} size={size} value={rank} />;
            }

            const rankValue = value > rank ? rank : 0;
            return (
              <RankItem key={rank} itemSize={itemSize} value={rankValue} />
            );
          })}
        </IndicatorBox>

        <Title1 textAlign="center">{helpText}</Title1>
      </InnerWrapper>
    </Wrapper>
  );
};

const mapStatesToProps = (state) => ({
  value: AuthSelectors.selectRank(state),
});

export default connect(mapStatesToProps)(RankProgressView);
