import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Box, DialogBox, Caption3, PopHeading, SvgIcon } from 'components/core';
import { DefaultLoader, LoadingContainer } from 'components/loading';
import { Avatar } from 'components/program';

import API from 'services/api';
import { transformSummaryItem } from 'utils/transform';

const CenterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

function SummaryModal({ show, stats, programId, onOk, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [allSummaryItems, setSummaryItem] = useState([]);

  const { weightChange, subscriptionCount } = stats || {};

  const loadSummaries = async () => {
    setSummaryItem([]);
    if (!programId) {
      return;
    }

    setIsLoading(true);
    const records = await API.getProgramSummary(programId);
    setSummaryItem(records.map((item) => transformSummaryItem(item)));

    setIsLoading(false);
  };

  useEffect(() => {
    loadSummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  return (
    <DialogBox show={show} onClickOutside={onOk}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        m={[5, 8]}
      >
        <CenterContainer mb={4}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <PopHeading>{subscriptionCount}</PopHeading>
            <SvgIcon name="bmi-shared" width={96} height={61} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <PopHeading mr={2}>{weightChange || 0}</PopHeading>
            <SvgIcon name="balance" width={112} height={112} />
          </Box>
        </CenterContainer>
        {allSummaryItems.map((item) => {
          const {
            user: { id, name, avatar },
            change,
          } = item;
          return (
            <CenterContainer mb={4} pl={2} pr={4} key={id}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Link to={`/users/${id}`}>
                  <Avatar mr={2} name={name} {...avatar} />
                </Link>
                <Caption3>{name}</Caption3>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Caption3 mr={2}>{change || 0}</Caption3>
                <Caption3>lbs</Caption3>
              </Box>
            </CenterContainer>
          );
        })}
        {isLoading && (
          <LoadingContainer>
            <DefaultLoader />
          </LoadingContainer>
        )}
      </Box>
    </DialogBox>
  );
}

export default SummaryModal;
