import { select, put, call } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import SubscriptionActions, {
  SubscriptionSelectors,
} from './SubscriptionRedux';
import { AuthSelectors } from './AuthRedux';
import ProfileActions from './ProfileRedux';

import API from '../services/api';
import { getErrors } from 'utils/err';
import {
  transformProgram,
  transformProgramForAnalytics,
} from 'utils/transform';
import history from 'utils/history';
import { calcBmiUSC, calcWeightFromBmi } from 'services/calc';
import Analytics, { EVENTS } from 'services/analytics';

export function* loadSubscriptionProgramInfo(action) {
  const { programId } = action;

  const state = yield select();

  yield put(SubscriptionActions.setSubscriptionLoading(true));

  let existingProgram = state.search.programs.find(
    (program) => program.id === programId
  );

  if (!existingProgram) {
    const apiResponse = yield call(API.getProgram, programId);
    existingProgram = transformProgram(apiResponse);
  }

  if (existingProgram) {
    yield put(SubscriptionActions.setSubscriptionProgram(existingProgram));
  } else {
    yield put(SubscriptionActions.setSubscriptionError(new Error('Not Found')));
  }
  yield put(SubscriptionActions.setSubscriptionLoading(false));
}

export function* subscriptionSetGoal(action) {
  const profile = yield select(AuthSelectors.selectProfile);
  const { height } = profile;

  const { goal } = action || {};
  if (!goal) {
    yield put(SubscriptionActions.setSubscriptionGoalSuccess({}));
  }
  const { goalType, value } = goal;

  let bmi, weight;

  if (goalType === 'bmi') {
    bmi = value;
    weight = calcWeightFromBmi({ height, bmi });
  } else {
    weight = value;
    bmi = calcBmiUSC({ height, weight });
  }

  yield put(SubscriptionActions.setSubscriptionGoalSuccess({ bmi, weight }));
}

export function* subscriptionMake(payload) {
  const { data } = payload;
  yield put(SubscriptionActions.setSubscriptionMaking(true));
  const subsribingProgram = yield select(
    SubscriptionSelectors.selectSubscribingProgram
  );

  const { id: programId } = subsribingProgram || {};
  const actionProgramId = programId;
  const { goal, action } = data || {};
  let values = { action };
  if (!isEmpty(goal)) {
    values = {
      goalBmi: goal.bmi,
      goalWeight: goal.weight,
      action,
    };
  }

  const profileView = 'current';

  try {
    yield call(API.subscribeProgram, actionProgramId, values);
    yield put(ProfileActions.setProfileView(profileView));
    yield put(SubscriptionActions.setSubscriptionMaking(false));
    Analytics.track(EVENTS.PROGRAM_SUBSCRIBED, {
      ...transformProgramForAnalytics(subsribingProgram),
    });
    history.push('/profile/current');
  } catch (err) {
    const error = getErrors(err);
    yield put(SubscriptionActions.setSubscriptionError(error));
    yield put(SubscriptionActions.setSubscriptionMaking(false));
  }
}
