import axios from 'axios';
import qs from 'qs';
import omit from 'lodash-es/omit';
import { generateAssetUrl, generateVideoAssetUrl } from 'utils/form';

const VALID_CODES = ['Gqptb8JB', 'p8jpNFD9', 'jUD4WCjH'];

function createApi() {
  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
  });

  const setAuthHeader = (authToken) => {
    if (authToken) {
      apiClient.defaults.headers.common[
        'Authorization'
      ] = `bearer ${authToken}`;
    } else {
      delete apiClient.defaults.headers.common['Authorization'];
    }
  };

  const checkInviteCode = async ({ code }) => {
    return VALID_CODES.includes(code);
  };

  const loginWithEmail = async ({ email, password }) => {
    const { data } = await apiClient.post('/auth/login', {
      email,
      password,
    });
    return data;
  };

  const resetPassword = async ({ email }) => {
    const { data } = await apiClient.post('/auth/reset-password', {
      email,
    });
    return data;
  };

  const changePassword = async ({ password, newPassword }) => {
    const { data } = await apiClient.put('/me/change-password', {
      password,
      newPassword,
    });
    return data;
  };

  const deleteAccount = async () => {
    const { data } = await apiClient.delete('/me');
    return data;
  };

  const loginWithSocial = async ({ type, token }) => {
    const { data } = await apiClient.post('/auth/loginSso', {
      type,
      token,
    });
    return data;
  };

  const registerWithEmail = async ({ email, password, profile }) => {
    const { data } = await apiClient.post('/auth/register', {
      email,
      password,
      profile,
    });
    return data;
  };

  const registerWithSocial = async (params) => {
    const { data } = await apiClient.post('/auth/registerSso', params);
    return data;
  };

  const getAuthToken = async (authToken, { email }) => {
    setAuthHeader(authToken);
    const { data } = await apiClient.post('/auth/refresh', {
      email,
    });
    return data;
  };

  const getMe = async () => {
    const { data } = await apiClient.get('/me');
    return data;
  };

  const checkEmailIsUnique = async (email) => {
    const { data } = await apiClient.post('/auth/check-email', { email });
    return data;
  };

  const checkUsernameIsUnique = async (username) => {
    const { data } = await apiClient.post('/auth/check-username', { username });
    return data;
  };

  const updateProfile = async (values) => {
    const dataToSend = omit(values, '_id');
    const { data } = await apiClient.put('/me', dataToSend);
    return data;
  };

  const getSignedPutUrl = async ({ bucketKey, contentType }) => {
    const { data } = await apiClient.post('/media/signed-url-put-object', {
      bucketKey,
      contentType,
    });

    return data;
  };

  const getSignedPostUrl = async ({ bucketKey, contentType }) => {
    const { data } = await apiClient.post('/media/signed-url-post-object', {
      bucketKey,
      contentType,
    });

    return data;
  };

  const getSignedPartPutUrl = async ({ uploadId, bucketKey, partNumber }) => {
    const { data } = await apiClient.post('/media/get-part-upload-url', {
      fileName: bucketKey,
      partNumber,
      uploadId,
    });

    return data;
  };

  const getMultiUploadId = async ({ bucketKey, contentType }) => {
    const { data } = await apiClient.post('/media/start-multipart', {
      fileName: bucketKey,
      fileType: contentType,
    });

    return data;
  };

  const updatePartMedia = async (
    partNumber,
    presignedUrl,
    blob,
    contentType,
    uploadCallback
  ) => {
    const uploadPartCallback = (progressEvent) => {
      if (uploadCallback) {
        uploadCallback(progressEvent, partNumber);
      }
    };

    return axios.put(presignedUrl, blob, {
      headers: { 'Content-Type': contentType },
      onUploadProgress: uploadPartCallback,
    });
  };

  const uploadMultipartFile = async (
    uploadId,
    file,
    bucketKey,
    uploadCallback
  ) => {
    const fileSize = file.size;
    let FILE_CHUNK_SIZE = Math.min(20000000, Math.ceil(fileSize / 5)); // 20MB
    FILE_CHUNK_SIZE = Math.max(10000000, FILE_CHUNK_SIZE); // 10 MB
    const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;

    const processedResult = new Array(NUM_CHUNKS).fill(0);

    const uploadPartCallback = (progressEvent, partNumber) => {
      processedResult[partNumber] = progressEvent.loaded * 100;
      const sum = processedResult.reduce((ac, c) => ac + c, 0);

      if (uploadCallback) {
        uploadCallback({
          loaded: sum / 100,
          total: fileSize,
        });
      }
    };

    try {
      let promisesArray = [];
      let start, end, blob;
      let uploadPartsArray = [];

      const MAX_CHANNEL_SIZE = 3;
      let currentChannelNum = 0;
      for (let index = 1; index < NUM_CHUNKS + 1; index++) {
        start = (index - 1) * FILE_CHUNK_SIZE;
        end = index * FILE_CHUNK_SIZE;
        blob = index < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);

        // (1) Generate presigned URL for each part

        let { presignedUrl } = await getSignedPartPutUrl({
          uploadId,
          bucketKey,
          partNumber: index,
        });

        // (2) Puts each file part into the storage server
        let uploadResp = updatePartMedia(
          index,
          presignedUrl,
          blob,
          file.type,
          uploadPartCallback
        );

        currentChannelNum += 1;
        promisesArray.push(uploadResp);

        if (currentChannelNum >= MAX_CHANNEL_SIZE) {
          const resolvedArray = await Promise.all(promisesArray);

          resolvedArray.forEach((resolvedPromise, channelIndex) => {
            uploadPartsArray.push({
              ETag: resolvedPromise.headers.etag,
              PartNumber: index - (MAX_CHANNEL_SIZE - channelIndex) + 1,
            });
          });
          currentChannelNum = 0;
          promisesArray = [];
        }
      }

      if (promisesArray.length > 0) {
        const startIndex = NUM_CHUNKS + 1 - promisesArray.length;
        const resolvedArray = await Promise.all(promisesArray);
        resolvedArray.forEach((resolvedPromise, channelIndex) => {
          uploadPartsArray.push({
            ETag: resolvedPromise.headers.etag,
            PartNumber: startIndex + channelIndex,
          });
        });
      }

      // let resolvedArray = await Promise.all(promisesArray);

      // (3) Calls the CompleteMultipartUpload endpoint in the backend server

      await apiClient.post(`/media/complete-multipart`, {
        fileName: bucketKey,
        parts: uploadPartsArray,
        uploadId: uploadId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const uploadVideoFile = async (file, bucketKey, uploadCallback) => {
    const contentType = file.type;
    const { uploadId } = await getMultiUploadId({
      bucketKey,
      contentType,
    });

    await uploadMultipartFile(uploadId, file, bucketKey, uploadCallback);
    const assetUrl = generateVideoAssetUrl(bucketKey);
    return assetUrl;
  };

  const uploadFile = async (file, bucketKey, uploadCallback) => {
    const contentType = file.type;

    const isImage = contentType.indexOf('image/') > -1;

    if (!isImage) {
      return uploadVideoFile(file, bucketKey, uploadCallback);
    }

    const {
      signedURL: { url: signedUrl, fields },
    } = await getSignedPostUrl({
      bucketKey,
      contentType: isImage ? contentType : undefined,
    });

    const formData = new FormData();
    Object.entries(fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', file);

    await axios.post(signedUrl, formData, {
      onUploadProgress: uploadCallback,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    const assetUrl = generateAssetUrl(bucketKey);

    return assetUrl;

    // return new Promise((resolve, reject) => {
    //   const contentType = file.type;
    //   const reader = new FileReader();
    //   reader.onabort = () => {
    //     console.log('file reading was aborted');
    //     reject('file reading was abortedabort');
    //   };
    //   reader.onerror = () => {
    //     console.log('file reading has failed');
    //     reject('file reading has failed');
    //   };
    //   reader.onload = async () => {
    //     const binaryStr = reader.result;

    //     const { signedURL: putUrl } = await getSignedPutUrl({
    //       bucketKey,
    //       contentType,
    //     });
    //     await axios.put(putUrl, binaryStr, {
    //       onUploadProgress: uploadCallback,
    //       maxContentLength: Infinity,
    //       maxBodyLength: Infinity,
    //     });

    //     const assetUrl = generateAssetUrl(bucketKey);
    //     resolve(assetUrl);
    //   };
    //   reader.readAsArrayBuffer(file);
    // });
  };

  const getAllMyPrograms = async (filters = {}) => {
    const query = qs.stringify(filters);
    let url = '/me/programs';
    if (query) {
      url = `${url}?${query}`;
    }
    const { data } = await apiClient.get(url);
    return data;
  };

  const getProgram = async (programId) => {
    let url = `/programs/${programId}`;
    const { data } = await apiClient.get(url);
    return data;
  };

  const getPublicProgram = async (programId) => {
    let url = `/programs/${programId}/public`;
    const { data } = await apiClient.get(url);
    return data;
  };

  const searchPrograms = async (filters) => {
    const query = qs.stringify(filters);
    let url = '/programs';
    if (query) {
      url = `${url}?${query}`;
    }
    const { data } = await apiClient.get(url);

    return data;
  };

  const createProgram = async (values) => {
    let url = '/programs';
    const { data } = await apiClient.post(url, values);

    return data;
  };

  const updateProgram = async (programid, values) => {
    let url = `/programs/${programid}`;
    const { data } = await apiClient.put(url, values);

    return data;
  };
  const deleteProgram = async (programid) => {
    let url = `/programs/${programid}`;
    const { data } = await apiClient.delete(url);

    return data;
  };

  const startProgram = async (programid) => {
    let url = `/programs/${programid}/start`;
    const { data } = await apiClient.put(url);

    return data;
  };

  const restartProgram = async (programId) => {
    let url = `/programs/${programId}/restart`;
    const { data } = await apiClient.put(url);

    return data;
  };

  const completeProgram = async (programId, values) => {
    let url = `/programs/${programId}/complete`;
    const { data } = await apiClient.put(url);

    return data;
  };

  const createRecord = async (programId, values) => {
    let url = `/programs/${programId}/records`;
    const { data } = await apiClient.post(url, values);

    return data;
  };

  const deleteRecord = async (programId, recordId) => {
    const url = `programs/${programId}/records/${recordId}`;
    const { data } = await apiClient.delete(url);

    return data;
  };

  const completeAction = async (programId, sectionId, actionId) => {
    const url = `/programs/${programId}/actions/${sectionId}/${actionId}/complete`;
    const { data } = await apiClient.post(url);

    return data;
  };

  const subscribeProgram = async (programId, values) => {
    let url = `/programs/${programId}/subscribe`;
    const { data } = await apiClient.put(url, values);

    return data;
  };

  const upgradeToPremium = async (paymentToken) => {
    const url = `/me/premium`;

    const { data } = await apiClient.post(url, { paymentToken });
    return data;
  };

  const cancelPremium = async () => {
    const url = `/me/premium`;
    await apiClient.delete(url);
    return true;
  };

  const connectVendor = async (code) => {
    const url = `/me/connect-vendor`;
    await apiClient.post(url, { code });
    return true;
  };

  const giveRating = async (programId, values) => {
    const { score, description } = values || {};

    const newValue = {
      score: score - 1,
      description,
    };

    let url = `/programs/${programId}/rating`;
    await apiClient.post(url, newValue);
    return true;
  };

  const getRatings = async (programId) => {
    let url = `/programs/${programId}/rating`;
    const { data } = await apiClient.get(url);

    return data;
  };

  const setPriceToProgram = async (programId, values) => {
    let url = `/programs/${programId}`;
    const { data } = await apiClient.put(url, values);

    return data;
  };

  const promoteProgram = async (programId, promoType, paymentToken) => {
    let url = `/programs/${programId}/promote`;
    const { data } = await apiClient.post(url, {
      promoType,
      paymentToken,
    });

    return data;
  };

  const demoteProgram = async (programId, promoType, paymentToken) => {
    let url = `/programs/${programId}/demote`;
    const { data } = await apiClient.post(url);

    return data;
  };

  const getProgramSummary = async (programId) => {
    let url = `/programs/${programId}/summary`;
    const { data } = await apiClient.get(url);

    return data;
  };

  const purchaseProgam = async (programId, params) => {
    let url = `/programs/${programId}/purchase`;

    const { data } = await apiClient.post(url, params);

    return data;
  };

  const getNote = async (date) => {
    const url = `/tasks/${date}`;

    const { data } = await apiClient.get(url);

    return data;
  };

  const updateNote = async (date, note) => {
    const url = `/tasks/${date}`;

    const { data } = await apiClient.put(url, { date, note });

    return data;
  };

  const getAllRecords = async (filters) => {
    const query = qs.stringify(filters);
    const url = `/summary?${query}`;
    const { data } = await apiClient.get(url);

    return data;
  };

  const getChatMessages = async (programId) => {
    const url = `/programs/${programId}/chat`;
    const { data } = await apiClient.get(url);

    return data;
  };

  const createChatMessage = async (programId, message) => {
    const url = `/programs/${programId}/chat`;
    const { data } = await apiClient.post(url, { message });

    return data;
  };

  const updateChatMessage = async (programId, chatId, message) => {
    const url = `/programs/${programId}/chat/${chatId}`;
    const { data } = await apiClient.put(url, { message });

    return data;
  };

  const deleteChatMessage = async (programId, chatId) => {
    const url = `/programs/${programId}/chat/${chatId}`;
    const { data } = await apiClient.delete(url);

    return data;
  };

  const replyMessage = async (programId, chatId, message) => {
    const url = `/programs/${programId}/chat/${chatId}/reply`;
    const { data } = await apiClient.post(url, { message });

    return data;
  };

  const getUserAssociations = async (userId, type) => {
    const url = `/users/${userId}/associations?type=${type}`;
    const { data } = await apiClient.get(url);

    return data;
  };

  const getUserPrograms = async (userId, filters) => {
    const query = qs.stringify(filters);
    let url = `/users/${userId}/programs`;
    if (query) {
      url = `${url}?${query}`;
    }
    const { data } = await apiClient.get(url);

    return data;
  };

  const getUserDetail = async (userId, includeAssociations = []) => {
    const url = `/users/${userId}`;
    const { data } = await apiClient.get(url);

    if (includeAssociations) {
      const promises = includeAssociations.map((associationType) => {
        if (associationType === 'programs') {
          return getUserPrograms(userId, { all: true });
        }
        return getUserAssociations(userId, associationType);
      });
      const associationResult = await Promise.all(promises);

      const associationData = {};
      associationResult.forEach((item, index) => {
        associationData[includeAssociations[index]] = item;
      });

      return {
        ...data,
        ...associationData,
      };
    }

    return data;
  };

  const getAllPrograms = async (filters) => {
    const query = qs.stringify(filters);
    let url = `/programs/all`;
    if (query) {
      url = `${url}?${query}`;
    }
    const { data } = await apiClient.get(url);

    return data;
  };

  const toggleLikeProgram = async (programId) => {
    let url = `/programs/${programId}/like`;
    const { data } = await apiClient.put(url);

    return data;
  };

  const readNotifications = async () => {
    let url = `/me/read`;
    const { data } = await apiClient.post(url);

    return data;
  };

  const followUser = async (userId) => {
    let url = `/users/${userId}/follow`;
    const { data } = await apiClient.put(url);

    return data;
  };

  const unfollowUser = async (userId) => {
    let url = `/users/${userId}/follow`;
    const { data } = await apiClient.delete(url);

    return data;
  };

  return {
    setAuthHeader,
    loginWithEmail,
    loginWithSocial,
    resetPassword,
    changePassword,
    registerWithEmail,
    registerWithSocial,
    getAuthToken,
    getMe,
    checkInviteCode,
    updateProfile,
    getSignedPutUrl,
    uploadFile,
    getProgram,
    getPublicProgram,
    getAllMyPrograms,
    searchPrograms,
    createProgram,
    updateProgram,
    deleteProgram,
    startProgram,
    restartProgram,
    completeProgram,
    createRecord,
    deleteRecord,
    completeAction,
    subscribeProgram,
    upgradeToPremium,
    cancelPremium,
    connectVendor,
    setPriceToProgram,
    giveRating,
    getProgramSummary,
    getRatings,
    purchaseProgam,
    getNote,
    updateNote,
    getAllRecords,
    getChatMessages,
    createChatMessage,
    updateChatMessage,
    deleteChatMessage,
    replyMessage,
    getUserDetail,
    getUserPrograms,
    getUserAssociations,
    promoteProgram,
    demoteProgram,
    deleteAccount,
    getAllPrograms,
    checkUsernameIsUnique,
    checkEmailIsUnique,
    toggleLikeProgram,
    readNotifications,
    followUser,
    unfollowUser,
  };
}

const apiClient = createApi();

export default apiClient;
