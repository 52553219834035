import React from 'react';
import styled from 'styled-components';
import { Box, PSelectButton } from 'components/core';

const SelectButton = styled(PSelectButton)`
  width: ${(props) => props.wrapperSize}px;
  height: ${(props) => props.wrapperSize}px;
`;

const TypeRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProfileViewSelect = ({
  value,
  size = 160,
  onChange,
  options,
  ...props
}) => {
  const handleClick = (name) => {
    if (onChange) onChange(name);
  };

  return (
    <TypeRow {...props}>
      {options.map((name, index) => (
        <SelectButton
          key={name}
          name={name}
          wrapperSize={size}
          size={size / 2}
          selected={value === name}
          onClick={() => handleClick(name, index)}
        />
      ))}
    </TypeRow>
  );
};

export default ProfileViewSelect;
