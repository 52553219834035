import Text from './Text';

const InputFeedback = Text.withComponent('div');

InputFeedback.defaultProps = {
  fontSize: 1,
  lineHeight: 1.2,
  mb: 4,
  px: 2,
  color: 'red',
  textAlign: 'left'
};

export default InputFeedback;
