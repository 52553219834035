import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import upperFirst from 'lodash-es/upperFirst';

import {
  Box,
  DialogBox,
  Body2,
  Body2Dark,
  Title1,
  Button,
} from 'components/core';
import Player from './Player';

import { getSummaryLines } from 'utils/form';
import ProfileActions from 'redux/ProfileRedux';
import AppleHalfImage from 'assets/AppleHalf.png';

import API from 'services/api';

const IMPOSSIBLE_CATEGORIES = ['master', 'bmi', 'weight'];

const PlaceHolderImage = styled.img`
  width: 40%;
`;

function TrackingPlayerModal({
  position,
  show,
  isLoading,
  category,
  data,
  onClose,
  onTrackComplete,
  ...props
}) {
  const dispatch = useDispatch();
  const { summaryTitle, summaryLines } = getSummaryLines(category, data);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let { id: actionId, note, record, assetType } = data || {};

  const { programId, sectionId, trackable, day } = position || {};

  const showCompletedButton =
    trackable && !record && !IMPOSSIBLE_CATEGORIES.includes(category);

  const noSummary = !summaryLines.length && !note && !showCompletedButton;

  const convertedNote = (note || '').split(/\r?\n/).filter((item) => !!item);

  const isPlayable = assetType === 'image' || assetType === 'video';

  const handleTaskCompleteChange = async () => {
    setIsSubmitting(true);

    try {
      const resp = await API.completeAction(programId, sectionId, actionId);
      onTrackComplete(resp.record);
      dispatch(
        ProfileActions.updateProgramAction(programId, sectionId, day, {
          record: resp.record,
        })
      );
    } catch (e) {
      console.error(e);
    }

    setIsSubmitting(false);
  };

  const handleClickOutSide = () => {
    if (!isSubmitting) {
      onClose();
    }
  };

  return (
    <DialogBox
      bg="white"
      show={show}
      onClickOutside={handleClickOutSide}
      hasClose={false}
      backDropColor="backDrop"
    >
      {category !== 'master' && (
        <Title1 mt={[2, 4]} textAlign="center">
          {upperFirst(category)}
        </Title1>
      )}

      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        {...props}
        minWidth={12}
        minHeight={12}
      >
        {isPlayable ? (
          <Player data={data} category={category} />
        ) : (
          <PlaceHolderImage src={AppleHalfImage} />
        )}
      </Box>

      {!noSummary && (
        <Box m={6}>
          {summaryTitle && <Body2Dark mb={4}>{summaryTitle}</Body2Dark>}
          {summaryLines.map((summaryLine, index) => (
            <Body2 pl={4} mb={4} key={`${summaryLine}_${index}`}>
              {summaryLines.length > 1 ? '-' : ''} {summaryLine}
            </Body2>
          ))}
          {note && <Body2Dark mb={4}>Note</Body2Dark>}
          {convertedNote &&
            convertedNote.length > 0 &&
            convertedNote.map((noteLine, index) => (
              <Body2 pl={4} key={`${noteLine}_${index}`}>
                - {noteLine}
              </Body2>
            ))}

          {showCompletedButton && (
            <Box
              display="flex"
              flexDirection="row"
              alignSelf="stretch"
              justifyContent={['center', 'flex-end']}
              mt={4}
            >
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleTaskCompleteChange}
              >
                Complete
              </Button>
            </Box>
          )}
        </Box>
      )}
    </DialogBox>
  );
}

export default TrackingPlayerModal;
