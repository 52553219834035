import React from 'react';
import { UNIT_OPTIONS } from 'services/terms';
import CommonBarChart from './CommonBarChart';

const RANGES = {
  calories: [
    [0, 1000],
    [1000, 2000],
    [2000, 3000],
    [3000, 4000],
  ],
  protein: [
    [0, 50],
    [50, 100],
    [100, 200],
    [200, 400],
  ],
  carbs: [
    [0, 200],
    [200, 300],
    [300, 400],
    [400, 500],
  ],
  fat: [
    [0, 10],
    [10, 20],
    [20, 50],
    [50, 100],
  ],
};
const Y_LABELS = {
  calories: ['4k', '3k', '2k', '1k'],
  protein: [400, 200, 100, 50],
  carbs: [500, 400, 300, 200],
  fat: [100, 50, 20, 10],
};
const COLOR_OPTIONS = ['Under', 'Ideal', 'Over', 'Obese'];

function DietChart({ sectionType, unit, ...props }) {
  const unitObj = UNIT_OPTIONS.diet.find((u) => u.value === unit);
  if (!unitObj) {
    return null;
  }

  return (
    <CommonBarChart
      sectionType={sectionType}
      unit={unit}
      yLabels={Y_LABELS[unit]}
      ranges={RANGES[unit]}
      yAxisLabel={unitObj.label}
      colorOptions={COLOR_OPTIONS}
      {...props}
    />
  );
}

export default DietChart;
