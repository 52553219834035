import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box } from 'components/core';
import Calendar from './Calendar';

import { colors } from 'styles/theme';

const MODAL_OPEN_CLASS = 'body--modal-open';

const Wrapper = styled(Box)`
  position: relative;
  z-index: 100;
`;

const Modal = styled(Box)`
  display: flex;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: opacity 0.4s ease-out, background-color 0.4s ease-out;
  position: fixed;
  z-index: 99999;

  opacity: ${(props) => (props.show ? 1 : 0)};
  overflow-y: scroll;
`;

const ModalContainer = styled(Box)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1008px;
  min-height: 632px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
  background: ${colors.background};
  border-radius: 2px;
`;

const ModalInnerContainer = styled(Box)`
  flex: 1;
  border: 1px solid ${colors.text};
  box-sizing: border-box;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

Modal.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};

const CalendarPopup = ({ show, onClickOutside, ...props }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add(MODAL_OPEN_CLASS);
    } else {
      document.body.classList.remove(MODAL_OPEN_CLASS);
    }

    return () => {
      document.body.classList.remove(MODAL_OPEN_CLASS);
    };
  }, [show]);

  return (
    <Wrapper>
      <Modal show={show} onClick={onClickOutside}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ModalContainer {...props}>
            <ModalInnerContainer>
              <Calendar onDone={onClickOutside} />
            </ModalInnerContainer>
          </ModalContainer>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default CalendarPopup;
