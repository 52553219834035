import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import Register from './pages/Register';
import UserDetail from './pages/UserDetail';
import ProgramShare from './pages/ProgramShare';
import ResetPassword from './pages/ResetPassword';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import { AppLayout } from 'containers/layout';

const AppNoUser = () => {
  return (
    <Switch>
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/auth/login" component={Login} />
      <Route exact path="/auth/register" component={Register} />
      <Route exact path="/auth/reset-password" component={ResetPassword} />
      <Route
        exact
        path="/users/:userId"
        render={(props) => (
          <AppLayout>
            <UserDetail {...props} />
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/users/:userId/programs/:programId"
        render={(props) => (
          <AppLayout>
            <ProgramShare {...props} />
          </AppLayout>
        )}
      />
      <Route render={() => <Redirect to="/auth/login" />} />
    </Switch>
  );
};

export default AppNoUser;
