import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import startCase from 'lodash-es/startCase';
import { Box, Title1 } from 'components/core';
import {
  GeneralSettings,
  SecuritySettings,
  BillingSettings,
} from 'containers/settings';

import { colors } from 'styles/theme';
import SettingsActions, { SettingsSelectors } from 'redux/SettingsRedux';
import { DefaultLoader, LoadingContainer } from 'components/loading';

const SelectButton = styled(Box)`
  cursor: pointer;
  border: 1px solid ${colors.text};
  background: ${(props) => (props.selected ? colors.text : colors.clear)};
  color: ${(props) => (props.selected ? colors.contrastText : colors.text)};
  width: 176px;
  height: 48px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: column
  justify-content: center;
  align-items: center;
`;

const SETTINGS_VIEWS = ['general', 'security', 'billing'];

function SettingsPage({
  currentView,
  isLoading,
  loadSettings,
  setCurrentView,
  ...props
}) {
  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderView = () => {
    switch (currentView) {
      case 'general':
        return <GeneralSettings />;
      case 'security':
        return <SecuritySettings />;
      case 'billing':
        return <BillingSettings />;
      default:
    }
    return null;
  };

  return (
    <Box alignSelf="stretch" pt={8} pb={8} px={2} {...props}>
      <Title1 mb={4}>Settings</Title1>

      <Box display="flex" flexDirection="row">
        {SETTINGS_VIEWS.map((name) => (
          <SelectButton
            key={name}
            name={name}
            selected={currentView === name}
            onClick={() => setCurrentView(name)}
            mr={[2, 6]}
          >
            {startCase(name)}
          </SelectButton>
        ))}
      </Box>

      {renderView()}

      {isLoading && (
        <LoadingContainer>
          <DefaultLoader />
        </LoadingContainer>
      )}
    </Box>
  );
}

const mapStatesToProps = (state) => ({
  currentView: SettingsSelectors.selectSettingsView(state),
  isLoading: SettingsSelectors.selectIsSettingsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentView: (data) => dispatch(SettingsActions.setSettingsView(data)),
  loadSettings: () => dispatch(SettingsActions.setLoadSettingsRequest()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(SettingsPage);
