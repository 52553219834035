import { combineReducers } from 'redux';
import { reducer as app } from './AppRedux';
import { reducer as auth } from './AuthRedux';
import { reducer as search } from './SearchRedux';
import { reducer as create } from './CreateRedux';
import { reducer as profile } from './ProfileRedux';
import { reducer as calendar } from './CalendarRedux';
import { reducer as settings } from './SettingsRedux';
import { reducer as subscription } from './SubscriptionRedux';
import { reducer as report } from './ReportRedux';
import { reducer as otherUser } from './OtherUserRedux';

const reducers = combineReducers({
  app,
  auth,
  search,
  create,
  profile,
  calendar,
  settings,
  subscription,
  report,
  otherUser,
});

export default reducers;
