import React from 'react';
import { Box } from 'components/core';
import DarkSelect from './DarkSelect';

const FEET_OPTIONS = [4, 5, 6, 7].map((item) => {
  return {
    value: item,
    label: `${item}'`,
  };
});

const INCH_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
  return {
    value: item,
    label: `${item - 1}"`,
  };
});

function HeightField({ value: initialValue = {}, onChange, ...props }) {
  const { feet, inches } = initialValue;

  const onChangeFeet = ({ value: newFeet }) => {
    if (onChange) {
      onChange({
        feet: newFeet,
        inches,
      });
    }
  };

  const onChangeInches = ({ value: newInches }) => {
    if (onChange) {
      onChange({
        feet,
        inches: newInches,
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      alignSelf="stretch"
      alignItems="center"
      justifyContent="center"
      flex={1}
      {...props}
    >
      <DarkSelect
        mr={[0, 4]}
        mb={[2, 0]}
        flex={1}
        width={1}
        placeholder="ft"
        value={feet}
        options={FEET_OPTIONS}
        onChange={onChangeFeet}
      />
      <DarkSelect
        flex={1}
        width={1}
        placeholder="inches"
        value={inches}
        onChange={onChangeInches}
        options={INCH_OPTIONS}
      />
    </Box>
  );
}

export default HeightField;
