import React, { useState } from 'react';
import styled from 'styled-components';
// import startCase from 'lodash-es/startCase';
import {
  Box,
  Select,
  BInput,
  BTextArea,
  Button,
  Title1,
  Checkbox,
  Body2,
  // OptionButton,
} from 'components/core';
import { FormUpload } from 'components/upload';

import { DIET_OPTIONS } from 'services/terms';
import { getIsValid, generateUploadKey } from 'utils/form';
import { getCategoryImageKey } from 'utils/image';

const TextArea = styled(BTextArea)`
  min-height: 108px;
`;

const ValueText = styled(BInput)`
  max-width: 112px;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

// const OptionRow = styled(Box)`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-self: stretch;
// `;

const isValid = getIsValid(['type', 'value', 'fat', 'proteins', 'carbs']);
const typeOptions = DIET_OPTIONS.concat({ value: 'other', label: 'Other' });
// const dayOptions = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const category = 'diet';

// const genMap = (arr = []) => {
//   const result = {};
//   arr.forEach((item) => {
//     result[item] = item;
//   });
//   return result;
// };

// const getArr = (obj = {}) => {
//   return Object.keys(obj).filter((key) => !!obj[key]);
// };

function DietForm({
  userId,
  userGender,
  position,
  onSubmit,
  allowRepeat = true,
  value: initialValue = {},
  ...props
}) {
  const [type, setType] = useState(initialValue.type || 'fasting');
  const [note, setNote] = useState(initialValue.note);
  const [value, setValue] = useState(initialValue.value);
  const [proteins, setProteins] = useState(initialValue.proteins);
  const [carbs, setCarbs] = useState(initialValue.carbs);
  const [fat, setFat] = useState(initialValue.fat);

  // const [weekdays, setDays] = useState(genMap(initialValue.weekdays));
  const [other, setOther] = useState(initialValue.other);

  const [assetType, setAssetType] = useState(initialValue.assetType || 'image');
  const [assetUrl, setAssetUrl] = useState(initialValue.assetUrl || '');
  const [thumbUrl, setThumb] = useState(initialValue.thumbUrl || '');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRepeated, setRepeated] = useState(false);

  const { day } = position || { day: 1 };
  const uploadKey = generateUploadKey(userId, category, 0, day);
  const videoCategoryImage = getCategoryImageKey(category, type, userGender);

  const onUpload = ({ assetUrl, type, fileKey }) => {
    setAssetType(type);
    setAssetUrl(assetUrl);
    if (type === 'image') {
      setThumb(`https://bcult.imgix.net/${fileKey}`);
    } else if (type === 'video') {
      setThumb(`https://bcult.imgix.net/${videoCategoryImage}`);
    } else {
      setThumb('https://source.unsplash.com/random/105x155');
    }
    setIsDisabled(false);
  };

  const onReset = () => {
    setAssetType('');
    setAssetUrl('');
    setThumb('');
  };

  const onChangeType = ({ value: newType }) => {
    setType(newType);

    if (newType !== 'other') {
      setOther('');
    }
  };

  const onChangeValue = (e) => {
    const { value } = e.target;

    setValue(value);
  };

  const onChangeProtein = (e) => {
    const { value } = e.target;

    setProteins(value);
  };

  const onChangeCarbs = (e) => {
    const { value } = e.target;

    setCarbs(value);
  };

  const onChangeFat = (e) => {
    const { value } = e.target;

    setFat(value);
  };

  const onChangeOther = (e) => {
    const { value } = e.target;

    setOther(value);
  };

  const onChangeNote = (e) => {
    const { value } = e.target;

    setNote(value);
  };

  // const onToggleOption = (option) => {
  //   const { [option]: optionValue, ...restDays } = weekdays;

  //   if (optionValue) {
  //     setDays(restDays);
  //     return;
  //   }

  //   setDays({
  //     ...restDays,
  //     [option]: option,
  //   });
  // };

  const onSubmitForm = () => {
    const submitValue = {
      type,
      value,
      carbs,
      fat,
      proteins,
      note,
      // days: getArr(weekdays),
      other,
      assetType,
      assetUrl,
      thumbUrl,
    };
    if (!isValid(submitValue)) {
      return;
    }

    if (onSubmit) {
      onSubmit(submitValue, isRepeated);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      alignSelf="stretch"
      alignItems={['center', 'flex-start']}
      justifyContent="flex-start"
      {...props}
    >
      <FormUpload
        category={category}
        thumbUrl={thumbUrl}
        uploadKey={uploadKey}
        assetType={assetType}
        assetUrl={assetUrl}
        onReset={onReset}
        onUpload={onUpload}
        onUploadProgress={(isUploading) => {
          setIsDisabled(isUploading);
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        alignItems={['center', 'flex-start']}
        justifyContent="flex-start"
        py={[2, 8]}
        pr={[0, 6]}
      >
        <Title1 mb={[4, 8]}>Diet</Title1>
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          alignSelf="stretch"
          alignItems="center"
          justifyContent="flex-start"
          mb={[2, 4]}
        >
          <Select
            minWidth="200px"
            placeholder="Type"
            value={type}
            options={typeOptions}
            onChange={onChangeType}
            width={[1, 'unset']}
            mr={[0, 6]}
          />
          <BInput
            value={other}
            disabled={type !== 'other'}
            placeholder="Other..."
            onChange={onChangeOther}
            mt={[2, 0]}
          />
        </Box>
        {/* <OptionRow mb={[2, 4]}>
          {dayOptions.map((name, index) => (
            <OptionButton
              key={name}
              name={name}
              selected={!!weekdays[name]}
              onClick={() => onToggleOption(name, index)}
            >
              {startCase(name)}
            </OptionButton>
          ))}
        </OptionRow> */}
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          mb={[2, 4]}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignSelf="stretch"
            alignItems="center"
            justifyContent="space-between"
            mr={[0, 2]}
            mb={[2, 0]}
          >
            <ValueText
              value={value}
              placeholder="Calories"
              onChange={onChangeValue}
              mr={2}
            />

            <ValueText
              value={proteins}
              placeholder="Protein"
              onChange={onChangeProtein}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignSelf="stretch"
            alignItems="center"
            justifyContent="space-between"
          >
            <ValueText
              value={carbs}
              placeholder="Carbs"
              onChange={onChangeCarbs}
              mr={2}
            />

            <ValueText value={fat} placeholder="Fat" onChange={onChangeFat} />
          </Box>
        </Box>

        <TextArea
          placeholder="Note..."
          value={note}
          onChange={onChangeNote}
          mb={[2, 4]}
        />
        {allowRepeat && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            alignSelf={['unset', 'stretch']}
            mb={[2, 6]}
          >
            <Checkbox
              variant="tick"
              size="sm"
              pt={`2px`}
              mr={1}
              checked={isRepeated}
              onChange={() => setRepeated(!isRepeated)}
            />
            <Body2>Repeat</Body2>
          </Box>
        )}
        <Button
          variant="secondary"
          onClick={onSubmitForm}
          disabled={isDisabled}
        >
          OK
        </Button>
      </Box>
    </Box>
  );
}

export default DietForm;
