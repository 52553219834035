import styled from 'styled-components';
import {
  borderColor,
  borderRadius,
  space,
  fontSize,
  fontWeight,
  lineHeight,
  variant,
  flexbox,
  width,
  color,
  borderWidth,
} from 'styled-system';

const buttonSize = variant({
  prop: 'size',
  key: 'iconButtonSizes',
});

const IconButton = styled('button')`
  appearance: button;
  cursor: pointer;
  border-style: solid;
  outline: 0;

  &:disabled {
    cursor: not-allowed;
  }

  ${borderColor}
  ${borderWidth}
  ${borderRadius}
  ${buttonSize}
  ${space}
  ${width}  
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${flexbox}
  ${color}
`;

IconButton.defaultProps = {
  size: 'medium',
  borderRadius: 1,
  fontSize: 2,
  lineHeight: 1.29,
  fontWeight: 'normal',
  bg: 'clear',
  borderColor: 'lightGray',
  borderWidth: 1,
  p: 0,
};

export default IconButton;
