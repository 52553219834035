import { all, takeLatest, debounce } from 'redux-saga/effects';
import { AppTypes } from './AppRedux';
import { startup } from './AppSaga';
import { AuthTypes } from './AuthRedux';
import {
  storeToken,
  clearToken,
  refreshAuthToken,
  loadUser,
  storeUser,
  readNotifications,
} from './AuthSaga';
import { ProgramCreateTypes } from './CreateRedux';
import {
  createFinalize,
  createSetGoal,
  initEditFromExistingProgram,
  watchSettingFormData,
} from './CreateSaga';
import { ProfileTypes } from './ProfileRedux';
import { loadAllMyPrograms, addRecord } from './ProfileSaga';
import { SearchTypes } from './SearchRedux';
import { searchPrograms } from './SearchSaga';
import { SubscriptionTypes } from './SubscriptionRedux';
import {
  subscriptionSetGoal,
  subscriptionMake,
  loadSubscriptionProgramInfo,
} from './SubscriptionSaga';
import { SettingsTypes } from './SettingsRedux';
import { loadSettings } from './SettingsSaga';

import { ReportTypes } from './ReportRedux';
import { loadReportdata } from './ReportSaga';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    takeLatest(AppTypes.STARTUP, startup),
    takeLatest(AuthTypes.SET_LOGGED_IN, storeToken),
    takeLatest(AuthTypes.SET_LOGGED_OUT, clearToken),
    takeLatest(AuthTypes.SET_USER, storeUser),
    takeLatest(AuthTypes.SET_READ_NOTIFICATIONS, readNotifications),
    takeLatest(AuthTypes.SET_REFRESH_AUTH_REQUEST, refreshAuthToken),
    takeLatest(AuthTypes.SET_USER_LOAD_REQUEST, loadUser),
    takeLatest(
      ProgramCreateTypes.SET_CREATE_INIT_FROM_EXISTING_REQUEST,
      initEditFromExistingProgram
    ),
    takeLatest(ProgramCreateTypes.SET_CREATE_GOAL, createSetGoal),
    takeLatest(
      ProgramCreateTypes.SET_CREATE_INSERT_FORM_DATA,
      watchSettingFormData
    ),
    takeLatest(ProgramCreateTypes.SET_CREATE_FINALIZE_REQUEST, createFinalize),
    takeLatest(ProfileTypes.SET_LOAD_MY_PROGRAMS_REQUEST, loadAllMyPrograms),
    takeLatest(ProfileTypes.SET_RECORD_REQUEST, addRecord),
    takeLatest(SearchTypes.SET_SEARCH_PROGRAMS_REQUEST, searchPrograms),
    debounce(250, SearchTypes.SET_SEARCH_QUERY, searchPrograms),
    takeLatest(SearchTypes.SET_SEARCH_SORT, searchPrograms),
    takeLatest(SearchTypes.SET_SEARCH_CATEGORY, searchPrograms),
    takeLatest(
      SubscriptionTypes.SET_SUBSCRIPTION_PROGRAM_LOAD_REQUEST,
      loadSubscriptionProgramInfo
    ),
    takeLatest(SubscriptionTypes.SET_SUBSCRIPTION_GOAL, subscriptionSetGoal),
    takeLatest(
      SubscriptionTypes.SET_SUBSCRIPTION_MAKE_REQUEST,
      subscriptionMake
    ),
    takeLatest(SettingsTypes.SET_LOAD_SETTINGS_REQUEST, loadSettings),
    takeLatest(ReportTypes.SET_REPORT_DATA_LOAD_REQUEST, loadReportdata),
  ]);
}
