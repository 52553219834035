import React, { useState, useEffect } from 'react';

import { Box } from 'components/core';
import { DefaultLoader } from 'components/loading';

function Player({ src, category, ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const handleOnLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [src]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <img
        style={{ maxWidth: '100%' }}
        src={src}
        alt={category}
        onLoad={handleOnLoad}
      />
      {isLoading && <DefaultLoader />}
    </Box>
  );
}

export default Player;
