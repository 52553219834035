import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import get from 'lodash-es/get';

import {
  Box,
  Body2Dark,
  InputGroup,
  InputFeedback,
  BcultLogo,
  Checkbox,
  Body4,
} from 'components/core';
import { InputField, EmailField, UsernameField } from 'components/form';
import { IconSelect, Switch } from 'components/others';
import { HeightFormField } from 'containers/fields';

import AuthActions from 'redux/AuthRedux';

import API from 'services/api';
import { getInchesFromFeet, getInchesCm, getLbsFromKg } from 'services/calc';
import { getErrors } from 'utils/err';
import Analytics, { EVENTS } from 'services/analytics';

const LinkText = styled(Body4)`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  text-decoration: underline;
`;

const Wrapper = styled(Box)`
  width: 592px;
  min-height: 368px;

  width: 592px;
  max-width: 592px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 5px;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const MainSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 464px;

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const NAMES = {
  american: [
    'gender-american-male',
    'gender-american-female',
    'gender-american-binary',
  ],
  egyptian: [
    'gender-egyptian-male',
    'gender-egyptian-female',
    'gender-egyptian-binary',
  ],
};

const getGender = (genderType = 'gender-egyptian-male') => {
  const splits = genderType.split('-');
  return splits.pop();
};

const getUserType = (genderType) => {
  if (genderType === 'gender-american-male') {
    return 'leader';
  }

  if (genderType === 'gender-american-female') {
    return 'follower';
  }

  if (genderType === 'gender-american-male') {
    return 'both';
  }
};

const GenderToolTips = ['Male', 'Female', 'Non-Binary'];
const TypeToolTips = ['Leader', 'Follower', 'Both'];

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  username: Yup.string()
    .required('Please enter a username')
    .min(3, 'Please enter username with 3 characters or longer'),
  password: Yup.string()
    .required('Please enter a password')
    .min(6, 'Please enter a password 6 digits or longer'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password do not match')
    .min(6, 'Please enter confirm password'),
  age: Yup.number()
    .required('Please enter your age')
    .typeError('Please enter your age'),
  height: Yup.object().shape({
    feet: Yup.number()
      .required('Please enter height')
      .typeError('Please enter height'),
    inches: Yup.number()
      .required('Please enter height')
      .typeError('Please enter height'),
  }),
  heightMetric: Yup.number()
    .when('isMetric', {
      is: (isMetric) => !!isMetric,
      then: Yup.number().required('Please enter your height'),
    })
    .nullable(),
  facebookId: Yup.string(),
  weight: Yup.number()
    .required('Please enter your weight')
    .typeError('Please enter weight'),
  isMetric: Yup.boolean().nullable(),
});

const OnboardingForm = (props) => {
  const { onSubmit, facebook } = props;
  const location = useLocation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [sourceGenderType, setSourceGenderType] = useState(NAMES.egyptian[0]);
  const [targetGenderType, setTargetGenderType] = useState(NAMES.american[1]);

  const facebookEmail = facebook
    ? get(facebook, 'email')
    : get(location, 'state.email');
  const facebookId = facebook
    ? get(facebook, 'facebookId')
    : get(location, 'state.facebookId');
  const facebookToken = facebook
    ? get(facebook, 'facebookToken')
    : get(location, 'state.facebookToken');

  useEffect(() => {
    Analytics.track(EVENTS.REIGSETER_FORM_VIEWED);
  }, []);

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setStatus } = actions;
    if (!isAgreed) {
      setStatus({
        message: 'Need to agree terms',
      });
      return;
    }

    const {
      email,
      password,
      height,
      heightMetric,
      weight,
      username,
      isMetric,
    } = values;
    const heightInches = isMetric
      ? getInchesCm(heightMetric)
      : getInchesFromFeet(height);

    const weightLbs = isMetric ? getLbsFromKg(weight) : weight;

    try {
      const newProfile = {
        username,
        height: heightInches,
        gender: getGender(sourceGenderType),
        userType: getUserType(targetGenderType),
        weight: weightLbs,
      };
      const newUser = {
        email,
        password,
        profile: newProfile,
      };

      if (values.facebookId) {
        newUser.facebookId = values.facebookId;
        newUser.type = 'Facebook';
        newUser.token = facebookToken;
      }

      const data = await (values.facebookId
        ? API.registerWithSocial(newUser)
        : API.registerWithEmail(newUser));

      Analytics.track(EVENTS.USER_SIGNED_UP, {
        email,
        ...newProfile,
      });

      setIsSubmitted(true);

      setTimeout(() => {
        onSubmit(data);
      }, 300);
    } catch (err) {
      const error = getErrors(err);
      setStatus({
        message: error.message || 'Invalid credentials',
      });
    }
    setSubmitting(true);
  };

  return (
    <Formik
      initialValues={{
        email: facebookEmail || '',
        facebookId: facebookId || '',
        password: '',
        age: '',
        height: {
          feet: 5,
          inches: 6,
        },
        weight: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}
    >
      {({
        isValid,
        isSubmitting,
        submitForm,
        status,
        setStatus,
        errors,
        values,
        setFieldValue,
      }) => {
        const { isMetric } = values;
        return (
          <Form>
            <Wrapper my={[4, 10]}>
              <BcultLogo />
              <MainSection mt={6}>
                <Body2Dark color="blueGray">Who are you?</Body2Dark>
                <IconSelect
                  py={4}
                  value={sourceGenderType}
                  options={NAMES.egyptian}
                  tooltips={GenderToolTips}
                  onChange={(genderType) => {
                    setSourceGenderType(genderType);
                  }}
                />

                <Box mb={[2, 4]} width={1}>
                  <Box
                    display="flex"
                    flexDirection={['column', 'row']}
                    alignItems="flex-start"
                  >
                    <InputGroup mr={[0, 4]} flex={1}>
                      <Field component={InputField} name="age" label="Age" />
                    </InputGroup>
                    <InputGroup flex={1}>
                      <Field
                        component={InputField}
                        name="weight"
                        label={isMetric ? 'Weight (Kg)' : 'Weight (lbs)'}
                      />
                    </InputGroup>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={['row', 'column']}
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      alignSelf="stretch"
                      mr={[2, 0]}
                    >
                      <Body2Dark color="blueGray" mr={[1, 4]}>
                        Height
                      </Body2Dark>
                      {!isMetric ? (
                        <Field component={HeightFormField} name="height" />
                      ) : (
                        <InputGroup mr={4}>
                          <Field
                            component={InputField}
                            name="heightMetric"
                            label="Height (cm)"
                          />
                        </InputGroup>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      alignSelf={['unset', 'stretch']}
                    >
                      <Checkbox
                        variant="tick"
                        size="sm"
                        checked={isMetric}
                        pt={`2px`}
                        mr={1}
                        onChange={() => {
                          setStatus(null);
                          setFieldValue('isMetric', !isMetric);
                        }}
                      />
                      <Body2Dark color="blueGray">Metric</Body2Dark>
                    </Box>
                  </Box>
                </Box>

                <Body2Dark color="blueGray" mb={2}>
                  My new name is...
                </Body2Dark>
                <InputGroup>
                  <Field
                    component={UsernameField}
                    name="username"
                    label="Your nick name"
                  />
                </InputGroup>
                <Body2Dark color="blueGray" mb={2}>
                  My e-Mail
                </Body2Dark>
                <InputGroup>
                  <Field
                    component={EmailField}
                    disabled={!!facebookEmail}
                    name="email"
                    type="email"
                    label="Email Address"
                  />
                </InputGroup>

                <Body2Dark color="blueGray" mb={2}>
                  My Password
                </Body2Dark>

                <Field
                  component={InputField}
                  mb={[2, 4]}
                  name="password"
                  type="password"
                  label="Password"
                />

                <Field
                  component={InputField}
                  mb={[2, 4]}
                  name="passwordConfirm"
                  type="password"
                  label="Confirm Password"
                />

                <Body2Dark color="blueGray">I want to be...</Body2Dark>
                <IconSelect
                  mb={[2, 4]}
                  py={4}
                  options={NAMES.american}
                  tooltips={TypeToolTips}
                  value={targetGenderType}
                  onChange={(genderType) => {
                    setTargetGenderType(genderType);
                  }}
                />

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  alignSelf={['unset', 'stretch']}
                  mb={[2, 4]}
                >
                  <Checkbox
                    variant="tick"
                    size="sm"
                    checked={isAgreed}
                    pt={`2px`}
                    mr={1}
                    onChange={() => setIsAgreed(!isAgreed)}
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    <Body4 color="blueGray" mr={1}>
                      I have read and accept the
                    </Body4>
                    <a
                      href={`${process.env.REACT_APP_APP_URL}/privacy`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkText color="blueGray">Privacy Policy</LinkText>
                    </a>
                    <Body4 color="blueGray" mx={1}>
                      and
                    </Body4>
                    <a
                      href={`${process.env.REACT_APP_APP_URL}/terms`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkText color="blueGray"> Terms of Use</LinkText>
                    </a>
                  </Box>
                </Box>
                {status && status.message && (
                  <InputFeedback> {status.message} </InputFeedback>
                )}
                <Box display="flex" alignSelf="center">
                  <Switch
                    variant="primary"
                    disabled={isSubmitting}
                    selected={isSubmitted}
                    onChange={() => {
                      submitForm();
                    }}
                  />
                </Box>
              </MainSection>
            </Wrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: ({ token, user }) => {
    dispatch(AuthActions.setLoggedIn(token));
  },
});

export default connect(null, mapDispatchToProps)(OnboardingForm);
