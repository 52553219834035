const OTHER_PROFILE_VIEWED = 'Profile Page Viewed';
const REIGSETER_FORM_VIEWED = 'Register Form Viewed';
const LOGIN_FORM_VIEWED = 'Login Form Viewed';
const SUBSCRIPTION_FORM_VIEWED = 'Subscription Form Viewed';

const USER_SIGNED_UP = 'User signed up';

const USER_UPGRADED_TO_MASTER = 'User upgraded to master';
const USER_DOWNGRADE_FROM_MASTER = 'User downgraded from master';
const USER_CONNECT_STRIPE = 'User connected stripe';
const USER_DISCONNECT_STRIPE = 'User disconnected stripe';

const PROGRAM_CREATED = 'Program created';

const PROGRAM_NEW_CREATED = 'new program saved later';
const PROGRAM_NEW_FINALIZED = 'new program finalized';
const PROGRAM_NEW_SAVED = 'new program saved';
const PROGRAM_COMPLETED_CREATED = 'completed program saved later';
const PROGRAM_COMPLETED_FINALIZED = 'completed program finalized';
const PROGRAM_COMPLETED_SAVED = 'completed program saved';

const PROGRAM_CLONED = 'Program cloned';
const PROGRAM_DELETED = 'Program deleted';
const PROGRAM_UPDATED = 'Program updated';
const PROGRAM_SHARED = 'Program shared';
const PROGRAM_SUBSCRIBED = 'Program subscribed';

const PROGRAM_LIKED = 'Like program';

const PROGRAM_ITEM_CLICKED = 'Program Item Clicked';
const PROGRAM_ITEM_ADDED = 'Program Item Added';
const PROGRAM_ITEM_REMOVED = 'Program Item Removed';

const PROGRAM_TEMPLATE_ADDED = 'Template added';
const PROGRAM_GOAL_SET = 'Goal set';
const PROGRAM_GAOL_REMOVED = 'Goal removed';

const CLICKED_EGG_CREATE = 'Create Egg Button Clicked';
const JOIN_CLICKED = 'Subscribe Clicked';

const CATEGORY_SEARCH = 'Search Category Clicked';
const CATEGORY_SEARCH_REMOVED = 'Search Category Removed';
const SEARCH_TAKEN = 'Search programs';

if (!window.currentTimestamp) {
  window.currentTimestamp = new Date().getTime();
}

const DEFAULT_OPTIONS = {
  integrations: {
    Amplitude: {
      session_id: window.currentTimestamp,
    },
  },
};

export const addUtmParmas = (url, platform) => {
  if (platform !== 'facebook' && platform !== 'twitter') {
    return url;
  }
  const separator = url.indexOf('?') > -1 ? '&' : '?';

  const utmCampaign = `${encodeURIComponent(
    'utm_campaign'
  )}=${encodeURIComponent('bcult-share')}`;
  const utmMedium = `${encodeURIComponent('utm_medium')}=${encodeURIComponent(
    'shared-social'
  )}`;
  const utmSource = `${encodeURIComponent('utm_source')}=${encodeURIComponent(
    platform
  )}`;
  return `${url}${separator}${utmCampaign}&${utmMedium}&${utmSource}`;
};

const getSegmentInstance = () => {
  return window.analytics;
};

const withSegmentInstance = (fn) => {
  return (...args) => fn(getSegmentInstance(), ...args);
};

const identify = (analytics, userInfo) => {
  const { _id: userId, email, profile } = userInfo;
  analytics.identify(
    userId,
    {
      email,
      ...profile,
    },
    { ...DEFAULT_OPTIONS }
  );
};

const logout = (analytics) => {
  analytics.reset();
};

const track = (analytics, eventName, context, traits) => {
  analytics.track(eventName, context, { ...DEFAULT_OPTIONS });
};

export const SCREEN = {
  OTHER_PROFILE: 'other_profile',
  SEARCH: 'search',
};

export const EVENTS = {
  OTHER_PROFILE_VIEWED,
  REIGSETER_FORM_VIEWED,
  LOGIN_FORM_VIEWED,
  SUBSCRIPTION_FORM_VIEWED,
  USER_SIGNED_UP,
  PROGRAM_CREATED,
  PROGRAM_UPDATED,
  PROGRAM_DELETED,
  PROGRAM_CLONED,
  PROGRAM_SUBSCRIBED,
  PROGRAM_SHARED,
  PROGRAM_LIKED,
  PROGRAM_ITEM_CLICKED,
  PROGRAM_ITEM_ADDED,
  PROGRAM_ITEM_REMOVED,
  JOIN_CLICKED,
  USER_UPGRADED_TO_MASTER,
  USER_DOWNGRADE_FROM_MASTER,
  USER_CONNECT_STRIPE,
  USER_DISCONNECT_STRIPE,
  PROGRAM_NEW_CREATED,
  PROGRAM_NEW_FINALIZED,
  PROGRAM_NEW_SAVED,
  PROGRAM_COMPLETED_CREATED,
  PROGRAM_COMPLETED_FINALIZED,
  PROGRAM_COMPLETED_SAVED,

  CLICKED_EGG_CREATE,
  CATEGORY_SEARCH,
  CATEGORY_SEARCH_REMOVED,
  SEARCH_TAKEN,
  PROGRAM_TEMPLATE_ADDED,
  PROGRAM_GOAL_SET,
  PROGRAM_GAOL_REMOVED,
};

export default {
  identify: withSegmentInstance(identify),
  reset: withSegmentInstance(logout),
  track: withSegmentInstance(track),
};
