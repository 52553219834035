/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Box, Checkbox, SvgIcon, SmallDropdown } from 'components/core';
import WeightChart from './WeightChart';
import WellnessChart from './WellnessChart';
import StrengthChart from './StrengthChart';
import FitnessChart from './FitnessChart';
import DietChart from './DietChart';

import ReportActions, { ReportSelectors } from 'redux/ReportRedux';
import { PROGRAM_ACTION_TYPES, UNIT_OPTIONS } from 'services/terms';
import useViewport from 'hooks/useViewport';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const unitMap = {
  fitness: 'minutes',
  weight: 'bmi',
  strength: 'lbs',
  diet: 'calories',
};

const getContainerSize = (width) => {
  if (width > 768) {
    return 1080;
  }

  return 320;
};

const CHARTS = {
  weight: WeightChart,
  wellness: WellnessChart,
  strength: StrengthChart,
  fitness: FitnessChart,
  diet: DietChart,
};

const ReportProgressView = ({ isLoading, loadData, recordData, ...props }) => {
  const [mode, setMode] = useState('weight');
  const [unit, setUnit] = useState(unitMap);

  const { width } = useViewport();
  const containerSize = getContainerSize(width);

  const isBigGraph = containerSize > 1070;

  useEffect(() => {
    loadData();
  }, []);

  const handleSelectMode = (newMode) => {
    setMode(newMode);
    const currentUnitOptions = UNIT_OPTIONS[newMode];
    if (currentUnitOptions) {
      setUnit({
        ...unit,
        [newMode]: currentUnitOptions[0].value,
      });
    }
  };

  const handleChangeUnit = (newUnit, forMode) => {
    setUnit({
      ...unit,
      [forMode]: newUnit.value,
    });
  };

  const Chart = CHARTS[mode];

  return (
    <Wrapper p={4} {...props}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignSelf="stretch"
      >
        {PROGRAM_ACTION_TYPES.map((item) => {
          const unitOptions = UNIT_OPTIONS[item];
          const currentUnit = unit[item];
          return (
            <Box
              display="flex"
              flexDirection={isBigGraph ? 'row' : 'column'}
              alignItems="center"
              key={item}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                alignSelf="stretch"
                key={item}
              >
                <Checkbox
                  size={isBigGraph ? 'lm' : 'sm'}
                  checked={mode === item}
                  onChange={() => handleSelectMode(item)}
                />
                <SvgIcon
                  ml={isBigGraph ? 3 : 0}
                  name={`fit-${item}`}
                  width={isBigGraph ? 56 : 32}
                  height={isBigGraph ? 56 : 32}
                />
              </Box>
              {unitOptions && (
                <SmallDropdown
                  value={currentUnit}
                  options={unitOptions}
                  onChange={(newOption) => handleChangeUnit(newOption, item)}
                />
              )}
            </Box>
          );
        })}
      </Box>

      {Chart && (
        <Chart
          isBigGraph
          currentUnit={unit.weight}
          containerSize={containerSize}
          recordData={recordData}
          sectionType={mode}
          unit={unit[mode]}
        />
      )}
    </Wrapper>
  );
};

const mapStatesToProps = (state) => ({
  isLoading: ReportSelectors.selectLoading(state),
  recordData: ReportSelectors.selectData(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadData: () => dispatch(ReportActions.setReportDataLoadRequest()),
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ReportProgressView);
