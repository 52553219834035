const facebookError = ({ provider, type, description, error }) => {
  return Error(error && error.description ? error.description : description);
};

const facebookScopes = ['public_profile', 'email'].join(',');

/**
 * Loads Facebook SDK.
 * @param {string} appId
 * @param {array|string} scope
 * @see https://developers.facebook.com/docs/javascript/quickstart
 */
export const load = () => {
  const appId = process.env.REACT_APP_FACEBOOK_ID;

  return new Promise((resolve) => {
    // @TODO: handle errors
    if (document.getElementById('facebook-jssdk')) {
      return resolve();
    }

    const firstJS = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');

    js.src = '//connect.facebook.net/en_US/sdk.js';
    js.id = 'facebook-jssdk';

    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        xfbml: true,
        version: 'v5.0',
      });

      return resolve();
    };

    if (!firstJS) {
      document.appendChild(js);
    } else {
      firstJS.parentNode.appendChild(js);
    }
  });
};

/**
 * Gets Facebook user profile if connected.
 * @param {Object} response
 */
export const handleLoginStatus = (response) =>
  new Promise((resolve, reject) => {
    if (!response.authResponse) {
      return reject(
        facebookError({
          provider: 'facebook',
          type: 'auth',
          description: 'Authentication failed',
          error: response,
        })
      );
    }

    switch (response.status) {
      case 'connected':
        resolve({
          ...response.authResponse,
        });
        break;
      case 'not_authorized':
      case 'unknown':
        return reject(
          facebookError({
            provider: 'facebook',
            type: 'auth',
            description:
              'Authentication has been cancelled or an unknown error occurred',
            error: response,
          })
        );
      default:
    }
  });

/**
 * Trigger Facebook login popup.
 * Requires SDK to be loaded first.
 * @see https://developers.facebook.com/docs/reference/javascript/FB.login/v2.9
 */
export const login = () =>
  new Promise((resolve, reject) => {
    window.FB.login(
      (response) => handleLoginStatus(response).then(resolve, reject),
      { scope: facebookScopes }
    );
  });

/**
 * Trigger Facebook logout.
 * Requires SDK to be loaded first.
 * @see https://developers.facebook.com/docs/reference/javascript/FB.logout
 */
export const logout = () =>
  new Promise((resolve) => {
    window.FB.logout(resolve);
  });

/**
 * Helper to generate user account data.
 * @param {Object} response
 */
export const generatePayload = (response) => ({
  token: response.accessToken,
  type: 'Facebook',
});

export const share = (url, description) => {
  return window.FB.ui({
    display: 'popup',
    method: 'share',
    href: url,
    quote: description,
  });
};
