import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colors } from 'styles/theme';

function ProgressLoader({ avatar, ...props }) {
  return (
    <CircularProgressbar
      styles={{
        // Customize the root svg element
        root: {},
        // Customize the path, i.e. the "completed progress"
        path: {
          // Path color
          stroke: colors.blueGray,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
          // Customize transition animation
          transition: 'stroke-dashoffset 0.5s ease 0s',
          // Rotate the path
          transform: 'rotate(0.25turn)',
          transformOrigin: 'center center',
        },
        // Customize the circle behind the path, i.e. the "total progress"
        trail: {
          // Trail color
          stroke: colors.contrastText,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
          // Rotate the trail
          transform: 'rotate(0.25turn)',
          transformOrigin: 'center center',
        },
        // Customize the text
        text: {
          // Text color
          fill: colors.blueGray,
          // Text size
          fontSize: '16px',
        },
        // Customize background - only used when the `background` prop is true
      }}
      {...props}
    />
  );
}

export default ProgressLoader;
