import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { select, put, call, delay } from 'redux-saga/effects';
import get from 'lodash-es/get';
import { subscribeToChannel, unsubscribeFromChannel } from 'services/event';
import Analytics from 'services/analytics';

import API from 'services/api';
import AuthActions from './AuthRedux';

const REFRESH_THRESHOLD = 300000;

export function* refreshAuthToken() {
  const state = yield select();
  const { auth } = state;

  if (!auth.token) {
    return;
  }
  console.log('refreshing token');
  const { token, me } = auth;
  try {
    const { token: newToken } = yield API.getAuthToken(token, me);
    yield put(AuthActions.setLoggedIn(newToken));
  } catch (err) {
    yield put(AuthActions.setLoggedOut());
  }
}

export function* loadUser() {
  yield true;
}

export function* storeToken(action) {
  const { token } = action;
  localStorage.setItem('authToken', token);

  try {
    const decoded = jwtDecode(token);
    const { exp } = decoded;

    const diff = moment(exp).diff(moment());
    const threshHold = Math.max(diff - REFRESH_THRESHOLD, 0);

    API.setAuthHeader(token);

    const user = yield call(API.getMe);
    yield put(AuthActions.setUser(user));

    const userId = get(user, '_id');
    subscribeToChannel(userId);

    yield delay(threshHold);
    yield put(AuthActions.setRefreshAuthRequest());
  } catch (er) {
    console.log('error on decoding auth token');
  }

  yield true;
}

export function* storeUser(action) {
  const { user } = action;
  if (!user) {
    return;
  }

  localStorage.setItem('bUser', JSON.stringify(user));
  Analytics.identify(user);

  yield true;
}

export function* clearToken() {
  Analytics.reset();
  unsubscribeFromChannel();
  API.setAuthHeader(null);
  localStorage.removeItem('authToken');
  localStorage.removeItem('bUser');
  yield true;
}

export function* readNotifications() {
  yield call(API.readNotifications);
  yield true;
}
